import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { PageContainer } from '@/components';
import OffbrandTitleBody, {
  OffbrandTitleBodyBlokProps,
} from '@/blocks/OffbrandTitleBody/OffbrandTitleBody';

export interface OffbrandThreeColumnsTextBlokProps extends SbBlokData {
  title: string;
  items: Array<OffbrandTitleBodyBlokProps>;
}

export interface OffbrandThreeColumnsTextProps {
  blok: OffbrandThreeColumnsTextBlokProps;
}

const OffbrandThreeColumnsText = ({
  blok,
  ...restProps
}: OffbrandThreeColumnsTextProps) => {
  const { title, items } = blok;

  return (
    <section {...storyblokEditable(blok)} className="w-full bg-GSsteel/50 ">
      <PageContainer className="sm:!w-8/12 lg:!w-10/12 2xl:!w-full !py-6 sm:!py-8 lg:!py-10">
        <h2
          className="font-dosis text-GSbase/black font-bold text-2xl text-center
            sm:text-[28px] sm:leading-10
            lg:text-[32px] lg:leading-[48px]"
        >
          {title}
        </h2>
        <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-x-8 sm:gap-y-8 sm:grid-cols-3">
          {items?.map((item: OffbrandTitleBodyBlokProps) => (
            <OffbrandTitleBody blok={item} key={item._uid} {...restProps} />
          ))}
        </div>
      </PageContainer>
    </section>
  );
};

export default OffbrandThreeColumnsText;
