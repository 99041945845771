import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';
import React from 'react';

import { ChevronDownIcon } from '@/icons/ChevronDown';
import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import WebsiteSubject, {
  WebsiteSubjectBlokProps,
} from '@/blocks/WebsiteSubject/WebsiteSubject';
import PageContainer from '@/components/PageContainer/PageContainer';
import StoryblokLink from '@/components/StoryblokLink/StoryblokLink';

export interface WebsiteSubjectsBlokProps extends SbBlokData {
  id?: string;
  seeAllLabel: string;
  subjects: Array<WebsiteSubjectBlokProps>;
  title: Array<TitleBlokProps>;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

export interface WebsiteSubjectsProps {
  blok: WebsiteSubjectsBlokProps;
}

const WebsiteSubjects = ({ blok }: WebsiteSubjectsProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    id,
    seeAllLabel,
    subjects,
    title: [title] = [],
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('w-full bg-white', {
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer>
        {title && <TitleBlok blok={title} headingLevel="h2" />}
        <div className="flex flex-col w-full gap-7 sm:gap-9 lg:gap-[54px]">
          <div
            className="
            grid grid-cols-2 gap-2
            sm:grid-cols-4 sm:gap-4
            lg:grid-cols-6 lg:gap-4 xl:gap-8
            "
          >
            {subjects.slice(0, 12).map((subject) => (
              <WebsiteSubject blok={subject} key={subject._uid} />
            ))}
          </div>
          <div className="flex flex-col w-full">
            <div className="flex flex-col gap-[22px] sm:gap-[30px] lg:gap-[46px]">
              <button
                className="flex items-center justify-center gap-2 text-lg font-medium underline text-GSblue/500"
                type="button"
                onClick={() => setIsOpen(!isOpen)}
              >
                {seeAllLabel}
                <ChevronDownIcon
                  className={cx('stroke-GSblue/600', { 'rotate-180': isOpen })}
                />
              </button>
              <div
                className={cx(
                  'text-gray-500 grid grid-cols-2 gap-2 gap-y-3 sm:grid-cols-4 sm:gap-4 sm:gap-y-5 lg:grid-cols-6 lg:gap-8',
                  {
                    block: isOpen,
                    hidden: !isOpen,
                  },
                )}
              >
                {subjects.slice(12).map((subject) => (
                  <StoryblokLink
                    testId={subject._uid}
                    key={subject._uid}
                    className="text-center underline text-GSblue/500 text-sm lg:text-base"
                    link={subject.link}
                  >
                    {subject.label}
                  </StoryblokLink>
                ))}
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </section>
  );
};

export default WebsiteSubjects;
