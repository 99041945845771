export const CloseIcon = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1565 7.55375C25.3518 7.35849 25.3518 7.0419 25.1565 6.84664C24.9613 6.65138 24.6447 6.65138 24.4494 6.84664L15.9781 15.318L7.55277 6.89263C7.35751 6.69737 7.04093 6.69737 6.84567 6.89263C6.6504 7.08789 6.6504 7.40447 6.84567 7.59974L15.271 16.0251L6.89139 24.4047C6.69613 24.5999 6.69613 24.9165 6.89139 25.1118C7.08665 25.307 7.40324 25.307 7.5985 25.1118L15.9781 16.7322L24.4037 25.1578C24.599 25.353 24.9155 25.353 25.1108 25.1578C25.3061 24.9625 25.3061 24.6459 25.1108 24.4507L16.6852 16.0251L25.1565 7.55375Z"
      fill="#0B2FAC"
    />
  </svg>
);
