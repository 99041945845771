import { getStoryblokApi } from '@storyblok/react';

const API_ROUTES = {
  DATASOURCE_ENTRIES: 'cdn/datasource_entries',
};

export const DATASOURCES_IDS = {
  FORMS: 'forms',
  FAQ: 'faq',
  COUNTRIES: 'countries',
};

type DatasourceEntry = {
  id: number;
  name: string;
  value: string;
  dimension_value: string;
};

export type FormTextDataStructure = {
  [key: string]: string;
};

export type ErrorMessages = {
  [key: string]: string;
};

export const getDatasourceEntry = async (datasourceID: string, language: string) => {
  const storyblokApi = getStoryblokApi();

  // for cache invalidation , { cv: +new Date() }
  const { data } = await storyblokApi.get(`${API_ROUTES.DATASOURCE_ENTRIES}?datasource=${datasourceID}&dimension=${language}&per_page=1000`, { cv: +new Date() });

  const formDataStructure: FormTextDataStructure = data.datasource_entries.reduce(
    (acc: FormTextDataStructure, curr: DatasourceEntry) => ({
      ...acc,
      [curr.name]: curr.dimension_value || curr.value,
    }), {},
  );
  return formDataStructure;
};
