import PersonCard, {
  PersonCardBlokProps,
} from '@/blocks/PersonCard/PersonCard';
// eslint-disable-next-line
import { default as Nuka } from 'nuka-carousel';
import React from 'react';
import { ArrowIcon } from '../../icons/ArrowIcon';

interface CarouselProps {
  items: Array<PersonCardBlokProps>;
  ratingsVisibility: 'show' | 'hide';
  subjectsVisibility: 'show' | 'hide';
}
interface NukkaControlsProps {
  nextSlide: () => void;
  previousSlide: () => void;
}

const PeopleCarousel = ({
  items,
  ratingsVisibility,
  subjectsVisibility,
}: CarouselProps) => {
  const renderCenterCenterControls = (props: NukkaControlsProps) => {
    const { nextSlide, previousSlide } = props;
    return (
      <div className="navigation flex relative top-8">
        <div
          className="cursor-pointer w-8 h-8 sm:w-10 sm:h-10"
          onClick={previousSlide}
        >
          <ArrowIcon />
        </div>
        <div
          className="cursor-pointer rotate-180 w-8 h-8 sm:w-10 sm:h-10"
          onClick={nextSlide}
        >
          <ArrowIcon />
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full items-center mb-8 gap-2 md:gap-4 md:mb-10 lg:gap-8">
      <Nuka
        cellAlign="center"
        slidesToShow={1}
        cellSpacing={32}
        keyCodeConfig={{ firstSlide: [0] }}
        wrapAround
        defaultControlsConfig={{
          pagingDotsClassName: 'hidden',
          nextButtonClassName: 'hidden',
          prevButtonClassName: 'hidden',
        }}
        renderBottomRightControls={renderCenterCenterControls}
        renderBottomCenterControls={() => false}
        renderCenterCenterControls={() => false}
      >
        {items.map((nestedBlok) => (
          <PersonCard
            key={nestedBlok._uid}
            blok={nestedBlok}
            ratingVisibility={ratingsVisibility}
            subjectVisibility={subjectsVisibility}
          />
        ))}
      </Nuka>
    </div>
  );
};

export default PeopleCarousel;
