import { ParsedUrlQuery } from 'querystring';

export const fillWithUrlParams = (
  text: string,
  urlQuery: ParsedUrlQuery,
  removeUnused: boolean = true,
): string => {
  const mustacheRegex = /{{\s*(.*?)\s*}}/g;
  const foundVariables = text.matchAll(mustacheRegex);

  const paramValueMap: Record<string, string> = {};
  let textVariable;

  do {
    textVariable = foundVariables.next();

    if (textVariable && textVariable.value) {
      const [paramNameInMustache, paramName] = textVariable.value;

      const value = urlQuery[paramName];

      if (value) {
        paramValueMap[paramNameInMustache] = Array.isArray(value)
          ? value.toString()
          : value;
      } else if (removeUnused) {
        paramValueMap[paramNameInMustache] = '';
      }
    }
  } while (!textVariable.done);

  if (Object.keys(paramValueMap).length) {
    return Object.entries(paramValueMap).reduce((memo, [key, value]) => {
      return memo.replace(key, value);
    }, text);
  }

  return text;
};
