import { SbBlokData } from '@storyblok/react';
import cx from 'classnames';

export interface FullscreenController {
  deviceClassNames: string;
  isMobileFullscreen: boolean;
  isTabletFullscreen: boolean;
  isDesktopFullscreen: boolean;
}

interface FullscreenControllerBlokProps extends SbBlokData {
  desktop?: 'enabled' | 'disabled' | '';
  fullscreen?: 'enabled' | 'disabled' | 'device' | '';
  mobile?: 'enabled' | 'disabled' | '';
  tablet?: 'enabled' | 'disabled' | '';
}

interface FullscreenControllerProps {
  fullscreen: Array<FullscreenControllerBlokProps>;
}

const isTruthy = (value?: string) => value === 'enabled';

const isTruthyForAnyDevice = ({
  desktop,
  mobile,
  tablet,
  fullscreen,
}: FullscreenControllerBlokProps) => {
  if (fullscreen !== 'device') {
    return isTruthy(fullscreen);
  }

  return isTruthy(mobile) || isTruthy(tablet) || isTruthy(desktop);
};

const buildDeviceClassNames = (props: FullscreenControllerBlokProps) => {
  const { desktop, fullscreen, mobile, tablet } = props;

  switch (fullscreen) {
    case 'enabled':
      return 'min-h-screen';
    case 'device':
      return cx({
        'min-h-screen':
          isTruthy(fullscreen) || (fullscreen === 'device' && isTruthy(mobile)),
        'sm:min-h-screen': fullscreen === 'device' && isTruthy(tablet),
        'lg:min-h-screen': fullscreen === 'device' && isTruthy(desktop),
        'min-h-0':
          fullscreen === 'device' &&
          !isTruthy(mobile) &&
          isTruthyForAnyDevice(props),
        'sm:min-h-0':
          fullscreen === 'device' &&
          !isTruthy(tablet) &&
          isTruthyForAnyDevice(props),
        'lg:min-h-0':
          fullscreen === 'device' &&
          !isTruthy(desktop) &&
          isTruthyForAnyDevice(props),
      });
    case 'disabled':
    default:
      return '';
  }
};

export const useFullscreenController = (
  componentBlok: SbBlokData,
): FullscreenController => {
  // fallback in case some old boolean fullscreen prop is used
  if (typeof componentBlok.fullscreen === 'boolean') {
    return {
      deviceClassNames: buildDeviceClassNames({
        fullscreen: componentBlok.fullscreen ? 'enabled' : 'disabled',
      }),
      isMobileFullscreen: false,
      isTabletFullscreen: false,
      isDesktopFullscreen: false,
    };
  }

  const { fullscreen: [fullscreenBlok] = [] } =
    componentBlok as unknown as FullscreenControllerProps;

  const {
    desktop = 'disabled',
    fullscreen = 'disabled',
    mobile = 'disabled',
    tablet = 'disabled',
  } = (fullscreenBlok as FullscreenControllerBlokProps) || {};

  return {
    deviceClassNames: buildDeviceClassNames({
      fullscreen,
      mobile,
      tablet,
      desktop,
    }),
    isMobileFullscreen:
      isTruthy(fullscreen) || (fullscreen === 'device' && isTruthy(mobile)),
    isTabletFullscreen:
      isTruthy(fullscreen) || (fullscreen === 'device' && isTruthy(tablet)),
    isDesktopFullscreen:
      isTruthy(fullscreen) || (fullscreen === 'device' && isTruthy(desktop)),
  };
};
