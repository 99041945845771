export const StarIcon = ({ fillPercent = 100 }: { fillPercent?: number }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
    role="img"
  >
    <defs>
      <mask id={`mask${fillPercent}`}>
        <rect x="0" y="0" width="20" height="20" fill="white" />
        <rect x={`${fillPercent}%`} y="0" width="20" height="20" fill="black" />
      </mask>
    </defs>
    <path
      mask={`url(#mask${fillPercent})`}
      d="M10.5001 1.66669L13.0751 6.88335L18.8334 7.72502L14.6667 11.7834L15.6501 17.5167L10.5001 14.8084L5.35008 17.5167L6.33341 11.7834L2.16675 7.72502L7.92508 6.88335L10.5001 1.66669Z"
      fill="#9F80FF"
    />
  </svg>
);
