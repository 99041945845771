export const ScrollIcon = ({
  firstPartClassName,
  secondPartClassName,
  scrollPosition,
}: {
  firstPartClassName: string;
  secondPartClassName: string;
  scrollPosition: 'left' | 'right';
}) => (
  <svg
    className="max-w-full"
    viewBox="0 0 356 364"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMinYMid meet"
    role="img"
    style={{
      transform: scrollPosition === 'right' ? 'scale(-1, 1)' : 'scale(1, 1)',
    }}
  >
    <path
      d="M295.397 204.402C295.397 204.402 295.427 204.495 295.443 204.542C298.054 215.326 298.959 226.045 298.103 236.525C297.38 245.512 295.361 254.058 292.105 262.074C292.199 261.871 292.281 261.689 292.391 261.48C291.795 262.917 291.194 264.338 290.53 265.728C289.103 268.747 287.491 271.689 285.71 274.547C277.935 286.865 268.638 298.212 258.099 308.253C247.814 318.039 236.206 326.703 223.586 334.004C219.365 336.448 215.008 338.747 210.547 340.889C201.919 345.044 192.879 348.642 183.53 351.617C169.463 356.109 154.58 359.245 139.294 360.975C123.986 362.695 108.146 363.001 92.204 361.907C76.1246 360.771 59.7899 358.199 43.6617 354.249C27.2421 350.22 10.871 344.709 -5.01242 337.865C-21.2869 330.854 -37.2361 322.34 -52.4123 312.608L4.13701 192.754C19.2585 202.591 35.2186 211.085 51.5143 218.105C67.3817 224.954 83.7579 230.481 100.193 234.505C116.327 238.471 132.677 241.037 148.768 242.152C164.715 243.262 180.55 242.941 195.857 241.221C211.181 239.495 226.08 236.355 240.131 231.867C249.496 228.888 258.536 225.29 267.148 221.14C271.625 218.992 275.977 216.678 280.203 214.25C285.465 211.191 290.554 207.929 295.439 204.422L295.397 204.402Z"
      className={firstPartClassName}
    />
    <path
      d="M349.061 75.0857C353.789 89.0994 355.614 103.074 354.478 116.614C353.621 127.147 351.007 137.077 346.676 146.255C346.061 147.577 345.441 148.884 344.804 150.247C328.819 184.157 308.388 227.488 292.354 261.465C295.572 253.495 297.179 245.153 297.912 236.197C298.768 225.769 295.938 206.701 295.382 204.396C294.604 201.249 293.683 198.096 292.639 194.949C287.906 180.92 280.574 167.564 270.759 155.241C260.62 142.47 248.304 131.393 234.129 122.284L290.662 2.43551C304.772 11.6174 317.084 22.6793 327.233 35.4291C337.027 47.7421 344.359 61.0979 349.066 75.1013L349.061 75.0857Z"
      className={secondPartClassName}
    />
  </svg>
);
