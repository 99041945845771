import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import { SeoLinksGroupBlokProps } from '../SeoLinksGroup/SeoLinksGroup';
import TitleBlok, { TitleBlokProps } from '../Title/Title';
import { ScrollIcon } from './Icons';

export interface SeoLinksSectionBlokProps extends SbBlokData {
  title?: Array<TitleBlokProps>;
  groups: Array<SeoLinksGroupBlokProps>;
  id?: string;
}

interface SeoLinksSectionProps {
  blok: SeoLinksSectionBlokProps;
}

const SeoLinksSection = ({ blok, ...restProps }: SeoLinksSectionProps) => {
  const { title: [title] = [], groups, id } = blok;
  return (
    <div
      className="bg-GSblue/50 sm:py-20 relative"
      {...storyblokEditable(blok)}
      id={id}
    >
      <ScrollIcon />
      {title && <TitleBlok blok={title} headingLevel="h2" />}
      {groups.map((nestedBlok: SbBlokData) => (
        <StoryblokComponent
          blok={nestedBlok}
          key={nestedBlok._uid}
          {...restProps}
        />
      ))}
    </div>
  );
};

export default SeoLinksSection;
