import React from 'react';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

import LanguageSwitcherComponent from '../../components/LanguageSwitcher/LanguageSwitcher';
import { SSGDataContext } from '../../helpers/contexts';

interface LanguageSwitcherItemBlokProps extends SbBlokData {
  label: string;
  language: string;
  overrideUrl: string;
}

export interface LanguageSwitcherBlokProps extends SbBlokData {
  items: Array<LanguageSwitcherItemBlokProps>;
  keepUrlParams: boolean;
  title: string;
}

interface LanguageSwitcherProps {
  blok: LanguageSwitcherBlokProps;
}

const LanguageSwitcher = ({ blok }: LanguageSwitcherProps) => {
  const { alternateUrls = [] } = React.useContext(SSGDataContext);
  const [query, setQuery] = React.useState<string>('');
  const { items, keepUrlParams, title } = blok;

  React.useEffect(() => {
    if (window) {
      const params = new URL(window.location.href).searchParams.toString();
      setQuery(params);
    }
  }, []);

  // Combines input data items: { label: 'English', lang: 'en-gb' }
  // with alternate urls data like { hrefLang: 'en-gb', href: 'https://gostudent.org/en-gb/prices' }
  // the result is: { label: 'English', href: 'https://gostudent.org/en-gb/prices' }
  const targetItems = React.useMemo(() => {
    return items.map(({ label, language, overrideUrl }) => {
      const alternateUrl = alternateUrls.find(
        ({ hrefLang }) => hrefLang === language,
      );
      let href = `/${language}/${alternateUrl ? `${alternateUrl.slug}` : ''}`;

      if (!href.endsWith('/')) {
        href += '/';
      }

      if (overrideUrl) {
        href = overrideUrl;
      }

      return {
        label,
        href: `${href}${keepUrlParams && query ? `?${query}` : ''}`,
      };
    });
  }, [alternateUrls, items, query]);

  return (
    <div {...storyblokEditable(blok)}>
      <LanguageSwitcherComponent items={targetItems} title={title} />
    </div>
  );
};

export default LanguageSwitcher;
