import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { PageContainer } from '@/components';
import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import ImageCard, { ImageCardBlokProps } from '../ImageCard/ImageCard';

export interface TeamPicturesBlokProps extends SbBlokData {
  title: TitleBlokProps[];
  founders: ImageCardBlokProps[];
  team: ImageCardBlokProps[];
}

interface TeamPicturesProps {
  blok: TeamPicturesBlokProps;
}

const TeamPictures = ({ blok }: TeamPicturesProps) => {
  const { title: [title] = [], founders, team } = blok;

  return (
    <section {...storyblokEditable(blok)} className="w-full">
      <PageContainer>
        <div className="w-full grid grid-cols-6 sm:grid-cols-12 gap-x-2 gap-y-4 sm:gap-x-4 sm:gap-y-6 lg:gap-x-8 lg:gap-y-10">
          {title && (
            <div className="col-span-6 sm:col-span-10 sm:col-start-2 lg:col-span-8 lg:col-start-3">
              <TitleBlok blok={title} headingLevel="h2" />
            </div>
          )}
          <div className="flex flex-col col-span-6 sm:col-span-10 sm:col-start-2 lg:col-span-8 lg:col-start-3 gap-y-4 lg:gap-y-8">
            {founders && founders.length && (
              <div className="grid sm:grid-cols-2 gap-y-5 sm:gap-x-4 lg:gap-8">
                {founders?.map((founder) => (
                  <ImageCard blok={founder} key={founder._uid} />
                ))}
              </div>
            )}
            {team && team.length && (
              <div className="grid grid-cols-6 sm:grid-cols-12 gap-2 sm:gap-4 lg:gap-8">
                {team?.map((member) => (
                  <div
                    className="col-span-3 sm:col-span-4 grid gap-2 sm:gap-8"
                    key={member._uid}
                  >
                    <ImageCard blok={member} type="small" />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </PageContainer>
    </section>
  );
};

export default TeamPictures;
