import cloneDeep from 'clone-deep';
import { RichTextSchema } from '@storyblok/react';

const mpcRichTextSchema = cloneDeep(RichTextSchema);

mpcRichTextSchema.nodes.paragraph = () => {
  return {
    tag: [
      {
        tag: 'span',
        attrs: {
          class: 'text-GSbase/black',
        },
      },
    ],
  };
};

mpcRichTextSchema.marks.highlight = () => {
  return {
    tag: [
      {
        tag: 'span',
        attrs: {
          class: 'text-GSgreen/500',
        },
      },
    ],
  };
};

mpcRichTextSchema.marks.textStyle = () => {
  // we do not support changing text color
  return {
    tag: '',
  };
};

export default mpcRichTextSchema;
