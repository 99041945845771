import { storyblokEditable, SbBlokData } from '@storyblok/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import StoryblokImage from '../../components/StoryblokImage/StoryblokImage';
import PageContainer from '../../components/PageContainer/PageContainer';

export interface BrandBannerSectionBlokProps extends SbBlokData {
  id?: string;
  logos: Array<Asset>;
}

export interface BrandBannerSectionProps {
  blok: BrandBannerSectionBlokProps;
}

const BrandBannerSection = ({ blok }: BrandBannerSectionProps) => {
  const { id, logos } = blok;

  return (
    <section {...storyblokEditable(blok)} className="bg-GSblue/50" id={id}>
      <PageContainer className="w-full grid grid-cols-6 sm:grid-cols-12 !py-6 sm:!py-4 lg:!py-4">
        <div className="w-full col-span-full lg:col-span-10 lg:col-start-2">
          <Swiper
            slidesPerView={3}
            spaceBetween={24}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop
            modules={[Autoplay]}
            breakpoints={{
              640: {
                slidesPerView: 6,
                spaceBetween: 32,
              },
            }}
          >
            {logos.map((nestedBlok) => (
              <SwiperSlide key={nestedBlok.id}>
                <StoryblokImage blok={nestedBlok} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </PageContainer>
    </section>
  );
};

export default BrandBannerSection;
