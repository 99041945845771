import TextInput from '@/components/TextInput/TextInput';
import { FormDataType } from '@/helpers/form/formHelpersTypes';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';

const FIELD_ID = 'email';

export const FieldData: FormDataType = {
  hubspotId: 'email',
  id: FIELD_ID,
  type: 'text',
  validationType: 'string',
  validations: [
    {
      type: 'matches',
      key: 'email_error_message_valid',
      params: [/^\S+@\S+\.\S+$/],
    },
  ],
};

export interface FormEmailFieldBlokProps extends SbBlokData {
  label: string;
  placeholder: string;
  readOnly: boolean;
  formikErrors: FormikErrors<{ [FIELD_ID]: string }>;
  touched: FormikTouched<{ [FIELD_ID]: boolean }>;
}

interface FormEmailFieldProps {
  blok: FormEmailFieldBlokProps;
  field: FieldInputProps<string>;
  isTutorForm?: boolean;
}

const FormEmailField = ({
  blok,
  field,
  isTutorForm = false,
}: FormEmailFieldProps) => {
  const { label, placeholder, formikErrors, touched, readOnly } = blok;

  return (
    <div {...storyblokEditable(blok)}>
      <TextInput
        type="text"
        placeholder={placeholder}
        error={
          formikErrors[FIELD_ID] && touched[FIELD_ID]
            ? formikErrors[FIELD_ID]
            : ''
        }
        {...field}
        label={label}
        disabled={readOnly}
        isTutorForm={isTutorForm}
      />
    </div>
  );
};

export default FormEmailField;
