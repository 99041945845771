import React from 'react';
import {
  ISbRichtext,
  SbBlokData,
  renderRichText,
  storyblokEditable,
} from '@storyblok/react';
import {
  Field,
  FieldProps,
  FormikErrors,
  FormikProps,
  FormikTouched,
  FormikValues,
} from 'formik';

import Checkbox from '../../components/Checkbox/Checkbox';
import HubspotScrapperField from '../../components/HubspotScrapperField/HubspotScrapperField';
import { FormDataType } from '../../helpers/form/formHelpersTypes';
import richTextSchema from './richTextSchema';

const FIELD_ID = 'agreedToTC';

export const FieldData: FormDataType = {
  hubspotId: 'agreed_to_t_c',
  id: FIELD_ID,
  type: 'boolean',
  validationType: 'bool',
};

export interface FormTermsAndConditionsTutorFieldBlokProps extends SbBlokData {
  formikErrors: FormikErrors<{ [FIELD_ID]: string }>;
  formikProps: FormikProps<FormikValues>;
  readOnly: boolean;
  richTextLabel?: ISbRichtext;
  touched: FormikTouched<{ [FIELD_ID]: boolean }>;
}

interface FormTermsAndConditionsTutorFieldProps extends FieldProps {
  blok: FormTermsAndConditionsTutorFieldBlokProps;
}

const FormTermsAndConditionsTutorField = ({
  blok,
  field,
}: FormTermsAndConditionsTutorFieldProps) => {
  const { formikErrors, touched, readOnly, richTextLabel, formikProps } = blok;

  React.useEffect(() => {
    formikProps.getFieldHelpers('muttersprachenC2Niveao').setValue(field.value);
    formikProps
      .getFieldHelpers('allowedToWorkWithMinors')
      .setValue(field.value);
    formikProps.getFieldHelpers('n18YearsOrOlderTutor').setValue(field.value);
  }, []);

  const renderLabel = () =>
    richTextLabel && (
      <div
        className="break-words min-w-0 flex flex-col lg:text-base font-medium"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: renderRichText(richTextLabel, { schema: richTextSchema }),
        }}
      />
    );

  const error =
    formikErrors[FIELD_ID] && touched[FIELD_ID] ? formikErrors[FIELD_ID] : '';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formikProps
      .getFieldHelpers('muttersprachenC2Niveao')
      .setValue(event.currentTarget.checked);
    formikProps
      .getFieldHelpers('allowedToWorkWithMinors')
      .setValue(event.currentTarget.checked);
    formikProps
      .getFieldHelpers('n18YearsOrOlderTutor')
      .setValue(event.currentTarget.checked);
    field.onChange(event);
  };

  return (
    <div
      {...storyblokEditable(blok)}
      className="w-full flex flex-col gap-y-2 lg:gap-y-4"
    >
      <HubspotScrapperField
        name="muttersprachen_c2_niveao"
        value={field.value}
      />
      <Field name="muttersprachenC2Niveao">
        {({ field: hiddenField }: FieldProps) => (
          <input
            {...hiddenField}
            className="hidden"
            id="muttersprachenC2Niveao"
            value={field.value}
          />
        )}
      </Field>

      <HubspotScrapperField
        name="allowed_to_work_with_minors_"
        value={field.value ? 'Yes' : 'No'}
      />
      <Field name="allowedToWorkWithMinors">
        {({ field: hiddenField }: FieldProps) => (
          <input
            {...hiddenField}
            className="hidden"
            id="allowedToWorkWithMinors"
            value={field.value}
          />
        )}
      </Field>

      <HubspotScrapperField
        name="n18_years_or_older__tutor_"
        value={field.value}
      />
      <Field name="n18YearsOrOlderTutor">
        {({ field: hiddenField }: FieldProps) => (
          <input
            {...hiddenField}
            className="hidden"
            id="n18YearsOrOlderTutor"
            value={field.value}
          />
        )}
      </Field>

      <HubspotScrapperField name={FieldData.hubspotId} value={field.value} />
      <Checkbox
        defaultChecked={field.value}
        disabled={readOnly}
        label={renderLabel()}
        name={FIELD_ID}
        onChange={handleChange}
        isTutorForm
      />
      {error && (
        <div className="text-xs text-GSfunctional/error font-medium">
          {error}
        </div>
      )}
    </div>
  );
};

export default FormTermsAndConditionsTutorField;
