import React from 'react';

import {
  fallbackLink,
  languageTagToLanguage,
} from '@/components/TrustpilotWidget/utils';
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import { SbBlokData } from '@storyblok/react';

export interface TrustpilotBadgeBlokProps extends SbBlokData {
  fallbackImage?: Asset;
  language: string;
}

interface TrustpilotBadgeProps {
  blok: TrustpilotBadgeBlokProps;
}

const TrustpilotBadge = ({ blok }: TrustpilotBadgeProps) => {
  const { language, fallbackImage } = blok;

  React.useEffect(() => {
    if (!document.getElementById('trustpilot-widget-script')) {
      const script = document.createElement('script');
      script.setAttribute('id', 'trustpilot-widget-script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('async', 'true');
      script.setAttribute(
        'src',
        '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
      );

      document.body.appendChild(script);
    }
  }, []);

  return (
    <div
      className="trustpilot-widget min-h-20 h-20 max-h-20"
      data-locale="en-GB"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5b9bd0cf3738d6000130250d"
      data-style-height="80px"
      data-style-width="120px"
      data-theme="light"
      data-stars="4,5"
      data-review-languages={languageTagToLanguage(language)}
    >
      <div className="flex justify-center bg-white text-black w-full">
        <a
          href={fallbackLink(language)}
          target="_blank"
          rel="noreferrer noopener"
        >
          {fallbackImage && fallbackImage.filename ? (
            <StoryblokImage
              blok={fallbackImage}
              fallbackAlt="Trustpilot"
              className="h-20 max-h-20"
            />
          ) : (
            <span>Trustpilot</span>
          )}
        </a>
      </div>
    </div>
  );
};

export default TrustpilotBadge;
