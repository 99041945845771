export const CheckIcon = ({ className }: { className?: string }) => (
  <svg className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0,16a16,16 0 1,0 32,0a16,16 0 1,0 -32,0" fill="#fff" />
    <mask id="a" maskUnits="userSpaceOnUse" x="4" y="5" width="24" height="25">
      <path fill="#D9D9D9" d="M4 5.033h24v24H4z" />
    </mask>
    <g mask="url(#a)">
      <path
        d="m13.55 23.033-5.7-5.7 1.425-1.425 4.275 4.275 9.175-9.175 1.425 1.425-10.6 10.6Z"
        fill="#4A9A6F"
      />
    </g>
  </svg>
);
