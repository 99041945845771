/* eslint-disable jsx-a11y/control-has-associated-label */
import cx from 'classnames';
import { CloseIcon } from '../../icons/Close';

interface DrawerProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

const Drawer = ({ children, isOpen, onClose, title }: DrawerProps) => (
  <nav
    className={cx('z-[100] lg:hidden', {
      block: isOpen,
      hidden: !isOpen,
    })}
  >
    <div className="fixed inset-0 bg-black bg-opacity-25" />
    <div className="fixed inset-0 overflow-y-auto font-worksans">
      <div className="flex min-h-full items-center justify-center p-4 text-center">
        <div className="absolute right-0 top-0 bottom-0 w-full max-w-md transform overflow-hidden bg-white text-left shadow-xl transition-all">
          <div className="flex justify-between items-center py-3 px-4 sm:p-5 sm:pl-6 border-b border-GSparchment/200">
            <span className="text-base sm:text-lg font-bold text-GSdeep/500">
              {title}
            </span>
            <button
              type="button"
              className="select-none border-none focus:outline-none"
              onClick={onClose}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="flex flex-col flex-wrap py-4 sm:py-6">{children}</div>
        </div>
      </div>
    </div>
  </nav>
);

export default Drawer;
