import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import { BenefitImageScroll } from './BenefitImageScroll';

export interface BenefitImageBlokProps extends SbBlokData {
  image?: Asset;
  mobileImage?: Asset;
  alignment: 'edge' | 'center' | 'cover';
  backgroundScroll: 'enabled' | 'disabled';
  position?:
    | 'bottom'
    | 'center'
    | 'left'
    | 'left bottom'
    | 'left top'
    | 'right'
    | 'right bottom'
    | 'right top'
    | 'top';
  scrollColorVariant: 'GSgreen/500' | 'GSyellow/500';
  visibleOnMobile: 'enabled' | 'disabled';
}

interface BenefitImageProps {
  blok: BenefitImageBlokProps;
  parentLayoutDirection?: 'default' | 'reversed';
}

const BenefitImage = ({ blok, parentLayoutDirection }: BenefitImageProps) => {
  const {
    alignment,
    backgroundScroll,
    image,
    mobileImage,
    position,
    scrollColorVariant,
  } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="grid gap-2 grid-cols-6 h-full w-full relative"
    >
      {backgroundScroll === 'enabled' && (
        <div
          className={cx(
            'self-center col-span-4 sm:col-span-full row-start-1 sm:col-start-1',
            {
              'col-start-3': parentLayoutDirection === 'reversed',
              'col-start-1': parentLayoutDirection === 'default',
            },
          )}
          data-testid="Scroll image"
        >
          <BenefitImageScroll
            firstPartClassName="fill-GSdarkpurple/500"
            secondPartClassName={cx({
              'fill-GSgreen/500': scrollColorVariant === 'GSgreen/500',
              'fill-GSyellow/500': scrollColorVariant === 'GSyellow/500',
            })}
            isReversed={parentLayoutDirection === 'reversed'}
          />
        </div>
      )}
      {image && (
        <StoryblokImage
          className={cx('rounded sm:rounded-lg', {
            'col-span-6 col-start-1 row-start-1 m-auto':
              backgroundScroll === 'disabled',
            'col-span-3 col-end-6 mt-auto mb-0 row-start-1':
              backgroundScroll === 'enabled' &&
              parentLayoutDirection === 'default',
            'col-span-3 col-start-2 mt-auto mb-0 row-start-1':
              backgroundScroll === 'enabled' &&
              parentLayoutDirection === 'reversed',
            'sm:ml-auto sm:mr-0':
              (alignment === 'center' && parentLayoutDirection === 'default') ||
              (alignment === 'edge' && parentLayoutDirection === 'reversed'),
            'hidden sm:block':
              backgroundScroll === 'enabled' ||
              (mobileImage && mobileImage.filename),
            'object-cover h-full w-full sm:absolute': alignment === 'cover',
            'object-bottom': position === 'bottom' && alignment === 'cover',
            'object-center': position === 'center' && alignment === 'cover',
            'object-left': position === 'left' && alignment === 'cover',
            'object-left-bottom':
              position === 'left bottom' && alignment === 'cover',
            'object-left-top': position === 'left top' && alignment === 'cover',
            'object-right': position === 'right' && alignment === 'cover',
            'object-right-bottom':
              position === 'right bottom' && alignment === 'cover',
            'object-right-top':
              position === 'right top' && alignment === 'cover',
            'object-top': position === 'top' && alignment === 'cover',
          })}
          blok={image}
        />
      )}
      {mobileImage && (
        <StoryblokImage
          className={cx(
            'col-span-6 col-start-1 row-start-1 m-auto sm:hidden rounded sm:rounded-lg',
            {
              'sm:ml-auto':
                (alignment === 'center' &&
                  parentLayoutDirection === 'default') ||
                (alignment === 'edge' && parentLayoutDirection === 'reversed'),
              hidden: backgroundScroll === 'enabled',
              'object-cover h-full w-full': alignment === 'cover',
            },
          )}
          blok={mobileImage}
        />
      )}
    </div>
  );
};

export default BenefitImage;
