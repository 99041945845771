import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

export interface HeroContentBoxTestimonialBlokProps extends SbBlokData {
  author: string;
  quote: string;
}

interface HeroContentBoxTestimonialProps {
  blok: HeroContentBoxTestimonialBlokProps;
  backgroundColor: 'dark' | 'light';
}

const HeroContentBoxTestimonial = ({
  blok,
  backgroundColor = 'light',
}: HeroContentBoxTestimonialProps) => {
  const { author, quote } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx(
        'flex break-words gap-2 sm:gap-4 flex-col text-base sm:text-lg lg:text-xl',
        {
          'text-GSdeep/900': backgroundColor === 'light',
          'text-GSbase/white': backgroundColor === 'dark',
        },
      )}
    >
      <div className="font-bold">{author}</div>
      <div className="font-normal whitespace-pre-wrap">{quote}</div>
    </div>
  );
};

export default HeroContentBoxTestimonial;
