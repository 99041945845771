import PageContainer from '@/components/PageContainer/PageContainer';
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';

export interface OffbrandHeaderBlokProps extends SbBlokData {
  actions?: Array<CtaButtonBlokProps>;
  label: string;
  logoImage: Asset;
}

interface OffbrandHeaderProps {
  blok: OffbrandHeaderBlokProps;
}

const OffbrandHeader = ({ blok }: OffbrandHeaderProps) => {
  const { actions, label, logoImage } = blok;

  return (
    <nav {...storyblokEditable(blok)} className="w-full">
      <PageContainer className="!py-2 sm:!py-4">
        <div
          className="
          w-full
          flex
          flex-row
          items-center
          font-dosis
          gap-x-2
          font-extrabold
          text-base
          text-GSbase/black
          "
        >
          {logoImage && logoImage.filename && (
            <StoryblokImage
              blok={logoImage}
              fallbackSrc="/assets/image.svg"
              className="h-8 sm:h-10 lg:h-12"
              lazyLoading={false}
            />
          )}
          <div>{label}</div>
          {actions && actions.length > 0 && (
            <div className="ml-auto flex">
              {actions.map((ctaBlok) => (
                <StoryblokComponent
                  blok={ctaBlok}
                  size="btn-XS"
                  key={ctaBlok._uid}
                />
              ))}
            </div>
          )}
        </div>
      </PageContainer>
    </nav>
  );
};

export default OffbrandHeader;
