import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';
import useResponsive from '@/helpers/layout/useResponsive';

import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import Rating from '@/components/Rating/Rating';
import CtaLink, { CtaLinkBlokProps } from '../CtaLink/CtaLink';

export interface TutorProfileBlokProps extends SbBlokData {
  description: string;
  image: Asset;
  name: string;
  rating: number;
  subjects: string;
  viewProfileButton?: Array<CtaLinkBlokProps>;
}

interface TutorProfileProps {
  blok: TutorProfileBlokProps;
  className?: string;
}

const TutorProfile = ({ blok, className }: TutorProfileProps) => {
  const { isMobile, isTablet } = useResponsive();

  const {
    description,
    image,
    name,
    rating,
    subjects,
    viewProfileButton: [viewProfileButton] = [],
  } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx(
        'flex flex-col col-span-full sm:col-span-4 lg:col-span-3 bg-GSbase/white border border-GSpurple/500 rounded-lg break-words',
        className,
      )}
    >
      <div className="rounded-lg overflow-hidden">
        <StoryblokImage
          className="aspect-square w-full object-cover"
          blok={image}
        />
      </div>
      <div className="flex flex-col gap-y-2 sm:gap-y-4 p-4 sm:p-6 lg:p-8 flex-grow">
        <div className="flex flex-col gap-y-1">
          <div className="text-GSdeep/900 font-bold text-base lg:text-lg">
            {name}
          </div>
          <div className="text-GSdeep/900 font-normal text-sm lg:text-base whitespace-pre-wrap line-clamp-3">
            {description}
          </div>
        </div>
        <div className="text-GSdeep/900 font-bold text-sm lg:text-base">
          {subjects}
        </div>
        <div className="flex flex-col flex-grow">
          <div className="flex-grow" />
          <div className="flex justify-between items-center flex-wrap">
            <Rating value={rating} />
            {viewProfileButton && (
              <div>
                <CtaLink
                  size={isMobile || isTablet ? 'btn-XS' : 'btn-SM'}
                  blok={viewProfileButton}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorProfile;
