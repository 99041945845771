import { ComponentType } from 'react';
import cx from 'classnames';

export interface TitleProps {
  alignment?: 'left' | 'center' | 'right' | '';
  header: string;
  headingLevel?: ComponentType | keyof JSX.IntrinsicElements;
  subheader?: string;
  layout?: 'default' | 'full' | '';
  children?: React.ReactNode;
  theme?: 'default' | 'ideal' | '';
  topic?: string;
}

const Title = ({
  alignment = 'center',
  children,
  headingLevel,
  header,
  subheader,
  layout = 'default',
  theme,
  topic,
}: TitleProps) => {
  const Tag = headingLevel || 'h2';

  return (
    <div className="w-full">
      <div
        className={cx('flex flex-col gap-y-2', {
          'items-center': alignment === '' || alignment === 'center',
          'items-start': alignment === 'left',
          'items-end': alignment === 'right',
        })}
      >
        {topic && (
          <div className="font-medium text-xs leading-6 lg:text-sm lg:leading-6 text-GSsteel/500 tracking-[2px]">
            {topic}
          </div>
        )}
        {header && (
          <Tag
            className={cx('font-raleway', {
              'font-bold text-xl sm:text-2xl lg:text-[28px] lg:leading-[40px]':
                (layout === 'default' || layout === '') && theme !== 'ideal',
              'font-extrabold text-[28px] leading-10 sm:text-[32px] sm:leading-[48px] lg:text-[40px] lg:leading-[56px]':
                layout === 'full' && theme !== 'ideal',
              'text-center': alignment === '' || alignment === 'center',
              'text-left': alignment === 'left',
              'text-right': alignment === 'right',
              'text-GSblue/600': theme !== 'ideal',
              'text-GSbase/black': theme === 'ideal',
              'font-bold text-[28px] leading-10 lg:text-[40px] lg:leading-[56px]':
                theme === 'ideal',
            })}
          >
            {header}
          </Tag>
        )}
        {subheader && (
          <div
            className={cx(
              'font-worksans text-GSdeep/900 font-normal text-center lg:text-lg',
              {
                'text-sm sm:text-base': theme !== 'ideal',
                'pt-2 text-lg': theme === 'ideal',
              },
            )}
          >
            {subheader}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Title;
