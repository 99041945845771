import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import { useState } from 'react';
import cx from 'classnames';

import PageContainer from '@/components/PageContainer/PageContainer';
import { CtaLinkBlokProps } from '../CtaLink/CtaLink';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';

export interface NotificationBlokProps extends SbBlokData {
  headline: string;
  colorVariant: 'variantBlue50' | 'variantGreen200' | 'variantBlue500';
  action: Array<CtaLinkBlokProps | CtaButtonBlokProps>;
  sticky: 'yes' | 'no';
  closeButtonVisibility: 'show' | 'hide';
}

export interface NotificationsProps {
  blok: NotificationBlokProps;
}

const Notification = ({ blok }: NotificationsProps) => {
  const { action, colorVariant, headline, sticky, closeButtonVisibility } =
    blok;

  const [showNotification, setShowNotification] = useState(true);

  return (
    <>
      {showNotification && (
        <section
          {...storyblokEditable(blok)}
          className={cx({
            'bg-GSblue/50 text-GSdeep/900': colorVariant === 'variantBlue50',
            'bg-GSgreen/200 text-GSdeep/900':
              colorVariant === 'variantGreen200',
            'bg-GSblue/500 text-GSbase/white':
              colorVariant === 'variantBlue500',
            'sticky top-0 z-50': sticky === 'yes',
          })}
        >
          <PageContainer className="!py-2 !sm:py-4">
            <div className="flex flex-row gap-x-2 w-full sm:gap-x-6">
              <div
                className="
                flex flex-col gap-2 grow items-start
                sm:items-center sm:flex-row sm:gap-4
                lg:justify-center lg:gap-8"
              >
                <div className="text-sm font-medium lg:text-base">
                  {headline}
                </div>
                {action?.map(
                  (nestedBlok: CtaLinkBlokProps | CtaButtonBlokProps) => (
                    <StoryblokComponent
                      blok={nestedBlok}
                      key={nestedBlok._uid}
                      background={
                        colorVariant === 'variantBlue500' ? 'darkBG' : 'lightBG'
                      }
                      size="btn-SM"
                    />
                  ),
                )}
              </div>
              {closeButtonVisibility === 'show' && (
                <button
                  aria-label="Close"
                  type="button"
                  onClick={() => setShowNotification(false)}
                  className="self-start ml-auto sm:self-center"
                  placeholder="button"
                >
                  <svg
                    className={cx({
                      'stroke-GSbase/white': colorVariant === 'variantBlue500',
                    })}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.8558 4.85364C16.0511 4.65838 16.0511 4.3418 15.8558 4.14654C15.6606 3.95128 15.344 3.95128 15.1487 4.14654L9.98674 9.30853L4.85349 4.17528C4.65823 3.98002 4.34165 3.98002 4.14639 4.17528C3.95112 4.37054 3.95112 4.68712 4.14639 4.88239L9.27963 10.0156L4.17497 15.1203C3.9797 15.3156 3.9797 15.6321 4.17497 15.8274C4.37023 16.0227 4.68681 16.0227 4.88207 15.8274L9.98674 10.7227L15.1202 15.8562C15.3154 16.0514 15.632 16.0514 15.8273 15.8562C16.0225 15.6609 16.0225 15.3443 15.8273 15.149L10.6938 10.0156L15.8558 4.85364Z"
                      fill="#010911"
                    />
                  </svg>
                </button>
              )}
            </div>
          </PageContainer>
        </section>
      )}
    </>
  );
};

export default Notification;
