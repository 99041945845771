import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  ISbRichtext,
  SbBlokData,
  ISbStoryData,
  renderRichText,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';

import PageContainer from '../../components/PageContainer/PageContainer';
import ButtonComponent from '../../components/Button/Button';
import TitleBlok, { TitleBlokProps } from '../Title/Title';
import { PressReleasePageBlokProps } from '../PressReleasePage/PressReleasePage';
import CtaButton, { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import mpcRichTextSchema from './richTextSchema';

export type PressReleaseStoryData = {
  [K in keyof ISbStoryData]: K extends 'content'
    ? PressReleasePageBlokProps
    : ISbStoryData[K];
};

export interface PressReleasesSectionBlokProps extends SbBlokData {
  content?: Array<PressReleaseStoryData>;
  cta?: Array<CtaButtonBlokProps>;
  excerptCharsLimit?: number;
  id?: string;
  readMoreLabel?: string;
  title?: Array<TitleBlokProps>;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

export interface PressReleasesSectionProps {
  blok: PressReleasesSectionBlokProps;
}

const PressReleasesSection = ({ blok }: PressReleasesSectionProps) => {
  const {
    content = [],
    cta: [cta] = [],
    excerptCharsLimit = 250,
    id,
    readMoreLabel,
    title: [title] = [],
    visibility = 'enabled',
    visibilityOnMobile = 'enabled',
  } = blok;

  const getExcerpt = (postContent: ISbRichtext | undefined) => {
    const html = renderRichText(postContent, {
      schema: mpcRichTextSchema,
    });

    return `${html.replace(/<[^>]+>/g, '').substring(0, excerptCharsLimit)}...`;
  };

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('w-full bg-white', {
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer>
        {title && <TitleBlok blok={title} headingLevel="h2" />}
        <div className="w-full flex flex-col sm:grid sm:grid-cols-12 gap-2 sm:gap-4 lg:gap-8">
          {content
            .filter(({ full_slug }) => full_slug)
            .map(
              ({
                content: {
                  title: pressReleaseTitle,
                  info: pressReleaseInfo,
                  content: postContent,
                } = {},
                full_slug,
                uuid,
              }) => (
                <div
                  key={uuid}
                  className={cx(
                    'w-full h-full flex flex-col justify-between',
                    'p-4 sm:p-6 lg:p-10 gap-2 sm:gap-4 lg:gap-8',
                    'sm:col-span-6 lg:col-span-4',
                    'transition shadow-lg rounded-lg box-border border border-GSblue/300 bg-GSbase/white',
                    'hover:bg-GSblue/100 hover:ring-1 hover:ring-GSblue/300 hover:ring-inset',
                  )}
                >
                  <div className="flex flex-col gap-2 sm:gap-10 w-full">
                    <div className="text-GSblue/600 break-words text-xs sm:text-sm sm:leading-4 font-medium">
                      {pressReleaseInfo}
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="text-GSblue/600 break-words whitespace-pre-wrap text-lg font-bold sm:text-2xl font-raleway">
                        {pressReleaseTitle}
                      </div>
                      <div className="text-GSdeep/900 font-worksans text-xs font-normal sm:text-base">
                        {getExcerpt(postContent)}
                      </div>
                    </div>
                  </div>
                  <ButtonComponent
                    className="max-w-fit"
                    label={readMoreLabel}
                    link={{
                      cached_url: full_slug,
                      fieldtype: 'multilink',
                      linktype: 'story',
                      id: uuidv4(),
                      url: '',
                    }}
                    hasRTicon
                    type="textLink"
                  />
                </div>
              ),
            )}
        </div>
        {cta && <CtaButton blok={cta} />}
      </PageContainer>
    </section>
  );
};

export default PressReleasesSection;
