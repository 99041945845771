import cx from 'classnames';
import { FieldInputProps } from 'formik';
import React from 'react';

export interface TextareaProps extends FieldInputProps<string> {
  charactersLimit: number;
  charactersRemainingLabel: string;
  disabled?: boolean;
  error: string;
  hubspotId?: string;
  label?: string;
  name: string;
  placeholder: string;
}

const defaultClasses =
  'h-24 min-h-24 bg-GSbase/white text-sm font-normal py-3 px-2 border-2 border-GSsteel/300 rounded text-GSdeep/900 placeholder:text-GSsteel/500';
const hoverClasses = 'hover:border-GSblue/500';
const focusClasses =
  'focus:ring-GSblue/100 focus:ring-offset-0 focus:ring-4 focus:border-GSblue/500 focus:outline-none';
const disabledClasses =
  'disabled:bg-GSsteel/50 disabled:border-GSsteel/100 disabled:text-GSsteel/900 disabled:text-opacity-40';

const Textarea = (field: TextareaProps) => {
  const { disabled, error, label, name, value } = field;
  const { charactersLimit, charactersRemainingLabel, hubspotId, ...fieldRest } =
    field;

  return (
    <div className="w-full flex flex-col gap-y-2">
      {hubspotId && (
        <>
          <label className="hidden" htmlFor={hubspotId}>
            {hubspotId}
          </label>
          <input className="hidden" value={value} name={hubspotId} readOnly />
        </>
      )}
      <div className="w-full flex flex-col gap-y-1">
        {label && (
          <label className="font-medium text-sm text-GSdeep/900" htmlFor={name}>
            {label}
          </label>
        )}
        <textarea
          className={cx(
            defaultClasses,
            hoverClasses,
            focusClasses,
            disabledClasses,
            {
              '!border-GSfunctional/error': error && error !== '',
              'placeholder:!text-GSsteel/500/[.2]': disabled,
            },
          )}
          {...fieldRest}
          id={name}
          disabled={disabled}
          maxLength={charactersLimit}
        />
      </div>
      <div className="text-xs text-GSsteel/500 font-medium flex">
        <div>
          {charactersRemainingLabel?.replace(
            '{{charactersRemaining}}',
            `${charactersLimit - value?.length}`,
          )}
        </div>
        <div className="ml-auto">
          {value?.length}/{charactersLimit}
        </div>
      </div>
      {error && (
        <div className="text-xs text-GSfunctional/error font-medium">
          {error}
        </div>
      )}
    </div>
  );
};

export default Textarea;
