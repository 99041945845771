import { SbBlokData } from '@storyblok/react';

import TitleBlok, { TitleBlokProps } from '../Title/Title';
import FaqSectionItem from '../FaqSectionItem/FaqSectionItem';
import { FaqSectionItemBlokProps } from '../FaqSectionItem/types';

export interface FaqSectionGridBlokProps extends SbBlokData {
  items: Array<FaqSectionItemBlokProps>;
  title: Array<TitleBlokProps>;
}

export interface FaqSectionGridProps {
  blok: FaqSectionGridBlokProps;
}

const FaqSectionGrid = ({ blok, ...restProps }: FaqSectionGridProps) => {
  const { title: [title] = [], items } = blok;

  const filteredItems = items.filter((item) => item.visibility !== 'disabled');

  return (
    <>
      <TitleBlok blok={title} />
      <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-x-8 sm:gap-y-8 sm:grid-cols-2">
        {filteredItems?.map((item: FaqSectionItemBlokProps) => (
          <FaqSectionItem blok={item} key={item._uid} {...restProps} />
        ))}
      </div>
    </>
  );
};

export default FaqSectionGrid;
