import Head from 'next/head';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';

import { Cookiebot, Gtm, Optimizely } from '@/components';
import { GtmIdContext } from '@/helpers/contexts';
import { offBrandFonts } from '@/helpers/text/fonts';

export interface OffbrandPageBlokProps extends SbBlokData, MpcData {
  content: Array<SbBlokData>;
  optimizelyId?: string;
  seoTitle: string;
  seoDescription: string;
  seoIndexing: 'enabled' | 'disabled';
  gtmScripts?: 'disabled' | 'enabled' | '';
  cookiebotScripts?: 'disabled' | 'enabled' | '';
  serverSideGtm?: 'disabled' | 'enabled' | '';
}

interface OffbrandPageProps {
  blok: OffbrandPageBlokProps;
}

const OffbrandPage = ({ blok, ...restProps }: OffbrandPageProps) => {
  const {
    optimizelyId,
    seoTitle,
    seoDescription,
    seoIndexing,
    gtmScripts,
    cookiebotScripts,
    serverSideGtm,
  } = blok;

  return (
    // eslint-disable-next-line react/jsx-boolean-value
    <GtmIdContext.Provider value="GTM-N7N7Z9T">
      <Head>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        {seoIndexing === 'disabled' && <meta name="robots" content="noindex" />}
        {offBrandFonts()}
      </Head>
      {optimizelyId && <Optimizely id={optimizelyId} />}
      {gtmScripts !== 'disabled' && (
        <Gtm serverSide={serverSideGtm === 'enabled'} />
      )}
      {cookiebotScripts !== 'disabled' && (
        <Cookiebot language={blok._mpc.language} />
      )}
      <main
        {...storyblokEditable(blok)}
        className="overflow-x-clip w-full font-dosis bg-GSbase/white group offbrand min-h-screen flex flex-col"
      >
        {blok.content?.map((nestedBlok: SbBlokData) => (
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
            {...restProps}
          />
        ))}
      </main>
    </GtmIdContext.Provider>
  );
};

export default OffbrandPage;
