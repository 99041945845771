import {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
} from 'react-select';

export const ClearIndicator = (props: ClearIndicatorProps) => (
  <components.ClearIndicator {...props}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4001 7.99998C14.4001 11.5346 11.5347 14.4 8.0001 14.4C4.46548 14.4 1.6001 11.5346 1.6001 7.99998C1.6001 4.46535 4.46548 1.59998 8.0001 1.59998C11.5347 1.59998 14.4001 4.46535 14.4001 7.99998ZM4.89294 4.89287C5.28346 4.50234 5.91663 4.50234 6.30715 4.89287L8.0001 6.58581L9.69304 4.89287C10.0836 4.50234 10.7167 4.50234 11.1073 4.89287C11.4978 5.28339 11.4978 5.91656 11.1073 6.30708L9.41431 8.00003L11.1073 9.69297C11.4978 10.0835 11.4978 10.7167 11.1073 11.1072C10.7167 11.4977 10.0836 11.4977 9.69304 11.1072L8.0001 9.41424L6.30716 11.1072C5.91663 11.4977 5.28347 11.4977 4.89294 11.1072C4.50242 10.7167 4.50242 10.0835 4.89294 9.69297L6.58588 8.00003L4.89294 6.30708C4.50241 5.91656 4.50241 5.28339 4.89294 4.89287Z"
        fill="#010911"
      />
    </svg>
  </components.ClearIndicator>
);

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const { isDisabled } = props;

  return (
    <components.DropdownIndicator {...props}>
      <svg
        className={
          isDisabled ? 'stroke-GSbase/black/40' : 'stroke-GSbase/black'
        }
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 8L10 14L4 8" strokeLinecap="round" />
      </svg>
    </components.DropdownIndicator>
  );
};
