import { SbBlokData } from '@storyblok/react';
import { useEffect, useState } from 'react';

interface MessageBirdChatWidgetBlokProps extends SbBlokData {
  widgetId: string;
}

interface MessageBirdChatWidgetProps {
  blok: MessageBirdChatWidgetBlokProps;
  setRunScriptCallback: (callback: Function) => void;
}

const MessageBirdChatWidget = ({
  blok,
  setRunScriptCallback,
}: MessageBirdChatWidgetProps) => {
  const { widgetId } = blok;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!document.getElementById('live-chat-widget-script') && widgetId) {
      const script = document.createElement('script');
      script.setAttribute('id', 'live-chat-widget-script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('async', 'true');
      script.setAttribute(
        'src',
        `https://livechat.messagebird.com/bootstrap.js?widgetId=${widgetId}`,
      );
      script.addEventListener('load', () => setLoaded(true));

      document.body.appendChild(script);
    }
  }, [widgetId]);

  const onToggle = (isOpen: boolean) => {
    if (window.MessageBirdChatWidget) {
      if (!isOpen) {
        window.MessageBirdChatWidget.hide();
      }
    }
  };

  useEffect(() => {
    if (
      loaded &&
      window.MessageBirdChatWidget &&
      !window.MessageBirdChatWidget.iframeHasLoaded
    ) {
      window.MessageBirdChatWidget.on('toggle', onToggle);
      window.MessageBirdChatWidget.hide();
      window.MessageBirdChatWidget.init();
    }
  }, [loaded]);

  const toggleChat = () => {
    if (window.MessageBirdChatWidget) {
      window.MessageBirdChatWidget.toggleChat(true);
      window.MessageBirdChatWidget.show();
    }
  };

  useEffect(() => {
    setRunScriptCallback(toggleChat);
  }, [setRunScriptCallback]);

  return null;
};

export default MessageBirdChatWidget;
