export const ScrollIcon = () => (
  <svg
    width="199"
    height="228"
    viewBox="0 0 199 228"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="sm:absolute top-0 ml-[-30px] h-40 sm:h-auto sm:ml-auto pointer-events-none"
  >
    <mask
      id="mask0_207_79306"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="199"
      height="228"
    >
      <rect
        width="199"
        height="228"
        transform="matrix(-1 0 0 1 199 0)"
        fill="#E3E8F6"
      />
    </mask>
    <g mask="url(#mask0_207_79306)">
      <path
        d="M88.8234 149.32C88.8234 149.32 88.9146 149.324 88.9602 149.326C99.2312 148.964 109.013 147.153 118.139 143.886C125.968 141.094 133.076 137.295 139.401 132.562C139.243 132.691 139.102 132.806 138.944 132.95C140.074 132.092 141.19 131.232 142.261 130.326C144.593 128.371 146.808 126.275 148.907 124.055C157.927 114.399 165.697 103.67 171.991 92.19C178.122 80.9895 182.918 68.9232 186.245 56.3168C187.36 52.0999 188.311 47.8012 189.096 43.4507C190.624 35.0342 191.553 26.3982 191.848 17.6468C192.306 4.47521 191.349 -9.06862 189.039 -22.6209C186.713 -36.1888 182.993 -49.8722 177.999 -63.3048C172.934 -76.8457 166.524 -90.2592 158.937 -103.162C151.207 -116.297 142.168 -129.031 132.063 -141.025C121.712 -153.315 110.103 -164.962 97.6018 -175.65L5.02296 -98.1139C17.6027 -87.4977 29.1966 -75.8364 39.5622 -63.5311C49.6671 -51.5523 58.7218 -38.8171 66.4509 -25.6676C74.0531 -12.7641 80.4623 0.664387 85.512 14.2197C90.521 27.653 94.2264 41.3357 96.5517 54.9037C98.8763 68.4866 99.8324 82.0455 99.3745 95.2021C99.0795 103.969 98.1507 112.605 96.6227 121.006C95.8368 125.371 94.871 129.67 93.7716 133.887C92.3711 139.147 90.7465 144.307 88.8526 149.351L88.8234 149.32Z"
        fill="#5F48D3"
      />
      <path
        d="M-12.9067 226.663C0.77769 227.34 13.6955 225.533 25.4796 221.286C34.6525 218.005 42.846 213.36 49.9353 207.422C50.959 206.574 51.9674 205.725 53.0226 204.848C79.221 182.922 112.698 154.899 138.944 132.914C132.651 137.604 125.619 140.999 117.821 143.793C108.74 147.047 91.0288 149.222 88.8341 149.301C85.8324 149.393 82.7899 149.363 79.7213 149.226C66.0218 148.548 52.2673 145.474 38.8074 140.017C24.8674 134.39 11.8874 126.483 0.19384 116.503L-92.3922 194.031C-80.65 203.938 -67.6852 211.845 -53.761 217.486C-40.3157 222.927 -26.5612 226.002 -12.8915 226.663L-12.9067 226.663Z"
        fill="#5DC18B"
      />
    </g>
  </svg>
);
