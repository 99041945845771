import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import BenefitImage, {
  BenefitImageBlokProps,
} from '../BenefitImage/BenefitImage';
import BenefitHeading, {
  BenefitHeadingBlokProps,
} from '../BenefitHeading/BenefitHeading';
import BenefitHeadingList, {
  BenefitHeadingListBlokProps,
} from '../BenefitHeadingList/BenefitHeadingList';
import Video, { VideoBlokProps } from '../Video/Video';

export interface BenefitContentBlokProps extends SbBlokData {
  heading: Array<BenefitHeadingBlokProps>;
  headingList: Array<BenefitHeadingListBlokProps>;
  image: Array<BenefitImageBlokProps>;
  layout: 'imageAndHeading' | 'imageAndHeadingList' | 'headingList';
  layoutDirection: 'default' | 'reversed';
  layoutSize: 'wide' | 'default' | 'narrow' | 'ratio2to3';
  backgroundColor: 'none' | 'GSparchment/200' | 'GSblue/50';
  mobileImagePlacement: 'default' | 'top' | 'bottom';
  contentType?: '' | 'image' | 'video';
  video?: Array<VideoBlokProps>;
}

interface BenefitContentProps {
  blok: BenefitContentBlokProps;
  headingsLevel: 'h2' | 'h3';
}

const BenefitContent = ({ blok, headingsLevel }: BenefitContentProps) => {
  const {
    backgroundColor,
    heading: [heading] = [],
    headingList: [headingList] = [],
    image: [image] = [],
    layout = 'imageAndHeading',
    layoutDirection = 'default',
    layoutSize = 'default',
    mobileImagePlacement = 'default',
    contentType = 'image',
    video: [video] = [],
  } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="grid grid-cols-6 sm:grid-cols-12 gap-2 sm:gap-4 lg:gap-8"
    >
      <div
        className={cx('grid rounded sm:rounded-lg gap-2 sm:gap-4 lg:gap-8', {
          'grid-cols-6 col-span-6 sm:grid-cols-12 col-start-1 sm:col-span-12':
            layoutSize === 'wide',
          'grid-cols-6 col-span-6 col-start-1 sm:grid-cols-12 sm:col-span-12 sm:col-start-1 lg:grid-cols-10 lg:col-start-2 lg:col-span-10':
            layoutSize === 'default' || layoutSize === 'ratio2to3',
          'grid-cols-6 sm:grid-cols-10 lg:grid-cols-8 col-start-1 sm:col-start-2 lg:col-start-3 col-span-6 sm:col-span-10 lg:col-span-8':
            layoutSize === 'narrow',
          'bg-transparent': backgroundColor === 'none' || !backgroundColor,
          'bg-GSparchment/200': backgroundColor === 'GSparchment/200',
          'bg-GSblue/50': backgroundColor === 'GSblue/50',
        })}
      >
        {(layout === 'imageAndHeading' || layout === 'imageAndHeadingList') && (
          <>
            <div
              className={cx('self-center h-full w-full', {
                'col-span-6 sm:col-span-7': layoutSize === 'wide',
                'col-span-6 sm:col-span-6 lg:col-span-5':
                  layoutSize === 'default',
                'col-span-6 sm:col-span-5 lg:col-span-4':
                  layoutSize === 'narrow' || layoutSize === 'ratio2to3',
                'col-start-1': layoutDirection === 'default',
                'col-start-1 sm:col-start-6 sm:order-last':
                  layoutDirection === 'reversed' && layoutSize === 'wide',
                'col-start-1 sm:col-start-7 lg:col-start-6 sm:order-last':
                  layoutDirection === 'reversed' && layoutSize === 'default',
                'col-start-1 sm:col-start-8 lg:col-start-7 sm:order-last':
                  layoutDirection === 'reversed' && layoutSize === 'ratio2to3',
                'col-start-1 sm:col-start-6 lg:col-start-5 sm:order-last':
                  layoutDirection === 'reversed' && layoutSize === 'narrow',
                'hidden sm:block':
                  image && image.visibleOnMobile === 'disabled',
                'order-last':
                  (layoutDirection === 'reversed' &&
                    mobileImagePlacement !== 'top') ||
                  (layoutDirection === 'default' &&
                    mobileImagePlacement === 'bottom'),
              })}
            >
              {(contentType === 'image' || contentType === '') && image && (
                <BenefitImage
                  blok={image}
                  parentLayoutDirection={layoutDirection}
                />
              )}
              {contentType === 'video' && video && <Video blok={video} />}
            </div>
            <div
              className={cx('flex self-center', {
                'col-span-6 sm:col-span-5': layoutSize === 'wide',
                'col-span-6 sm:col-span-6 lg:col-span-5':
                  layoutSize === 'default',
                'col-span-6 sm:col-span-7 lg:col-span-6':
                  layoutSize === 'ratio2to3',
                'col-span-6 sm:col-span-5 lg:col-span-4':
                  layoutSize === 'narrow',
                'col-start-1 sm:col-start-8':
                  layoutDirection === 'default' && layoutSize === 'wide',
                'col-start-1 sm:col-start-7 lg:col-start-6':
                  layoutDirection === 'default' && layoutSize === 'default',
                'col-start-1 sm:col-start-6 lg:col-start-5':
                  layoutDirection === 'default' &&
                  (layoutSize === 'narrow' || layoutSize === 'ratio2to3'),
                'col-start-1': layoutDirection === 'reversed',
              })}
            >
              {layout === 'imageAndHeading' && heading && (
                <BenefitHeading
                  blok={heading}
                  parentLayout={layout}
                  parentLayoutDirection={layoutDirection}
                  isCard={backgroundColor !== 'none'}
                  hasTopPaddingOnMobile={
                    image && image.backgroundScroll === 'disabled'
                  }
                  textCenteredOnMobile={
                    image && image.backgroundScroll === 'enabled'
                  }
                  headingsLevel={headingsLevel}
                />
              )}
              {layout === 'imageAndHeadingList' && headingList && (
                <BenefitHeadingList
                  blok={headingList}
                  parentLayout={layout}
                  headingsLevel={headingsLevel}
                />
              )}
            </div>
          </>
        )}
        {layout === 'headingList' && (
          <div className="col-span-full">
            {headingList && (
              <BenefitHeadingList
                blok={headingList}
                parentLayout={layout}
                headingsLevel={headingsLevel}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BenefitContent;
