import { useEffect, useState } from 'react';

const useOptimizely = () => {
  const [optimizelyReady, setOptimizelyReady] = useState(false);
  const [optimizelyExperiment, setOptimizelyExperiment] = useState<
    string | undefined
  >('');

  const onInitialized = () => {
    setOptimizelyReady(true);
    setOptimizelyExperiment(window.optExperiment);
  };

  useEffect(() => {
    if (window) {
      window.optimizely = window.optimizely || [];

      if (window.optimizely instanceof Array) {
        window.optimizely.push({
          type: 'addListener',
          filter: {
            type: 'lifecycle',
            name: 'initialized',
          },
          handler: onInitialized,
        });
      } else if (window.optimizely.initialized) {
        onInitialized();
      }
    }
  }, []);

  return {
    optimizelyReady,
    optimizelyExperiment,
  };
};

export default useOptimizely;
