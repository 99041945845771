import cx from 'classnames';

import { PageContainer } from '@/components';
import HeroContentBox, {
  HeroContentBoxBlokProps,
} from '../HeroContentBox/HeroContentBox';
import HeroFormBox, { HeroFormBoxBlokProps } from '../HeroFormBox/HeroFormBox';
import HeroImage, { HeroImageBlokProps } from '../HeroImage/HeroImage';
import { FullscreenController } from '../FullscreenController/FullscreenController';

const HeroWithScrollLayout = ({
  backgroundColor,
  colorVariant,
  content,
  form,
  fullscreenController,
  image,
  layout,
  layoutDirection,
}: HeroWithImageLayoutProps) => (
  <div
    className={cx(
      'w-full h-full flex flex-col sm:gap-4 sm:grid sm:grid-rows-1 sm:grid-cols-12 lg:gap-8',
      fullscreenController?.deviceClassNames,
    )}
  >
    <div
      className={cx('w-full col-span-6', {
        'sm:col-span-5':
          image?.layout === 'full' && image?.widthRatio === 'fortyPercent',
        'lg:self-end lg:h-auto': image?.scrollOnDesktopPlacement !== 'top',
        'lg:self-start lg:h-full': image?.scrollOnDesktopPlacement === 'top',
        'sm:self-end': image?.scrollOnTabletPlacement !== 'top',
        'sm:self-start sm:h-full': image?.scrollOnTabletPlacement === 'top',
        '!h-full': image?.layout === 'full' || image?.scrollType === 'over',
        '2xl:pr-[calc(8.333333%+32px)]':
          layoutDirection === 'default' &&
          image?.scrollType !== 'over' &&
          image?.layout !== 'full',
        '2xl:pl-[calc(8.333333%+32px)]':
          layoutDirection === 'reversed' &&
          image?.scrollType !== 'over' &&
          image?.layout !== 'full',
      })}
    >
      {image && (
        <HeroImage
          blok={image}
          backgroundColor={backgroundColor}
          colorVariant={colorVariant}
          isReversed={layoutDirection === 'reversed'}
        />
      )}
    </div>
    <div
      className={cx(
        'w-full col-span-6 flex flex-col px-4 py-8 gap-y-4 sm:py-12 sm:grid sm:grid-cols-6 lg:py-16 lg:gap-8',
        {
          'max-w-[768px] lg:max-w-[752px]': !(
            image?.layout === 'full' && image?.widthRatio === 'fortyPercent'
          ),
          'sm:order-last justify-self-start sm:px-0 sm:pr-6 lg:pr-6 2xl:pr-8':
            layoutDirection === 'default',
          'sm:order-first justify-self-end sm:px-0 sm:pl-6 lg:pl-10':
            layoutDirection === 'reversed',
          'pt-1 sm:pt-8': image && image.backgroundScrollOnMobile === 'enabled',
        },
      )}
    >
      <div
        className={cx(
          'w-full self-center col-span-6 col-start-1 lg:col-span-5',
          {
            'lg:col-start-2': layoutDirection === 'reversed',
            'lg:col-start-1': layoutDirection === 'default',
            'lg:!col-span-4 lg:!col-start-2 2xl:!col-span-5 2xl:!col-start-2':
              image &&
              image.scrollType === 'over' &&
              image.backgroundScroll === 'enabled',
            'lg:!col-span-full':
              image?.layout === 'full' && image?.widthRatio === 'fortyPercent',
            '!self-auto': form && form.alignment === 'top',
          },
        )}
      >
        {layout === 'imageAndContent' && content && (
          <HeroContentBox
            blok={content}
            colorVariant={colorVariant}
            backgroundColor={backgroundColor}
          />
        )}
        {layout === 'imageAndForm' && form && <HeroFormBox blok={form} />}
      </div>
    </div>
  </div>
);

const HeroWithoutScrollLayout = ({
  fullscreenController,
  layoutDirection,
  image,
  backgroundColor,
  colorVariant,
  content,
  layout,
  form,
}: HeroWithImageLayoutProps) => (
  <PageContainer>
    <div
      className={cx(
        'grid grid-col-1 sm:grid-cols-12 sm:grid-rows-1 gap-4 lg:gap-8',
        {
          'gap-y-6': image.layout === 'influencer',
        },
        fullscreenController?.deviceClassNames,
      )}
    >
      <div
        className={cx({
          'order-last sm:col-start-8 lg:col-start-8':
            layoutDirection === 'reversed' && image.layout !== 'influencer',
          'sm:col-start-1 lg:col-start-2': layoutDirection === 'default',
          'self-center sm:col-span-5 lg:col-span-4':
            image.layout !== 'influencer',
          'sm:col-span-6 lg:col-span-5': image.layout === 'influencer',
          'order-last sm:col-start-7 lg:col-start-7':
            image.layout === 'influencer' && layoutDirection === 'reversed',
          'order-last sm:col-start-8 lg:!col-start-7 sm:col-span-6 lg:col-span-5':
            layout === 'selfBooking' && layoutDirection === 'reversed',
        })}
      >
        {image && (
          <HeroImage
            blok={image}
            backgroundColor={backgroundColor}
            colorVariant={colorVariant}
          />
        )}
      </div>
      <div
        className={cx('sm:col-span-6 lg:col-span-5', {
          'sm:col-start-1 lg:!col-start-2': layoutDirection === 'reversed',
          'sm:col-start-7 lg:col-start-7': layoutDirection === 'default',
          'sm:!col-span-7 lg:!col-span-5': layout === 'selfBooking',
        })}
      >
        {(layout === 'imageAndContent' || layout === 'selfBooking') &&
          content && (
            <HeroContentBox
              blok={content}
              colorVariant={colorVariant}
              backgroundColor={backgroundColor}
            />
          )}
        {layout === 'imageAndForm' && form && <HeroFormBox blok={form} />}
      </div>
    </div>
  </PageContainer>
);

export interface HeroWithImageLayoutProps {
  backgroundColor: 'dark' | 'light';
  colorVariant: 'scrollVariantGreen500' | 'scrollVariantYellow500';
  content: HeroContentBoxBlokProps;
  form: HeroFormBoxBlokProps;
  fullscreenController?: FullscreenController;
  image: HeroImageBlokProps;
  layout: 'imageAndContent' | 'imageAndForm' | 'formAndContent' | 'selfBooking';
  layoutDirection: 'default' | 'reversed';
}

const HeroWithImageLayout = (props: HeroWithImageLayoutProps) => {
  const { image, layout } = props;
  const withScrollLayout = image
    ? image.backgroundScroll === 'enabled' &&
      image.layout !== 'influencer' &&
      layout !== 'selfBooking'
    : true;

  return withScrollLayout || image.layout === 'full' ? (
    <HeroWithScrollLayout {...props} />
  ) : (
    <HeroWithoutScrollLayout {...props} />
  );
};

export default HeroWithImageLayout;
