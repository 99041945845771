import {
  RichTextSchema,
  ISbRichtext,
  SbBlokData,
  renderRichText,
  storyblokEditable,
} from '@storyblok/react';
import cloneDeep from 'clone-deep';

const mpcRichTextSchema = cloneDeep(RichTextSchema);

mpcRichTextSchema.marks.link = (node: ISbNode) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const schema: any = RichTextSchema.marks.link(node);

  schema.tag[0].attrs.class =
    'text-GSblue/600 hover:text-GSblue/700 underline font-medium';

  return schema;
};

mpcRichTextSchema.marks.textStyle = () => {
  // we do not support changing text color
  return {
    tag: '',
  };
};

export interface LegalNoticeItemBlokProps extends SbBlokData {
  content: ISbRichtext;
  title: string;
}

interface LegalNoticeItemProps {
  blok: LegalNoticeItemBlokProps;
}

const LegalNoticeItem = ({ blok }: LegalNoticeItemProps) => {
  const { content, title } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col items-start w-full gap-2 lg:gap-4"
    >
      <div
        className="
          font-raleway text-GSblue/600
          text-lg font-bold
          sm:text-xl
          lg:text-2xl
        "
      >
        {title}
      </div>
      {content && (
        <div
          className="break-words min-w-0 text-GSbase/black text-sm font-worksans font-normal"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: renderRichText(content, { schema: mpcRichTextSchema }),
          }}
        />
      )}
    </div>
  );
};

export default LegalNoticeItem;
