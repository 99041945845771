import * as FormMessageField from '@/blocks/FormMessageField/FormMessageField';
import * as FormPromoCodeField from '@/blocks/FormPromoCodeField/FormPromoCodeField';
import * as FormSubjectField from '@/blocks/FormSubjectField/FormSubjectField';
import * as FormCityField from '@/blocks/FormCityField/FormCityField';
import * as FormSchoolLevelField from '@/blocks/FormSchoolLevelField/FormSchoolLevelField';
import * as FormIntentField from '@/blocks/FormIntentField/FormIntentField';
import * as FormPostalCodeField from '@/blocks/FormPostalCodeField/FormPostalCodeField';
import * as FormOnlineOrRemoteField from '@/blocks/FormOnlineOrRemoteField/FormOnlineOrRemoteField';
import * as FormNameField from '@/blocks/FormNameField/FormNameField';
import * as FormPhoneField from '@/blocks/FormPhoneField/FormPhoneField';
import * as FormEmailField from '@/blocks/FormEmailField/FormEmailField';
import * as FormTermsAndConditionsField from '@/blocks/FormTermsAndConditionsField/FormTermsAndConditionsField';
import * as FormNewsletterField from '@/blocks/FormNewsletterField/FormNewsletterField';
import * as FormAgeField from '@/blocks/FormAgeField/FormAgeField';
import * as FormUserTypeField from '@/blocks/FormUserTypeField/FormUserTypeField';
import * as FormDiscoveryChannelField from '@/blocks/FormDiscoveryChannelField/FormDiscoveryChannelField';
import * as FormInfluencerCampaignField from '@/blocks/FormInfluencerCampaignField/FormInfluencerCampaignField';
import * as NameField from '@/blocks/NameField/NameField';
import * as EmailField from '@/blocks/EmailField/EmailField';
import * as PhoneNumberField from '@/blocks/PhoneNumberField/PhoneNumberField';
import * as TermsAndConditionsCheckbox from '@/blocks/TermsAndConditionsCheckbox/TermsAndConditionsCheckbox';
import * as FormSchoolGradeField from '@/blocks/FormSchoolGradeField/FormSchoolGradeField';
import * as FormLeadOwnerEmailField from '@/blocks/FormLeadOwnerEmailField/FormLeadOwnerEmailField';
import * as FormTutoringSubjectsField from '@/blocks/FormTutoringSubjectsField/FormTutoringSubjectsField';
import * as FormLastNameField from '@/blocks/FormLastNameField/FormLastNameField';
import * as FormCertifiedTeacherField from '@/blocks/FormCertifiedTeacherField/FormCertifiedTeacherField';
import * as FormSharePersonalDataTutorField from '@/blocks/FormSharePersonalDataTutorField/FormSharePersonalDataTutorField';
import * as FormTermsAndConditionsTutorField from '@/blocks/FormTermsAndConditionsTutorField/FormTermsAndConditionsTutorField';
import { FormDataType } from './formHelpersTypes';
import { FormTextDataStructure } from '../datasources/fetchDatasources';

const FieldsData: {
  [key: string]: FormDataType;
} = {
  formLeadOwnerEmailField: FormLeadOwnerEmailField.FieldData,
  formPromoCodeField: FormPromoCodeField.FieldData,
  formSubjectField: FormSubjectField.FieldData,
  formCityField: FormCityField.FieldData,
  formSchoolLevelField: FormSchoolLevelField.FieldData,
  formPostalCodeField: FormPostalCodeField.FieldData,
  formOnlineOrRemoteField: FormOnlineOrRemoteField.FieldData,
  formNameField: FormNameField.FieldData,
  formPhoneField: FormPhoneField.FieldData,
  formEmailField: FormEmailField.FieldData,
  formTermsAndConditionsField: FormTermsAndConditionsField.FieldData,
  formNewsletterField: FormNewsletterField.FieldData,
  formAgeField: FormAgeField.FieldData,
  formUserTypeField: FormUserTypeField.FieldData,
  formDiscoveryChannelField: FormDiscoveryChannelField.FieldData,
  formIntentField: FormIntentField.FieldData,
  formInfluencerCampaignField: FormInfluencerCampaignField.FieldData,
  formMessageField: FormMessageField.FieldData,
  formSchoolGradeField: FormSchoolGradeField.FieldData,
  formTutoringSubjectsField: FormTutoringSubjectsField.FieldData,
  formLastNameField: FormLastNameField.FieldData,
  formCertifiedTeacherField: FormCertifiedTeacherField.FieldData,
  formSharePersonalDataTutorField: FormSharePersonalDataTutorField.FieldData,
  formTermsAndConditionsTutorField: FormTermsAndConditionsTutorField.FieldData,

  // DEPRECATED
  nameField: NameField.FieldData,
  emailField: EmailField.FieldData,
  phoneNumberField: PhoneNumberField.FieldData,
  termsAndConditionsCheckbox: TermsAndConditionsCheckbox.FieldData,
};

export const getFieldData = (componentName: string | undefined) => {
  if (componentName === undefined) {
    throw new Error(`Field ${componentName} does not exist!`);
  }

  if (FieldsData[componentName]) {
    return FieldsData[componentName];
  }

  throw new Error(`Field ${componentName} does not exist!`);
};

export const getFieldLabels = (
  formDatasources: FormTextDataStructure,
  fieldId: string,
) => {
  return Object.fromEntries(
    Object.entries(formDatasources).filter(([key]) =>
      key.startsWith(`${fieldId}_`),
    ),
  );
};
