import cx from 'classnames';
import StoryblokLink from '../StoryblokLink/StoryblokLink';

import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
} from './Icons';
import StoryblokImage from '../StoryblokImage/StoryblokImage';

export type IconId =
  | 'facebook'
  | 'instagram'
  | 'linkedin'
  | 'tiktok'
  | 'twitter'
  | 'youtube';

const renderSocialIcon = (id: IconId, classNames: string, size: string) => {
  switch (id) {
    case 'facebook':
      return <FacebookIcon className={cx(classNames, size)} />;
    case 'instagram':
      return <InstagramIcon className={cx(classNames, size)} />;
    case 'linkedin':
      return <LinkedinIcon className={cx(classNames, size)} />;
    case 'tiktok':
      return <TiktokIcon className={cx(classNames, size)} />;
    case 'twitter':
      return <TwitterIcon className={cx(classNames, size)} />;
    case 'youtube':
      return <YoutubeIcon className={cx(classNames, size)} />;
    default:
      return null;
  }
};

interface IconLinkProps {
  id: IconId;
  color: string;
  size: string;
  link: Link;
  image?: Asset;
}

const IconLinkComponent = (props: IconLinkProps) => {
  const { color, link, id, image, size } = props;

  return (
    <StoryblokLink testId={id} link={link}>
      {id && renderSocialIcon(id, color, size)}
      {image && <StoryblokImage blok={image} className={cx(size, 'w-full')} />}
    </StoryblokLink>
  );
};

export default IconLinkComponent;
