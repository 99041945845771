import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { BulletIcon } from './Icons';

export interface SubjectBlokProps extends SbBlokData {
  label: string;
}

interface SubjectProps {
  blok: SubjectBlokProps;
}

const Subject = ({ blok }: SubjectProps) => (
  <div className="flex flex-row" {...storyblokEditable(blok)}>
    <div role="img">
      <BulletIcon />
    </div>
    <div className="font-worksans text-GSdeep/900 font-bold text-base sm:text-lg lg:text-xl">
      {blok.label}
    </div>
  </div>
);

export default Subject;
