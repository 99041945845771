import { CheckboxInput } from '@/components';
import { FormDataType } from '@/helpers/form/formHelpersTypes';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';

const FIELD_ID = 'termsAndConditions';

export const FieldData: FormDataType = {
  hubspotId: '',
  id: FIELD_ID,
  type: 'boolean',
  validationType: 'bool',
};

export interface TermsAndConditionsCheckboxBlokProps extends SbBlokData {
  name: string;
  label: string;
  formikErrors: FormikErrors<{ termsAndConditions: string }>;
  touched: FormikTouched<{ termsAndConditions: boolean }>;
}

interface TermsAndConditionsCheckboxProps {
  blok: TermsAndConditionsCheckboxBlokProps;
  field: FieldInputProps<string>;
}

const TermsAndConditionsCheckbox = ({
  blok,
  field,
}: TermsAndConditionsCheckboxProps) => {
  const { formikErrors, label, touched } = blok;

  return (
    <div {...storyblokEditable(blok)}>
      <CheckboxInput
        hubspotId=""
        error={
          formikErrors.termsAndConditions && touched.termsAndConditions
            ? formikErrors.termsAndConditions
            : ''
        }
        {...field}
        label={label}
      />
    </div>
  );
};

export default TermsAndConditionsCheckbox;
