export const RatingIcon = ({ className }: { className?: string }) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10.5 1.66666L13.075 6.88332L18.8333 7.72499L14.6666 11.7833L15.65 17.5167L10.5 14.8083L5.34996 17.5167L6.33329 11.7833L2.16663 7.72499L7.92496 6.88332L10.5 1.66666Z"
      fill="#9F80FF"
    />
  </svg>
);
