export const ClearIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4001 8.0001C14.4001 11.5347 11.5347 14.4001 8.0001 14.4001C4.46548 14.4001 1.6001 11.5347 1.6001 8.0001C1.6001 4.46548 4.46548 1.6001 8.0001 1.6001C11.5347 1.6001 14.4001 4.46548 14.4001 8.0001ZM4.89294 4.89299C5.28346 4.50247 5.91663 4.50247 6.30715 4.89299L8.0001 6.58594L9.69304 4.89299C10.0836 4.50247 10.7167 4.50247 11.1073 4.89299C11.4978 5.28352 11.4978 5.91668 11.1073 6.3072L9.41431 8.00015L11.1073 9.69309C11.4978 10.0836 11.4978 10.7168 11.1073 11.1073C10.7167 11.4978 10.0836 11.4978 9.69304 11.1073L8.0001 9.41436L6.30716 11.1073C5.91663 11.4978 5.28347 11.4978 4.89294 11.1073C4.50242 10.7168 4.50242 10.0836 4.89294 9.69309L6.58588 8.00015L4.89294 6.3072C4.50241 5.91668 4.50241 5.28352 4.89294 4.89299Z"
      fill="#0B2FAC"
    />
  </svg>
);
