import TextInput from '@/components/TextInput/TextInput';
import { FormDataType } from '@/helpers/form/formHelpersTypes';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';

const FIELD_ID = 'firstName';

export const FieldData: FormDataType = {
  hubspotId: 'firstname',
  id: FIELD_ID,
  type: 'text',
  validationType: 'string',
};

export interface FormNameFieldBlokProps extends SbBlokData {
  label: string;
  placeholder: string;
  readOnly: boolean;
  formikErrors: FormikErrors<{ [FIELD_ID]: string }>;
  touched: FormikTouched<{ [FIELD_ID]: boolean }>;
}

interface FormNameFieldProps {
  blok: FormNameFieldBlokProps;
  field: FieldInputProps<string>;
  isTutorForm?: boolean;
}

const FormNameField = ({
  blok,
  field,
  isTutorForm = false,
}: FormNameFieldProps) => {
  const { label, placeholder, formikErrors, touched, readOnly } = blok;

  return (
    <div {...storyblokEditable(blok)}>
      <TextInput
        type="text"
        placeholder={placeholder}
        error={
          formikErrors[FIELD_ID] && touched[FIELD_ID]
            ? formikErrors[FIELD_ID]
            : ''
        }
        {...field}
        label={label}
        disabled={readOnly}
        isTutorForm={isTutorForm}
      />
    </div>
  );
};

export default FormNameField;
