import { CheckboxInput } from '@/components';
import { FormDataType } from '@/helpers/form/formHelpersTypes';
import { ISbRichtext, SbBlokData, storyblokEditable } from '@storyblok/react';
import { FieldProps, FormikErrors, FormikTouched } from 'formik';

const FIELD_ID = 'termsAndConditions';

export const FieldData: FormDataType = {
  hubspotId: '',
  id: FIELD_ID,
  type: 'boolean',
  validationType: 'bool',
};

export interface FormTermsAndConditionsFieldBlokProps extends SbBlokData {
  label: string;
  readOnly: boolean;
  formikErrors: FormikErrors<{ [FIELD_ID]: string }>;
  touched: FormikTouched<{ [FIELD_ID]: boolean }>;
  richTextLabel?: ISbRichtext;
}

interface FormTermsAndConditionsFieldProps extends FieldProps {
  blok: FormTermsAndConditionsFieldBlokProps;
}

const FormTermsAndConditionsField = ({
  blok,
  field,
}: FormTermsAndConditionsFieldProps) => {
  const { label, formikErrors, touched, readOnly, richTextLabel } = blok;

  return (
    <div {...storyblokEditable(blok)}>
      <CheckboxInput
        hubspotId={FieldData.hubspotId}
        error={
          formikErrors[FIELD_ID] && touched[FIELD_ID]
            ? formikErrors[FIELD_ID]
            : ''
        }
        {...field}
        defaultChecked={field.value}
        label={label}
        richTextLabel={richTextLabel}
        disabled={readOnly}
      />
    </div>
  );
};

export default FormTermsAndConditionsField;
