import PageContainer from '@/components/PageContainer/PageContainer';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import SeoLink, { SeoLinkBlokProps } from '../SeoLink/SeoLink';
import { isEmptyLink } from '../../components/StoryblokLink/StoryblokLink';

export interface SeoLinksGroupBlokProps extends SbBlokData {
  title: string;
  links: Array<SeoLinkBlokProps>;
}

interface SeoLinksGroupProps {
  blok: SeoLinksGroupBlokProps;
}

const SeoLinksGroup = ({ blok, ...restProps }: SeoLinksGroupProps) => {
  const { title, links } = blok;

  return title ? (
    <div {...storyblokEditable(blok)} className="sm:px-24">
      <PageContainer className="!py-8 z-10">
        <div className="grid gap-3 w-full">
          <div className="font-worksans text-lg font-bold text-GSblue/600 py-2 border-b border-GSblue/100 sm:text-xl">
            {title}
          </div>
          <div className="grid grid-cols-2 gap-y-4 gap-x-8 sm:grid-cols-4 sm:gap-y-3 lg:gap-y-4">
            {links.map((nestedBlok: SeoLinkBlokProps) =>
              isEmptyLink(nestedBlok.link) ? null : (
                <SeoLink
                  blok={nestedBlok}
                  key={nestedBlok._uid}
                  {...restProps}
                />
              ),
            )}
          </div>
        </div>
      </PageContainer>
    </div>
  ) : null;
};

export default SeoLinksGroup;
