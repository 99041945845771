import { TrustpilotFallbackReviewBlokProps } from '@/blocks/TrustpilotFallbackReview/TrustpilotFallbackReview';
import useResponsive from '@/helpers/layout/useResponsive';
import { StoryblokComponent } from '@storyblok/react';
// eslint-disable-next-line
import { default as Nuka } from 'nuka-carousel';
import React from 'react';
import { ArrowIcon } from '../../icons/ArrowIcon';

interface CarouselProps {
  items: Array<TrustpilotFallbackReviewBlokProps>;
}
interface NukkaControlsProps {
  nextSlide: () => void;
  previousSlide: () => void;
}

const Carousel = ({ items }: CarouselProps) => {
  const { isMobile, isTablet } = useResponsive();

  const renderCenterCenterControls = (props: NukkaControlsProps) => {
    const { nextSlide, previousSlide } = props;
    return (
      <div className="navigation flex relative top-14">
        <div
          className="cursor-pointer w-8 h-8 sm:w-10 sm:h-10"
          onClick={previousSlide}
        >
          <ArrowIcon />
        </div>
        <div
          className="cursor-pointer rotate-180 w-8 h-8 sm:w-10 sm:h-10"
          onClick={nextSlide}
        >
          <ArrowIcon />
        </div>
      </div>
    );
  };

  const getNumberOfSlides = () => {
    if (isMobile) {
      return 1.1;
    }
    if (isTablet) {
      return 2;
    }
    return 3;
  };

  return (
    <div className="flex flex-col w-full items-center mb-8 gap-2 md:gap-4 md:mb-10 lg:gap-8">
      <Nuka
        cellAlign="center"
        slidesToShow={getNumberOfSlides()}
        cellSpacing={32}
        keyCodeConfig={{ firstSlide: [1] }}
        wrapAround
        defaultControlsConfig={{
          pagingDotsClassName: 'hidden',
          nextButtonClassName: 'hidden',
          prevButtonClassName: 'hidden',
        }}
        renderBottomRightControls={renderCenterCenterControls}
        renderBottomCenterControls={() => false}
        renderCenterCenterControls={() => false}
      >
        {items.map((nestedBlok) => (
          <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
        ))}
      </Nuka>
    </div>
  );
};

export default Carousel;
