import { SbBlokData, storyblokEditable } from '@storyblok/react';

import FaqItemComponent from '../../components/FaqItem/FaqItem';

export interface FaqItemBlokProps extends SbBlokData {
  body?: string;
  title: string;
}

export interface FaqItemProps {
  blok: FaqItemBlokProps;
  isExpanded: boolean;
  setExpanded: (id: string | undefined) => void;
}

const FaqItem = ({ blok, isExpanded, setExpanded }: FaqItemProps) => {
  const { title, body = '' } = blok;

  return (
    <div {...storyblokEditable(blok)}>
      <FaqItemComponent
        body={body}
        id={blok._uid}
        isExpanded={isExpanded}
        setExpanded={setExpanded}
        title={title}
      />
    </div>
  );
};

export default FaqItem;
