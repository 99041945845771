import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';

import CtaButtonBlok, {
  CtaButtonBlokProps,
} from '@/blocks/CtaButton/CtaButton';
import { ScrollIcon } from './Icons';
import { SocialMediaLinksBlokProps } from '../SocialMediaLinks/SocialMediaLinks';

export interface ThankYouBlokProps extends SbBlokData {
  title: string;
  content: string;
  layout: 'cta' | 'social-media';
  layoutDirection: 'default' | 'reversed';
  cta: Array<CtaButtonBlokProps>;
  socialTitle: string;
  social: Array<SocialMediaLinksBlokProps>;
}

interface ThankYouProps {
  blok: ThankYouBlokProps;
}

const ThankYou = ({ blok }: ThankYouProps) => {
  const {
    title,
    content,
    cta: [ctaButton] = [],
    layout,
    layoutDirection,
    socialTitle,
    social: [social] = [],
  } = blok;

  return (
    <section
      {...storyblokEditable(blok)}
      className="
        w-full flex flex-col px-2 gap-y-12 py-8 bg-GSparchment/200
        sm:grid sm:grid-cols-12 sm:grid-rows-1 sm:gap-x-4 sm:px-4 sm:py-12
        lg:gap-x-8 lg:py-16
      "
    >
      <div
        className={cx(
          'w-full flex flex-col text-center gap-y-6 sm:col-span-8 sm:col-start-3 sm:gap-x-2 sm:gap-y-8 lg:col-span-4 lg:justify-center lg:text-start lg:gap-y-10',
          {
            'lg:col-start-8': layoutDirection === 'reversed',
            'lg:col-start-2': layoutDirection === 'default',
          },
        )}
      >
        <div
          className="
            w-full flex flex-col gap-y-2
            sm:gap-y-4
          "
        >
          <div
            className="
              font-raleway font-extrabold text-2xl text-GSblue/600
              sm:text-[28px] sm:leading-10
              lg:text-[32px] lg:leading-[48px]
            "
          >
            {title}
          </div>
          <div
            className="
              font-normal text-base text-GSdeep/900
              sm:text-lg
              lg:text-xl
            "
          >
            {content}
          </div>
        </div>
        {layout === 'cta' && ctaButton && (
          <div className="w-full flex flex-col sm:max-w-fit self-center lg:self-start">
            <CtaButtonBlok blok={ctaButton} />
          </div>
        )}
        {layout === 'social-media' && (
          <div
            className="
              flex flex-col gap-y-4 self-center justify-center
              sm:gap-y-6
              lg:self-start lg:justify-start
            "
          >
            <div className="font-bold text-xl">{socialTitle}</div>
            {social && <StoryblokComponent blok={social} />}
          </div>
        )}
      </div>
      <div
        className={cx(
          'flex h-full sm:grid-cols-6 sm:gap-x-2 sm:col-span-full lg:col-span-6',
          {
            'ml-[-8px] sm:ml-[-16px] lg:order-first':
              layoutDirection === 'reversed',
            'mr-[-8px] sm:mr-[-16px] -scale-x-[1] lg:order-last lg:col-start-7':
              layoutDirection === 'default',
          },
        )}
      >
        <div className="w-full self-end lg:self-center">
          <ScrollIcon
            firstPartClassName="fill-GSdarkpurple/500"
            secondPartClassName="fill-GSgreen/500"
          />
        </div>
      </div>
    </section>
  );
};

export default ThankYou;
