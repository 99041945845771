import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import { RatingIcon } from './Icons';

export interface PersonCardBlokProps extends SbBlokData {
  image: Asset;
  name: string;
  subject?: string;
}

interface PersonCardProps {
  blok: PersonCardBlokProps;
  className?: string;
  ratingVisibility?: 'show' | 'hide';
  subjectVisibility?: 'show' | 'hide';
}

const PersonCard = ({
  blok,
  className,
  ratingVisibility = 'show',
  subjectVisibility = 'show',
}: PersonCardProps) => {
  const { image, subject } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx(
        'w-full flex flex-col items-center gap-2 lg:gap-4',
        className,
      )}
    >
      <div className="w-full rounded-lg overflow-hidden">
        {image.filename ? (
          <StoryblokImage
            className="w-full aspect-square object-cover"
            blok={image}
          />
        ) : (
          <img
            className="w-full bg-GSgrey/500"
            alt="placeholder"
            src="/assets/person.svg"
          />
        )}
      </div>
      {ratingVisibility === 'show' && (
        <div className="flex">
          <RatingIcon className="w-4 h-4 lg:w-5 lg:h-5" />
          <RatingIcon className="w-4 h-4 lg:w-5 lg:h-5" />
          <RatingIcon className="w-4 h-4 lg:w-5 lg:h-5" />
          <RatingIcon className="w-4 h-4 lg:w-5 lg:h-5" />
          <RatingIcon className="w-4 h-4 lg:w-5 lg:h-5" />
        </div>
      )}
      <div className="w-full flex flex-col items-center gap-1">
        <div className="text-GSdeep/900 font-bold text-xl break-words w-full text-center">
          {blok.name}
        </div>
        {subjectVisibility === 'show' && (
          <div className="text-GSdeep/900 font-medium text-sm break-words w-full text-center">
            {subject || '\u00A0'}
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonCard;
