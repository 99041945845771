import { useState } from 'react';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';

import PageContainer from '@/components/PageContainer/PageContainer';
import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import FaqItem, { FaqItemBlokProps } from '@/blocks/FaqItem/FaqItem';
import { CtaLinkBlokProps } from '@/blocks/CtaLink/CtaLink';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';

export interface FaqBlokProps extends SbBlokData {
  backgroundColor: string;
  title: Array<TitleBlokProps>;
  items: Array<FaqItemBlokProps>;
  id?: string;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
  contentWidth: 'narrow' | 'wide';
  ctaLink: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
}

export interface FaqProps {
  blok: FaqBlokProps;
}

const Faq = ({ blok, ...restProps }: FaqProps) => {
  const [expandedItemId, setExpandedItemId] = useState<string | undefined>();

  const {
    title: [title],
    items,
    backgroundColor,
    id,
    visibility,
    visibilityOnMobile,
    contentWidth = 'narrow',
    ctaLink = [],
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('w-full text-GSdeep/900', {
        'bg-GSbase/white':
          !backgroundColor || backgroundColor === 'GSbase/white',
        'bg-GSparchment/200': backgroundColor === 'GSparchment/200',
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer>
        <div className="w-full grid grid-cols-6 sm:grid-cols-12 gap-x-2 gap-y-8 sm:gap-x-4 sm:gap-y-6 lg:gap-8">
          <div
            className={cx('grid col-span-6 sm:col-span-12', {
              'lg:col-start-2 lg:col-span-10': contentWidth === 'wide',
              'sm:col-start-2 sm:col-span-10 lg:col-start-3 lg:col-span-8':
                contentWidth === 'narrow',
            })}
          >
            <TitleBlok blok={title} headingLevel="h2" />
          </div>
          <div
            className={cx(
              'w-full grid col-span-6 sm:col-span-12 gap-2 sm:gap-4',
              {
                'lg:col-start-2 lg:col-span-10': contentWidth === 'wide',
                'sm:col-start-2 sm:col-span-10 lg:col-start-3 lg:col-span-8':
                  contentWidth === 'narrow',
              },
            )}
          >
            {items?.map((item: FaqItemBlokProps) => (
              <FaqItem
                blok={item}
                key={item._uid}
                isExpanded={expandedItemId === item._uid}
                setExpanded={setExpandedItemId}
                {...restProps}
              />
            ))}
          </div>
        </div>
        {ctaLink && ctaLink.length > 0 && (
          <div className="w-full flex flex-col sm:flex-row gap-2 sm:gap-8 justify-center">
            {ctaLink.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </div>
        )}
      </PageContainer>
    </section>
  );
};

export default Faq;
