import FormikDropdown from '@/components/Dropdown/FormikDropdown';
import { FormDataType } from '@/helpers/form/formHelpersTypes';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FieldProps, FormikErrors, FormikTouched } from 'formik';

const FIELD_ID = 'schoolGrade';

export const FieldData: FormDataType = {
  hubspotId: 'school_grade',
  id: FIELD_ID,
  type: 'text',
  validationType: 'string',
} as const;

export interface FormSchoolGradeFieldBlokProps extends SbBlokData {
  label: string;
  placeholder: string;
  readOnly: boolean;
  formikErrors: FormikErrors<{ [FIELD_ID]: string }>;
  showLabel?: boolean;
  touched: FormikTouched<{ [FIELD_ID]: boolean }>;
  config?: { content: { options: Array<{ label: string; value: string }> } };
}

interface FormSchoolGradeFieldProps extends FieldProps {
  blok: FormSchoolGradeFieldBlokProps;
}

const FormSchoolGradeField = ({
  blok,
  ...props
}: FormSchoolGradeFieldProps) => {
  const {
    label,
    placeholder,
    formikErrors,
    touched,
    readOnly,
    showLabel = true,
    config: { content: { options = [] } = {} } = {},
  } = blok;

  const error =
    formikErrors[FIELD_ID] && touched[FIELD_ID] ? formikErrors[FIELD_ID] : '';

  return (
    <div {...storyblokEditable(blok)} className="h-full">
      <FormikDropdown
        disabled={readOnly}
        hubspotId={FieldData.hubspotId}
        label={showLabel ? label : undefined}
        placeholder={placeholder}
        options={options.map(
          ({
            label: subjectLabel,
            value: subjectValue,
          }: {
            label: string;
            value: string;
          }) => ({
            label: subjectLabel,
            value: subjectValue,
          }),
        )}
        error={error}
        {...props}
      />
    </div>
  );
};

export default FormSchoolGradeField;
