import cx from 'classnames';

export const ArrowDownIcon = ({ className }: { className: string }) => (
  <svg
    className={cx('stroke-GSdeep/500', className)}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.2 9.60005L12 16.8L4.79995 9.60005" strokeLinecap="round" />
  </svg>
);
