import { SbBlokData } from '@storyblok/react';
import cx from 'classnames';

export interface BackgroundController {
  deviceClassNames: string;
}

interface BackgroundControllerBlokProps extends SbBlokData {
  background?: 'transparent' | 'GSparchment/200' | 'device' | '';
  desktop?: 'transparent' | 'GSparchment/200' | '';
  mobile?: 'transparent' | 'GSparchment/200' | '';
  tablet?: 'transparent' | 'GSparchment/200' | '';
}

interface BackgroundControllerProps {
  background: Array<BackgroundControllerBlokProps>;
}

const buildDeviceClassNames = (props: BackgroundControllerBlokProps) => {
  const { background, desktop, mobile, tablet } = props;

  switch (background) {
    case 'transparent':
      return '!border-none bg-transparent';
    case 'GSparchment/200':
      return 'bg-GSparchment/200';
    case 'device':
      return cx({
        'bg-transparent !border-none': mobile === 'transparent',
        'bg-GSparchment/200': mobile === 'GSparchment/200',
        'sm:bg-transparent sm:!border-none': tablet === 'transparent',
        'sm:bg-GSparchment/200': tablet === 'GSparchment/200',
        'lg:bg-transparent lg:!border-none': desktop === 'transparent',
        'lg:bg-GSparchment/200': desktop === 'GSparchment/200',
      });
    default:
      return '';
  }
};

export const useBackgroundController = (
  componentBlok: SbBlokData,
): BackgroundController => {
  const { background: [backgroundBlok] = [] } =
    componentBlok as unknown as BackgroundControllerProps;

  const {
    background = 'GSparchment/200',
    desktop = 'GSparchment/200',
    mobile = 'GSparchment/200',
    tablet = 'GSparchment/200',
  } = (backgroundBlok as BackgroundControllerBlokProps) || {};

  return {
    deviceClassNames: buildDeviceClassNames({
      background: background === '' ? 'GSparchment/200' : background,
      mobile: mobile === '' ? 'GSparchment/200' : mobile,
      tablet: tablet === '' ? 'GSparchment/200' : tablet,
      desktop: desktop === '' ? 'GSparchment/200' : desktop,
    }),
  };
};
