import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';
import PageContainer from '@/components/PageContainer/PageContainer';
import CtaButton, { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import CtaLink, { CtaLinkBlokProps } from '../CtaLink/CtaLink';
import { WatchIcon, CalendarIcon, StarIcon, InfoIcon } from './Icons';
import Title, { TitleBlokProps } from '../Title/Title';

export interface PricesSectionBlokProps extends SbBlokData {
  averageCostPerLesson: string;
  averageCostPerLessonText: string;
  averageCostPerLessonMostPopular: string;
  averageCostPerLessonTextMostPopular: string;
  backgroundColor: 'GSpurple/100' | 'GSpurple/50' | 'GSbase/white';
  backgroundColorCustom: 'GSpurple/100' | 'GSpurple/50' | 'GSbase/white';
  backgroundColorMostPopular: 'GSpurple/100' | 'GSpurple/50' | 'GSbase/white';
  contact: string;
  ctaButton: Array<CtaButtonBlokProps>;
  ctaButtonMostPopular: Array<CtaButtonBlokProps>;
  ctaLink: Array<CtaLinkBlokProps>;
  custom: string;
  customPackage: string;
  id?: string;
  lessonDuration: string;
  lessonDurationMostPopular: string;
  lessonDurationText: string;
  lessonDurationTextMostPopular: string;
  lessonsPerWeek: string;
  lessonsPerWeekText: string;
  lessonsPerWeekMostPopular: string;
  lessonsPerWeekTextMostPopular: string;
  membershipDuration: string;
  membershipDurationText: string;
  membershipDurationMostPopular: string;
  membershipDurationTextMostPopular: string;
  packageType: string;
  packageTypeMostPopular: string;
  title: Array<TitleBlokProps>;
  tooltip: string;
  tooltipMostPopular: string;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

export interface PricesSectionProps {
  blok: PricesSectionBlokProps;
}

const PricesSection = ({ blok }: PricesSectionProps) => {
  const {
    averageCostPerLesson,
    averageCostPerLessonText,
    averageCostPerLessonMostPopular,
    averageCostPerLessonTextMostPopular,
    backgroundColor,
    backgroundColorCustom,
    backgroundColorMostPopular,
    contact,
    ctaButton: [cta] = [],
    ctaButtonMostPopular: [ctaMP] = [],
    ctaLink: [ctaLink] = [],
    custom,
    customPackage,
    id,
    lessonDuration,
    lessonDurationMostPopular,
    lessonDurationText,
    lessonDurationTextMostPopular,
    lessonsPerWeek,
    lessonsPerWeekText,
    lessonsPerWeekMostPopular,
    lessonsPerWeekTextMostPopular,
    membershipDuration,
    membershipDurationText,
    membershipDurationMostPopular,
    membershipDurationTextMostPopular,
    packageType,
    packageTypeMostPopular,
    title: [title] = [],
    tooltip,
    tooltipMostPopular,
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('bg-GSbase/white', {
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer>
        {title && (
          <Title
            blok={{
              ...title,
            }}
          />
        )}
        <div className="w-full lg:grid lg:grid-cols-12">
          <div className="lg:col-start-2 lg:col-span-10 flex flex-col gap-2 sm:grid sm:gap-4 lg:flex lg:flex-row lg:gap-8">
            <div
              className={cx(
                'lg:w-1/3 border rounded-sm shadow-md border-GSpurple/300 flex flex-col gap-4 pb-4 sm:pb-6 sm:gap-6 lg:pb-8 hover:bg-GSpurple/100',
                {
                  'bg-GSpurple/50': backgroundColor === 'GSpurple/50',
                  'bg-GSpurple/100': backgroundColor === 'GSpurple/100',
                  'bg-GSbase/white': backgroundColor === 'GSbase/white',
                },
              )}
            >
              {packageType.length > 0 ? (
                <div className="py-3 bg-GSdarkpurple/500 border-2 border-GSdarkpurple/500 rounded-sm text-GSbase/white text-sm font-bold text-worksans text-center">
                  {packageType}
                </div>
              ) : (
                <div className="py-[24px] text-GSbase/white text-sm font-bold text-worksans text-center" />
              )}
              <div className="px-6 flex flex-col gap-2 sm:gap-4 lg:gap-8">
                <div className="flex gap-x-4 justify-center items-center text-GSblue/500">
                  <div className="text-5xl font-extrabold text-worksans text-GSblue/500">
                    {lessonsPerWeek}
                  </div>
                  <div className="inline font-medium text-worksans text-GSblue/500">
                    {lessonsPerWeekText}
                  </div>
                </div>

                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center justify-start gap-x-2">
                      <WatchIcon />
                      <div className="text-worksans text-GSdeep/900">
                        {lessonDurationText}
                        <div className="inline text-worksans text-base font-bold text-GSdeep/900">
                          {lessonDuration}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-start gap-x-2">
                      <CalendarIcon />
                      <div className="text-worksans text-GSdeep/900">
                        {membershipDurationText}
                        <div className="inline text-worksans text-base font-bold text-GSdeep/900">
                          {membershipDuration}
                        </div>
                      </div>
                      <div className="group relative flex justify-center">
                        <InfoIcon />
                        <span className="absolute rounded scale-0 border border-GSsteel/300 bg-GSbase/white text-GSdeep/900 p-2 text-xs group-hover:scale-100 w-[175px] text-center text-xs bottom-full">
                          {tooltip}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-start gap-x-2">
                      <StarIcon />
                      <div className="text-worksans text-GSdeep/900">
                        {averageCostPerLessonText}
                        <div className="inline text-worksans text-base font-bold text-GSdeep/900">
                          {averageCostPerLesson}
                        </div>
                      </div>
                    </div>
                  </div>
                  {cta && <CtaButton blok={cta} type="primary" size="btn-MD" />}
                </div>
              </div>
            </div>

            <div
              className={cx(
                'lg:w-1/3 border rounded-sm shadow-md border-GSpurple/300 flex flex-col gap-4 sm:gap-6 pb-4 sm:pb-6 lg:pb-8 hover:bg-GSpurple/100',
                {
                  'bg-GSpurple/50':
                    backgroundColorMostPopular === 'GSpurple/50',
                  'bg-GSpurple/100':
                    backgroundColorMostPopular === 'GSpurple/100',
                  'bg-GSbase/white':
                    backgroundColorMostPopular === 'GSbase/white',
                },
              )}
            >
              <div className="py-3 bg-GSdarkpurple/500 text-GSbase/white text-sm font-bold text-worksans text-center border-2 border-GSdarkpurple/500 rounded-sm">
                {packageTypeMostPopular}
              </div>
              <div className="flex flex-col px-6 gap-2 sm:gap-4 lg:gap-8">
                <div className="flex gap-x-4 justify-center items-center">
                  <div className="text-5xl font-extrabold text-worksans text-GSblue/500">
                    {lessonsPerWeekMostPopular}
                  </div>
                  <div className="inline font-medium text-worksans text-GSblue/500">
                    {lessonsPerWeekTextMostPopular}
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap-2 font-normal">
                    <div className="flex items-center justify-start gap-x-2">
                      <WatchIcon />
                      <div className="text-worksans text-GSdeep/900">
                        {lessonDurationTextMostPopular}
                        <div className="inline text-worksans text-base font-bold text-GSdeep/900">
                          {lessonDurationMostPopular}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-start gap-x-2">
                      <CalendarIcon />
                      <div className="text-worksans text-GSdeep/900">
                        {membershipDurationTextMostPopular}
                        <div className="inline text-worksans text-base text-GSdeep/900 font-bold">
                          {membershipDurationMostPopular}
                        </div>
                      </div>
                      <div className="group relative flex justify-center">
                        <InfoIcon />
                        <span className="absolute scale-0 border rounded border-GSsteel/300 bg-GSbase/white text-GSdeep/900 p-2 text-xs group-hover:scale-100 w-[175px] text-center text-xs bottom-full">
                          {tooltipMostPopular}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-start gap-x-2">
                      <StarIcon />
                      <div className="text-worksans text-GSdeep/900">
                        {averageCostPerLessonTextMostPopular}
                        <div className="inline text-worksans text-base font-bold text-GSdeep/900">
                          {averageCostPerLessonMostPopular}
                        </div>
                      </div>
                    </div>
                  </div>
                  {ctaMP && (
                    <CtaButton blok={ctaMP} type="primary" size="btn-MD" />
                  )}
                </div>
              </div>
            </div>

            <div
              className={cx(
                'lg:w-1/3 sm:col-start-1 sm:col-span-2 border border-GSpurple/500 rounded-sm shadow-md flex flex-col justify-center gap-6 sm:py-6 hover:bg-GSpurple/100 pb-4 pt-4',
                {
                  'bg-GSpurple/50': backgroundColorCustom === 'GSpurple/50',
                  'bg-GSpurple/100': backgroundColorCustom === 'GSpurple/100',
                  'bg-GSbase/white': backgroundColorCustom === 'GSbase/white',
                },
              )}
            >
              <div className="flex flex-col gap-2">
                <div className="text-GSblue/500 text-[32px] leading-[48px] font-worksans text-center font-extrabold text-GSblue/500">
                  {custom}
                </div>
                <div className="flex flex-col sm:gap-2 px-8 sm:flex-row sm:justify-center lg:flex lg:flex-col text-center">
                  <div className="text-GSdeep/900 font-normal text-worksans text-base sm:text-lg lg:text-xl text-GSdeep/900">
                    {customPackage}
                  </div>
                  <div className="font-GSdeep/900 font-bold text-worksans text-base lg:text-xl sm:text-lg lg:text-xl text-GSdeep/900">
                    {contact}
                  </div>
                </div>
              </div>

              <div>{ctaLink && <CtaLink blok={ctaLink} />}</div>
            </div>
          </div>
        </div>
      </PageContainer>
    </section>
  );
};

export default PricesSection;
