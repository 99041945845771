import { subjectMapping, hsSubjectsMapping } from '@gostudent/subjects';

const isHubspotValue = (subject: string): boolean =>
  Object.values(hsSubjectsMapping).includes(subject);

export const mapToHubspotValue = (subject: string): string => {
  if (isHubspotValue(subject)) {
    return subject;
  }

  return hsSubjectsMapping[subject] || subject;
};

const isMfeValue = (subject: string): boolean =>
  Object.values(subjectMapping).includes(subject);

export const mapToMfeValue = (subject: string): string => {
  if (isMfeValue(subject)) {
    return subject;
  }

  return subjectMapping[subject] || subject;
};
