import { CookieValueTypes, getCookie, hasCookie } from 'cookies-next';

export const isDefinedNonEmptyStringCookie = (value: CookieValueTypes) => {
  if (typeof value === 'string') {
    if (value.trim() === '') {
      return false;
    }

    return true;
  }

  return false;
};

export const getReferrer = () => {
  const cookieName = 'referring';

  if (hasCookie(cookieName)) {
    const value = getCookie(cookieName);
    if (isDefinedNonEmptyStringCookie(value)) {
      return value;
    }
  }

  try {
    // eslint-disable-next-line no-restricted-globals
    if (top) {
      // eslint-disable-next-line no-restricted-globals
      const topValue = top.document.referrer;
      if (isDefinedNonEmptyStringCookie(topValue)) {
        return topValue;
      }
    }
  } catch (err) {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (parent) {
        // eslint-disable-next-line no-restricted-globals
        const parentValue = parent.document.referrer;
        if (isDefinedNonEmptyStringCookie(parentValue)) {
          return parentValue;
        }
      }
      // eslint-disable-next-line no-empty
    } catch (parentErr) {}
  }

  const docValue = document.referrer;
  if (isDefinedNonEmptyStringCookie(docValue)) {
    return docValue;
  }

  return window.location.href;
};
