import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';
import { useState } from 'react';

import { CtaButtonBlokProps } from '@/blocks/CtaButton/CtaButton';
import { CtaLinkBlokProps } from '@/blocks/CtaLink/CtaLink';
import { WebsiteMenuMobileOverlay } from '@/components';
import PageContainer from '@/components/PageContainer/PageContainer';
import WebsiteMenuDropdown, {
  WebsiteMenuDropdownBlokProps,
} from '@/blocks/WebsiteMenuDropdown/WebsiteMenuDropdown';
import { WebsiteMenuLinkBlokProps } from '@/blocks/WebsiteMenuLink/WebsiteMenuLink';
import StoryblokLink from '@/components/StoryblokLink/StoryblokLink';
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import LanguageSwitcher, {
  LanguageSwitcherBlokProps,
} from '@/blocks/LanguageSwitcher/LanguageSwitcher';
import { LanguageSwitcherIcon, MenuIcon } from './icons';
import ImageSet, { ImageSetBlokProps } from '../ImageSet/ImageSet';
import { usePositionController } from '../PositionController/PositionController';
import { useBackgroundController } from '../BackgroundController/BackgroundController';

export interface WebsiteHeaderBlokProps extends SbBlokData {
  actions: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
  logoImage: Asset;
  logoImageSet?: Array<ImageSetBlokProps>;
  logoLink: Link;
  menuItems: Array<WebsiteMenuDropdownBlokProps | WebsiteMenuLinkBlokProps>;
  languageSwitcher: Array<
    WebsiteMenuDropdownBlokProps | LanguageSwitcherBlokProps
  >;
  menuLabel: string;
}

interface WebsiteHeaderProps {
  blok: WebsiteHeaderBlokProps;
}

const WebsiteHeader = ({ blok, ...restProps }: WebsiteHeaderProps) => {
  const backgroundController = useBackgroundController(blok);
  const positionController = usePositionController(blok);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileLanguageOpen, setMobileLanguageOpen] = useState(false);

  const {
    actions: [firstAction, secondAction] = [],
    languageSwitcher: [languageSwitcher] = [],
    logoImage,
    logoImageSet: [logoImageSet] = [],
    logoLink,
    menuItems,
    menuLabel,
  } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx('top-0 z-50 w-full', positionController.deviceClassNames)}
    >
      <nav
        className={cx(
          'w-full border-y-[1px] border-GSparchment/400',
          backgroundController.deviceClassNames,
        )}
      >
        <PageContainer className="!py-5">
          <div className="flex flex-row w-full gap-2 sm:gap-4 lg:gap-8 items-center">
            <div className="flex flex-1 justify-start">
              <StoryblokLink link={logoLink}>
                {logoImageSet ? (
                  <ImageSet blok={logoImageSet} />
                ) : (
                  <StoryblokImage
                    blok={logoImage}
                    fallbackSrc="/assets/image.svg"
                    className="h-8 sm:h-10"
                  />
                )}
              </StoryblokLink>
            </div>
            <div className="hidden md:flex ml-auto gap-x-6 mr-0 ">
              {firstAction && (
                <StoryblokComponent
                  blok={firstAction}
                  size="btn-SM"
                  hasRTicon={false}
                />
              )}
              {secondAction && (
                <StoryblokComponent
                  blok={secondAction}
                  size="btn-SM"
                  hasRTicon={false}
                />
              )}
            </div>
            {languageSwitcher &&
              languageSwitcher.component === 'websiteMenuDropdown' && (
                <>
                  <div className="select-none hidden lg:flex items-center cursor-pointer">
                    <WebsiteMenuDropdown
                      blok={{
                        ...(languageSwitcher as WebsiteMenuDropdownBlokProps),
                        isLanguageSwitcher: true,
                      }}
                    />
                  </div>
                  <div
                    className="select-none flex items-center cursor-pointer lg:hidden"
                    onClick={() => setMobileLanguageOpen(true)}
                  >
                    <LanguageSwitcherIcon className="w-9 h-5 sm:w-11 sm:h-6" />
                  </div>
                </>
              )}
            {languageSwitcher &&
              languageSwitcher.component === 'languageSwitcher' && (
                <LanguageSwitcher
                  blok={languageSwitcher as LanguageSwitcherBlokProps}
                />
              )}
            {menuItems && menuItems.length !== 0 && (
              <div
                className="select-none flex items-center ml-8 cursor-pointer lg:hidden"
                onClick={() => setMobileMenuOpen(true)}
              >
                <MenuIcon />
              </div>
            )}
          </div>
        </PageContainer>
      </nav>
      {menuItems && menuItems.length !== 0 && (
        <nav className="w-full border-b-[1px] border-GSparchment/400 bg-GSbase/white hidden lg:block">
          <PageContainer className="!py-0">
            <div className="flex-row w-full justify-center flex py-2 lg:gap-x-3 xl:gap-x-10">
              {menuItems.map((nestedBlok: SbBlokData) => (
                <StoryblokComponent
                  blok={nestedBlok}
                  key={nestedBlok._uid}
                  {...restProps}
                />
              ))}
            </div>
          </PageContainer>
        </nav>
      )}
      {languageSwitcher &&
        languageSwitcher.component === 'websiteMenuDropdown' && (
          <WebsiteMenuMobileOverlay
            isOpen={mobileLanguageOpen}
            closeModal={() => setMobileLanguageOpen(false)}
            menuItems={
              (languageSwitcher as WebsiteMenuDropdownBlokProps)
                ?.items as Array<WebsiteMenuLinkBlokProps>
            }
            actions={[]}
            label={(languageSwitcher as WebsiteMenuDropdownBlokProps)?.title}
          />
        )}
      <WebsiteMenuMobileOverlay
        isOpen={mobileMenuOpen}
        closeModal={() => setMobileMenuOpen(false)}
        menuItems={menuItems}
        actions={blok.actions}
        label={menuLabel}
      />
    </div>
  );
};

export default WebsiteHeader;
