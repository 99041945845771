export const CloseIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1573 7.55375C25.3525 7.35849 25.3525 7.0419 25.1573 6.84664C24.962 6.65138 24.6454 6.65138 24.4501 6.84664L15.9788 15.318L7.5535 6.89263C7.35824 6.69737 7.04166 6.69737 6.8464 6.89263C6.65114 7.08789 6.65114 7.40447 6.8464 7.59974L15.2717 16.0251L6.89213 24.4047C6.69686 24.5999 6.69686 24.9165 6.89213 25.1118C7.08739 25.307 7.40397 25.307 7.59923 25.1118L15.9788 16.7322L24.4044 25.1578C24.5997 25.353 24.9163 25.353 25.1115 25.1578C25.3068 24.9625 25.3068 24.6459 25.1115 24.4507L16.6859 16.0251L25.1573 7.55375Z"
      fill="#0B2FAC"
    />
  </svg>
);
