import Head from 'next/head';
import { SbBlokData } from '@storyblok/react';

export interface StructuredDataProductBlockProps extends SbBlokData {
  description: string;
  highPrice: number;
  lowPrice: number;
  name: string;
  offerCount: number;
  priceCurrency: string;
  ratingValue: number;
  reviewCount: number;
}

interface StructuredDataProductProps {
  blok: StructuredDataProductBlockProps;
}

const generateStructuredData = (blok: StructuredDataProductBlockProps) => {
  const productLdJsonObject = {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    name: blok.name,
    description: blok.description,
    brand: 'Gostudent',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: blok.ratingValue,
      reviewCount: blok.reviewCount,
    },
    offers: {
      '@type': 'AggregateOffer',
      priceCurrency: blok.priceCurrency,
      highPrice: blok.highPrice,
      lowPrice: blok.lowPrice,
      offerCount: blok.offerCount,
      seller: {
        '@type': 'Organization',
        name: 'Gostudent',
      },
    },
  };

  return productLdJsonObject;
};

const StructuredDataProduct = ({ blok }: StructuredDataProductProps) => (
  <Head>
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(generateStructuredData(blok)),
      }}
    />
  </Head>
);

export default StructuredDataProduct;
