import IconLinkComponent, { IconId } from '@/components/IconLink/IconLink';
import { setIconColor, setIconSize } from '@/helpers/layout/layoutHelpers';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

export interface IconLinkBlokProps extends SbBlokData {
  id: IconId;
  link: Link;
  image?: Asset;
}
export type IconColor = 'black' | 'blue' | 'white';
export type IconSize = 'medium' | 'large';

interface IconLinkProps {
  blok: IconLinkBlokProps;
  color: IconColor;
  size: IconSize;
}

const IconLink = ({ blok, color, size }: IconLinkProps) => (
  <div {...storyblokEditable(blok)}>
    <IconLinkComponent
      color={setIconColor(color)}
      id={blok.id}
      size={setIconSize(size)}
      link={blok.link}
      image={blok.image}
    />
  </div>
);
export default IconLink;
