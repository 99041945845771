import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import {
  SbBlokData,
  storyblokEditable,
  renderRichText,
  ISbRichtext,
} from '@storyblok/react';
import mpcRichTextSchema from './richTextSchema';

export interface MessageBlokProps extends SbBlokData {
  image?: Asset;
  text?: ISbRichtext;
}

interface MessageProps {
  blok: MessageBlokProps;
}

const Message = ({ blok }: MessageProps) => {
  const { image, text } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="w-full text-sm font-medium text-GSdeep/900 flex justify-center gap-x-1"
    >
      {text && (
        <div
          className="break-words min-w-0 text-center"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: renderRichText(text, {
              schema: mpcRichTextSchema,
            }),
          }}
        />
      )}
      {image && <StoryblokImage blok={image} className="self-start" />}
    </div>
  );
};

export default Message;
