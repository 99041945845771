import { Button } from '@/components';
import Dropdown from '@/components/Dropdown/Dropdown';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { ClearIcon } from '../../icons/Clear';
import { SearchIcon } from '../../icons/Search';

interface Filter {
  name: string;
  id: number;
}

export interface JobFiltersBlokProps extends SbBlokData {
  title: string;
  clearLabel: string;
  locationFilterLabel: string;
  departmentFilterLabel: string;
  searchButtonLabel: string;
}

export interface JobFiltersProps {
  blok: JobFiltersBlokProps;
  onFilterChange: Function;
  onFilterSearch: () => void;
  onClearFilters: () => void;
  departments: Array<Filter>;
  locations: Array<GreenHouseOffices>;
  selectedLocation: string;
  selectedDepartment: string;
}

const JobFilters = ({
  blok,
  onFilterChange,
  onFilterSearch,
  onClearFilters,
  departments = [],
  locations = [],
  selectedLocation,
  selectedDepartment,
}: JobFiltersProps) => {
  const {
    title = '',
    clearLabel = '',
    locationFilterLabel = '',
    departmentFilterLabel = '',
    searchButtonLabel = '',
  } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="w-full flex flex-col bg-GSparchment/200 p-4 py-4 px-4 gap-2 sm:gap-4 lg:gap-6 sm:py-10 sm:px-6"
    >
      <div>
        <span className="w-full [word-break:break-word] font-raleway text-lg font-bold text-GSblue/600 lg:text-2xl">
          {title}
        </span>
        <div className="flex flex-row justify-end items-end text-GSblue/500 underline text-sm font-medium leading-5 gap-2 py-3">
          <div
            className="cursor-pointer items-center flex gap-2"
            onClick={onClearFilters}
          >
            <div className="[word-break:break-word]">{clearLabel}</div>
            <ClearIcon />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <Dropdown
          name="location"
          options={locations.map((location) => ({
            value: location.name,
            label: location.location,
          }))}
          onChange={(option) => onFilterChange('location', option)}
          getValue={() =>
            selectedLocation
              ? {
                  value: selectedLocation,
                  label: selectedLocation,
                }
              : {
                  value: locationFilterLabel,
                  label: locationFilterLabel,
                }
          }
          placeholder={locationFilterLabel}
        />
        <Dropdown
          name="department"
          options={departments.map((department) => ({
            value: department.name,
            label: department.name,
          }))}
          onChange={(option) => onFilterChange('department', option)}
          getValue={() =>
            selectedDepartment
              ? {
                  value: selectedDepartment,
                  label: selectedDepartment,
                }
              : {
                  value: departmentFilterLabel,
                  label: departmentFilterLabel,
                }
          }
          placeholder={departmentFilterLabel}
        />
        <Button
          onClick={onFilterSearch}
          label={searchButtonLabel}
          type="primary"
          hasRTicon
          iconRT={<SearchIcon />}
          size="btn-MD"
        />
      </div>
    </div>
  );
};

export default JobFilters;
