import { EVENTS } from './constants';
import { EVENTS_BUILDER } from './gtmEventBuilder';

export const getDataLayer = () => window.dataLayer || [];

export const getEventBuilder = (event: string) =>
  EVENTS_BUILDER[event]
    ? EVENTS_BUILDER[event]
    : EVENTS_BUILDER[EVENTS.GENERIC_EVENT];

export const pushEventToDataLayer = async (
  event: string,
  data: { [key: string]: string | boolean | number | object },
) => {
  const dataLayer = getDataLayer();

  const eventMethod = getEventBuilder(event);

  if (eventMethod) {
    const { fields } = await eventMethod(data);
    dataLayer.push({ event, ...fields });
  }
};
