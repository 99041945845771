import Head from 'next/head';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';
import Link, { LinkBlokProps } from '../Link/Link';

export interface BreadcrumbsBlockProps extends SbBlokData {
  links?: Array<LinkBlokProps>;
  id?: string;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

interface BreadcrumbsProps {
  blok: BreadcrumbsBlockProps;
}

const generateStructuredData = (
  links: Array<LinkBlokProps>,
  id: string = '',
) => {
  const breadcrumbsLdJsonObject = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: links.map(({ link, label }, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: label,
      item: link.url,
    })),
  };

  return (
    <script
      data-testid={`${id}-structured-data`}
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(breadcrumbsLdJsonObject),
      }}
    />
  );
};

const Breadcrumbs = ({ blok }: BreadcrumbsProps) => {
  const { links = [], id, visibility, visibilityOnMobile } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return links && links.length > 0 ? (
    <>
      <Head>{generateStructuredData(links, id)}</Head>
      <div
        {...storyblokEditable(blok)}
        className={cx('w-full max-w-full sm:w-auto', {
          'hidden sm:block':
            visibility === 'enabled' && visibilityOnMobile === 'disabled',
          hidden: visibility === 'disabled',
        })}
        id={id}
      >
        <div className="flex flex-row px-4 py-4 sm:px-6 sm:py-6 lg:px-10 text-GSdeep/900 flex-wrap border-b border-GSparchment/400">
          {links.map(
            (link: LinkBlokProps, index: number, arr: Array<LinkBlokProps>) => (
              <div
                key={link._uid}
                className="flex text-xs sm:text-sm font-normal"
              >
                <Link blok={link} />
                {index + 1 !== arr.length && (
                  <span className="px-2">&nbsp;/</span>
                )}
              </div>
            ),
          )}
        </div>
      </div>
    </>
  ) : null;
};

export default Breadcrumbs;
