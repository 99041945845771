import { FieldInputProps, FieldProps, FormikProps, FormikValues } from 'formik';
import { OptionType } from '@/helpers/form/formHelpersTypes';
import CheckboxGroup from './CheckboxGroup';

export interface FormikCheckboxGroupProps extends FieldProps {
  description?: string;
  disabled?: boolean;
  error?: string;
  field: FieldInputProps<Array<string> | string>;
  form: FormikProps<FormikValues>;
  isMulti?: boolean;
  label?: string;
  options: OptionType[];
}

const FormikCheckboxGroup = ({
  field,
  form,
  isMulti,
  options,
  ...rest
}: FormikCheckboxGroupProps) => {
  const getValues = (): Array<string> => {
    if (Array.isArray(field.value)) {
      return field.value;
    }

    if (typeof field.value === 'string' && field.value !== '') {
      return [field.value];
    }

    return [];
  };

  const onChange = (values: Array<string>) => {
    if (isMulti) {
      form.setFieldValue(field.name, values);
    } else {
      form.setFieldValue(field.name, values.reverse()[0]);
    }
  };

  return (
    <CheckboxGroup
      name={field.name}
      onChange={onChange}
      values={getValues()}
      options={options}
      {...rest}
    />
  );
};

export default FormikCheckboxGroup;
