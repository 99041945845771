import cx from 'classnames';

interface PageContainerProps {
  className?: string;
  children: React.ReactNode;
  rootClassName?: string;
}

// Mobile: 0px -> 639px
// Tablet: 640px -> 1023px => sm
// Desktop: 1024px -> 1536px => lg
// Big screens: 1537px -> and up => 2xl => w-full and max-w-[1536px]
// - px-10 in parent to have some paddings on screen size from range (1536px, 1536px + 2 * 40px)
const PageContainer = ({
  className,
  rootClassName,
  children,
}: PageContainerProps) => {
  const mobileClasses = 'flex flex-col w-full items-center px-4 py-8 gap-y-4';
  const tabletClasses = 'sm:px-6 sm:py-12 sm:gap-y-6 sm:m-auto';
  const desktopClasses = 'lg:px-10 lg:py-16 lg:gap-x-8 lg:gap-y-10';
  const largeScreenClasses = '2xl:max-w-[1536px] 2xl:w-full';

  return (
    <div className={cx('overflow-x-clip w-full 2xl:px-10', rootClassName)}>
      <div
        className={cx(
          mobileClasses,
          tabletClasses,
          desktopClasses,
          largeScreenClasses,
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default PageContainer;
