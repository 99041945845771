import TextInput from '@/components/TextInput/TextInput';
import { FormDataType } from '@/helpers/form/formHelpersTypes';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';

const FIELD_ID = 'email';

export const FieldData: FormDataType = {
  hubspotId: 'email',
  id: FIELD_ID,
  type: 'text',
  validationType: 'string',
  validations: [
    {
      type: 'matches',
      key: 'email_error_message_valid',
      params: [/^\S+@\S+\.\S+$/],
    },
  ],
};

export interface EmailFieldBlokProps extends SbBlokData {
  emailLabel: string;
  emailPlaceholder: string;
  formikErrors: FormikErrors<{ email: string }>;
  touched: FormikTouched<{ email: boolean }>;
}

interface EmailFieldProps {
  blok: EmailFieldBlokProps;
  field: FieldInputProps<string>;
}

const EmailField = ({ blok, field }: EmailFieldProps) => {
  const { emailLabel, emailPlaceholder, formikErrors, touched } = blok;
  return (
    <div {...storyblokEditable(blok)}>
      <TextInput
        type="email"
        placeholder={emailPlaceholder}
        error={formikErrors.email && touched.email ? formikErrors.email : ''}
        {...field}
        label={emailLabel}
      />
    </div>
  );
};

export default EmailField;
