export const ScrollIcon = ({
  firstPartClassName,
  secondPartClassName,
  layout,
}: {
  firstPartClassName: string;
  secondPartClassName: string;
  layout: 'default' | 'reversed';
}) =>
  layout === 'default' ? (
    <svg viewBox="0 0 250 298" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M111.621 189.137C111.621 189.137 111.735 189.141 111.792 189.144C124.64 188.693 136.875 186.406 148.288 182.274C158.079 178.743 166.968 173.935 174.876 167.944C174.679 168.108 174.502 168.253 174.305 168.436C175.718 167.349 177.113 166.261 178.452 165.114C181.368 162.639 184.137 159.986 186.761 157.174C198.038 144.95 207.75 131.364 215.616 116.827C223.277 102.643 229.268 87.3618 233.421 71.3954C234.812 66.0545 235.998 60.6098 236.978 55.0996C238.884 44.4394 240.04 33.5006 240.402 22.4152C240.967 5.73083 239.761 -11.4261 236.861 -28.5946C233.943 -45.783 229.28 -63.1185 223.024 -80.1374C216.678 -97.2934 208.651 -114.289 199.152 -130.639C189.474 -147.282 178.158 -163.419 165.51 -178.619C152.554 -194.194 138.024 -208.955 122.38 -222.503L6.62741 -124.351C22.3705 -110.894 36.8811 -96.1147 49.8556 -80.5202C62.5039 -65.3394 73.8389 -49.2012 83.5161 -32.5391C93.0344 -16.1886 101.061 0.82589 107.387 18.0002C113.661 35.0198 118.306 52.3546 121.224 69.543C124.141 86.7504 125.346 103.926 124.782 120.592C124.419 131.696 123.263 142.635 121.358 153.276C120.378 158.805 119.173 164.249 117.8 169.591C116.052 176.252 114.023 182.788 111.658 189.177L111.621 189.137Z"
        className={firstPartClassName}
      />
      <path
        d="M-15.5982 287.108C1.5093 287.975 17.6568 285.696 32.3854 280.329C43.8505 276.182 54.0901 270.309 62.9485 262.797C64.2276 261.724 65.4877 260.651 66.8063 259.542C99.5424 231.805 141.373 196.357 174.169 168.547C166.305 174.479 157.517 178.773 147.77 182.303C136.42 186.416 114.28 189.157 111.537 189.255C107.784 189.369 103.981 189.329 100.145 189.154C83.0183 188.286 65.8215 184.385 48.9913 177.468C31.5608 170.337 15.3289 160.318 0.703661 147.678L-114.987 245.749C-100.301 258.296 -84.088 268.314 -66.6773 275.464C-49.8653 282.361 -32.6685 286.262 -15.5792 287.109L-15.5982 287.108Z"
        className={secondPartClassName}
      />
    </svg>
  ) : (
    <svg viewBox="0 0 250 298" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M138.367 189.425C138.367 189.425 138.253 189.43 138.196 189.432C125.347 188.982 113.111 186.692 101.697 182.554C91.9057 179.018 83.0159 174.204 75.1073 168.204C75.3042 168.368 75.4812 168.513 75.6788 168.696C74.2652 167.608 72.8706 166.519 71.5308 165.37C68.6156 162.891 65.8456 160.234 63.2217 157.419C51.9448 145.176 42.2324 131.57 34.3671 117.011C26.7062 102.806 20.7153 87.5018 16.5629 71.5113C15.1717 66.1623 13.9857 60.7094 13.0066 55.1908C11.1008 44.5143 9.94542 33.5589 9.58382 22.4566C9.02016 5.74662 10.2273 -11.4366 13.1283 -28.6316C16.0476 -45.8465 20.7125 -63.2089 26.97 -80.2543C33.3173 -97.437 41.3461 -114.459 50.8472 -130.834C60.5272 -147.504 71.8454 -163.666 84.4951 -178.89C97.4535 -194.49 111.986 -209.275 127.633 -222.844L243.39 -124.549C227.645 -111.071 213.132 -96.2679 200.155 -80.6487C187.505 -65.4439 176.168 -49.2802 166.488 -32.592C156.968 -16.2159 148.94 0.825237 142.613 18.0263C136.336 35.0724 131.69 52.434 128.771 69.6489C125.852 86.8828 124.646 104.085 125.209 120.776C125.571 131.897 126.727 142.853 128.632 153.51C129.612 159.048 130.816 164.5 132.189 169.85C133.937 176.521 135.965 183.067 138.331 189.465L138.367 189.425Z"
        className={firstPartClassName}
      />
      <path
        d="M265.596 287.547C248.488 288.416 232.34 286.135 217.611 280.761C206.146 276.609 195.906 270.727 187.048 263.205C185.769 262.131 184.509 261.056 183.19 259.946C150.455 232.171 108.625 196.674 75.8302 168.826C83.6938 174.766 92.482 179.065 102.229 182.6C113.579 186.719 135.719 189.462 138.463 189.56C142.216 189.674 146.019 189.634 149.855 189.458C166.982 188.588 184.18 184.68 201.011 177.752C218.442 170.609 234.675 160.575 249.302 147.916L364.99 246.121C350.302 258.688 334.088 268.721 316.677 275.882C299.864 282.79 282.666 286.698 265.577 287.548L265.596 287.547Z"
        className={secondPartClassName}
      />
    </svg>
  );
