import TextInput from '@/components/TextInput/TextInput';
import { FormDataType } from '@/helpers/form/formHelpersTypes';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';

const FIELD_ID = 'age';

export const FieldData: FormDataType = {
  hubspotId: 'age',
  id: FIELD_ID,
  type: 'text',
  validationType: 'number',
} as const;

export interface FormAgeFieldBlokProps extends SbBlokData {
  label: string;
  placeholder: string;
  readOnly: boolean;
  formikErrors: FormikErrors<{ [FIELD_ID]: string }>;
  touched: FormikTouched<{ [FIELD_ID]: boolean }>;
}

interface FormAgeFieldProps {
  blok: FormAgeFieldBlokProps;
  field: FieldInputProps<number>;
}

const FormAgeField = ({ blok, field }: FormAgeFieldProps) => {
  const { label, placeholder, formikErrors, touched, readOnly } = blok;

  return (
    <div {...storyblokEditable(blok)}>
      <TextInput
        hubspotId={FieldData.hubspotId}
        type="number"
        placeholder={placeholder}
        error={
          formikErrors[FIELD_ID] && touched[FIELD_ID]
            ? formikErrors[FIELD_ID] || ''
            : ''
        }
        {...field}
        label={label}
        disabled={readOnly}
      />
    </div>
  );
};

export default FormAgeField;
