import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';

import PageContainer from '@/components/PageContainer/PageContainer';
import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import Subject, { SubjectBlokProps } from '../Subject/Subject';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import { CtaLinkBlokProps } from '../CtaLink/CtaLink';

export interface SubjectListBlokProps extends SbBlokData {
  id?: string;
  subjects: Array<SubjectBlokProps>;
  title: Array<TitleBlokProps>;
  visibilityOnMobile: 'show' | 'hide';
  cta?: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
}

interface SubjectListProps {
  blok: SubjectListBlokProps;
}

const SubjectList = ({ blok }: SubjectListProps) => {
  const {
    cta = [],
    id,
    subjects,
    title: [title],
    visibilityOnMobile = 'show',
  } = blok;

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('bg-GSparchment/200 w-full', {
        'hidden sm:block': visibilityOnMobile === 'hide',
      })}
      id={id}
    >
      <PageContainer>
        <TitleBlok blok={title} headingLevel="h2" />
        <div className="flex flex-col w-full">
          <div
            className="
            grid grid-cols-2 gap-y-4
            sm:grid-cols-3 sm:gap-y-5
            lg:grid-cols-4 lg:gap-y-8
            "
          >
            {subjects?.map((subject: SubjectBlokProps) => (
              <Subject blok={subject} key={subject._uid} />
            ))}
          </div>
        </div>
        {cta && cta.length > 0 && (
          <div className="w-full flex flex-col sm:flex-row gap-2 sm:gap-8 justify-center">
            {cta.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </div>
        )}
      </PageContainer>
    </section>
  );
};

export default SubjectList;
