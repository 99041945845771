const HubspotScrapperField = ({
  name,
  value,
}: {
  name: string;
  value: string;
}) => (
  <>
    <label className="hidden" htmlFor={name}>
      {name}
    </label>
    <input name={name} id={name} className="hidden" value={value} readOnly />
  </>
);

export default HubspotScrapperField;
