import {
  components,
  MultiValueRemoveProps,
  OptionProps,
  ValueContainerProps,
} from 'react-select';
import cx from 'classnames';

import CheckboxInput from '../CheckboxInput/CheckboxInput';

export const ValueContainer = ({ children, ...rest }: ValueContainerProps) => {
  const { isMulti } = rest;
  return (
    <components.ValueContainer {...rest}>
      <div className={cx('flex gap-1 w-full pr-2', { 'flex-wrap': isMulti })}>
        {children}
      </div>
    </components.ValueContainer>
  );
};

export const MultiValueRemove = (props: MultiValueRemoveProps) => (
  <components.MultiValueRemove {...props}>
    <div className="group hover:bg-GSblue/500 w-full h-full flex items-center p-0.5 rounded-r">
      <svg
        className="group-hover:fill-GSbase/white"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7555 3.95365C12.9508 3.75839 12.9508 3.44181 12.7555 3.24654C12.5603 3.05128 12.2437 3.05128 12.0484 3.24654L7.98954 7.30542L3.95365 3.26954C3.75839 3.07428 3.44181 3.07428 3.24654 3.26954C3.05128 3.4648 3.05128 3.78138 3.24654 3.97664L7.28243 8.01253L3.26941 12.0256C3.07415 12.2208 3.07415 12.5374 3.26941 12.7327C3.46467 12.9279 3.78125 12.9279 3.97651 12.7327L7.98954 8.71964L12.0256 12.7557C12.2208 12.9509 12.5374 12.9509 12.7327 12.7557C12.9279 12.5604 12.9279 12.2438 12.7327 12.0485L8.69665 8.01253L12.7555 3.95365Z"
        />
      </svg>
    </div>
  </components.MultiValueRemove>
);

export const Option = (props: OptionProps<unknown>) => {
  const { label, isSelected, isMulti } = props;

  return (
    <components.Option {...props}>
      {isMulti ? (
        <CheckboxInput label={label} checked={isSelected} onChange={() => {}} />
      ) : (
        <div
          className={cx('flex flex-row w-full', {
            'bg-GSblue/100': isSelected,
          })}
        >
          <div>{label}</div>
          {isSelected && (
            <div className="ml-auto">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_3115_8676)">
                  <path
                    d="M3 12L7 16L18 5"
                    stroke="#0B2FAC"
                    strokeLinecap="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3115_8676">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          )}
        </div>
      )}
    </components.Option>
  );
};
