import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';

import StatisticItem, {
  StatisticItemBlokProps,
} from '@/blocks/StatisticItem/StatisticItem';
import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import PageContainer from '@/components/PageContainer/PageContainer';
import Image, { ImageBlokProps } from '../Image/Image';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import { CtaLinkBlokProps } from '../CtaLink/CtaLink';

export interface StatisticsBlokProps extends SbBlokData {
  badge: Array<ImageBlokProps>;
  cta: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
  id?: string;
  items: Array<StatisticItemBlokProps>;
  theme?: 'default' | 'ideal' | '';
  title: Array<TitleBlokProps>;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

export interface StatisticsProps {
  blok: StatisticsBlokProps;
}

const Statistics = ({ blok }: StatisticsProps) => {
  const {
    badge: [badge] = [],
    cta = [],
    id,
    items,
    theme,
    visibility,
    visibilityOnMobile,
    title: [title] = [],
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx({
        'bg-GSbase/white': theme !== 'ideal',
        'bg-GSblue/500': theme === 'ideal',
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer
        className={cx({
          '!gap-y-8 sm:!gap-y-14 !py-10 sm:!py-12 lg:!py-16': theme === 'ideal',
        })}
      >
        <div className="w-full grid grid-cols-6 sm:grid-cols-12 gap-x-2 sm:gap-x-4 lg:gap-x-8 gap-y-4 sm:gap-y-6 lg:gap-y-8 break-words">
          {title && (
            <div className="w-full col-span-full lg:col-start-2 lg:col-span-10">
              <TitleBlok blok={title} headingLevel="h2" theme={theme} />
            </div>
          )}
          <div
            className={cx(
              'gap-4 sm:gap-x-6 lg:gap-x-8 flex w-full flex-col sm:flex-row sm:items-center sm:justify-between col-span-full lg:col-start-2 lg:col-span-10',
              {
                ' p-4 sm:p-6 lg:p-10 bg-white rounded border border-solid border-GSsteel/100 shadow-lg':
                  theme !== 'ideal',
              },
            )}
          >
            {badge && (
              <div className="flex justify-center gap-x-4 shrink-0 sm:border-r-2 sm:border-GSblue/200 sm:pr-6 lg:pr-8">
                <Image blok={badge} className="min-h-24 h-24 max-h-24" />
              </div>
            )}
            <div
              className={cx(
                'grid grid-cols-2 sm:flex w-full h-full sm:items-center text-center',
                {
                  'gap-2 sm:gap-6 lg:gap-8 sm:justify-between':
                    theme !== 'ideal',
                  'gap-y-6 gap-x-2 justify-around': theme === 'ideal',
                },
              )}
            >
              {items.map((nestedBlok: StatisticItemBlokProps) => (
                <StatisticItem
                  blok={nestedBlok}
                  key={nestedBlok._uid}
                  theme={theme}
                />
              ))}
            </div>
          </div>
          {cta && cta.length > 0 && (
            <div className="w-full col-span-full">
              <div className="w-full flex flex-col sm:flex-row gap-2 sm:gap-8 justify-center">
                {cta.map((nestedBlok) => (
                  <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                ))}
              </div>
            </div>
          )}
        </div>
      </PageContainer>
    </section>
  );
};

export default Statistics;
