const LinkToIcon = ({
  className,
  height = 24,
  width = 24,
}: {
  className?: string;
  height?: number;
  width?: number;
}) => (
  <svg
    className={className}
    height={height}
    width={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8352 9.44977C17.7986 9.39432 17.7559 9.34171 17.7071 9.2929C17.7071 9.2929 17.7071 9.2929 17.7071 9.29289L11.7071 3.29289C11.3166 2.90237 10.6834 2.90237 10.2929 3.29289C9.90236 3.68342 9.90236 4.31658 10.2929 4.70711L14.5858 9.00001H2.99999C2.44771 9.00001 1.99999 9.44772 1.99999 10C1.99999 10.5523 2.44771 11 2.99999 11H14.5858L10.2929 15.2929C9.90237 15.6834 9.90237 16.3166 10.2929 16.7071C10.6834 17.0976 11.3166 17.0976 11.7071 16.7071L17.7071 10.7071C18.0488 10.3654 18.0915 9.83792 17.8352 9.44977Z"
    />
    <title>Link to icon</title>
  </svg>
);

export default LinkToIcon;
