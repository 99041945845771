import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import PageContainer from '@/components/PageContainer/PageContainer';
import { isEmptyLink } from '@/components/StoryblokLink/StoryblokLink';
import CtaLink, { CtaLinkBlokProps } from '../CtaLink/CtaLink';
import { PhoneIcon, SendIcon, WhatsAppIcon } from './Icons';

export interface ContactsSectionBlokProps extends SbBlokData {
  callSchedulingInfo?: Array<CtaLinkBlokProps>;
  id?: string;
  linesInfo: string;
  generalInfo: Array<CtaLinkBlokProps>;
  phoneInfo: Array<CtaLinkBlokProps>;
  tutorInfo: Array<CtaLinkBlokProps>;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

interface ContactsSectionProps {
  blok: ContactsSectionBlokProps;
}

const ContactsSection = ({ blok }: ContactsSectionProps) => {
  const {
    callSchedulingInfo: [callSchedulingInfo] = [],
    id,
    generalInfo: [generalInfo] = [],
    phoneInfo: [phoneInfo] = [],
    tutorInfo: [tutorInfo] = [],
    linesInfo,
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('w-full', {
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer>
        <div className="w-full flex flex-col gap-2">
          <div className="w-full flex flex-col sm:flex-row justify-center gap-2 sm:gap-8">
            {phoneInfo && (
              <div className="flex items-center gap-2 self-center">
                <WhatsAppIcon className="w-4 h-4 sm:w-6 sm:h-6" />
                <div>
                  <CtaLink blok={phoneInfo} hasRTicon={false} />
                </div>
              </div>
            )}
            {callSchedulingInfo && !isEmptyLink(callSchedulingInfo.link) && (
              <div className="flex items-center gap-2 self-center">
                <PhoneIcon className="w-4 h-4 sm:w-6 sm:h-6 stroke-GSdarkpurple/500" />
                <div>
                  <CtaLink blok={callSchedulingInfo} hasRTicon={false} />
                </div>
              </div>
            )}
            {generalInfo && (
              <div className="flex items-center gap-2 self-center">
                <SendIcon className="w-4 h-4 sm:w-6 sm:h-6 stroke-GSdarkpurple/500" />
                <div>
                  <CtaLink blok={generalInfo} hasRTicon={false} />
                </div>
              </div>
            )}
            {tutorInfo && (
              <div className="flex items-center gap-2 self-center">
                <SendIcon className="w-4 h-4 sm:w-6 sm:h-6 stroke-GSdarkpurple/500" />
                <div>
                  <CtaLink blok={tutorInfo} hasRTicon={false} />
                </div>
              </div>
            )}
          </div>
          {linesInfo && (
            <div className="text-GSdeep/900 self-center text-center text-xs sm:text-sm font-normal">
              {linesInfo}
            </div>
          )}
        </div>
      </PageContainer>
    </section>
  );
};

export default ContactsSection;
