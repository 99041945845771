import React from 'react';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';

import WebsitePageWrapper, {
  WebsitePageWrapperProps,
} from './WebsitePageWrapper';

export interface WebsitePageBlokProps extends WebsitePageWrapperProps {
  content: Array<SbBlokData>;
}

interface WebsitePageProps {
  blok: WebsitePageBlokProps;
}

const WebsitePage = ({ blok, ...restProps }: WebsitePageProps) => {
  const { content = [], ...rest } = blok;

  return (
    <WebsitePageWrapper {...rest}>
      {content.map((nestedBlok: SbBlokData) => (
        <StoryblokComponent
          blok={nestedBlok}
          key={nestedBlok._uid}
          {...restProps}
        />
      ))}
    </WebsitePageWrapper>
  );
};

export default WebsitePage;
