export const PhoneIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="7.24994" y="2.8125" width="10.5" height="18.3751" rx="1" />
    <path
      d="M10.5312 19.2188H14.4687"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5312 2.8125V4.28126C10.5312 4.5574 10.7551 4.78126 11.0312 4.78126H13.9687C14.2449 4.78126 14.4687 4.5574 14.4687 4.28126V2.8125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SendIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.65998 12L5.29998 19.2L22.1 12L5.29997 4.80005L8.65998 12ZM8.65998 12L12.86 12"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const WhatsAppIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9408 8.52425C21.1636 6.74701 18.8045 5.76818 16.2875 5.76367C11.1001 5.76367 6.88258 9.98573 6.87807 15.1686C6.87807 16.8285 7.3111 18.4434 8.13205 19.8733L6.79688 24.7494L11.7858 23.4413C13.1615 24.19 14.7087 24.587 16.283 24.587H16.2875C21.4703 24.587 25.6924 20.3649 25.6969 15.1776C25.6969 12.6651 24.718 10.3015 22.9408 8.52425ZM16.2875 22.9992C14.8801 22.9992 13.5043 22.6203 12.3045 21.9076L12.0203 21.7362L9.06127 22.5121L9.85065 19.6252L9.6657 19.3275C8.88084 18.0825 8.47036 16.6436 8.47036 15.1686C8.47036 10.8563 11.9797 7.35145 16.292 7.35145C18.3805 7.35145 20.3426 8.1679 21.8221 9.64291C23.2972 11.1224 24.1091 13.0846 24.1091 15.1731C24.1046 19.4899 20.5952 22.9992 16.2875 22.9992ZM20.5772 17.1398C20.3426 17.0225 19.1879 16.4541 18.9714 16.3729C18.7548 16.2962 18.597 16.2557 18.4436 16.4902C18.2857 16.7248 17.8347 17.257 17.6993 17.4104C17.564 17.5683 17.4242 17.5863 17.1896 17.469C16.9551 17.3518 16.1973 17.1037 15.2996 16.3008C14.6005 15.6783 14.1313 14.9069 13.9915 14.6724C13.8562 14.4378 13.978 14.3115 14.0953 14.1942C14.199 14.0905 14.3298 13.9191 14.4471 13.7838C14.5644 13.6484 14.605 13.5492 14.6816 13.3913C14.7583 13.2335 14.7222 13.0981 14.6636 12.9809C14.605 12.8636 14.1358 11.7043 13.9374 11.2352C13.7479 10.7751 13.554 10.8383 13.4096 10.8337C13.2743 10.8247 13.1164 10.8247 12.9585 10.8247C12.8007 10.8247 12.5481 10.8834 12.3316 11.1179C12.115 11.3525 11.5106 11.9208 11.5106 13.0801C11.5106 14.2394 12.3541 15.3535 12.4714 15.5114C12.5887 15.6693 14.1268 18.0419 16.4859 19.0613C17.0453 19.3049 17.4828 19.4493 17.8256 19.5575C18.3895 19.7379 18.8992 19.7109 19.3052 19.6522C19.7562 19.5846 20.6945 19.0839 20.8929 18.5336C21.0869 17.9833 21.0869 17.5142 21.0283 17.4149C20.9696 17.3157 20.8117 17.257 20.5772 17.1398Z"
      fill="#25D366"
    />
  </svg>
);
