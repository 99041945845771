import Head from 'next/head';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';

import { Cookiebot, Gtm, Optimizely } from '@/components';
import { useEffect } from 'react';
import { pushEventToDataLayer } from '@/helpers/gtm/gtmHelper';
import { EVENTS } from '@/helpers/gtm/constants';
import { GtmIdContext } from '@/helpers/contexts';
import HrefData, { HrefDataBlokProps } from '@/blocks/HrefData/HrefData';
import { landingPageFonts } from '@/helpers/text/fonts';
import Footer, { FooterBlokProps } from '../Footer/Footer';

export interface LandingPageBlokProps extends SbBlokData, MpcData {
  notifications: Array<SbBlokData>;
  config?: {
    content: {
      footer: Array<FooterBlokProps>;
    };
  };
  content: Array<SbBlokData>;
  datalayerLocale: 'GR' | 'DE' | 'IT' | 'FR';
  datalayerPageType: 'tutor' | 'student';
  datalayerPageTypeTwo: 'ooh_corona' | 'munich_aah' | 'promo' | 'partner';
  datalayerPromoType:
    | 'newsletter'
    | 'thank_you'
    | 'comparison'
    | 'recruitment'
    | 'pv';
  hrefLinks: Array<HrefDataBlokProps>;
  optimizelyId?: string;
  seoTitle: string;
  seoDescription: string;
  seoIndexing: 'yes' | 'no';
  gtmScripts?: 'disabled' | 'enabled' | '';
  cookiebotScripts?: 'disabled' | 'enabled' | '';
  serverSideGtm?: 'disabled' | 'enabled' | '';
}

interface LandingPageProps {
  blok: LandingPageBlokProps;
}

const LandingPage = ({ blok, ...restProps }: LandingPageProps) => {
  const {
    config: { content: { footer: [footer] = [] } = {} } = {},
    optimizelyId,
    datalayerLocale,
    datalayerPageType,
    datalayerPageTypeTwo,
    datalayerPromoType,
    hrefLinks = [],
    seoTitle,
    seoDescription,
    seoIndexing,
    gtmScripts,
    cookiebotScripts,
    serverSideGtm,
  } = blok;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      pushEventToDataLayer(EVENTS.PAGE_LOAD, {
        locale: datalayerLocale || undefined,
        pageType: datalayerPageType || undefined,
        pageTypeTwo: datalayerPageTypeTwo || undefined,
        promoType: datalayerPromoType || undefined,
      });
    }
  }, []);

  return (
    <GtmIdContext.Provider value="GTM-N7N7Z9T">
      <Head>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        {seoIndexing === 'no' && <meta name="robots" content="noindex" />}
        {landingPageFonts()}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-chrome-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="512x512"
          href="/android-chrome-512x512.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
      </Head>
      {hrefLinks &&
        hrefLinks.map((nestedBlock) => (
          <HrefData blok={nestedBlock} key={nestedBlock._uid} />
        ))}
      {optimizelyId && <Optimizely id={optimizelyId} />}
      {gtmScripts !== 'disabled' && (
        <Gtm serverSide={serverSideGtm === 'enabled'} />
      )}
      {cookiebotScripts !== 'disabled' && (
        <Cookiebot language={blok._mpc.language} />
      )}
      {blok.notifications?.map((nestedBlok: SbBlokData) => (
        <StoryblokComponent
          blok={nestedBlok}
          key={nestedBlok._uid}
          {...restProps}
        />
      ))}
      <main
        {...storyblokEditable(blok)}
        className="overflow-x-clip w-full font-worksans bg-GSbase/white"
      >
        {blok.content?.map((nestedBlok: SbBlokData) => (
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
            {...restProps}
          />
        ))}
        {footer && <Footer blok={footer} />}
      </main>
    </GtmIdContext.Provider>
  );
};

export default LandingPage;
