import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { PageContainer } from '@/components';
import OffbrandHowItWorksStep, {
  OffbrandHowItWorksStepBlokProps,
} from '@/blocks/OffbrandHowItWorksStep/OffbrandHowItWorksStep';
import { StarIcon } from '@/blocks/OffbrandHowItWorks/StarIcon';

export interface OffbrandHowItWorksBlokProps extends SbBlokData {
  steps: Array<OffbrandHowItWorksStepBlokProps>;
  reviewStars: number;
  reviewContent: string;
  reviewAuthor: string;
}

export interface OffbrandHowItWorksProps {
  blok: OffbrandHowItWorksBlokProps;
}

const OffbrandHowItWorks = ({
  blok,
  ...restProps
}: OffbrandHowItWorksProps) => {
  const { steps, reviewStars, reviewContent, reviewAuthor } = blok;

  return (
    <section {...storyblokEditable(blok)} className="w-full bg-GSbase/white">
      <PageContainer className="sm:!w-8/12 lg:!w-10/12 2xl:!w-full !py-6 sm:!py-8 lg:!py-10">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-6 lg:gap-x-8 gap-y-6">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-6 lg:gap-x-8 lg:col-span-3 gap-y-6">
            {steps?.map((item: OffbrandHowItWorksStepBlokProps) => (
              <OffbrandHowItWorksStep
                blok={item}
                key={item._uid}
                {...restProps}
              />
            ))}
          </div>
          <div className="flex flex-col text-GSdeep/900 font-dosis lg:col-span-1">
            <div className="flex flex-col gap-y-4 bg-GSgrey/50 p-8 shadow-xl rounded-lg">
              <div className="flex flex-row gap-x-2">
                {Array.from({ length: reviewStars }, (_, index) => (
                  <StarIcon key={`star_icon_${index}`} />
                ))}
              </div>
              <div className="font-semibold text-lg break-words whitespace-pre-wrap">
                &ldquo;{reviewContent}&rdquo;
              </div>
              <div className="text-GSsteel/500 text-xs break-words whitespace-pre-wrap">
                {reviewAuthor}
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </section>
  );
};

export default OffbrandHowItWorks;
