import { Content } from '../storyblok/types';

export const getAllComponentsByName = (
  content: Content,
  names: Array<string>,
): Array<Content> => {
  const result = [];

  if (typeof content === 'object' && content !== null) {
    if (content.component && names.includes(content.component)) {
      result.push(content);
    } else {
      result.push(
        ...Object.values(content)
          .map((value) => getAllComponentsByName(value, names))
          .flat(),
      );
    }
  } else if (Array.isArray(content)) {
    result.push(
      ...(content as Array<Content>)
        .map((value) => getAllComponentsByName(value, names))
        .flat(),
    );
  }

  return result;
};
