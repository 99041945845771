import cloneDeep from 'clone-deep';
import { RichTextSchema } from '@storyblok/react';

const faqRichTextSchema = cloneDeep(RichTextSchema);

faqRichTextSchema.nodes.bullet_list = () => {
  return {
    tag: [
      {
        tag: 'ul',
        attrs: {
          class: 'pl-6 list-disc',
        },
      },
    ],
  };
};

faqRichTextSchema.nodes.ordered_list = () => ({
  tag: [
    {
      tag: 'ol',
      attrs: {
        class: 'pl-6 list-decimal',
      },
    },
  ],
});

faqRichTextSchema.marks.bold = () => ({
  tag: [
    {
      tag: 'b',
      attrs: {
        class: 'font-bold',
      },
    },
  ],
});

faqRichTextSchema.marks.link = (node: ISbNode) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const schema: any = RichTextSchema.marks.link(node);

  schema.tag[0].attrs.class = 'text-GSblue/500 hover:text-GSblue/700 underline';

  return schema;
};

faqRichTextSchema.marks.textStyle = () => {
  // we do not support changing text color
  return {
    tag: '',
  };
};

export default faqRichTextSchema;
