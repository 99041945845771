import cx from 'classnames';
import { ISbRichtext, renderRichText } from '@storyblok/react';

import WebsitePageWrapper, {
  WebsitePageWrapperProps,
} from '../WebsitePage/WebsitePageWrapper';
import { PageContainer } from '../../components';
import CtaLink, { CtaLinkBlokProps } from '../CtaLink/CtaLink';
import mpcRichTextSchema from '../RichtextSection/richTextSchema';

export interface PressReleasePageBlokProps extends WebsitePageWrapperProps {
  info: string;
  title: string;
  content: ISbRichtext;
  viewAllNewsLink?: Array<CtaLinkBlokProps>;
}

interface PressReleasePageProps {
  blok: PressReleasePageBlokProps;
}

const PressReleasePage = ({ blok }: PressReleasePageProps) => {
  const {
    info,
    title,
    content,
    viewAllNewsLink: [viewAllNewsLink] = [],
    ...rest
  } = blok;

  return (
    <WebsitePageWrapper {...rest}>
      <PageContainer className="!items-start gap-4 sm:gap-10">
        <div
          className={cx(
            'w-full h-full flex flex-col justify-between',
            'p-4 sm:p-6 lg:p-10 gap-2 sm:gap-4 lg:gap-8',
            'sm:col-span-6 lg:col-span-4',
            'transition shadow-lg rounded-lg box-border border border-GSblue/300 bg-GSbase/white',
          )}
        >
          <div className="text-GSblue/600 font-worksans text-xs font-medium sm:text-sm">
            {info}
          </div>
          <div className="text-GSblue/600 font-raleway text-lg font-bold sm:text-2xl">
            {title}
          </div>
          {viewAllNewsLink && (
            <div className="self-end">
              <CtaLink blok={viewAllNewsLink} />
            </div>
          )}
        </div>
        <div
          className="break-words min-w-0"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: renderRichText(content, { schema: mpcRichTextSchema }),
          }}
        />
      </PageContainer>
    </WebsitePageWrapper>
  );
};

export default PressReleasePage;
