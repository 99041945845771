import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import PageContainer from '@/components/PageContainer/PageContainer';
import CtaLink, { CtaLinkBlokProps } from '../CtaLink/CtaLink';
import ContentCard, { ContentCardBlokProps } from '../ContentCard/ContentCard';

export interface ContentCardSectionBlokProps extends SbBlokData {
  cards: Array<ContentCardBlokProps>;
  ctaLink: Array<CtaLinkBlokProps>;
  ctaMessage: string;
  id?: string;
  layoutSize: 'default' | 'wide';
  title: Array<TitleBlokProps>;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

interface ContentCardSectionProps {
  blok: ContentCardSectionBlokProps;
}

const ContentCardSection = ({ blok }: ContentCardSectionProps) => {
  const {
    cards,
    ctaLink: [ctaLink] = [],
    ctaMessage,
    id,
    layoutSize = 'default',
    title: [title] = [],
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx({
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer>
        <div className="w-full flex flex-col lg:grid lg:grid-cols-12 gap-4 sm:gap-6 lg:gap-10">
          <div
            className={cx('w-full', {
              'lg:col-span-10 lg:col-start-2': layoutSize === 'default',
              'lg:col-span-full lg:col-start-1': layoutSize === 'wide',
            })}
          >
            <TitleBlok blok={title} headingLevel="h2" />
          </div>
          <div
            className={cx('w-full flex flex-col gap-4 sm:gap-9 lg:gap-[54px]', {
              'lg:col-span-10 lg:col-start-2': layoutSize === 'default',
              'lg:col-span-full lg:col-start-1': layoutSize === 'wide',
            })}
          >
            <div className="w-full flex flex-col sm:grid sm:grid-cols-12 lg:grid-cols-10 gap-2 sm:gap-4 lg:gap-8">
              {cards.map((card) => (
                <div className="sm:col-span-6 lg:col-span-5" key={card._uid}>
                  <ContentCard blok={card} />
                </div>
              ))}
            </div>
            {(ctaMessage || ctaLink) && (
              <div className="w-full flex flex-col sm:flex-row justify-center items-center gap-0 sm:gap-4">
                {ctaMessage && (
                  <div className="text-GSdeep/900 text-base font-medium lg:text-lg lg:font-normal">
                    {ctaMessage}
                  </div>
                )}
                {ctaLink && <CtaLink blok={ctaLink} />}
              </div>
            )}
          </div>
        </div>
      </PageContainer>
    </section>
  );
};

export default ContentCardSection;
