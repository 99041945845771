import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max';

export const isPhoneValid = (value: string) => {
  return isValidPhoneNumber(value);
};

// Removes unwanted 0 from the valid number:
// +440201234578 => +44201234578
export const getPrefixedNationalNumber = (value: string) => {
  const { countryCallingCode, nationalNumber } = parsePhoneNumber(value);

  return `+${countryCallingCode}${nationalNumber}`;
};
