const SendMessageIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.65998 12L5.29998 19.2L22.1 12L5.29997 4.80005L8.65998 12ZM8.65998 12L12.86 12"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SendMessageIcon;
