import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';
import { useRouter } from 'next/router';

import { fillWithUrlParams } from '@/helpers/text/replacer';
import { BulletIcon } from './Icons';

export interface HeroContentBoxListItemBlokProps extends SbBlokData {
  description?: string;
  text: string;
}

interface HeroContentBoxListItemProps {
  blok: HeroContentBoxListItemBlokProps;
  colorVariant: 'scrollVariantGreen500' | 'scrollVariantYellow500';
  layout: 'bulletList' | 'featureList';
}

const HeroContentBoxListItem = ({
  blok,
  colorVariant = 'scrollVariantGreen500',
  layout = 'bulletList',
}: HeroContentBoxListItemProps) => {
  const router = useRouter();
  const { description, text } = blok;

  return text ? (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col gap-2 w-full items-start"
    >
      <div className="flex gap-2 w-full items-start">
        {layout === 'bulletList' && (
          <BulletIcon
            className={cx(
              'w-4 h-4 mt-[3px] sm:w-6 sm:h-6 shrink-0 grow-0 sm:mt-0.5',
              {
                'fill-GSgreen/500': colorVariant === 'scrollVariantGreen500',
                'fill-GSpurple/500': colorVariant === 'scrollVariantYellow500',
              },
            )}
          />
        )}
        <div
          className={cx('break-words min-w-0 text-base sm:text-lg lg:text-xl', {
            'font-normal': layout === 'bulletList',
            'font-bold': layout === 'featureList',
          })}
        >
          {fillWithUrlParams(text, router.query)}
        </div>
      </div>
      {layout === 'featureList' && description && (
        <div className="font-normal text-sm sm:text-base">{description}</div>
      )}
    </div>
  ) : null;
};

export default HeroContentBoxListItem;
