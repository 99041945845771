import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';
import React from 'react';

import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import PageContainer from '@/components/PageContainer/PageContainer';

export interface OneColumnSectionBlokProps extends SbBlokData {
  alignContent?: 'left' | 'center' | 'right';
  content?: Array<SbBlokData>;
  id?: string;
  layoutSize?: 'medium' | 'default' | '';
  title?: Array<TitleBlokProps>;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

export interface OneColumnSectionProps {
  blok: OneColumnSectionBlokProps;
}

const OneColumnSection = ({ blok }: OneColumnSectionProps) => {
  const {
    alignContent = 'center',
    content = [],
    id,
    layoutSize = 'default',
    title: [title] = [],
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('w-full bg-white', {
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer className="w-full grid grid-cols-6 sm:grid-cols-12 gap-x-2 gap-y-4 sm:gap-x-4 sm:gap-y-6 lg:gap-x-8 lg:gap-y-10 grid-flow-row">
        {title && (
          <div
            className={cx({
              'col-span-full cols-start-1 lg:col-span-10 lg:col-start-2':
                layoutSize === 'medium',
              'col-span-full col-start-1':
                layoutSize === 'default' || layoutSize === '',
            })}
          >
            <TitleBlok blok={title} headingLevel="h2" />
          </div>
        )}
        {content && content.length > 0 && (
          <div
            className={cx({
              'col-span-full cols-start-1 lg:col-span-10 lg:col-start-2':
                layoutSize === 'medium',
              'col-span-full col-start-1':
                layoutSize === 'default' || layoutSize === '',
            })}
          >
            <div
              className={cx(
                'w-full flex flex-col gap-y-2 sm:gap-y-6 lg:gap-y-10 text-sm lg:text-base',
                {
                  'items-start': alignContent === 'left',
                  'items-center': alignContent === 'center',
                  'items-end': alignContent === 'right',
                },
              )}
            >
              {content.map((nestedBlok: SbBlokData) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
              ))}
            </div>
          </div>
        )}
      </PageContainer>
    </section>
  );
};

export default OneColumnSection;
