import { storyblokEditable, SbBlokData } from '@storyblok/react';
import cx from 'classnames';

import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import PageContainer from '../../components/PageContainer/PageContainer';
import TitleBlok, { TitleBlokProps } from '../Title/Title';

export interface PressLogosBlokProps extends SbBlokData {
  title: Array<TitleBlokProps>;
  header: string;
  id?: string;
  logos: Array<Asset>;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
  iconDisplay: 'pressLogos' | 'awards';
}

export interface PressLogosProps {
  blok: PressLogosBlokProps;
}

const PressLogos = ({ blok }: PressLogosProps) => {
  const {
    header,
    id,
    logos,
    visibility,
    visibilityOnMobile,
    title: [title] = [],
    iconDisplay = 'pressLogos',
  } = { ...blok, iconDisplay: blok.iconDisplay || undefined };

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('bg-GSbase/white', {
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer
        className={cx({
          '!gap-2 sm:!gap-2 lg:!gap-4':
            !iconDisplay || iconDisplay === 'pressLogos',
          'w-full grid grid-cols-6 sm:grid-cols-12': iconDisplay === 'awards',
        })}
      >
        {title && iconDisplay === 'awards' ? (
          <div className="w-full col-span-full sm:col-start-1 lg:col-start-2 sm:col-span-12 lg:col-span-10">
            <TitleBlok blok={title} headingLevel="h2" />
          </div>
        ) : (
          <div
            className="
            text-GSblue/600 text-sm font-medium
            sm:text-base
            lg:text-lg
          "
          >
            {header}
          </div>
        )}
        <div
          className={cx('flex flex-row flex-wrap justify-around w-full', {
            'gap-y-2': !iconDisplay || iconDisplay === 'pressLogos',
            'w-full col-span-full sm:col-start-1 lg:col-start-2 sm:col-span-12 lg:col-span-10 gap-x-8 gap-y-4 break-words':
              iconDisplay === 'awards',
          })}
        >
          {logos.map((logo) => (
            <StoryblokImage
              blok={logo}
              key={logo.id}
              className={cx({
                'w-full grow shrink-0 basis-1/4 max-w-[25%] self-center sm:basis-1/8 sm:max-w-[12%]':
                  !iconDisplay || iconDisplay === 'pressLogos',
                'h-[96px] sm:h-[128px] lg:h-[152px]': iconDisplay === 'awards',
              })}
            />
          ))}
        </div>
      </PageContainer>
    </section>
  );
};

export default PressLogos;
