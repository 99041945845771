import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';
import { useState } from 'react';

import AccordionWithTimelineItem, {
  AccordionWithTimelineItemBlokProps,
} from '@/blocks/AccordionWithTimelineItem/AccordionWithTimelineItem';
import { CtaLinkBlokProps } from '@/blocks/CtaLink/CtaLink';

import PageContainer from '@/components/PageContainer/PageContainer';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';

export interface AccordionWithTimelineBlokProps extends SbBlokData {
  backgroundColor: string;
  items: Array<AccordionWithTimelineItemBlokProps>;
  icon: Asset;
  id?: string;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
  ctaLink: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
}

export interface AccordionWithTimelineProps {
  blok: AccordionWithTimelineBlokProps;
}

const AccordionWithTimeline = ({
  blok,
  ...restProps
}: AccordionWithTimelineProps) => {
  const [expandedItemId, setExpandedItemId] = useState<string | undefined>();

  const {
    items,
    backgroundColor,
    icon,
    id,
    visibility,
    visibilityOnMobile,
    ctaLink = [],
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('w-full text-GSdeep/900', {
        'bg-GSbase/white':
          !backgroundColor || backgroundColor === 'GSbase/white',
        'bg-GSparchment/200': backgroundColor === 'GSparchment/200',
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer>
        <div className="w-full grid grid-cols-6 sm:grid-cols-12 gap-x-2 gap-y-8 sm:gap-x-4 sm:gap-y-6 lg:gap-8">
          <div
            className={cx(
              'w-full relative grid col-span-6 sm:col-span-12 lg:col-start-2 lg:col-span-10',
            )}
          >
            {items?.map(
              (item: AccordionWithTimelineItemBlokProps, index: number) => (
                <AccordionWithTimelineItem
                  key={item._uid}
                  blok={item}
                  index={index}
                  isLastItem={index === items.length - 1}
                  icon={icon}
                  isExpanded={expandedItemId === item._uid}
                  setExpanded={setExpandedItemId}
                  {...restProps}
                />
              ),
            )}
          </div>
        </div>
        {ctaLink && ctaLink.length > 0 && (
          <div className="w-full flex flex-col sm:flex-row gap-2 sm:gap-8 justify-center">
            {ctaLink.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </div>
        )}
      </PageContainer>
    </section>
  );
};

export default AccordionWithTimeline;
