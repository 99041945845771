import { Content } from './types';

export const getUsedComponentsNames = (content: Content): Array<string> => {
  const result: Array<string> = [];

  if (typeof content === 'object' && content !== null) {
    if (content.component) {
      result.push(content.component);
    }

    result.push(
      ...Object.values(content)
        .map((value) => getUsedComponentsNames(value))
        .flat(),
    );
  } else if (Array.isArray(content)) {
    result.push(
      ...(content as Array<Content>)
        .map((value) => getUsedComponentsNames(value))
        .flat(),
    );
  }

  const uniqueValues = new Set(result);
  return Array.from(uniqueValues);
};
