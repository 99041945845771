import { FormBlokProps } from '@/blocks/Form/FormType';

const getDeprecatedFields = (
  blok: FormBlokProps,
  filterRequiredFields: boolean,
) => {
  const {
    firstName: [firstName] = [],
    email: [email] = [],
    phone: [phone] = [],
    newsletter: [newsletter] = [],
    termsAndConditions: [termsAndConditions] = [],
  } = blok;

  const fields = filterRequiredFields
    ? [firstName, email, phone, termsAndConditions]
    : [firstName, email, phone, newsletter, termsAndConditions];

  return fields.filter(Boolean);
};

export const getAllDeprecatedFields = (blok: FormBlokProps) =>
  getDeprecatedFields(blok, false);

export const getAllRequiredDeprecatedFields = (blok: FormBlokProps) =>
  getDeprecatedFields(blok, true);

export const hasMixedContent = (blok: FormBlokProps) => {
  let contentUsed = 0;
  if (blok.fields?.length) {
    contentUsed++;
  }

  if (blok.steps?.length) {
    contentUsed++;
  }

  if (getAllDeprecatedFields(blok).length) {
    contentUsed++;
  }

  return contentUsed > 1;
};
