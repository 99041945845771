export const BulletIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mr-3"
    name="icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 25.8C20.0692 25.8 25.7999 20.0693 25.7999 13C25.7999 5.93077 20.0692 0.200012 13 0.200012C5.93071 0.200012 0.199951 5.93077 0.199951 13C0.199951 20.0693 5.93071 25.8 13 25.8ZM19.3071 10.5071C19.6976 10.1166 19.6976 9.48343 19.3071 9.09291C18.9165 8.70238 18.2834 8.70238 17.8928 9.09291L10.6 16.3858L7.30706 13.0929C6.91653 12.7024 6.28337 12.7024 5.89284 13.0929C5.50232 13.4834 5.50232 14.1166 5.89284 14.5071L9.89285 18.5071C10.2834 18.8976 10.9165 18.8976 11.3071 18.5071L19.3071 10.5071Z"
      fill="#5DC18B"
    />
  </svg>
);
