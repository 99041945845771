import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';
import React from 'react';

import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import PageContainer from '@/components/PageContainer/PageContainer';

export interface TwoColumnsSectionBlokProps extends SbBlokData {
  alignContent?: 'top' | 'center' | 'bottom';
  id?: string;
  leftColumn?: Array<SbBlokData>;
  rightColumn?: Array<SbBlokData>;
  title?: Array<TitleBlokProps>;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

export interface TwoColumnsSectionProps {
  blok: TwoColumnsSectionBlokProps;
}

const TwoColumnsSection = ({ blok }: TwoColumnsSectionProps) => {
  const {
    alignContent = 'top',
    id,
    leftColumn = [],
    rightColumn = [],
    title: [title] = [],
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('w-full bg-white', {
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer>
        {title && <TitleBlok blok={title} headingLevel="h2" />}
        <div
          className={cx(
            'w-full grid grid-cols-6 sm:grid-cols-12 gap-x-2 gap-y-4 sm:gap-x-6 sm:gap-y-6 lg:gap-x-8 lg:gap-y-10 text-sm lg:text-base',
            {
              'items-start': alignContent === 'top',
              'items-center': alignContent === 'center',
              'items-end': alignContent === 'bottom',
            },
          )}
        >
          <div className="col-span-6 flex flex-col gap-y-4 lg:gap-y-8 lg:col-span-5 lg:col-start-2">
            {leftColumn?.map((nestedBlok: SbBlokData) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </div>
          <div className="col-span-6 flex flex-col gap-y-4 lg:gap-y-8 lg:col-span-5">
            {rightColumn?.map((nestedBlok: SbBlokData) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </div>
        </div>
      </PageContainer>
    </section>
  );
};

export default TwoColumnsSection;
