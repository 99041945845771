import cx from 'classnames';
import React, { ReactElement } from 'react';
import StoryblokLink from '../StoryblokLink/StoryblokLink';
import SpinnerIcon from '../../icons/Spinner';
import LinkToIcon from '../../icons/LinkTo';

export type Size = 'btn-XS' | 'btn-SM' | 'btn-MD' | 'btn-LG' | 'btn-XL';

const SizeToInt = {
  'btn-XS': 16,
  'btn-SM': 16,
  'btn-MD': 20,
  'btn-LG': 24,
  'btn-XL': 24,
};

export interface ButtonProps {
  addInternalParams?: boolean;
  className?: string;
  type?: 'primary' | 'secondary' | 'textLink';
  // variant?: 'Default' | 'Icon only circle' | 'Icon only square';
  label?: string;
  hasRTicon?: boolean;
  hasLTicon?: boolean;
  // State: Default / Hover / Disabled / Hover&Focus
  size?: Size;
  background?: 'darkBG' | 'lightBG';
  link?: Link;
  isSubmit?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  testId?: string;
  disabled?: boolean;
  iconRT?: ReactElement;
  loading?: boolean;
}

const iconStyles = ({
  type,
  background,
}: Pick<ButtonProps, 'background' | 'type'> & {
  className?: string;
}) => ({
  'stroke-GSbase/white fill-GSbase/white': type === 'primary',
  'stroke-GSblue/500 fill-GSblue/500': type === 'secondary',
  'stroke-GSblue/500 fill-GSblue/500 group-hover:stroke-GSblue/700 group-hover:fill-GSblue/500':
    type === 'textLink',
  '!stroke-GSblue/100 !fill-GSblue/100':
    background === 'darkBG' && type === 'textLink',
});

const iconSize = (size: Size = 'btn-MD') => {
  const result = SizeToInt[size];

  return {
    height: result,
    width: result,
  };
};

const Button = ({
  addInternalParams,
  className,
  background = 'lightBG',
  hasRTicon = false,
  hasLTicon = false,
  isSubmit = false,
  label,
  link,
  onClick,
  size,
  type = 'primary',
  testId,
  disabled = false,
  iconRT,
  loading = false,
}: ButtonProps) => {
  const classNames = cx(
    'flex justify-center whitespace-nowrap w-full group box-border rounded px-6 py-2.5 text-sm sm:px-6 sm:py-3 sm:text-base xl:px-8 lg:py-3.5 lg:text-lg 2xl:px-8 2xl:py-[18px] 2xl:text-xl',
    {
      'ring-inset ring-2 ring-GSblue/600 bg-GSblue/500 text-GSbase/white hover:bg-GSblue/600 hover:ring-GSblue/700':
        type === 'primary',
      'bg-GSbase/white ring-inset ring-2 ring-GSblue/200 text-GSblue/500 hover:bg-GSblue/50 hover:ring-GSblue/300':
        type === 'secondary',
      'bg-transparent text-GSblue/500 hover:text-GSblue/700 !px-0 underline':
        type === 'textLink',
      '!bg-GSbase/white !ring-GSblue/300 !text-GSblue/500':
        background === 'darkBG' && type !== 'textLink',
      '!text-GSblue/100': background === 'darkBG' && type === 'textLink',
      '!text-xs !font-medium !py-2': size === 'btn-XS',
      '!text-sm !font-medium !py-2.5': size === 'btn-SM',
      '!text-base !font-medium !py-3.5': size === 'btn-MD',
    },
    className,
  );

  const renderContent = () => {
    const buttonIconStyles = iconStyles({
      type,
      background,
    });

    return (
      <div className="flex gap-x-2 justify-center items-center w-full">
        {hasLTicon && (
          <LinkToIcon
            className={cx('rotate-180', buttonIconStyles)}
            {...iconSize(size)}
          />
        )}
        <div className="break-words whitespace-pre-wrap text-center max-w-full">
          {label}
        </div>
        {hasRTicon &&
          (iconRT || (
            <LinkToIcon className={cx(buttonIconStyles)} {...iconSize(size)} />
          ))}
        {loading && (
          <span className="animate-spin">
            <SpinnerIcon
              className={cx('!fill-none', buttonIconStyles)}
              {...iconSize(size)}
            />
          </span>
        )}
      </div>
    );
  };

  return isSubmit || onClick ? (
    <button
      className={classNames}
      onClick={onClick}
      type={isSubmit ? 'submit' : 'button'}
      data-testid={testId}
      disabled={disabled || loading}
    >
      {renderContent()}
    </button>
  ) : (
    <StoryblokLink
      addInternalParams={addInternalParams}
      testId={testId}
      className={classNames}
      link={loading ? undefined : link}
    >
      {renderContent()}
    </StoryblokLink>
  );
};

export default Button;
