import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import StoryblokLink, {
  isEmptyLink,
} from '@/components/StoryblokLink/StoryblokLink';

export interface SimpleCardBlokProps extends SbBlokData {
  desktopLayout?: 'row' | 'column' | '';
  icon?: Asset;
  label?: string;
  link?: Link;
  mobileLayout?: 'row' | 'column' | '';
}

interface SimpleCardProps {
  blok: SimpleCardBlokProps;
}

const SimpleCard = ({ blok }: SimpleCardProps) => {
  const {
    desktopLayout = 'column',
    icon,
    label,
    link,
    mobileLayout = 'column',
  } = blok;

  const renderContent = () => (
    <div
      {...storyblokEditable(blok)}
      className={cx(
        'transition shadow-lg w-full h-full flex',
        'rounded-lg box-border border border-GSblue/300',
        'p-4 sm:p-8 gap-2 sm:gap-4',
        'bg-GSbase/white hover:bg-GSblue/100 hover:ring-1 hover:ring-GSblue/300 hover:ring-inset items-center',
        {
          'cursor-pointer': !isEmptyLink(link),
          'flex-row': mobileLayout === 'row',
          'flex-col': mobileLayout === 'column' || mobileLayout === '',
          'sm:flex-row': desktopLayout === 'row',
          'sm:flex-col': desktopLayout === 'column' || desktopLayout === '',
        },
      )}
    >
      {icon && (
        <StoryblokImage
          blok={icon}
          className="rounded sm:rounded-lg object-cover h-12 w-12 sm:h-16 sm:w-16"
        />
      )}
      {label && (
        <div className="text-GSdeep/500 text-sm font-medium lg:text-base break-words whitespace-pre-wrap text-center">
          {label}
        </div>
      )}
    </div>
  );

  return isEmptyLink(link) ? (
    renderContent()
  ) : (
    <StoryblokLink link={link}>{renderContent()}</StoryblokLink>
  );
};

export default SimpleCard;
