import { CheckboxInput } from '@/components';
import { FormDataType } from '@/helpers/form/formHelpersTypes';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FieldInputProps, FormikTouched } from 'formik';

const FIELD_ID = 'emailConsentNewsletter';

export const FieldData: FormDataType = {
  hubspotId: 'marketing_opt_in__instapage_',
  id: FIELD_ID,
  type: 'boolean',
  validationType: 'bool',
};

export interface NewsletterCheckboxBlokProps extends SbBlokData {
  name: string;
  label: string;
  touched: FormikTouched<{ newsletter: boolean }>;
}

interface NewsletterCheckboxProps {
  blok: NewsletterCheckboxBlokProps;
  field: FieldInputProps<string>;
}

const NewsletterCheckbox = ({ blok, field }: NewsletterCheckboxProps) => {
  const { label } = blok;

  return (
    <div {...storyblokEditable(blok)}>
      <CheckboxInput hubspotId={FieldData.hubspotId} {...field} label={label} />
    </div>
  );
};

export default NewsletterCheckbox;
