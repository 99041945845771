import React from 'react';
import cx from 'classnames';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

import { Button } from '@/components';
import EarningsCalculatorButton, {
  EarningsCalculatorButtonBlokProps,
} from '../EarningsCalculatorButton/EarningsCalculatorButton';
import EarningsPriceItem from '../EarningsPriceItem/EarningsPriceItem';

export interface EarningsCalculatorProps {
  blok: EarningsCalculatorBlokProps;
}

export interface EarningsCalculatorBlokProps extends SbBlokData {
  heading: string;
  backLabel: string;
  buttons: Array<EarningsCalculatorButtonBlokProps>;
}

const EarningsCalculator = ({ blok }: EarningsCalculatorProps) => {
  const { buttons = [], backLabel, heading } = blok;
  const [openedPriceIndex, setOpenedPriceIndex] = React.useState<number | null>(
    null,
  );

  const openPrice = (index: number) => setOpenedPriceIndex(index);
  const closePrice = () => setOpenedPriceIndex(null);

  const openedPrice =
    openedPriceIndex != null ? buttons[openedPriceIndex].price[0] : null;

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx(
        'w-full h-full box-border rounded shadow-xl gap-2 sm:gap-4 lg:gap-10 grid',
        {
          'bg-GSbase/white border border-GSdarkpurple/300':
            openedPrice === null,
          'bg-GSpurple/100 ring-1 ring--GSdarkpurple/500 ring-inset border border-GSdarkpurple/500':
            openedPrice !== null,
        },
      )}
    >
      <div
        className={cx(
          'flex flex-col gap-4 lg:gap-8 p-4 lg:p-10 col-start-1 row-start-1',
          {
            invisible: openedPrice !== null,
          },
        )}
      >
        <div className="text-GSblue/600 font-bold sm:text-base lg:text-xl text-center">
          {heading}
        </div>
        <div className="flex flex-col lg:grid lg:grid-cols-2 lg:grid-rows-2 gap-2 lg:gap-4">
          {buttons.map((nestedBlok, index) => (
            <div onClick={() => openPrice(index)} key={nestedBlok._uid}>
              <EarningsCalculatorButton blok={nestedBlok} />
            </div>
          ))}
        </div>
      </div>
      {openedPrice && (
        <div className="w-full h-full p-4 lg:p-10 col-start-1 row-start-1">
          <div className="flex flex-col gap-4 h-full">
            <div className="self-start">
              <Button
                onClick={closePrice}
                label={backLabel}
                type="textLink"
                hasLTicon
                size="btn-SM"
              />
            </div>
            <div className="h-full flex">
              <EarningsPriceItem blok={openedPrice} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EarningsCalculator;
