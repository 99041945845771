import { useEffect } from 'react';
import { SbBlokData } from '@storyblok/react';
import PageContainer from '../../components/PageContainer/PageContainer';
import TitleBlok, { TitleBlokProps } from '../Title/Title';

export interface CookieDeclarationBlokProps extends SbBlokData {
  language?: string;
  title: Array<TitleBlokProps>;
}

export interface CookieDeclarationProps {
  blok: CookieDeclarationBlokProps;
}

const CookieDeclaration = ({
  blok: { language, title: [title] = [] },
}: CookieDeclarationProps) => {
  useEffect(() => {
    if (!document.getElementById('CookieDeclaration')) {
      const cookieBotWrapper = document.getElementById('CookiebotDeclaration');
      if (cookieBotWrapper) {
        const script = document.createElement('script');
        script.id = 'CookieDeclaration';
        script.type = 'text/javascript';
        script.async = true;
        script.src = `https://consent.cookiebot.com/b65399a3-9209-4fdd-a0cd-08534994c22c/cd.js`;
        script.setAttribute(
          'data-culture',
          !language || language === 'default'
            ? document.documentElement.lang
            : language.split('-')[0],
        );

        cookieBotWrapper.appendChild(script);
      }
    }
  }, []);

  return (
    <section className="w-full">
      <TitleBlok blok={title} />
      <PageContainer className="!items-start">
        <div className="text-black w-full" id="CookiebotDeclaration" />
      </PageContainer>
    </section>
  );
};

export default CookieDeclaration;
