export const GREENHOUSE_ENDPOINT =
  'https://boards-api.greenhouse.io/v1/boards/gostudent/';

export const getGreenhouseData = async (
  route: 'jobs?content=true' | 'departments' | 'offices' | 'jobs',
  jobId?: string,
) => {
  const { greenhouseEndpoint } = process.env;
  const url = greenhouseEndpoint || GREENHOUSE_ENDPOINT;
  const path = jobId ? `${url + route}/${jobId}` : `${url + route}`;
  const request = await fetch(path);
  const res = await request.json();
  return res;
};
