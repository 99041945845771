export const ScrollIcon = ({
  firstPartClassName,
  secondPartClassName,
}: {
  firstPartClassName: string;
  secondPartClassName: string;
}) => (
  <svg
    className="max-w-full"
    viewBox="0 0 703 598"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMinYMid meet"
    role="img"
  >
    <path
      d="M363.306 94.1448C363.306 94.1448 363.189 93.9652 363.13 93.8754C348.392 74.7012 331.516 58.4668 312.718 45.5012C296.614 34.3639 279.545 25.9971 261.738 20.4229C262.203 20.5458 262.619 20.6586 263.114 20.7619C259.907 19.7829 256.719 18.8338 253.501 18.0334C246.529 16.2693 239.445 14.9211 232.278 13.9692C201.259 10.0332 169.871 9.97426 139.047 13.7802C108.988 17.5131 79.0761 24.9982 50.1277 36.0439C40.4417 39.7355 30.8218 43.8542 21.3276 48.3611C2.94721 57.0622 -15.0385 67.2156 -32.3802 78.7435C-58.5023 96.0643 -83.4101 116.61 -106.484 139.761C-129.568 162.962 -150.968 188.995 -170.143 217.12C-189.43 245.532 -206.632 276.342 -221.237 308.703C-236.095 341.656 -248.388 376.566 -257.793 412.491C-267.433 449.298 -274.108 487.628 -277.711 526.34L-1.09001 596.536C2.26449 557.772 8.98958 519.452 18.6399 482.596C28.0144 446.69 40.2885 411.75 55.1764 378.777C69.7617 346.387 86.9939 315.557 106.33 287.155C125.485 259 146.905 232.998 169.989 209.797C193.103 186.577 218.041 166.011 244.133 148.71C261.505 137.162 279.49 127.009 297.841 118.328C307.365 113.801 317.004 109.712 326.671 105.991C338.765 101.406 350.975 97.3879 363.327 94.0457L363.306 94.1448Z"
      className={firstPartClassName}
    />
    <path
      d="M652.036 182.113C634.644 154.969 613.491 132.725 589.166 116.023C570.279 102.987 550.091 93.7115 528.909 88.3378C525.869 87.5488 522.848 86.7897 519.709 85.98C441.466 66.0965 341.475 40.6956 263.054 20.8006C280.731 26.3738 296.914 35.1502 312.979 46.2276C331.688 59.123 361.559 91.7386 363.256 94.1342C367.537 99.7563 371.637 105.625 375.566 111.691C392.978 138.865 405.826 169.456 413.66 202.774C421.823 237.246 424.258 273.031 420.925 309.215L697.515 379.43C700.64 343.255 698.224 307.5 690.111 273.038C682.267 239.769 669.418 209.179 652.016 182.083L652.036 182.113Z"
      className={secondPartClassName}
    />
  </svg>
);
