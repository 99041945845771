export const BulletIcon = ({ className }: { className: string }) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21.5999C17.302 21.5999 21.6 17.3018 21.6 11.9999C21.6 6.69797 17.302 2.3999 12 2.3999C6.69809 2.3999 2.40002 6.69797 2.40002 11.9999C2.40002 17.3018 6.69809 21.5999 12 21.5999ZM17.0486 10.4484C17.5172 9.9798 17.5172 9.22 17.0486 8.75137C16.5799 8.28274 15.8201 8.28274 15.3515 8.75137L10.2 13.9028L8.04855 11.7514C7.57992 11.2827 6.82013 11.2827 6.3515 11.7514C5.88287 12.22 5.88287 12.9798 6.3515 13.4484L9.3515 16.4484C9.82013 16.9171 10.5799 16.9171 11.0486 16.4484L17.0486 10.4484Z"
      fill="#9F80FF"
    />
  </svg>
);
