// Based on names from the Trustpilot webpage:
// https://support.trustpilot.com/hc/en-us/articles/360019826379-TrustBox-widget-overview
export enum LayoutTypes {
  Carousel = 'carousel',
  Grid = 'grid',
  List = 'list',
  Mini = 'mini',
  Slider = 'slider',
}

type WidgetData = {
  id: string;
  height: string;
  mobileHeight?: string;
};

type WidgetTypes = { [key in LayoutTypes]?: WidgetData };

const widget: WidgetTypes = {
  [LayoutTypes.Carousel]: {
    id: '53aa8912dec7e10d38f59f36',
    height: '140px',
    mobileHeight: '270px',
  },
  [LayoutTypes.Grid]: {
    id: '539adbd6dec7e10e686debee',
    height: '500px',
  },
  [LayoutTypes.List]: {
    id: '539ad60defb9600b94d7df2c',
    height: '700px',
  },
  [LayoutTypes.Mini]: {
    id: '53aa8807dec7e10d38f59f32',
    height: '118px',
  },
  [LayoutTypes.Slider]: {
    id: '54ad5defc6454f065c28af8b',
    height: '240px',
  },
};

export const layoutToTemplateId = (layout: LayoutTypes) => widget[layout]?.id;

export const layoutToHeight = (layout: LayoutTypes, isMobile?: boolean) => {
  if (isMobile) {
    return widget[layout]?.mobileHeight || widget[layout]?.height;
  }

  return widget[layout]?.height;
};

export const fallbackLinkPrefix = (languageTag: string) => {
  const language = languageTag ? languageTag.split('-')[0] : 'en';

  if (language === 'en' || language === 'el') {
    return 'uk';
  }

  return language;
};

export const fallbackLink = (languageTag: string) => {
  const link = `https://${fallbackLinkPrefix(
    languageTag,
  )}.trustpilot.com/review/www.gostudent.org`;

  return link;
};

export const languageTagToLanguage = (languageTag: string) => {
  const language = languageTag ? languageTag.split('-')[0] : 'en';

  return language;
};
