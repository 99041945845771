import cloneDeep from 'clone-deep';
import { RichTextSchema } from '@storyblok/react';

const mpcRichTextSchema = cloneDeep(RichTextSchema);

mpcRichTextSchema.marks.textStyle = () => {
  // we do not support changing text color
  return {
    tag: '',
  };
};

export default mpcRichTextSchema;
