import AccordionWithTimeline from '../../blocks/AccordionWithTimeline/AccordionWithTimeline';
import AnimatedBackgroundSection from '../../blocks/AnimatedBackgroundSection/AnimatedBackgroundSection';
import BenefitHeading from '../../blocks/BenefitHeading/BenefitHeading';
import BenefitHeadingList from '../../blocks/BenefitHeadingList/BenefitHeadingList';
import BenefitImage from '../../blocks/BenefitImage/BenefitImage';
import BenefitSection from '../../blocks/BenefitSection/BenefitSection';
import BrandBannerSection from '../../blocks/BrandBannerSection/BrandBannerSection';
import Breadcrumbs from '../../blocks/Breadcrumbs/Breadcrumbs';
import ContactDetails from '../../blocks/ContactDetails/ContactDetails';
import ContactsSection from '../../blocks/ContactsSection/ContactsSection';
import ContentCardSection from '../../blocks/ContentCardSection/ContentCardSection';
import CookieDeclarationSection from '../../blocks/CookieDeclarationSection/CookieDeclarationSection';
import CtaButton from '../../blocks/CtaButton/CtaButton';
import CtaLabel from '../../blocks/CtaLabel/CtaLabel';
import CtaLink from '../../blocks/CtaLink/CtaLink';
import DownloadSection from '../../blocks/DownloadSection/DownloadSection';
import EarningsSection from '../../blocks/EarningsSection/EarningsSection';
import Faq from '../../blocks/Faq/Faq';
import FaqItem from '../../blocks/FaqItem/FaqItem';
import FaqSection from '../../blocks/FaqSection/FaqSection';
import FeatureCard from '../../blocks/FeatureCard/FeatureCard';
import FeaturesSection from '../../blocks/FeaturesSection/FeaturesSection';
import Footer from '../../blocks/Footer/Footer';
import Form from '../../blocks/Form/Form';
import FormAgeField from '../../blocks/FormAgeField/FormAgeField';
import FormCertifiedTeacherField from '../../blocks/FormCertifiedTeacherField/FormCertifiedTeacherField';
import FormCityField from '../../blocks/FormCityField/FormCityField';
import FormDiscoveryChannelField from '../../blocks/FormDiscoveryChannelField/FormDiscoveryChannelField';
import FormEmailField from '../../blocks/FormEmailField/FormEmailField';
import FormInfluencerCampaignField from '../../blocks/FormInfluencerCampaignField/FormInfluencerCampaignField';
import FormIntentField from '../../blocks/FormIntentField/FormIntentField';
import FormLastNameField from '../../blocks/FormLastNameField/FormLastNameField';
import FormLeadOwnerEmailField from '../../blocks/FormLeadOwnerEmailField/FormLeadOwnerEmailField';
import FormLegalText from '../../blocks/FormLegalText/FormLegalText';
import FormMessageField from '../../blocks/FormMessageField/FormMessageField';
import FormNameField from '../../blocks/FormNameField/FormNameField';
import FormNewsletterField from '../../blocks/FormNewsletterField/FormNewsletterField';
import FormOnlineOrRemoteField from '../../blocks/FormOnlineOrRemoteField/FormOnlineOrRemoteField';
import FormPhoneField from '../../blocks/FormPhoneField/FormPhoneField';
import FormPostalCodeField from '../../blocks/FormPostalCodeField/FormPostalCodeField';
import FormPromoCodeField from '../../blocks/FormPromoCodeField/FormPromoCodeField';
import FormSchoolGradeField from '../../blocks/FormSchoolGradeField/FormSchoolGradeField';
import FormSchoolLevelField from '../../blocks/FormSchoolLevelField/FormSchoolLevelField';
import FormSharePersonalDataTutorField from '../../blocks/FormSharePersonalDataTutorField/FormSharePersonalDataTutorField';
import FormSubjectField from '../../blocks/FormSubjectField/FormSubjectField';
import FormTermsAndConditionsField from '../../blocks/FormTermsAndConditionsField/FormTermsAndConditionsField';
import FormTermsAndConditionsTutorField from '../../blocks/FormTermsAndConditionsTutorField/FormTermsAndConditionsTutorField';
import FormTutoringSubjectsField from '../../blocks/FormTutoringSubjectsField/FormTutoringSubjectsField';
import FormUserTypeField from '../../blocks/FormUserTypeField/FormUserTypeField';
import Header from '../../blocks/Header/Header';
import Hero from '../../blocks/Hero/Hero';
import HeroContentBox from '../../blocks/HeroContentBox/HeroContentBox';
import HeroContentBoxList from '../../blocks/HeroContentBoxList/HeroContentBoxList';
import HeroContentBoxTestimonial from '../../blocks/HeroContentBoxTestimonial/HeroContentBoxTestimonial';
import HeroFormBox from '../../blocks/HeroFormBox/HeroFormBox';
import HrefData from '../../blocks/HrefData/HrefData';
import Icon from '../../blocks/Icon/Icon';
import IconLink from '../../blocks/IconLink/IconLink';
import Image from '../../blocks/Image/Image';
import ImageSet from '../../blocks/ImageSet/ImageSet';
import JobDetails from '../../blocks/JobDetails/JobDetails';
import JobListings from '../../blocks/JobListings/JobListings';
import LandingPage from '../../blocks/LandingPage/LandingPage';
import LanguageSwitcher from '../../blocks/LanguageSwitcher/LanguageSwitcher';
import LegalNoticeSection from '../../blocks/LegalNoticeSection/LegalNoticeSection';
import Link from '../../blocks/Link/Link';
import MarketingBanner from '../../blocks/MarketingBanner/MarketingBanner';
import Message from '../../blocks/Message/Message';
import MessageBirdChatWidget from '../../blocks/MessageBirdChatWidget/MessageBirdChatWidget';
import Modal from '../../blocks/Modal/Modal';
import Notification from '../../blocks/Notification/Notification';
import OffBrandHero from '../../blocks/OffBrandHero/OffBrandHero';
import OffbrandFooter from '../../blocks/OffbrandFooter/OffbrandFooter';
import OffbrandHeader from '../../blocks/OffbrandHeader/OffbrandHeader';
import OffbrandHowItWorks from '../../blocks/OffbrandHowItWorks/OffbrandHowItWorks';
import OffbrandPage from '../../blocks/OffbrandPage/OffbrandPage';
import OffbrandThankYouBlock from '../../blocks/OffbrandThankYouBlock/OffbrandThankYouBlock';
import OffbrandThreeColumnsText from '../../blocks/OffbrandThreeColumnsText/OffbrandThreeColumnsText';
import OneColumnSection from '../../blocks/OneColumnSection/OneColumnSection';
import Partial from '../../blocks/Partial/Partial';
import PartialReference from '../../blocks/PartialReference/PartialReference';
import PeopleList from '../../blocks/PeopleList/PeopleList';
import PressLogos from '../../blocks/PressLogos/PressLogos';
import PressReleasePage from '../../blocks/PressReleasePage/PressReleasePage';
import PressReleasesSection from '../../blocks/PressReleasesSection/PressReleasesSection';
import PricesSection from '../../blocks/PricesSection/PricesSection';
import RichtextItem from '../../blocks/RichtextItem/RichtextItem';
import RichtextSection from '../../blocks/RichtextSection/RichtextSection';
import Row from '../../blocks/Row/Row';
import SeoLink from '../../blocks/SeoLink/SeoLink';
import SeoLinksGroup from '../../blocks/SeoLinksGroup/SeoLinksGroup';
import SeoLinksSection from '../../blocks/SeoLinksSection/SeoLinksSection';
import SeoMetadata from '../../blocks/SeoMetadata/SeoMetadata';
import SimpleCardsSection from '../../blocks/SimpleCardsSection/SimpleCardsSection';
import SocialMediaLinks from '../../blocks/SocialMediaLinks/SocialMediaLinks';
import Statistics from '../../blocks/Statistics/Statistics';
import StructuredDataProduct from '../../blocks/StructuredDataProduct/StructuredDataProduct';
import SubjectList from '../../blocks/SubjectList/SubjectList';
import TeamPictures from '../../blocks/TeamPictures/TeamPictures';
import TemplatePage from '../../blocks/TemplatePage/TemplatePage';
import ThankYou from '../../blocks/ThankYou/ThankYou';
import ThankYouPage from '../../blocks/ThankYouPage/ThankYouPage';
import Title from '../../blocks/Title/Title';
import TrustpilotBadge from '../../blocks/TrustpilotBadge/TrustpilotBadge';
import TrustpilotFallbackReview from '../../blocks/TrustpilotFallbackReview/TrustpilotFallbackReview';
import TrustpilotWidget from '../../blocks/TrustpilotWidget/TrustpilotWidget';
import TutorsSection from '../../blocks/TutorsSection/TutorsSection';
import TwoColumnsSection from '../../blocks/TwoColumnsSection/TwoColumnsSection';
import Video from '../../blocks/Video/Video';
import VideoInformationTestimonial from '../../blocks/VideoInformationTestimonial/VideoInformationTestimonial';
import VideoTestimonialsSection from '../../blocks/VideoTestimonialsSection/VideoTestimonialsSection';
import WebsiteFooter from '../../blocks/WebsiteFooter/WebsiteFooter';
import WebsiteFooterLinkGroup from '../../blocks/WebsiteFooterLinkGroup/WebsiteFooterLinkGroup';
import WebsiteGeneralBanner from '../../blocks/WebsiteGeneralBanner/WebsiteGeneralBanner';
import WebsiteHeader from '../../blocks/WebsiteHeader/WebsiteHeader';
import WebsiteMenuDropdown from '../../blocks/WebsiteMenuDropdown/WebsiteMenuDropdown';
import WebsiteMenuLink from '../../blocks/WebsiteMenuLink/WebsiteMenuLink';
import WebsiteMenuSubmenu from '../../blocks/WebsiteMenuSubmenu/WebsiteMenuSubmenu';
import WebsitePage from '../../blocks/WebsitePage/WebsitePage';
import WebsiteSubject from '../../blocks/WebsiteSubject/WebsiteSubject';
import WebsiteSubjects from '../../blocks/WebsiteSubjects/WebsiteSubjects';

const components = {
  accordionWithTimeline: AccordionWithTimeline,
  animatedBackgroundSection: AnimatedBackgroundSection,
  benefitHeading: BenefitHeading,
  benefitHeadingList: BenefitHeadingList,
  benefitImage: BenefitImage,
  benefitSection: BenefitSection,
  brandBannerSection: BrandBannerSection,
  breadcrumbs: Breadcrumbs,
  contactDetails: ContactDetails,
  contactsSection: ContactsSection,
  contentCardSection: ContentCardSection,
  cookieDeclarationSection: CookieDeclarationSection,
  ctaButton: CtaButton,
  ctaLabel: CtaLabel,
  ctaLink: CtaLink,
  downloadSection: DownloadSection,
  earningsSection: EarningsSection,
  faq: Faq,
  faqItem: FaqItem,
  faqSection: FaqSection,
  featureCard: FeatureCard,
  featuresSection: FeaturesSection,
  footer: Footer,
  form: Form,
  formAgeField: FormAgeField,
  formCertifiedTeacherField: FormCertifiedTeacherField,
  formCityField: FormCityField,
  formDiscoveryChannelField: FormDiscoveryChannelField,
  formEmailField: FormEmailField,
  formInfluencerCampaignField: FormInfluencerCampaignField,
  formIntentField: FormIntentField,
  formLastNameField: FormLastNameField,
  formLeadOwnerEmailField: FormLeadOwnerEmailField,
  formLegalText: FormLegalText,
  formMessageField: FormMessageField,
  formNameField: FormNameField,
  formNewsletterField: FormNewsletterField,
  formOnlineOrRemoteField: FormOnlineOrRemoteField,
  formPhoneField: FormPhoneField,
  formPostalCodeField: FormPostalCodeField,
  formPromoCodeField: FormPromoCodeField,
  formSchoolGradeField: FormSchoolGradeField,
  formSchoolLevelField: FormSchoolLevelField,
  formSharePersonalDataTutorField: FormSharePersonalDataTutorField,
  formSubjectField: FormSubjectField,
  formTermsAndConditionsField: FormTermsAndConditionsField,
  formTermsAndConditionsTutorField: FormTermsAndConditionsTutorField,
  formTutoringSubjectsField: FormTutoringSubjectsField,
  formUserTypeField: FormUserTypeField,
  header: Header,
  hero: Hero,
  heroContentBox: HeroContentBox,
  heroContentBoxList: HeroContentBoxList,
  heroContentBoxTestimonial: HeroContentBoxTestimonial,
  heroFormBox: HeroFormBox,
  hrefData: HrefData,
  icon: Icon,
  iconLink: IconLink,
  image: Image,
  imageSet: ImageSet,
  jobDetails: JobDetails,
  jobListings: JobListings,
  landingPage: LandingPage,
  languageSwitcher: LanguageSwitcher,
  legalNoticeSection: LegalNoticeSection,
  link: Link,
  marketingBanner: MarketingBanner,
  message: Message,
  messageBirdChatWidget: MessageBirdChatWidget,
  modal: Modal,
  notification: Notification,
  offBrandHero: OffBrandHero,
  offbrandFooter: OffbrandFooter,
  offbrandHeader: OffbrandHeader,
  offbrandHowItWorks: OffbrandHowItWorks,
  offbrandPage: OffbrandPage,
  offbrandThankYouBlock: OffbrandThankYouBlock,
  offbrandThreeColumnsText: OffbrandThreeColumnsText,
  oneColumnSection: OneColumnSection,
  partial: Partial,
  partialReference: PartialReference,
  peopleList: PeopleList,
  pressLogos: PressLogos,
  pressReleasePage: PressReleasePage,
  pressReleasesSection: PressReleasesSection,
  pricesSection: PricesSection,
  richtextItem: RichtextItem,
  richtextSection: RichtextSection,
  row: Row,
  seoLink: SeoLink,
  seoLinksGroup: SeoLinksGroup,
  seoLinksSection: SeoLinksSection,
  seoMetadata: SeoMetadata,
  simpleCardsSection: SimpleCardsSection,
  socialMediaLinks: SocialMediaLinks,
  statistics: Statistics,
  structuredDataProduct: StructuredDataProduct,
  subjectList: SubjectList,
  teamPictures: TeamPictures,
  templatePage: TemplatePage,
  thankYou: ThankYou,
  thankYouPage: ThankYouPage,
  title: Title,
  trustpilotBadge: TrustpilotBadge,
  trustpilotFallbackReview: TrustpilotFallbackReview,
  trustpilotWidget: TrustpilotWidget,
  tutorsSection: TutorsSection,
  twoColumnsSection: TwoColumnsSection,
  video: Video,
  videoTestimonialsSection: VideoTestimonialsSection,
  videoInformationTestimonial: VideoInformationTestimonial,
  websiteFooter: WebsiteFooter,
  websiteFooterLinkGroup: WebsiteFooterLinkGroup,
  websiteGeneralBanner: WebsiteGeneralBanner,
  websiteHeader: WebsiteHeader,
  websiteMenuDropdown: WebsiteMenuDropdown,
  websiteMenuLink: WebsiteMenuLink,
  websiteMenuSubmenu: WebsiteMenuSubmenu,
  websitePage: WebsitePage,
  websiteSubject: WebsiteSubject,
  websiteSubjects: WebsiteSubjects,
};

export const getComponentsMap = (requiredComponents: Array<string>) => {
  const newComponents = { ...components };
  Object.keys(newComponents).forEach((key) => {
    if (!requiredComponents.includes(key)) {
      delete newComponents[key as keyof typeof components];
    }
  });

  return newComponents;
};
