import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';
import PageContainer from '@/components/PageContainer/PageContainer';
import { setCtaSize } from '@/helpers/layout/layoutHelpers';
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import CtaButton, { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import CtaLink, { CtaLinkBlokProps } from '../CtaLink/CtaLink';
import { ScrollIcon } from './Icons';
import Title, { TitleBlokProps } from '../Title/Title';

export interface MarketingBannerBlokProps extends SbBlokData {
  cta: Array<CtaButtonBlokProps>;
  ctaLink: Array<CtaLinkBlokProps>;
  backgroundColor: 'GSblue/50' | 'GSblue/500' | 'GSparchment/200';
  firstParagraph: string;
  heading: string;
  id?: string;
  image?: Asset;
  layoutDirection: 'default' | 'reversed';
  preHeading: string;
  scrollVariant: 'yellow' | 'green' | 'pink';
  secondParagraph: string;
  subText: string;
  title: Array<TitleBlokProps>;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

export interface MarketingBannerProps {
  blok: MarketingBannerBlokProps;
}

const MarketingBanner = ({ blok }: MarketingBannerProps) => {
  const {
    backgroundColor,
    cta: [firstCta] = [],
    ctaLink: [ctaLink] = [],
    firstParagraph,
    secondParagraph,
    heading,
    id,
    image,
    layoutDirection,
    preHeading,
    scrollVariant,
    subText,
    title: [title] = [],
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('bg-GSbase/white', {
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer>
        <div className="w-full grid grid-cols-6 sm:grid-cols-12 gap-2 sm:gap-4 lg:gap-8">
          <div className="sm:col-start-3 col-span-6 sm:col-span-8">
            {title && (
              <Title
                blok={{
                  ...title,
                }}
              />
            )}
          </div>
        </div>
        <div
          className={cx(
            'w-full grid grid-cols-6 sm:grid-cols-12 gap-2 sm:gap-4 lg:gap-8',
            {
              'bg-GSparchment/200': backgroundColor === 'GSparchment/200',
              'bg-GSblue/500': backgroundColor === 'GSblue/500',
              'bg-GSblue/50': backgroundColor === 'GSblue/50',
            },
          )}
        >
          <div
            className={cx('', {
              'col-start-5 col-span-2 sm:col-start-10 sm:col-span-3 sm:row-start-1 lg:col-start-11 lg:col-span-2 lg:row-start-1':
                layoutDirection === 'reversed',
              'col-start-1 col-span-2 sm:col-start-1 sm:col-span-3 sm:row-start-1 lg:col-start-1 lg:col-span-2 lg:row-start-1':
                layoutDirection === 'default',
            })}
          >
            <ScrollIcon
              layout={layoutDirection}
              firstPartClassName={cx('', {
                'fill-GSdarkpurple/500':
                  backgroundColor === 'GSparchment/200' ||
                  backgroundColor === 'GSblue/50',
                'fill-GSpurple/500': backgroundColor === 'GSblue/500',
                'fill-GSlightpink/500':
                  backgroundColor === 'GSblue/500' && scrollVariant === 'pink',
              })}
              secondPartClassName={cx('', {
                'fill-GSyellow/500':
                  (backgroundColor === 'GSparchment/200' ||
                    backgroundColor === 'GSblue/50' ||
                    backgroundColor === 'GSblue/500') &&
                  scrollVariant === 'yellow',
                'fill-GSgreen/500':
                  (backgroundColor === 'GSparchment/200' ||
                    backgroundColor === 'GSblue/50' ||
                    backgroundColor === 'GSblue/500') &&
                  scrollVariant === 'green',
                'fill-GSorange/500':
                  backgroundColor === 'GSblue/500' && scrollVariant === 'pink',
              })}
            />
          </div>

          {image && image.filename && (
            <StoryblokImage
              className={cx('hidden sm:block row-start-1 sm:self-end', {
                'sm:col-start-7 sm:col-span-5 lg:col-start-8 lg:col-span-4':
                  layoutDirection === 'reversed',
                'col-start-2 col-span-4 sm:col-start-2 sm:col-span-5':
                  layoutDirection === 'default',
              })}
              blok={image}
            />
          )}

          <div
            className={cx(
              'flex flex-col justify-center gap-4 sm:gap-6 lg:py-8 lg:gap-8 lg:px-0 px-4 pb-4 col-span-6 sm:col-span-6 sm:content-center lg:col-span-5',
              {
                'lg:col-start-7 sm:py-6 sm:pl-0 sm:pr-6':
                  layoutDirection === 'default',
                'lg:col-start-2 sm:py-6 sm:pl-6 sm:pr-0':
                  layoutDirection === 'reversed',
              },
            )}
          >
            <div className="break-words flex flex-col gap-2 sm:gap-4">
              <div className="flex flex-col gap-2 lg:gap-4">
                <div
                  className={cx('text-xs sm:text-sm lg:text-base', {
                    'text-GSbase/white': backgroundColor === 'GSblue/500',
                    'text-GSblue/600': backgroundColor !== 'GSblue/500',
                  })}
                >
                  {preHeading}
                </div>

                <h2
                  className={cx(
                    'font-raleway font-bold text-[20px] leading-[28px] sm:text-[24px] sm:leading-[32px] lg:text-[28px] lg:leading-[40px]',
                    {
                      'text-GSbase/white': backgroundColor === 'GSblue/500',
                      'text-GSblue/600': backgroundColor !== 'GSblue/500',
                    },
                  )}
                >
                  {heading}
                </h2>
              </div>

              <div className="flex flex-col gap-2">
                <div
                  className={cx(
                    'whitespace-pre-wrap font-worksans text-[16px] leading-[24px] sm:text-[16px] sm:leading-[24px] sm:text-[18px] sm:leading-[28px]',
                    {
                      'text-GSbase/white': backgroundColor === 'GSblue/500',
                      'text-GSdeep/900': backgroundColor !== 'GSblue/500',
                    },
                  )}
                >
                  {firstParagraph}
                </div>
                <div
                  className={cx(
                    'whitespace-pre-wrap font-worksans text-[16px] leading-[24px] sm:text-[16px] sm:leading-[24px] sm:text-[18px] sm:leading-[28px]',
                    {
                      'text-GSbase/white': backgroundColor === 'GSblue/500',
                      'text-GSdeep/900': backgroundColor !== 'GSblue/500',
                    },
                  )}
                >
                  {secondParagraph}
                </div>
              </div>
              <div
                className={cx(
                  'font-worksans text-[12px] leading-[16px] sm:text-14 sm:leading-20',
                  {
                    'text-GSbase/white': backgroundColor === 'GSblue/500',
                    'text-GSdeep/900': backgroundColor !== 'GSblue/500',
                  },
                )}
              >
                {subText}
              </div>
            </div>

            <div className="flex flex-col gap-2 items-start">
              {firstCta && (
                <CtaButton
                  blok={firstCta}
                  type={
                    backgroundColor === 'GSblue/500' ? 'secondary' : 'primary'
                  }
                  size={setCtaSize()}
                />
              )}
              {ctaLink && (
                <CtaLink
                  blok={ctaLink}
                  background={
                    backgroundColor === 'GSblue/500' ? 'darkBG' : 'lightBG'
                  }
                />
              )}
            </div>
          </div>
        </div>
      </PageContainer>
    </section>
  );
};

export default MarketingBanner;
