import { SbBlokData, storyblokEditable } from '@storyblok/react';

export interface CookieSettingsLinkBlokProps extends SbBlokData {
  label: string;
  link: Link;
}

interface CookieSettingsLinkProps {
  blok: CookieSettingsLinkBlokProps;
  className: string;
  children: React.ReactNode;
}

const CookieSettingsLink = ({
  blok,
  className,
  children,
}: CookieSettingsLinkProps) => (
  <li
    {...storyblokEditable(blok)}
    className="cursor-pointer max-w-full w-auto flex text-white font-regular text-xs"
  >
    <a
      className={className}
      rel="noopener noreferrer"
      onClick={() => window.Cookiebot.renew()}
    >
      {children}
    </a>
  </li>
);

export default CookieSettingsLink;
