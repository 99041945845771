import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import CtaButton, { CtaButtonBlokProps } from '../CtaButton/CtaButton';

export interface ContentCardBlokProps extends SbBlokData {
  ctaButton: Array<CtaButtonBlokProps>;
  heading: string;
  image: Asset;
  text: string;
  textAlignment: 'left' | 'center';
}

interface ContentCardProps {
  blok: ContentCardBlokProps;
}

const ContentCard = ({ blok }: ContentCardProps) => {
  const {
    ctaButton: [ctaButton] = [],
    heading,
    image,
    text,
    textAlignment,
  } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="transition shadow-lg w-full h-full flex flex-col rounded-lg box-border border border-GSblue/300 p-4 sm:p-6 lg:p-10 gap-2 sm:gap-4 lg:gap-10 bg-GSbase/white hover:bg-GSblue/100 hover:ring-1 hover:ring-GSblue/300 hover:ring-inset"
    >
      <StoryblokImage
        blok={image}
        className="rounded sm:rounded-lg object-cover h-full w-full"
      />
      <div
        className={cx(
          'w-full flex flex-col sm:grid sm:grid-cols-1 sm:h-full gap-2 sm:gap-4 lg:gap-8',
          {
            'text-left': textAlignment === 'left',
            'text-center': textAlignment === 'center',
          },
        )}
      >
        <div className="flex flex-col gap-2 w-full">
          <div className="text-GSblue/600 text-lg font-bold sm:text-xl lg:text-2xl break-words">
            {heading}
          </div>
          <div className="text-GSdeep/900 text-xs font-normal sm:text-sm lg:text-base break-words whitespace-pre-wrap">
            {text}
          </div>
        </div>
        {ctaButton && (
          <div className="w-full self-end">
            <CtaButton blok={ctaButton} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentCard;
