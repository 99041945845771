import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import StoryblokLink from '@/components/StoryblokLink/StoryblokLink';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

export interface WebsiteSubjectBlokProps extends SbBlokData {
  link: Link;
  label: string;
  icon: Asset;
}

export interface WebsiteSubjectProps {
  blok: WebsiteSubjectBlokProps;
}

const WebsiteSubject = ({ blok }: WebsiteSubjectProps) => {
  const { link, label, icon } = blok;

  return (
    <StoryblokLink
      testId={blok._uid}
      link={link}
      className="p-4 sm:p-6 xl:p-8 border border-GSblue/300 shadow-lg rounded hover:bg-GSblue/100"
      {...storyblokEditable(blok)}
    >
      <div className="break-words flex flex-col items-center gap-2 xl:gap-4">
        <StoryblokImage
          blok={icon}
          fallbackSrc="/assets/image.svg"
          className="h-12 sm:h-14 lg:h-16"
        />
        <div className="w-full text-center font-worksans text-GSdeep/500 font-medium text-sm sm:text-base xl:text-lg">
          {label}
        </div>
      </div>
    </StoryblokLink>
  );
};

export default WebsiteSubject;
