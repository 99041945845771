export const DropdownTriangle = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="20"
    height="8"
    viewBox="0 0 20 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 8.5H1.79289L2.64645 7.64645L8.93934 1.35355C9.52513 0.767766 10.4749 0.767767 11.0607 1.35355L17.3536 7.64645L18.2071 8.5H17H3Z"
      fill="white"
      stroke="#EDEBE1"
    />
  </svg>
);
