import PageContainer from '@/components/PageContainer/PageContainer';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import CtaButtonBlok, {
  CtaButtonBlokProps,
} from '@/blocks/CtaButton/CtaButton';
import { ScrollIcon } from './Icons';

export interface WebsiteGeneralBannerBlokProps extends SbBlokData {
  title: string;
  content: string;
  layoutDirection: 'default' | 'reversed';
  cta: Array<CtaButtonBlokProps>;
  backgroundColor: 'GSpurple/100' | 'GSgreen/100' | 'GSyellow/100';
  scrollVariant: 'green' | 'yellow';
  id?: string;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

interface WebsiteGeneralBannerProps {
  blok: WebsiteGeneralBannerBlokProps;
}

const WebsiteGeneralBanner = ({ blok }: WebsiteGeneralBannerProps) => {
  const {
    title,
    content,
    cta: [ctaButton] = [],
    layoutDirection,
    backgroundColor,
    scrollVariant,
    id,
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('w-full flex items-center relative xl:h-[450px] ', {
        'bg-GSpurple/100': backgroundColor === 'GSpurple/100',
        'bg-GSgreen/100': backgroundColor === 'GSgreen/100',
        'bg-GSyellow/100': backgroundColor === 'GSyellow/100',
        'hidden sm:grid':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer className="grid grid-cols-12 pt-0 pb-4 sm:py-4">
        <div
          className={cx(
            'w-full z-20 flex flex-col gap-y-4 col-span-full sm:col-span-8 sm:gap-x-2 sm:justify-center lg:col-span-7 lg:gap-y-8 2xl:col-span-8',
            {
              'sm:col-start-4 lg:col-start-5 2xl:col-start-4 min-[1800px]:col-start-3 min-[2100px]:col-start-2 min-[2100px]:col-span-10':
                layoutDirection === 'reversed',
              'sm:col-start-1 lg:col-start-2 xl:col-start-2 2xl:col-start-2':
                layoutDirection === 'default',
            },
          )}
        >
          <div className="w-full flex flex-col gap-y-2">
            <h2
              className="
              font-raleway font-extrabold text-GSblue/600 mt-2 text-xl
              sm:text-2xl sm:leading-8 lg:text-[28px] lg:leading-10"
            >
              {title}
            </h2>
            <div
              className="
              font-normal text-base text-GSdeep/900
              sm:text-lg
              lg:text-xl lg:text-[18px]
            "
            >
              {content}
            </div>
          </div>
          {ctaButton && (
            <div className="w-full flex flex-col sm:max-w-fit">
              <CtaButtonBlok blok={ctaButton} />
            </div>
          )}
        </div>
        <div
          className={cx('flex h-full col-span-4 sm:col-span-3 r-0 z-10 min:', {
            'ml-[-28px] sm:ml-[-48px] order-first lg:col-span-4 xl:col-span-3 2xl:col-span-1':
              layoutDirection === 'reversed',
            'col-start-10 mr-[-28px] sm:mr-[-48px] order-first sm:order-last ':
              layoutDirection === 'default',
          })}
        >
          <div
            className={cx(
              'w-full self-end lg:self-center xl:top-4 sm:w-[200px] lg:w-[300px] xl:w-[400px]',
              {
                'right-0 sm:absolute top-1': layoutDirection === 'default',
                'lg:left-0 lg:absolute': layoutDirection === 'reversed',
              },
            )}
          >
            <ScrollIcon
              scrollPosition={layoutDirection === 'default' ? 'right' : 'left'}
              firstPartClassName="fill-GSdarkpurple/500"
              secondPartClassName={cx('', {
                'fill-GSgreen/500': scrollVariant === 'green',
                'fill-GSyellow/500': scrollVariant === 'yellow',
              })}
            />
          </div>
        </div>
      </PageContainer>
    </section>
  );
};

export default WebsiteGeneralBanner;
