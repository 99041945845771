import cloneDeep from 'clone-deep';
import { RichTextSchema } from '@storyblok/react';

const mpcRichTextSchema = cloneDeep(RichTextSchema);

mpcRichTextSchema.marks.link = (node: ISbNode) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const schema: any = RichTextSchema.marks.link(node);

  schema.tag[0].attrs.class = 'underline';

  return schema;
};

mpcRichTextSchema.marks.textStyle = () => {
  // we do not support changing text color
  return {
    tag: '',
  };
};

export default mpcRichTextSchema;
