import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import { CtaLinkBlokProps } from '../CtaLink/CtaLink';

export interface BenefitHeadingBlokProps extends SbBlokData {
  actions: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
  header: string;
  firstParagraph: string;
  secondParagraph: string;
}

interface BenefitHeadingProps {
  blok: BenefitHeadingBlokProps;
  parentLayout: 'imageAndHeading' | 'imageAndHeadingList' | 'headingList';
  parentLayoutDirection?: 'default' | 'reversed';
  isCard?: boolean;
  hasTopPaddingOnMobile?: boolean;
  textCenteredOnMobile?: boolean;
  backgroundColor?:
    | 'none'
    | 'GSparchment/100'
    | 'GSpurple/100'
    | 'GSgreen/100'
    | 'GSyellow/100';
  textAlignment?: 'left' | 'center';
  headingsLevel: 'h2' | 'h3';
}

const BenefitHeading = ({
  backgroundColor = 'none',
  blok,
  isCard = false,
  parentLayout,
  parentLayoutDirection,
  hasTopPaddingOnMobile = true,
  textCenteredOnMobile = false,
  textAlignment = 'left',
  headingsLevel = 'h3',
}: BenefitHeadingProps) => {
  const { actions = [], header, firstParagraph, secondParagraph } = blok;

  const HeadingLevel = headingsLevel;

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx('rounded-lg gap-2 flex flex-col', {
        'justify-center': parentLayout === 'imageAndHeading',
        'bg-none': backgroundColor === 'none' || !backgroundColor,
        'bg-GSpurple/100': backgroundColor === 'GSpurple/100',
        'bg-GSgreen/100': backgroundColor === 'GSgreen/100',
        'bg-GSyellow/100': backgroundColor === 'GSyellow/100',
        'bg-GSparchment/100': backgroundColor === 'GSparchment/100',
        'text-center': textAlignment === 'center',
        'text-left': textAlignment === 'left',
        'text-center sm:text-left': textCenteredOnMobile,
        'py-4 sm:py-6 lg:py-10':
          isCard ||
          (backgroundColor !== 'none' && parentLayout !== 'imageAndHeading'),
        'px-4 sm:px-6 lg:px-10':
          isCard || (backgroundColor && backgroundColor !== 'none'),
        '!pt-0 sm:!pt-8': hasTopPaddingOnMobile === false,
        'sm:pl-8':
          parentLayout === 'imageAndHeading' &&
          parentLayoutDirection === 'reversed' &&
          isCard,
        'sm:pr-8':
          parentLayout === 'imageAndHeading' &&
          parentLayoutDirection === 'default' &&
          isCard,
      })}
    >
      {header && (
        <HeadingLevel
          className={cx('text-GSblue/600 font-raleway font-bold', {
            'text-xl sm:text-2xl lg:text-[28px] lg:leading-[40px]':
              headingsLevel === 'h2',
            'text-lg sm:text-xl lg:text-2xl': headingsLevel === 'h3',
          })}
        >
          {header}
        </HeadingLevel>
      )}
      <div className="flex flex-col gap-4 sm:gap-6 lg:gap-10">
        <div className="flex flex-col gap-2">
          <div className="text-GSdeep/900 font-normal text-sm lg:text-base whitespace-pre-wrap">
            {firstParagraph}
          </div>
          {secondParagraph && (
            <div className="text-GSdeep/900 font-normal text-sm lg:text-base whitespace-pre-wrap">
              {secondParagraph}
            </div>
          )}
        </div>
        {actions && actions.length > 0 && (
          <div className="flex gap-2 flex-col sm:flex-row">
            {actions.map((nestedBlock) => (
              <StoryblokComponent blok={nestedBlock} key={nestedBlock._uid} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BenefitHeading;
