import StoryblokLink from '@/components/StoryblokLink/StoryblokLink';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

export interface SeoLinkBlokProps extends SbBlokData {
  link: Link;
  label: string;
}

interface SeoLinkProps {
  blok: SeoLinkBlokProps;
}

const SeoLink = ({ blok }: SeoLinkProps) => {
  const { link, label } = blok;

  return (
    <StoryblokLink link={link} {...storyblokEditable(blok)}>
      <div className="font-worksans underline underline-offset-2 decoration-1 font-regular text-GSdeep/500">
        {label}
      </div>
    </StoryblokLink>
  );
};

export default SeoLink;
