import StoryblokImage from '../StoryblokImage/StoryblokImage';
import StoryblokLink from '../StoryblokLink/StoryblokLink';

interface SubjectCardProps {
  link?: Link;
  image?: Asset;
  label: string;
}

const SubjectCard = ({ link, image, label }: SubjectCardProps) => {
  return (
    <StoryblokLink
      link={link}
      className="flex items-center justify-center w-[110px] h-[110px] border border-GSblue/100 rounded hover:border-GSblue/300 hover:bg-GSblue/100"
    >
      <div className="break-words flex flex-col items-center gap-2 xl:gap-2">
        {image && (
          <StoryblokImage
            blok={image}
            fallbackSrc="/assets/image.svg"
            className="lg:h-10"
          />
        )}
        <div className="w-full text-center font-worksans text-GSdeep/500 font-medium text-sm">
          {label}
        </div>
      </div>
    </StoryblokLink>
  );
};

export default SubjectCard;
