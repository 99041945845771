import TextInput from '@/components/TextInput/TextInput';
import { FormDataType } from '@/helpers/form/formHelpersTypes';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';

const FIELD_ID = 'promoCode';

export const FieldData: FormDataType = {
  hubspotId: 'promo_code',
  id: FIELD_ID,
  type: 'text',
  validationType: 'string',
} as const;

export interface FormPromoCodeFieldBlokProps extends SbBlokData {
  label: string;
  placeholder: string;
  readOnly: boolean;
  formikErrors: FormikErrors<{ [FIELD_ID]: string }>;
  touched: FormikTouched<{ [FIELD_ID]: boolean }>;
}

interface FormPromoCodeFieldProps {
  blok: FormPromoCodeFieldBlokProps;
  field: FieldInputProps<string>;
}

const FormPromoCodeField = ({ blok, field }: FormPromoCodeFieldProps) => {
  const { label, placeholder, formikErrors, touched, readOnly } = blok;

  return (
    <div {...storyblokEditable(blok)}>
      <TextInput
        hubspotId={FieldData.hubspotId}
        type="text"
        placeholder={placeholder}
        error={
          formikErrors[FIELD_ID] && touched[FIELD_ID]
            ? formikErrors[FIELD_ID] || ''
            : ''
        }
        {...field}
        label={label}
        disabled={readOnly}
      />
    </div>
  );
};

export default FormPromoCodeField;
