import cx from 'classnames';

export type OptionType = {
  value: string;
  label: string;
};

export interface RadioGroupProps {
  defaultChecked?: string;
  description?: string;
  disabled?: boolean;
  error?: string;
  isTutorForm?: boolean;
  hubspotId: string;
  label?: string;
  name: string;
  onChange: (value: string) => void;
  options: OptionType[];
}

const RadioGroup = (props: RadioGroupProps) => {
  const {
    defaultChecked,
    description,
    disabled,
    error,
    hubspotId,
    isTutorForm = false,
    label,
    name,
    onChange,
    options,
  } = props;

  const handleChange = (event: React.FormEvent<HTMLInputElement>) =>
    onChange(event.currentTarget.value);

  return (
    <div className="flex flex-col gap-y-4">
      <label className="hidden" htmlFor={hubspotId}>
        {hubspotId}
      </label>
      <input
        name={hubspotId}
        className="hidden"
        value={defaultChecked}
        readOnly
      />
      <div className="flex flex-col gap-y-1">
        <div
          className={cx('text-GSdeep/900 text-sm font-medium', {
            'lg:text-base': isTutorForm,
          })}
        >
          {label}
        </div>
        {description && (
          <div className="text-GSsteel/500 text-xs lg:text-sm font-medium">
            {description}
          </div>
        )}
      </div>
      <div
        className={cx('flex gap-x-4', {
          'flex-col gap-y-1': options.length > 2,
        })}
      >
        {options.map(({ label: optionLabel, value }) => (
          <div key={value} className="flex gap-2 items-center">
            <div className="grid place-content-center">
              <input
                className="
                  peer appearance-none border-GSblue/200 checked:border-GSblue/500 border-2
                  w-4 h-4 rounded-full col-start-1 row-start-1 m-auto
                  focus:outline-none focus:ring-offset-0 focus:ring-2 focus:ring-GSblue/100
                  disabled:border-GSsteel/400"
                type="radio"
                id={value}
                name={name}
                value={value}
                disabled={disabled}
                onChange={handleChange}
                defaultChecked={defaultChecked === value}
              />
              <div
                className="
                  pointer-events-none peer-checked:bg-GSblue/500 peer-checked:peer-disabled:bg-GSsteel/400
                  rounded-full w-2 h-2 col-start-1 row-start-1 m-auto"
              />
            </div>
            <label
              className={cx('hover:cursor-pointer font-normal text-sm', {
                'text-GSdeep/900': !disabled,
                'text-GSsteel/500 opacity-60': disabled,
              })}
              htmlFor={value}
            >
              {optionLabel}
            </label>
          </div>
        ))}
      </div>
      {error && (
        <div className="text-xs text-GSfunctional/error font-medium">
          {error}
        </div>
      )}
    </div>
  );
};

export default RadioGroup;
