import { SbBlokData } from '@storyblok/react';
import cx from 'classnames';

export interface PositionController {
  deviceClassNames: string;
}

interface PositionControllerBlokProps extends SbBlokData {
  desktop?: 'top' | 'sticky' | '';
  position?: 'top' | 'sticky' | 'device' | '';
  mobile?: 'top' | 'sticky' | '';
  tablet?: 'top' | 'sticky' | '';
}

interface PositionControllerProps {
  position: Array<PositionControllerBlokProps>;
}

const buildDeviceClassNames = (props: PositionControllerBlokProps) => {
  const { desktop, position, mobile, tablet } = props;

  switch (position) {
    case 'top':
      return 'absolute';
    case 'sticky':
      return 'sticky';
    case 'device':
      return cx({
        absolute: mobile === 'top',
        sticky: mobile === 'sticky',
        'sm:absolute': tablet === 'top',
        'sm:sticky': tablet === 'sticky',
        'lg:absolute': desktop === 'top',
        'lg:sticky': desktop === 'sticky',
      });
    default:
      return '';
  }
};

export const usePositionController = (
  componentBlok: SbBlokData,
): PositionController => {
  const { position: [positionBlok] = [] } =
    componentBlok as unknown as PositionControllerProps;

  const {
    desktop = 'sticky',
    position = 'sticky',
    mobile = 'sticky',
    tablet = 'sticky',
  } = (positionBlok as PositionControllerBlokProps) || {};

  return {
    deviceClassNames: buildDeviceClassNames({
      position: position === '' ? 'sticky' : position,
      mobile: mobile === '' ? 'sticky' : mobile,
      tablet: tablet === '' ? 'sticky' : tablet,
      desktop: desktop === '' ? 'sticky' : desktop,
    }),
  };
};
