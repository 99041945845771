import Head from 'next/head';

const Optimizely = ({ id }: { id: string }) => (
  <>
    {id && (
      <Head>
        <script
          data-cookieconsent="ignore"
          src={`https://cdn.optimizely.com/js/${id}.js`}
          type="text/javascript"
        />
      </Head>
    )}
  </>
);

export default Optimizely;
