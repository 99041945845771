import { RichTextSchema } from '@storyblok/react';
import cloneDeep from 'clone-deep';

const mpcRichTextSchema = cloneDeep(RichTextSchema);

mpcRichTextSchema.marks.link = (node: ISbNode) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const schema: any = RichTextSchema.marks.link(node);

  schema.tag[0].attrs.class = 'underline font-medium';

  return schema;
};

mpcRichTextSchema.nodes.bullet_list = () => ({
  tag: [
    {
      tag: 'ul',
      attrs: {
        class: 'pl-6 list-disc font-normal font-worksans flex flex-col',
      },
    },
  ],
});

mpcRichTextSchema.marks.textStyle = () => {
  // we do not support changing text color
  return {
    tag: '',
  };
};

mpcRichTextSchema.nodes.paragraph = () => ({
  tag: [
    {
      tag: 'p',
      attrs: {
        class: 'font-worksans lg:text-base',
      },
    },
  ],
});

export default mpcRichTextSchema;
