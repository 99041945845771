import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';
import HeroContentBoxListItem, {
  HeroContentBoxListItemBlokProps,
} from '../HeroContentBoxListItem/HeroContentBoxListItem';

export interface HeroContentBoxListBlokProps extends SbBlokData {
  items: Array<HeroContentBoxListItemBlokProps>;
}

interface HeroContentBoxListProps {
  backgroundColor: 'dark' | 'light';
  blok: HeroContentBoxListBlokProps;
  colorVariant: 'scrollVariantGreen500' | 'scrollVariantYellow500';
  layout: 'bulletList' | 'featureList';
}

const HeroContentBoxList = ({
  backgroundColor = 'light',
  blok,
  colorVariant = 'scrollVariantGreen500',
  layout = 'bulletList',
}: HeroContentBoxListProps) => {
  const { items = [] } = blok;

  return (
    <>
      {(layout === 'bulletList' || layout === 'featureList') && (
        <div
          {...storyblokEditable(blok)}
          className={cx('flex flex-col gap-2 sm:gap-3', {
            'text-GSbase/white': backgroundColor === 'dark',
            'text-GSdeep/900': backgroundColor === 'light',
          })}
        >
          {items.map((listItemBlok: HeroContentBoxListItemBlokProps) => (
            <HeroContentBoxListItem
              blok={listItemBlok}
              key={listItemBlok._uid}
              colorVariant={colorVariant}
              layout={layout}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default HeroContentBoxList;
