import { EVENTS } from './constants';

const createHash = async (text: string) => {
  const msgUint8 = new TextEncoder().encode(text);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};

export const buildFormSubmissionSuccessEvent = async ({
  userEmail,
  userPhone,
}: {
  [key: string]: string | boolean | number | object;
}) => ({
  fields: {
    userData: {
      user_email_hash: await createHash(userEmail.toString()),
      user_email: userEmail,
      user_phone_hash: await createHash(userPhone.toString()),
      user_phone: userPhone,
    },
    formId: 'contactForm',
  },
});

export const buildGenericEvent = async (fields: {
  [key: string]: string | boolean | number | object;
}) => ({ fields });

export const EVENTS_BUILDER = {
  [EVENTS.FORM_SUBMISSION_SUCCESS]: buildFormSubmissionSuccessEvent,
  [EVENTS.GENERIC_EVENT]: buildGenericEvent,
};
