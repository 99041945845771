import { SbBlokData, storyblokEditable } from '@storyblok/react';

import { PhoneIcon } from './Icons';

export interface HeaderPhoneNumberBlokProps extends SbBlokData {
  number: string;
  iconVisibility: 'hide' | 'show';
}

interface HeaderPhoneNumberProps {
  blok: HeaderPhoneNumberBlokProps;
  disablePlacement?: boolean;
}

const HeaderPhoneNumber = ({ blok }: HeaderPhoneNumberProps) => {
  const { iconVisibility, number } = blok;

  return (
    <div {...storyblokEditable(blok)} className="flex ml-auto">
      {iconVisibility === 'show' && (
        <div className="flex items-center pr-2">
          <PhoneIcon />
        </div>
      )}
      <div className="text-GSdeep/900 font-bold text-base flex items-center">
        {number}
      </div>
    </div>
  );
};

export default HeaderPhoneNumber;
