import { SbBlokData, storyblokEditable } from '@storyblok/react';

export interface OffbrandTitleBodyBlokProps extends SbBlokData {
  title: string;
  body: string;
}

export interface OffbrandTitleBodyProps {
  blok: OffbrandTitleBodyBlokProps;
}

const OffbrandTitleBody = ({ blok }: OffbrandTitleBodyProps) => {
  const { title, body } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="w-full flex flex-col text-GSdeep/900 gap-2"
    >
      <h3 className="font-bold text-lg sm:text-xl lg:text-xl text-center sm:text-left">
        <span className="font-dosis break-words whitespace-pre-wrap">
          {title}
        </span>
      </h3>
      <div className="font-dosis font-normal text-xs sm:text-sm break-words whitespace-pre-wrap text-center sm:text-left">
        {body}
      </div>
    </div>
  );
};

export default OffbrandTitleBody;
