import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import PageContainer from '@/components/PageContainer/PageContainer';
import TitleBlok, { TitleBlokProps } from '../Title/Title';
import EarningsPriceItem, {
  EarningsPriceItemBlokProps,
} from '../EarningsPriceItem/EarningsPriceItem';
import EarningsCalculator, {
  EarningsCalculatorBlokProps,
} from '../EarningsCalculator/EarningsCalculator';

export interface EarningsSectionBlokProps extends SbBlokData {
  heading: string;
  id?: string;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
  subheading: string;
  title: Array<TitleBlokProps>;
  priceItems: Array<EarningsPriceItemBlokProps>;
  calculator: Array<EarningsCalculatorBlokProps>;
}

interface EarningsSectionProps {
  blok: EarningsSectionBlokProps;
}

const EarningsSection = ({ blok }: EarningsSectionProps) => {
  const {
    calculator: [calculator] = [],
    id,
    heading,
    priceItems: [firstPriceItem, secondPriceItem] = [],
    subheading,
    title: [title] = [],
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('w-full bg-GSparchment/200', {
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer>
        <div className="w-full flex flex-col sm:grid sm:grid-cols-12 gap-y-4 sm:gap-y-6 lg:gap-y-10 gap-x-2 sm:gap-x-4 lg:gap-x-8 break-words">
          <div className="w-full col-span-full lg:col-span-10 lg:col-start-2">
            <TitleBlok blok={title} headingLevel="h2" />
          </div>
          <div className="w-full col-start-1 col-span-full lg:col-span-10 lg:col-start-2 grid grid-cols-6 sm:grid-cols-12 lg:grid-cols-10 gap-y-4 lg:gap-y-10 gap-x-2 sm:gap-x-4 lg:gap-x-8 items-center">
            <div className="col-span-full sm:col-span-6 lg:col-span-6 flex flex-col gap-4">
              <div className="w-full flex flex-col gap-2">
                <h3 className="text-GSblue/600 font-bold text-lg lg:text-2xl text-center lg:text-left">
                  {heading}
                </h3>
                <h4 className="text-GSdeep/900 text-sm lg:text-base text-center lg:text-left">
                  {subheading}
                </h4>
              </div>
              <div className="flex gap-2 sm:gap-4 lg:gap-16 items-center justify-center lg:justify-start w-full">
                <div className="min-w-0 basis-1/2">
                  {firstPriceItem && (
                    <EarningsPriceItem blok={firstPriceItem} />
                  )}
                </div>
                <div className="min-w-5 grow-0">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.85351 5.76908C8.04877 5.57382 8.04877 5.25724 7.85351 5.06197C7.65824 4.86671 7.34166 4.86671 7.1464 5.06197L2.1464 10.062L1.79285 10.4155L2.1464 10.7691L7.1464 15.7691C7.34166 15.9643 7.65824 15.9643 7.85351 15.7691C8.04877 15.5738 8.04877 15.2572 7.85351 15.062L3.20706 10.4155L7.85351 5.76908ZM12.1464 15.062C11.9511 15.2572 11.9511 15.5738 12.1464 15.7691C12.3417 15.9643 12.6582 15.9643 12.8535 15.7691L17.8535 10.7691L18.2071 10.4155L17.8535 10.062L12.8535 5.06197C12.6582 4.86671 12.3417 4.86671 12.1464 5.06197C11.9511 5.25724 11.9511 5.57382 12.1464 5.76908L16.7928 10.4155L12.1464 15.062Z"
                      fill="#0B2FAC"
                    />
                  </svg>
                </div>
                <div className="min-w-0 basis-1/2">
                  {secondPriceItem && (
                    <EarningsPriceItem blok={secondPriceItem} />
                  )}
                </div>
              </div>
            </div>
            {calculator && (
              <div className="col-span-full sm:col-span-6 lg:col-span-4">
                <EarningsCalculator blok={calculator} />
              </div>
            )}
          </div>
        </div>
      </PageContainer>
    </section>
  );
};

export default EarningsSection;
