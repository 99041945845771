import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';

import PageContainer from '@/components/PageContainer/PageContainer';
import { PeopleCarousel } from '@/components';
import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import useResponsive from '@/helpers/layout/useResponsive';
import PersonCardBlok, { PersonCardBlokProps } from '../PersonCard/PersonCard';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import { CtaLinkBlokProps } from '../CtaLink/CtaLink';

export interface PeopleListBlokProps extends SbBlokData {
  id?: string;
  people: Array<PersonCardBlokProps>;
  ratingsVisibility: 'show' | 'hide';
  subjectsVisibility: 'show' | 'hide';
  title: Array<TitleBlokProps>;
  visibilityOnMobile: 'show' | 'hide';
  cta?: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
}

interface PeopleListProps {
  blok: PeopleListBlokProps;
}

const PeopleList = ({ blok }: PeopleListProps) => {
  const {
    cta = [],
    id,
    people = [],
    ratingsVisibility,
    subjectsVisibility,
    title: [title],
    visibilityOnMobile = 'show',
  } = blok;
  const { isMobile } = useResponsive();

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('bg-GSbase/white w-full', {
        'hidden sm:block': visibilityOnMobile === 'hide',
      })}
      id={id}
    >
      <PageContainer>
        {title && <TitleBlok blok={title} headingLevel="h2" />}
        <div
          className="
          grid grid-cols-1 gap-2 justify-center w-full
          sm:grid-cols-6 sm:gap-8
          "
        >
          {isMobile ? (
            <PeopleCarousel
              ratingsVisibility={ratingsVisibility}
              subjectsVisibility={subjectsVisibility}
              items={people}
            />
          ) : (
            people.map((person: PersonCardBlokProps, index: number) => (
              <PersonCardBlok
                blok={person}
                className={cx({ 'hidden sm:flex': index > 3 })}
                key={person._uid}
                ratingVisibility={ratingsVisibility}
                subjectVisibility={subjectsVisibility}
              />
            ))
          )}
        </div>
        {cta && cta.length > 0 && (
          <div className="w-full flex flex-col sm:flex-row gap-2 sm:gap-8 justify-center">
            {cta.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </div>
        )}
      </PageContainer>
    </section>
  );
};

export default PeopleList;
