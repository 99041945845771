import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import { ScrollIcon, SmallScrollIcon } from './Icons';
import { useTemplateDataContext } from '../TemplateData/TemplateDataContext';

export interface HeroImageBlokProps extends SbBlokData {
  backgroundScroll: 'enabled' | 'disabled';
  backgroundScrollOnMobile?: 'enabled' | 'disabled';
  image: Asset;
  badgeList: Array<Asset>;
  layout: 'default' | 'influencer' | 'full';
  widthRatio?: 'fortyPercent' | 'fiftyPercent' | '';
  influencerName: string;
  influencerQuote: string;
  influencerNamePlacement: 'imageCaption' | 'quoteTitle';
  scrollOnTabletPlacement?: 'default' | 'top';
  scrollOnDesktopPlacement?: 'default' | 'top';
  scrollType?: 'over' | 'under';
}

interface HeroImageProps {
  blok: HeroImageBlokProps;
  backgroundColor: 'dark' | 'light';
  colorVariant: 'scrollVariantGreen500' | 'scrollVariantYellow500';
  isReversed?: boolean;
}

const HeroImage = ({
  blok,
  backgroundColor,
  colorVariant,
  isReversed = false,
}: HeroImageProps) => {
  const {
    backgroundScroll = 'enabled',
    backgroundScrollOnMobile = 'disabled',
    badgeList,
    image,
    layout = 'default',
    influencerName,
    influencerNamePlacement = 'imageCaption',
    influencerQuote,
    scrollOnTabletPlacement = 'default',
    scrollOnDesktopPlacement = 'default',
    scrollType = 'under',
  } = {
    ...blok,
    backgroundScroll: blok.backgroundScroll || undefined,
    backgroundScrollOnMobile: blok.backgroundScrollOnMobile || undefined,
    influencerNamePlacement: blok.influencerNamePlacement || undefined,
    layout: blok.layout || undefined,
    scrollOnTabletPlacement: blok.scrollOnTabletPlacement || undefined,
    scrollType: blok.scrollType || undefined,
  };

  const { getFilledTemplateStringWithFallback, updateImageBlok } =
    useTemplateDataContext();

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx({
        'lg:h-full':
          backgroundScroll === 'enabled' && scrollOnDesktopPlacement === 'top',
        'lg:h-auto':
          backgroundScroll === 'enabled' && scrollOnDesktopPlacement !== 'top',
        'sm:h-full':
          backgroundScroll === 'enabled' && scrollOnTabletPlacement === 'top',
        'h-full': scrollType === 'over' && backgroundScroll === 'enabled',
        'hidden sm:block !h-full relative': layout === 'full',
      })}
    >
      {layout === 'default' && backgroundScroll === 'enabled' && (
        <div
          className={cx('relative sm:block', {
            'lg:h-full': scrollOnDesktopPlacement === 'top',
            'sm:h-full': scrollOnTabletPlacement === 'top',
            'h-full': scrollType === 'over' && backgroundScroll === 'enabled',
            hidden:
              !backgroundScrollOnMobile ||
              backgroundScrollOnMobile === 'disabled',
          })}
        >
          {scrollType === 'under' && (
            <>
              <div
                className={cx('max-w-[50%] sm:max-w-full', {
                  '-scale-x-[1] ml-auto': isReversed,
                })}
              >
                <ScrollIcon
                  firstPartClassName={cx('', {
                    'fill-GSdarkpurple/500': backgroundColor === 'light',
                    'fill-GSpurple/500': backgroundColor === 'dark',
                  })}
                  secondPartClassName={cx('', {
                    'fill-GSgreen/500':
                      colorVariant === 'scrollVariantGreen500',
                    'fill-GSyellow/500':
                      colorVariant === 'scrollVariantYellow500',
                  })}
                />
              </div>
              {image.filename && (
                <StoryblokImage
                  className={cx(
                    'hidden sm:block absolute bottom-0 lg:w-full lg:max-w-[60%] sm:max-h-full',
                    {
                      'right-0': isReversed,
                      'sm:max-w-[60%]': scrollOnTabletPlacement !== 'top',
                    },
                  )}
                  blok={image}
                  lazyLoading={false}
                />
              )}
            </>
          )}
          {scrollType === 'over' && (
            <div className="grid grid-cols-6 gap-2 sm:gap-4 lg:gap-8 h-full">
              <div
                className={cx('col-span-2 z-10 row-start-1 self-start', {
                  '-scale-x-[1] left-0 col-start-1': !isReversed,
                  'right-0 col-end-7': isReversed,
                })}
              >
                <SmallScrollIcon
                  firstPartClassName={cx({
                    'fill-GSdarkpurple/500': backgroundColor === 'light',
                    'fill-GSpurple/500': backgroundColor === 'dark',
                  })}
                  secondPartClassName={cx({
                    'fill-GSgreen/500':
                      colorVariant === 'scrollVariantGreen500',
                    'fill-GSyellow/500':
                      colorVariant === 'scrollVariantYellow500',
                  })}
                />
              </div>
              <div
                className={cx(
                  'hidden sm:block w-full row-start-1 col-start-1 col-span-6 p-4 pt-6',
                  'max-w-[768px] lg:max-w-[752px] gap-y-4 grid grid-cols-6',
                  'lg:p-0 lg:gap-8 sm:pb-6 sm:pt-16 lg:py-16',
                  {
                    'sm:p-6 sm:pr-0 lg:pl-10 ml-auto': !isReversed,
                    'sm:p-0 sm:pr-6 lg:pr-10 mr-auto': isReversed,
                  },
                )}
              >
                <div
                  className={cx(
                    'w-full self-center col-span-6 col-start-1 lg:col-span-5 2xl:col-span-5',
                    {
                      'lg:col-start-2': !isReversed,
                      '2xl:col-start-2': isReversed,
                    },
                  )}
                >
                  <StoryblokImage
                    className="w-full rounded-lg"
                    blok={image}
                    lazyLoading={false}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {layout === 'default' && backgroundScroll === 'disabled' && (
        <div className="flex flex-col gap-8">
          <div className={cx('sm:block', { hidden: badgeList?.length > 0 })}>
            <StoryblokImage
              className="m-auto"
              blok={image}
              lazyLoading={false}
            />
          </div>
          {badgeList && badgeList.length > 0 && (
            <div className="flex flex-row gap-2 sm:gap-4 lg:gap-8 justify-center">
              {badgeList.slice(0, 3).map((badge) => (
                <div key={badge.id}>
                  <StoryblokImage
                    blok={badge}
                    className="max-h-32"
                    lazyLoading={false}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {layout === 'influencer' && (
        <div
          className={cx('flex flex-col w-full', {
            'gap-y-4 sm:gap-6 lg:gap-8':
              influencerNamePlacement === 'quoteTitle',
            'gap-y-[44px] sm:gap-[52px] lg:gap-[60px]':
              influencerNamePlacement === 'imageCaption',
          })}
        >
          <div className="flex relative">
            <div className="w-full rounded-lg overflow-hidden">
              <StoryblokImage
                className="w-full"
                blok={updateImageBlok(`${blok.component}.image`, image)}
                lazyLoading={false}
              />
            </div>
            {influencerName && influencerNamePlacement === 'imageCaption' && (
              <div className="flex absolute w-full bottom-0">
                <div className="font-raleway mx-auto translate-y-2/4 text-GSblue/600 text-[28px] leading-10 font-extrabold py-2 px-4 bg-GSpurple/100 rounded">
                  {getFilledTemplateStringWithFallback(influencerName)}
                </div>
              </div>
            )}
          </div>
          {(influencerName || influencerQuote) && (
            <div className="flex flex-col gap-2">
              {influencerName && influencerNamePlacement === 'quoteTitle' && (
                <div
                  className={cx(
                    'font-raleway text-xl sm:text-2xl lg:text-[28px] lg:leading-10 font-bold',
                    {
                      'text-GSblue/600': backgroundColor === 'light',
                      'text-GSbase/white': backgroundColor === 'dark',
                    },
                  )}
                >
                  {getFilledTemplateStringWithFallback(influencerName)}
                </div>
              )}
              {influencerQuote && (
                <div
                  className={cx('text-base sm:text-lg lg:text-xl font-medium', {
                    'text-GSdeep/900': backgroundColor === 'light',
                    'text-GSbase/white': backgroundColor === 'dark',
                  })}
                >
                  {getFilledTemplateStringWithFallback(influencerQuote)}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {layout === 'full' && (
        <StoryblokImage
          className="object-cover h-full w-full sm:absolute"
          blok={image}
          lazyLoading={false}
        />
      )}
    </div>
  );
};

export default HeroImage;
