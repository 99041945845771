import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import { FormDataType } from '@/helpers/form/formHelpersTypes';
import Textarea from '@/components/Textarea/Textarea';
import React from 'react';

const FIELD_ID = 'message';

export const FieldData: FormDataType = {
  hubspotId: 'message',
  id: FIELD_ID,
  type: 'text',
  validationType: 'string',
} as const;

export interface FormMessageFieldBlokProps extends SbBlokData {
  charactersLimit: string;
  labels: Record<string, string>;
  readOnly: boolean;
  formikErrors: FormikErrors<{ [FIELD_ID]: string }>;
  touched: FormikTouched<{ [FIELD_ID]: boolean }>;
}

interface FormMessageFieldProps {
  blok: FormMessageFieldBlokProps;
  field: FieldInputProps<string>;
}

const FormMessageField = ({ blok, field }: FormMessageFieldProps) => {
  const { charactersLimit, labels, formikErrors, touched, readOnly } = blok;

  const label = labels[`${FIELD_ID}_label`];
  const placeholder = labels[`${FIELD_ID}_placeholder`];
  const charactersRemaining = labels[`${FIELD_ID}_characters_remaining`];

  return (
    <div {...storyblokEditable(blok)}>
      <Textarea
        charactersLimit={Number.parseInt(charactersLimit, 10) || 0}
        hubspotId={FieldData.hubspotId}
        placeholder={placeholder}
        error={
          formikErrors[FIELD_ID] && touched[FIELD_ID]
            ? formikErrors[FIELD_ID] || ''
            : ''
        }
        {...field}
        label={label}
        disabled={readOnly}
        charactersRemainingLabel={charactersRemaining}
      />
    </div>
  );
};

export default FormMessageField;
