import CookieSettingsLink, {
  CookieSettingsLinkBlokProps,
} from '@/blocks/CookieSettingsLink/CookieSettingsLink';
import { CtaLinkBlokProps } from '@/blocks/CtaLink/CtaLink';
import WebsiteFooterLinkGroupBlok, {
  WebsiteFooterLinkGroupBlokProps,
} from '@/blocks/WebsiteFooterLinkGroup/WebsiteFooterLinkGroup';
import { PageContainer } from '@/components';
import { GoStudentLogo } from '@/components/IconLink/Icons';
import StoryblokLink, {
  isEmptyLink,
} from '@/components/StoryblokLink/StoryblokLink';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';
import { useState } from 'react';
import DownloadSectionBlok, {
  DownloadSectionBlokProps,
} from '../DownloadSection/DownloadSection';
import SocialMediaLinksBlok, {
  SocialMediaLinksBlokProps,
} from '../SocialMediaLinks/SocialMediaLinks';

export interface WebsiteFooterBlokProps extends SbBlokData {
  linkGroups: Array<WebsiteFooterLinkGroupBlokProps>;
  legal: Array<CtaLinkBlokProps | CookieSettingsLinkBlokProps>;
  marketing: Array<DownloadSectionBlokProps>;
  social: Array<SocialMediaLinksBlokProps>;
  disclaimer: string;
  id?: string;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

interface WebsiteFooterProps {
  blok: WebsiteFooterBlokProps;
}

const WebsiteFooter = ({ blok }: WebsiteFooterProps) => {
  const [openAccordionId, setOpenAccordionId] = useState<string>('');

  const {
    linkGroups,
    legal,
    marketing: [marketing] = [],
    social: [social] = [],
    disclaimer,
    id,
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <footer
      {...storyblokEditable(blok)}
      className={cx('bg-GSblue/500 text-GSbase/white', {
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer>
        <div className="w-full sm:grid sm:grid-cols-12 lg:col-span-10 gap-x-8 gap-y-10">
          {linkGroups.map(
            (nestedBlock, index) =>
              index < 4 && (
                <div
                  className={cx('sm:col-span-4 lg:col-span-2 gap-3', {
                    'lg:col-start-2': index === 0,
                  })}
                  key={nestedBlock._uid}
                >
                  <WebsiteFooterLinkGroupBlok
                    blok={nestedBlock}
                    key={nestedBlock._uid}
                    align="col"
                    mobileAccordion
                    handleOpenAccordion={(groupId: string) => {
                      setOpenAccordionId(groupId);
                    }}
                    openAccordion={openAccordionId}
                  />
                </div>
              ),
          )}
          {marketing && (
            <div className="sm:col-span-4 lg:col-span-2">
              <DownloadSectionBlok blok={marketing} />
            </div>
          )}
          <div className="mt-8 sm:col-span-12 lg:col-start-2 lg:col-span-10">
            {linkGroups.map(
              (nestedBlock, index) =>
                index >= 4 && (
                  <WebsiteFooterLinkGroupBlok
                    blok={nestedBlock}
                    key={nestedBlock._uid}
                    align="row"
                    mobileAccordion={false}
                  />
                ),
            )}
          </div>
          <div className="w-full col-span-12 lg:col-start-2 lg:col-span-10">
            <ul className="flex lg:items-center sm:justify-center flex-row flex-wrap gap-4 sm:gap-10 text-GSdeep/500 border-t border-b border-GSblue/400 py-6">
              {legal?.map((legalBlok, index) => {
                if (legalBlok.component === 'cookieSettingsLink') {
                  return (
                    <CookieSettingsLink
                      blok={legalBlok}
                      className="flex text-white font-regular text-xs"
                      key={legalBlok._uid}
                    >
                      {legalBlok.label}
                    </CookieSettingsLink>
                  );
                }
                return isEmptyLink(legalBlok.link) ? null : (
                  <li key={legalBlok._uid}>
                    <StoryblokLink
                      link={legalBlok.link}
                      className="flex text-white font-regular text-xs"
                      testId={`WebsiteFooter-${index}`}
                    >
                      {legalBlok.label}
                    </StoryblokLink>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="mt-8 sm:col-span-12 lg:col-span-10 lg:col-start-2 sm:gap-4 ">
            <div className="w-full flex-col sm:flex-row flex justify-between items-center gap-6">
              <div className="flex flex-col sm:flex-row items-center gap-4">
                <GoStudentLogo />
                <span className="text-xs sm:py-0">{disclaimer}</span>
              </div>
              {social && <SocialMediaLinksBlok blok={social} />}
            </div>
          </div>
        </div>
      </PageContainer>
    </footer>
  );
};

export default WebsiteFooter;
