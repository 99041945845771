import { FormBlokProps } from './FormType';
import { FormFieldsRowBlokProps } from '../FormFieldsRow/FormFieldsRow';
import { FormStepBlokProps } from '../FormStep/FormStep';

export const getStepFields = (step?: FormStepBlokProps) => {
  if (!step) {
    return [];
  }

  const { fields = [] } = step;
  const result = fields.map((field) => {
    if (field.component === 'formFieldsRow') {
      return (field as FormFieldsRowBlokProps).fields || [];
    }

    return field;
  });

  return result.flat() || [];
};

export const getMultiStepFormFields = (formBlok: FormBlokProps) => {
  const { steps = [] } = formBlok;

  const result = steps.map((step) => getStepFields(step));

  return result.flat() || [];
};
