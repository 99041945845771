import cx from 'classnames';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

import ButtonComponent, { ButtonProps, Size } from '@/components/Button/Button';
import CtaButtonMessage, {
  CtaButtonMessageBlokProps,
} from '@/blocks/CtaButtonMessage/CtaButtonMessage';
import { useVisibilityController } from '../VisibilityController/VisibilityController';

export interface CtaButtonBlokProps extends SbBlokData {
  addParamsToUrl?: boolean;
  label: string;
  link: Link;
  message?: Array<CtaButtonMessageBlokProps>;
  type?: 'primary' | 'secondary' | 'textLink';
  background?: 'darkBG' | 'lightBG';
}

interface CtaButtonProps extends ButtonProps {
  blok: CtaButtonBlokProps;
  size?: Size;
  type?: 'primary' | 'secondary' | 'textLink';
  loading?: boolean;
}

const CtaButton = ({ blok, size, ...rest }: CtaButtonProps) => {
  const visibilityController = useVisibilityController(blok);

  const {
    addParamsToUrl,
    background = 'lightBG',
    label,
    link,
    message: [message] = [],
    type = 'primary',
  } = blok;

  if (!visibilityController.isVisible) {
    return null;
  }

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx(
        'w-full max-w-full sm:w-auto',
        visibilityController.deviceClassNames,
      )}
    >
      <div className="w-full flex flex-col gap-1 sm:gap-2 lg:gap-3 items-center max-w-full sm:w-auto">
        <ButtonComponent
          addInternalParams={addParamsToUrl}
          background={background}
          label={label}
          link={link}
          type={type}
          testId={`CtaButton-${blok._uid}`}
          size={size}
          {...rest}
        />
        {message && (
          <CtaButtonMessage
            blok={message}
            className={size === 'btn-XS' ? 'text-xs' : 'text-sm'}
          />
        )}
      </div>
    </div>
  );
};

export default CtaButton;
