import { SbBlokData, ISbStoryData, StoryblokComponent } from '@storyblok/react';

export interface PartialReferenceBlokProps extends SbBlokData {
  partial?: ISbStoryData;
}

interface PartialReferenceProps {
  blok: PartialReferenceBlokProps;
}

const PartialReference = ({ blok }: PartialReferenceProps) => {
  const { partial } = blok;

  // partial is string if relation is not yet resolved
  return partial && typeof partial !== 'string' ? (
    <StoryblokComponent blok={partial.content} />
  ) : null;
};
export default PartialReference;
