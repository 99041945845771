import { SbBlokData } from '@storyblok/react';

export interface FormFieldsRowBlokProps extends SbBlokData {
  fields?: Array<SbBlokData>;
}

interface FormFieldsRowProps {
  blok: FormFieldsRowBlokProps;
  fieldRenderer: (fieldBlok: SbBlokData) => JSX.Element;
}

const FormFieldsRow = ({ blok, fieldRenderer }: FormFieldsRowProps) => {
  const { fields = [] } = blok;

  return (
    <div className="flex flex-col gap-y-4 lg:grid lg:grid-cols-2 lg:grid-rows-1 lg:gap-x-4 lg:items-end">
      {fields.map((nestedBlok: SbBlokData) => fieldRenderer(nestedBlok))}
    </div>
  );
};

export default FormFieldsRow;
