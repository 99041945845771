import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';

export interface RowBlokProps extends SbBlokData {
  content: Array<SbBlokData>;
  layoutSize?: 'narrow' | 'medium' | 'full' | '';
}

interface RowProps {
  blok: RowBlokProps;
}

const Row = ({ blok }: RowProps) => {
  const { content = [], layoutSize = 'full' } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="w-full flex sm:grid sm:grid-cols-12 sm:gap-x-4 sm:gap-y-6 lg:gap-x-8 lg:gap-y-10"
    >
      <div
        className={cx('w-full flex justify-between', {
          'sm:col-span-8 sm:col-start-3': layoutSize === 'narrow',
          'sm:col-span-10 sm:col-start-2': layoutSize === 'medium',
          'sm:col-span-full sm:col-start-1':
            layoutSize === 'full' || layoutSize === '',
        })}
      >
        {content.map((nestedBlok: SbBlokData) => (
          <div key={nestedBlok._uid}>
            <StoryblokComponent blok={nestedBlok} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Row;
