import { SbBlokData, storyblokEditable } from '@storyblok/react';
import React from 'react';
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import { ReviewQuoteIcon } from '../../icons/ReviewQuoteIcon';

export interface TrustpilotFallbackReviewBlokProps extends SbBlokData {
  image: Asset;
  name: string;
  subText: string;
  reviewText: string;
  starsIcon: Asset;
}

interface TrustpilotFallbackReviewProps {
  blok: TrustpilotFallbackReviewBlokProps;
}

const TrustpilotFallbackReview = ({ blok }: TrustpilotFallbackReviewProps) => {
  const { image, name, subText, reviewText, starsIcon } = blok;
  const reviewCardClasses =
    'card bg-GSparchment/50 border border-1 border-solid border-GSparchment/300 flex flex-col ' +
    'sm:px-4 sm:py-10 lg:px-5 lg:py-8 gap-4 lg:gap-8';

  return (
    <div {...storyblokEditable(blok)} className="flex flex-col">
      <div className={reviewCardClasses}>
        <div className="[word-break:break-word] flex gap-2 lg:gap-4">
          {reviewText && (
            <span className="font-worksans font-regular text-xs sm:text-sm text-GSdeep/900">
              {reviewText}
            </span>
          )}
          <div className="flex w-12 h-12 sm:w-14 sm:h-14 lg:w-16 lg:h-16">
            <ReviewQuoteIcon />
          </div>
        </div>

        <div className="flex gap-4">
          {image && (
            <StoryblokImage blok={image} className="h-20 w-20 rounded-full" />
          )}
          <div className="flex flex-col gap-2">
            {name && (
              <span className="[word-break:break-word] font-worksans text-GSdeep/900 text-sm font-bold">
                {name}
              </span>
            )}
            {subText && (
              <span className="[word-break:break-word] text-xs font-medium text-GSblue/400">
                {subText}
              </span>
            )}
            {starsIcon && <StoryblokImage blok={starsIcon} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustpilotFallbackReview;
