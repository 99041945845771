import { SbBlokData, storyblokEditable } from '@storyblok/react';

import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import StoryblokLink, {
  isEmptyLink,
} from '@/components/StoryblokLink/StoryblokLink';

export interface ImageBlokProps extends SbBlokData {
  image: Asset;
  link: Link;
  altText: string;
}

interface ImageProps {
  blok: ImageBlokProps;
  className?: string;
  lazyLoading?: boolean;
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: (children: React.ReactElement) => React.ReactElement;
  children: React.ReactElement;
}) => (condition ? wrapper(children) : children);

const Image = ({ blok, className, lazyLoading = true }: ImageProps) => {
  const { altText, image, link } = blok;

  return (
    <div {...storyblokEditable(blok)}>
      <ConditionalWrapper
        condition={!isEmptyLink(link)}
        wrapper={(children) => (
          <StoryblokLink testId={blok._uid} link={link}>
            {children}
          </StoryblokLink>
        )}
      >
        <StoryblokImage
          blok={image}
          primaryAlt={altText}
          className={className}
          lazyLoading={lazyLoading}
        />
      </ConditionalWrapper>
    </div>
  );
};

export default Image;
