export const LanguageSwitcherIcon = ({ className }: { className?: string }) => (
  <svg
    viewBox="0 0 36 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M2.5 4.61279H12.8451" strokeWidth="1.25" />
    <path d="M7.31812 3.125V4.60261" strokeWidth="1.25" />
    <path
      d="M10.5979 4.61279C9.85908 6.82981 7.64266 10.5244 3.20862 13.8497M5.05624 6.82981C5.79504 8.06136 7.79018 10.8938 9.85908 12.3721"
      strokeWidth="1.25"
    />
    <path
      d="M11.0541 16.8749L11.9948 14.1228M17.3351 16.8749L16.2771 14.1228M11.9948 14.1228L14.0099 8.22607L16.2771 14.1228M11.9948 14.1228H16.2771"
      strokeWidth="1.25"
      strokeLinejoin="bevel"
    />
    <path d="M32.8 8.39995L28 13.2L23.2 8.39995" strokeLinecap="round" />
  </svg>
);
