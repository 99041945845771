import React from 'react';
import {
  ISbRichtext,
  SbBlokData,
  renderRichText,
  storyblokEditable,
} from '@storyblok/react';
import { FieldProps, FormikErrors, FormikTouched } from 'formik';

import Checkbox from '../../components/Checkbox/Checkbox';
import HubspotScrapperField from '../../components/HubspotScrapperField/HubspotScrapperField';
import { FormDataType } from '../../helpers/form/formHelpersTypes';
import richTextSchema from './richTextSchema';

const FIELD_ID = 'sharePersonalData';

export const FieldData: FormDataType = {
  hubspotId: 'share_personal_data',
  id: FIELD_ID,
  type: 'boolean',
  validationType: 'bool',
};

export interface FormSharePersonalDataTutorFieldBlokProps extends SbBlokData {
  formikErrors: FormikErrors<{ [FIELD_ID]: string }>;
  readOnly: boolean;
  richTextLabel?: ISbRichtext;
  touched: FormikTouched<{ [FIELD_ID]: boolean }>;
}

interface FormSharePersonalDataTutorFieldProps extends FieldProps {
  blok: FormSharePersonalDataTutorFieldBlokProps;
}

const FormSharePersonalDataTutorField = ({
  blok,
  field,
}: FormSharePersonalDataTutorFieldProps) => {
  const { formikErrors, touched, readOnly, richTextLabel } = blok;

  const renderLabel = () =>
    richTextLabel && (
      <div
        className="break-words min-w-0 gap-y-2 flex flex-col"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: renderRichText(richTextLabel, { schema: richTextSchema }),
        }}
      />
    );

  const error =
    formikErrors[FIELD_ID] && touched[FIELD_ID] ? formikErrors[FIELD_ID] : '';

  return (
    <div
      {...storyblokEditable(blok)}
      className="w-full flex flex-col gap-y-1 lg:gap-y-4"
    >
      <HubspotScrapperField name={FieldData.hubspotId} value={field.value} />
      <Checkbox
        defaultChecked={field.value}
        disabled={readOnly}
        label={renderLabel()}
        name={FIELD_ID}
        onChange={field.onChange}
        isTutorForm
      />
      {error && (
        <div className="text-xs text-GSfunctional/error font-medium">
          {error}
        </div>
      )}
    </div>
  );
};

export default FormSharePersonalDataTutorField;
