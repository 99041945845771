import { CookieValueTypes } from 'cookies-next';
import { createContext } from 'react';

export const GtmIdContext = createContext('');
export const ReferringFallback = createContext<CookieValueTypes>('');

type SSGDataContextType = {
  alternateUrls?: MpcAlternateUrls;
  formLabels?: Record<string, string>;
  countriesByCode?: Record<string, string>;
};

export const SSGDataContext = createContext<SSGDataContextType>({});
