import { FieldProps, FormikErrors, FormikTouched } from 'formik';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

import { FormDataType, OptionType } from '@/helpers/form/formHelpersTypes';
import { mapToHubspotValue } from '@/helpers/form/subjectHelpers';
import FormikCheckboxGroup from '@/components/CheckboxGroup/FormikCheckboxGroup';

const FIELD_ID = 'tutoringSubjects';

export const FieldData: FormDataType = {
  hubspotId: 'tutoring_subjects',
  id: FIELD_ID,
  type: 'array',
  validationType: 'mixed',
} as const;

export interface FormTutoringSubjectsFieldBlokProps extends SbBlokData {
  config?: { content: { options: Array<OptionType> } };
  description?: string;
  formikErrors: FormikErrors<{ [FIELD_ID]: string }>;
  label: string;
  readOnly: boolean;
  touched: FormikTouched<{ [FIELD_ID]: boolean }>;
}

interface FormTutoringSubjectsFieldProps extends FieldProps {
  blok: FormTutoringSubjectsFieldBlokProps;
}

const FormTutoringSubjectsField = ({
  blok,
  ...props
}: FormTutoringSubjectsFieldProps) => {
  const {
    config: { content: { options = [] } = {} } = {},
    description,
    formikErrors,
    label,
    readOnly,
    touched,
  } = blok;

  return (
    <div {...storyblokEditable(blok)} className="h-full">
      {FieldData.hubspotId && (
        <>
          <label className="hidden" htmlFor={FieldData.hubspotId}>
            {FieldData.hubspotId}
          </label>
          <input
            className="hidden"
            value={props.field.value.join(';')}
            name={FieldData.hubspotId}
            readOnly
          />
        </>
      )}
      <FormikCheckboxGroup
        description={description}
        disabled={readOnly}
        error={
          formikErrors[FIELD_ID] && touched[FIELD_ID]
            ? formikErrors[FIELD_ID]
            : ''
        }
        isMulti
        label={label}
        options={options.map(
          ({
            label: subjectLabel,
            value: subjectValue,
          }: {
            label: string;
            value: string;
          }) => ({
            label: subjectLabel,
            value: mapToHubspotValue(subjectValue),
          }),
        )}
        {...props}
      />
    </div>
  );
};

export default FormTutoringSubjectsField;
