import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';

import { ImageBlokProps } from '../Image/Image';

export interface OffBrandHeroFormBoxBlokProps extends SbBlokData {
  badges?: Array<ImageBlokProps>;
  cardFrame?: 'enabled' | 'disabled';
  form?: Array<SbBlokData>;
  header: string;
}

interface OffBrandHeroFormBoxProps {
  blok: OffBrandHeroFormBoxBlokProps;
}

const OffBrandHeroFormBox = ({ blok }: OffBrandHeroFormBoxProps) => {
  const {
    badges = [],
    cardFrame = 'enabled',
    form: [form] = [],
    header,
  } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx('w-full flex flex-col gap-4 text-lg sm:text-xl sm:gap-6', {
        'bg-GSbase/white rounded-lg py-4 px-6 sm:p-8': cardFrame === 'enabled',
      })}
    >
      <div className="flex flex-col gap-4 sm:gap-6 lg:gap-8">
        {header && (
          <div className="font-dosis font-bold text-GSbase/black text-lg text-center sm:text-xl">
            {header}
          </div>
        )}
        {form && <StoryblokComponent blok={form} />}
        {badges.length > 0 && (
          <div className="flex flex-row flex-wrap justify-center items-center gap-4">
            {badges.map((badge) => (
              <StoryblokComponent
                blok={badge}
                className="max-h-20"
                key={badge._uid}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default OffBrandHeroFormBox;
