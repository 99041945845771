export const ScrollIcon = ({
  firstPartClassName,
  secondPartClassName,
}: {
  firstPartClassName: string;
  secondPartClassName: string;
}) => (
  <svg
    className="max-w-full"
    viewBox="0 0 703 598"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMinYMid meet"
    role="img"
  >
    <path
      d="M363.306 94.1448C363.306 94.1448 363.189 93.9652 363.13 93.8754C348.392 74.7012 331.516 58.4668 312.718 45.5012C296.614 34.3639 279.545 25.9971 261.738 20.4229C262.203 20.5458 262.619 20.6586 263.114 20.7619C259.907 19.7829 256.719 18.8338 253.501 18.0334C246.529 16.2693 239.445 14.9211 232.278 13.9692C201.259 10.0332 169.871 9.97426 139.047 13.7802C108.988 17.5131 79.0761 24.9982 50.1277 36.0439C40.4417 39.7355 30.8218 43.8542 21.3276 48.3611C2.94721 57.0622 -15.0385 67.2156 -32.3802 78.7435C-58.5023 96.0643 -83.4101 116.61 -106.484 139.761C-129.568 162.962 -150.968 188.995 -170.143 217.12C-189.43 245.532 -206.632 276.342 -221.237 308.703C-236.095 341.656 -248.388 376.566 -257.793 412.491C-267.433 449.298 -274.108 487.628 -277.711 526.34L-1.09001 596.536C2.26449 557.772 8.98958 519.452 18.6399 482.596C28.0144 446.69 40.2885 411.75 55.1764 378.777C69.7617 346.387 86.9939 315.557 106.33 287.155C125.485 259 146.905 232.998 169.989 209.797C193.103 186.577 218.041 166.011 244.133 148.71C261.505 137.162 279.49 127.009 297.841 118.328C307.365 113.801 317.004 109.712 326.671 105.991C338.765 101.406 350.975 97.3879 363.327 94.0457L363.306 94.1448Z"
      className={firstPartClassName}
    />
    <path
      d="M652.036 182.113C634.644 154.969 613.491 132.725 589.166 116.023C570.279 102.987 550.091 93.7115 528.909 88.3378C525.869 87.5488 522.848 86.7897 519.709 85.98C441.466 66.0965 341.475 40.6956 263.054 20.8006C280.731 26.3738 296.914 35.1502 312.979 46.2276C331.688 59.123 361.559 91.7386 363.256 94.1342C367.537 99.7563 371.637 105.625 375.566 111.691C392.978 138.865 405.826 169.456 413.66 202.774C421.823 237.246 424.258 273.031 420.925 309.215L697.515 379.43C700.64 343.255 698.224 307.5 690.111 273.038C682.267 239.769 669.418 209.179 652.016 182.083L652.036 182.113Z"
      className={secondPartClassName}
    />
  </svg>
);

export const SmallScrollIcon = ({
  firstPartClassName,
  secondPartClassName,
}: {
  firstPartClassName: string;
  secondPartClassName: string;
}) => (
  <svg
    role="img"
    preserveAspectRatio="xMinYMid meet"
    viewBox="0 0 277 328"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_6533_3170"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="277"
      height="328"
    >
      <rect x="0.181641" width="276.818" height="327.568" fill="#E3E8F6" />
    </mask>
    <g mask="url(#mask0_6533_3170)">
      <path
        d="M153.151 207.347C153.151 207.347 153.025 207.352 152.961 207.355C138.705 206.853 125.128 204.339 112.461 199.802C101.595 195.926 91.7286 190.65 82.9501 184.078C83.1686 184.257 83.3651 184.417 83.5845 184.617C82.0154 183.425 80.4674 182.232 78.9801 180.973C75.744 178.258 72.669 175.349 69.7559 172.265C57.2362 158.857 46.4514 143.959 37.7154 128.018C29.2063 112.465 22.5495 95.7099 17.9321 78.2048C16.385 72.3492 15.0657 66.38 13.9758 60.3389C11.8544 48.6518 10.5654 36.6599 10.1571 24.5076C9.5209 6.21747 10.8492 -12.5896 14.0568 -31.4084C17.2847 -50.2489 22.4493 -69.2498 29.381 -87.9026C36.4123 -106.706 45.3093 -125.332 55.8404 -143.249C66.5697 -161.488 79.1169 -179.172 93.142 -195.826C107.51 -212.892 123.624 -229.065 140.975 -243.908L269.473 -136.243C252.012 -121.501 235.919 -105.308 221.531 -88.2204C207.505 -71.5863 194.937 -53.9019 184.209 -35.6423C173.657 -17.7242 164.76 0.9228 157.751 19.7459C150.798 38.3995 145.655 57.3996 142.427 76.2401C139.2 95.1015 137.872 113.929 138.508 132.199C138.917 144.372 140.206 156.364 142.327 168.03C143.417 174.092 144.758 180.06 146.284 185.917C148.227 193.22 150.482 200.386 153.111 207.391L153.151 207.347Z"
        className={firstPartClassName}
      />
      <path
        d="M294.351 314.746C275.357 315.686 257.428 313.177 241.072 307.28C228.34 302.725 216.968 296.275 207.129 288.03C205.708 286.852 204.308 285.674 202.843 284.457C166.481 254.011 120.017 215.099 83.5888 184.572C92.3232 191.084 102.083 195.8 112.907 199.678C125.511 204.197 150.094 207.216 153.14 207.326C157.306 207.453 161.529 207.411 165.788 207.222C184.803 206.28 203.893 202.011 222.576 194.433C241.924 186.62 259.94 175.639 276.171 161.781L404.675 269.433C388.377 283.189 370.382 294.169 351.056 302.002C332.394 309.558 313.303 313.827 294.33 314.746L294.351 314.746Z"
        className={secondPartClassName}
      />
    </g>
  </svg>
);
