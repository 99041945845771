import { SbBlokData } from '@storyblok/react';
import cx from 'classnames';

interface VisibilityControllerBlokProps extends SbBlokData {
  desktop?: 'enabled' | 'disabled' | '';
  mobile?: 'enabled' | 'disabled' | '';
  tablet?: 'enabled' | 'disabled' | '';
  visibility?: 'enabled' | 'disabled' | 'device' | '';
}

interface VisibilityControllerProps {
  visibility: Array<VisibilityControllerBlokProps>;
}

const isTruthy = (value?: string) =>
  value === 'enabled' || value === '' || value === undefined;

const isVisibleForAnyDevice = ({
  desktop,
  mobile,
  tablet,
  visibility,
}: VisibilityControllerBlokProps) => {
  if (visibility !== 'device') {
    return isTruthy(visibility);
  }

  return isTruthy(mobile) || isTruthy(tablet) || isTruthy(desktop);
};

const buildDeviceClassNames = ({
  desktop,
  mobile,
  tablet,
}: VisibilityControllerBlokProps) =>
  cx({
    hidden: !isTruthy(mobile),
    block: isTruthy(mobile),
    'sm:hidden': !isTruthy(tablet),
    'sm:block': isTruthy(tablet),
    'lg:hidden': !isTruthy(desktop),
    'lg:block': isTruthy(desktop),
  });

export const useVisibilityController = (componentBlok: SbBlokData) => {
  const { visibility: [visibilityBlok] = [] } =
    componentBlok as unknown as VisibilityControllerProps;

  const {
    desktop = 'enabled',
    mobile = 'enabled',
    tablet = 'enabled',
    visibility = 'enabled',
  } = (visibilityBlok as VisibilityControllerBlokProps) || {};

  return {
    deviceClassNames: buildDeviceClassNames({ mobile, tablet, desktop }),
    isVisible:
      isTruthy(visibility) ||
      isVisibleForAnyDevice({ visibility, mobile, tablet, desktop }),
    mobile,
    tablet,
    desktop,
  };
};
