import { storyblokEditable } from '@storyblok/react';
import { renderItemBody } from '../FaqSection/utils';
import { FaqSectionItemBlokProps } from './types';

export interface FaqSectionItemProps {
  blok: FaqSectionItemBlokProps;
}

const FaqSectionItem = ({ blok }: FaqSectionItemProps) => (
  <div {...storyblokEditable(blok)} className="flex flex-col gap-2">
    <h3 className="font-worksans text-GSdeep/900 font-bold text-base lg:text-lg break-words whitespace-pre-wrap">
      {blok.title}
    </h3>
    <div className="font-worksans text-GSdeep/900 font-normal text-sm lg:text-base break-words whitespace-pre-wrap">
      {renderItemBody(blok)}
    </div>
  </div>
);

export default FaqSectionItem;
