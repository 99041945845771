import cx from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { CloseIcon } from './icons';

interface ModalComponentProps {
  children: React.ReactElement;
  routeName: string;
  closeModalType: 'closeButton' | 'backdropClick' | 'buttonAndBackdrop';
}

const ModalComponent = ({
  children,
  routeName,
  closeModalType,
}: ModalComponentProps) => {
  const router = useRouter();
  const [hash, setHash] = useState('');

  useEffect(() => {
    document.body.style.overflow = hash === routeName ? 'hidden' : 'unset';
  }, [hash]);

  const updateHash = (str: string) => {
    setHash(str.includes('#') ? str.split('#')[1] : str);
  };
  const closeModal = () => {
    window.location.hash = '';
  };

  useEffect(() => {
    const onWindowHashChange = () => {
      updateHash(window.location.hash);
    };
    const onNextJSHashChange = (url: string) => {
      updateHash(url);
    };
    router.events.on('hashChangeStart', onNextJSHashChange);
    window.addEventListener('hashchange', onWindowHashChange);
    window.addEventListener('load', onWindowHashChange);
    return () => {
      router.events.off('hashChangeStart', onNextJSHashChange);
      window.removeEventListener('load', onWindowHashChange);
      window.removeEventListener('hashchange', onWindowHashChange);
    };
  }, [hash, router.asPath, router.events]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateHash(window.location.hash);
    }
  }, []);

  const handleCloseModal = () => {
    if (
      closeModalType === 'backdropClick' ||
      closeModalType === 'buttonAndBackdrop'
    ) {
      closeModal();
    }
  };
  return (
    <div
      className={cx(
        'modal fixed z-50 w-full h-full top-0 flex justify-center transition-all ease-in-out duration-300',
        {
          'pointer-events-none opacity-0 invisible': hash !== routeName,
          'opacity-1 visible': hash === routeName,
        },
      )}
    >
      <div
        className="backdrop z-50 w-full h-full absolute bg-GSdeep/900 bg-opacity-75 transition-opacity"
        onClick={handleCloseModal}
      />
      <div className="relative w-full h-full">
        <div className="absolute z-[60] py-6 sm:pt-32 w-full h-full overflow-y-auto">
          <div className="relative mx-6 sm:mx-auto bg-white rounded py-8 px-4 sm:py-12 sm:px-16 sm:w-full sm:max-w-[640px] lg:max-w-[895px] lg:px-28 lg:py-16 ">
            {(closeModalType === 'closeButton' ||
              closeModalType === 'buttonAndBackdrop') && (
              <div
                className="absolute flex justify-end cursor-pointer top-2 right-2 w-6 h-6 sm:h-8 sm:w-8 sm:right-4 sm:top-4"
                onClick={closeModal}
              >
                <CloseIcon />
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
