import cloneDeep from 'clone-deep';
import cx from 'classnames';
import { RichTextSchema } from '@storyblok/react';

const mpcRichTextSchema = cloneDeep(RichTextSchema);

mpcRichTextSchema.nodes.heading = (node: ISbNode) => ({
  tag: [
    {
      tag: `h${node.attrs.level}`,
      attrs: {
        class: cx({
          'pb-2 lg:pb-4 text-lg sm:text-xl font-raleway font-bold':
            node.attrs.level === 4,
        }),
      },
    },
  ],
});

mpcRichTextSchema.nodes.bullet_list = () => {
  return {
    tag: [
      {
        tag: 'ul',
        attrs: {
          style: 'list-style-type:disc;',
          class: 'pl-6 gap-y-2 flex flex-col py-2',
        },
      },
    ],
  };
};

mpcRichTextSchema.marks.link = (node: ISbNode) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const schema: any = RichTextSchema.marks.link(node);

  schema.tag[0].attrs.class = 'text-GSblue/500 hover:text-GSblue/700 underline';

  return schema;
};

mpcRichTextSchema.marks.textStyle = () => {
  // we do not support changing text color
  return {
    tag: '',
  };
};

export default mpcRichTextSchema;
