import { FormDataType } from '@/helpers/form/formHelpersTypes';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FieldProps, FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import { PhoneInputProps } from '@/components/PhoneInput/PhoneInput';

import dynamic from 'next/dynamic';

const PhoneInput = dynamic(
  () => import('../../components/PhoneInput/PhoneInput'),
  {
    loading: () => <div />,
  },
);

const FIELD_ID = 'phone';

export const FieldData: FormDataType = {
  hubspotId: 'phone',
  id: FIELD_ID,
  type: 'text',
  validationType: 'string',
  validations: [
    {
      type: 'phoneNumberValid',
      key: 'phone_error_message_valid',
      params: [],
    },
  ],
};

export interface FormPhoneFieldBlokProps extends SbBlokData {
  config?: { content: { countries: Array<CountryCode> } };
  preferredCountries?: Array<CountryCode>;
  formCountry: CountryCode;
  defaultCountry: CountryCode;
  label: string;
  labels: Record<string, string>;
  placeholder: string;
  readOnly: boolean;
  formikErrors: FormikErrors<{ [FIELD_ID]: string }>;
  touched: FormikTouched<{ [FIELD_ID]: boolean }>;
}

interface FormPhoneFieldProps extends FieldProps {
  blok: FormPhoneFieldBlokProps;
  isTutorForm?: boolean;
}

const FormPhoneField = ({
  blok,
  field,
  form,
  isTutorForm = false,
}: FormPhoneFieldProps) => {
  const {
    config,
    defaultCountry,
    formCountry,
    label,
    labels,
    formikErrors,
    placeholder,
    preferredCountries = [],
    readOnly,
    touched,
  } = blok;

  const finalLabel = label || labels[`${FIELD_ID}_label`];
  const finalPlaceholder = placeholder || labels[`${FIELD_ID}_placeholder`];
  const searchFieldPlaceholder = labels[`${FIELD_ID}_search_placeholder`];
  const searchPaneTitle = labels[`${FIELD_ID}_search_pane_title`];

  const handleChange = (e: string) => form.setFieldValue(FIELD_ID, e);

  const updatedField: PhoneInputProps = {
    ...field,
    countries: config?.content?.countries,
    defaultCountry: defaultCountry || formCountry,
    label: finalLabel,
    placeholder: finalPlaceholder,
    onChange: handleChange,
    error:
      formikErrors[FIELD_ID] && touched[FIELD_ID] ? formikErrors[FIELD_ID] : '',
    disabled: readOnly,
    preferredCountries,
    searchFieldPlaceholder,
    searchPaneTitle,
  };

  return (
    <div {...storyblokEditable(blok)}>
      <PhoneInput {...updatedField} isTutorForm={isTutorForm} />
    </div>
  );
};

export default FormPhoneField;
