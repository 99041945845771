import Head from 'next/head';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

import { Cookiebot, Gtm, Optimizely } from '@/components';
import HeaderBlok, { HeaderBlokProps } from '@/blocks/Header/Header';
import { landingPageFonts } from '@/helpers/text/fonts';
import ThankYouBlok, { ThankYouBlokProps } from '../ThankYou/ThankYou';

export interface ThankYouPageBlokProps extends SbBlokData, MpcData {
  header: Array<HeaderBlokProps>;
  thankYouContent: Array<ThankYouBlokProps>;
  optimizelyId?: string;
  seoTitle: string;
  seoDescription: string;
  seoIndexing: 'yes' | 'no';
}

interface ThankYouPageProps {
  blok: ThankYouPageBlokProps;
}

const ThankYouPage = ({ blok }: ThankYouPageProps) => {
  const {
    header: [header] = [],
    optimizelyId,
    seoTitle,
    seoDescription,
    seoIndexing,
    thankYouContent: [thankYouContent] = [],
  } = blok;

  return (
    <>
      <Head>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        {seoIndexing === 'no' && <meta name="robots" content="noindex" />}
        {landingPageFonts()}
      </Head>
      {optimizelyId && <Optimizely id={optimizelyId} />}
      <Gtm />
      <Cookiebot language={blok._mpc.language} />
      <main
        {...storyblokEditable(blok)}
        className="overflow-x-clip w-full flex flex-col font-worksans h-screen bg-GSparchment/200"
      >
        {header && <HeaderBlok blok={header} />}
        {thankYouContent && (
          <div className="w-full flex shrink-0 grow">
            <ThankYouBlok blok={thankYouContent} />
          </div>
        )}
      </main>
    </>
  );
};

export default ThankYouPage;
