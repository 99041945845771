import cx from 'classnames';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

import CtaButton, { CtaButtonBlokProps } from '../CtaButton/CtaButton';

export interface EarningsPriceItemProps {
  blok: EarningsPriceItemBlokProps;
}

export interface EarningsPriceItemBlokProps extends SbBlokData {
  header: string;
  price: string;
  perLabel: string;
  disclaimer: string;
  cta: Array<CtaButtonBlokProps>;
  layout: 'small' | 'big';
}

const EarningsPriceItem = ({ blok }: EarningsPriceItemProps) => {
  const { layout, header, price, perLabel, disclaimer, cta: [cta] = [] } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx('w-full flex flex-col', {
        'gap-2': layout === 'small',
        'items-center sm:gap-2 lg:gap-4': layout === 'big',
      })}
    >
      <div className="flex flex-col w-full">
        <h4
          className={cx('font-bold', {
            'text-GSdeep/500 text-sm text-center lg:text-left':
              layout === 'small',
            'text-GSblue/600 text-base lg:text-xl text-center':
              layout === 'big',
          })}
        >
          {header}
        </h4>
        <div className="flex gap-2 items-center justify-center lg:justify-start">
          <div
            className={cx('text-GSblue/500 font-extrabold min-w-0', {
              'text-2xl lg:text-[32px] lg:leading-[32px]': layout === 'small',
              'text-[32px] leading-[48px] lg:text-6xl': layout === 'big',
            })}
          >
            {price}
          </div>
          <div className="text-GSdeep/900 text-xs font-normal break-words min-w-0 block">
            <span>/ </span>
            <span>{perLabel}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 w-full">
        {cta && layout === 'big' && (
          <div className="w-full">
            <CtaButton blok={cta} />
          </div>
        )}
        <div
          className={cx('text-GSdeep/500 text-xs', {
            'font-normal sm:text-center lg:text-left': layout === 'small',
            'text-[10px] leading-4 lg:text-xs font-medium text-center':
              layout === 'big',
          })}
        >
          {disclaimer}
        </div>
      </div>
    </div>
  );
};

export default EarningsPriceItem;
