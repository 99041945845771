import cx from 'classnames';
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import {
  SbBlokData,
  storyblokEditable,
  renderRichText,
  ISbRichtext,
} from '@storyblok/react';
import mpcRichTextSchema from './richTextSchema';

export interface CtaButtonMessageBlokProps extends SbBlokData {
  textBeforeImage?: string;
  image?: Asset;
  textAfterImage?: string;
  richTextField?: ISbRichtext;
}

interface CtaButtonMessageProps {
  blok: CtaButtonMessageBlokProps;
  className?: string;
}

const CtaButtonMessage = ({ blok, className }: CtaButtonMessageProps) => {
  const { textBeforeImage, image, textAfterImage, richTextField } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx(
        'flex flex-row w-full text-sm font-medium text-GSdeep/900 gap-2 items-center flex-wrap justify-center',
        className,
      )}
    >
      {textBeforeImage && (
        <div className="break-words min-w-0">{textBeforeImage}</div>
      )}
      {image && <StoryblokImage blok={image} />}
      {textAfterImage && (
        <div className=" break-words min-w-0">{textAfterImage}</div>
      )}
      {richTextField && (
        <div
          className="break-words min-w-0"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: renderRichText(richTextField, {
              schema: mpcRichTextSchema,
            }),
          }}
        />
      )}
    </div>
  );
};

export default CtaButtonMessage;
