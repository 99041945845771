import { REQUIRED_FIELDS } from './formHelpers';

export const renderDuplicationError = (duplicatedFields: Array<string>) => (
  <div className="text-red-700">
    <div>DUPLICATED FIELDS FOUND</div>
    <div>Following fields are added more than once:</div>
    {duplicatedFields.map((fieldName) => (
      <div key={fieldName}>- {fieldName}</div>
    ))}
    <div className="text-sm">
      Please remove duplicates, otherwise the form won&apos;t work properly and
      this information will be visible in deployed page.
    </div>
  </div>
);

export const renderRequiredFieldsMissingError = (fieldsIds?: Array<string>) => (
  <div className="text-red-700">
    <div>MISSING REQUIRED FIELDS</div>
    <div>Following field(s) are missing:</div>
    {REQUIRED_FIELDS.map((fieldName) => {
      if (!fieldsIds?.find((id) => id === fieldName)) {
        return <div key={fieldName}>- {fieldName}</div>;
      }

      return null;
    })}
    <div className="text-sm">
      Please add the required fields, otherwise the form won&apos;t work
      properly and this information will be visible in deployed page.
    </div>
  </div>
);

export const renderMixedContentUsedError = () => (
  <div className="text-red-700">
    <div>NEW AND DEPRECATED CONTENT USED</div>
    <div className="text-sm">
      Please use Deprecated elements, Deprecated Fields OR the Steps
      functionality. Do not use both at same time. Otherwise the validation will
      not work.
    </div>
  </div>
);
