import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';
import React from 'react';

import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import PageContainer from '@/components/PageContainer/PageContainer';
import TutorProfile, {
  TutorProfileBlokProps,
} from '../TutorProfile/TutorProfile';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import { CtaLinkBlokProps } from '../CtaLink/CtaLink';

export interface TutorsSectionBlokProps extends SbBlokData {
  cta?: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
  id?: string;
  title?: Array<TitleBlokProps>;
  tutors?: Array<TutorProfileBlokProps>;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

export interface TutorsSectionProps {
  blok: TutorsSectionBlokProps;
}

const TutorsSection = ({ blok }: TutorsSectionProps) => {
  const {
    cta: [cta] = [],
    id,
    title: [title] = [],
    tutors = [],
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('w-full bg-white', {
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer>
        {title && <TitleBlok blok={title} headingLevel="h2" />}
        <div
          className={cx(
            'w-full grid grid-cols-6 sm:grid-cols-12 gap-x-2 gap-y-4 sm:gap-x-4 sm:gap-y-6 lg:gap-x-8 lg:gap-y-10',
            {
              'sm:flex sm:justify-center ': tutors.length < 4,
            },
          )}
        >
          {tutors.slice(0, 8).map((nestedBlok, index) => (
            <TutorProfile
              className={cx({
                'sm:basis-1/3 lg:basis-1/4': tutors.length < 4,
                'hidden lg:flex': index > 5,
              })}
              blok={nestedBlok}
              key={nestedBlok._uid}
            />
          ))}
        </div>
        {cta && <StoryblokComponent blok={cta} />}
      </PageContainer>
    </section>
  );
};

export default TutorsSection;
