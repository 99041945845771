import cx from 'classnames';
import { OptionType } from '@/helpers/form/formHelpersTypes';

export interface CheckboxGroupProps {
  description?: string;
  disabled?: boolean;
  error?: string;
  isTutorForm?: boolean;
  label?: string;
  name: string;
  onChange: (value: Array<string>) => void;
  options: OptionType[];
  values: Array<string>;
}

const CheckboxGroup = (props: CheckboxGroupProps) => {
  const {
    description,
    disabled,
    error,
    isTutorForm = false,
    label,
    name,
    onChange,
    options,
    values,
  } = props;

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const valueArray = [...values] || [];

    if (target.checked) {
      valueArray.push(target.id);
    } else {
      valueArray.splice(valueArray.indexOf(target.id), 1);
    }

    onChange(valueArray);
  };

  return (
    <div className="flex flex-col gap-y-4">
      <div
        className={cx('text-GSdeep/900 text-sm font-medium', {
          'lg:text-base': isTutorForm,
        })}
      >
        {label}
      </div>
      {description && (
        <div className="text-GSblue/200 text-xs font-medium">{description}</div>
      )}
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-y-2 gap-x-4">
        {options.map(({ label: optionLabel, value }) => (
          <div key={value} className="flex gap-2 items-center">
            <div className="grid place-content-center">
              <input
                className={cx(
                  'peer relative appearance-none w-4 h-4 shrink-0 border-2 border-GSblue/200 rounded-sm mt-0.5 bg-GSbase/white',
                  'focus:outline-none focus:ring-offset-0 focus:ring-2 focus:ring-GSblue/100',
                  'checked:bg-GSblue/500 checked:border-0',
                  'disabled:border-GSsteel/400 disabled:bg-GSsteel/400',
                  {
                    'cursor-pointer': !disabled,
                  },
                )}
                type="checkbox"
                id={value}
                name={name}
                value={value}
                disabled={disabled}
                onChange={handleChange}
                checked={values.includes(value)}
              />
              <svg
                className="absolute w-4 h-4 pointer-events-none peer-checked:stroke-GSbase/white mt-0.5"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 8.16667L6.75 11.0833L13 4" strokeWidth="2" />
              </svg>
            </div>
            <label
              className={cx('font-normal text-sm break-words min-w-0', {
                'text-GSdeep/900 cursor-pointer': !disabled,
                'text-GSsteel/500 opacity-60': disabled,
                'lg:text-base': isTutorForm,
              })}
              htmlFor={value}
            >
              {optionLabel}
            </label>
          </div>
        ))}
      </div>
      {error && (
        <div className="text-xs text-GSfunctional/error font-medium">
          {error}
        </div>
      )}
    </div>
  );
};

export default CheckboxGroup;
