import { SbBlokData, storyblokEditable } from '@storyblok/react';

import ButtonComponent from '@/components/Button/Button';
import CtaButtonMessage, {
  CtaButtonMessageBlokProps,
} from '@/blocks/CtaButtonMessage/CtaButtonMessage';

export interface FormSubmitButtonBlokProps extends SbBlokData {
  label: string;
  message?: Array<CtaButtonMessageBlokProps>;
}

interface FormSubmitButtonProps {
  blok: FormSubmitButtonBlokProps;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  isSubmit?: boolean;
  loading?: boolean;
}

const FormSubmitButton = ({
  blok,
  isSubmit = true,
  ...props
}: FormSubmitButtonProps) => {
  const { label, message: [message] = [] } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="w-full flex flex-col gap-3 items-center max-w-full sm:w-auto"
    >
      <ButtonComponent label={label} isSubmit={isSubmit} {...props} />
      {message && <CtaButtonMessage blok={message} />}
    </div>
  );
};

export default FormSubmitButton;
