import cx from 'classnames';

interface LinkProps {
  label: string;
  url: string;
  color?: string;
  isUnderline?: boolean;
  isClickable?: boolean;
}

const LinkComponent = ({
  color,
  label,
  url,
  isUnderline = false,
  isClickable = false,
}: LinkProps) => (
  <a
    href={url}
    className={cx(color, {
      'pointer-events-none': !isClickable,
      underline: isUnderline,
    })}
  >
    {label}
  </a>
);

export default LinkComponent;
