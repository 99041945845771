import { SbBlokData, storyblokEditable } from '@storyblok/react';
import {
  FieldInputProps,
  FormikErrors,
  FormikTouched,
  FormikValues,
} from 'formik';
import dynamic from 'next/dynamic';
import { FormDataType } from '@/helpers/form/formHelpersTypes';

const PhoneInputField = dynamic(
  () => import('../../components/PhoneInput/PhoneInput'),
  {
    loading: () => <div />,
  },
);

const FIELD_ID = 'phone';

export const FieldData: FormDataType = {
  hubspotId: 'phone',
  id: FIELD_ID,
  type: 'text',
  validationType: 'string',
  validations: [
    {
      type: 'phoneNumberValid',
      key: 'phone_error_message_valid',
      params: [],
    },
  ],
};

export interface PhoneInputBlokProps extends SbBlokData {
  label: string;
  phoneNumber: string;
  defaultCountry: string;
  labels: Record<string, string>;
  placeholder: string;
  onChange: (event: string) => void;
  formikErrors: FormikErrors<{ phone: string }>;
  touched: FormikTouched<{ phone: boolean }>;
}

export interface PhoneInputProps {
  blok: PhoneInputBlokProps;
  field: FieldInputProps<FormikValues>;
}

const PhoneInput = ({ blok, field }: PhoneInputProps) => {
  const {
    label: defaultLabel,
    placeholder: defaultPlaceholder,
    defaultCountry,
    labels,
    onChange,
    formikErrors,
    touched,
  } = blok;

  const label = labels.phone_label || defaultLabel;
  const placeholder = labels.phone_placeholder || defaultPlaceholder;
  const searchFieldPlaceholder = labels.phone_search_placeholder;
  const searchPaneTitle = labels.phone_search_pane_title;

  const handleChange = (e: string) => onChange(e || '');

  return (
    <div {...storyblokEditable(blok)}>
      <PhoneInputField
        {...field}
        defaultCountry={defaultCountry}
        label={label}
        placeholder={placeholder}
        error={formikErrors.phone && touched.phone ? formikErrors.phone : ''}
        onChange={handleChange}
        searchFieldPlaceholder={searchFieldPlaceholder}
        searchPaneTitle={searchPaneTitle}
        preferredCountries={[]}
      />
    </div>
  );
};

export default PhoneInput;
