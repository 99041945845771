import cx from 'classnames';

const PlayIcon = ({ className }: { className?: string }) => (
  <svg
    className={cx('cursor-pointer', className)}
    width="47"
    height="48"
    viewBox="0 0 47 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.5857 24C45.5857 36.7392 35.6578 47 23.4913 47C11.3249 47 1.39697 36.7392 1.39697 24C1.39697 11.2608 11.3249 1 23.4913 1C35.6578 1 45.5857 11.2608 45.5857 24Z"
      stroke="white"
      strokeWidth="2"
    />
    <path d="M18.6801 15V34L33.114 24.7714L18.6801 15Z" fill="white" />
  </svg>
);

const PauseIcon = ({ className }: { className?: string }) => (
  <svg
    className={cx('cursor-pointer', className)}
    width="47"
    height="48"
    viewBox="0 0 24 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5 12.5686C23.5 19.1981 18.3333 24.5392 12 24.5392C5.66668 24.5392 0.5 19.1981 0.5 12.5686C0.5 5.93914 5.66668 0.598022 12 0.598022C18.3333 0.598022 23.5 5.93914 23.5 12.5686Z"
      stroke="white"
    />
    <path
      d="M13.5893 16.2519C13.3627 16.2519 13.1675 16.1701 13.0037 16.0063C12.84 15.8425 12.7581 15.6473 12.7581 15.4207V9.8791C12.7581 9.65245 12.84 9.45725 13.0037 9.2935C13.1675 9.12974 13.3627 9.04787 13.5893 9.04787H14.005C14.2316 9.04787 14.4268 9.12974 14.5906 9.2935C14.7543 9.45725 14.8362 9.65245 14.8362 9.8791V15.4207C14.8362 15.6473 14.7543 15.8425 14.5906 16.0063C14.4268 16.1701 14.2316 16.2519 14.005 16.2519H13.5893ZM9.29462 16.2519C9.06797 16.2519 8.87276 16.1701 8.70901 16.0063C8.54526 15.8425 8.46338 15.6473 8.46338 15.4207V9.8791C8.46338 9.65245 8.54526 9.45725 8.70901 9.2935C8.87276 9.12974 9.06797 9.04787 9.29462 9.04787H9.71024C9.93689 9.04787 10.1321 9.12974 10.2958 9.2935C10.4596 9.45725 10.5415 9.65245 10.5415 9.8791V15.4207C10.5415 15.6473 10.4596 15.8425 10.2958 16.0063C10.1321 16.1701 9.93689 16.2519 9.71024 16.2519H9.29462Z"
      fill="white"
    />
  </svg>
);

const VideoOverlay = ({
  isStarted = false,
  isPaused = true,
  onPlay,
}: {
  isStarted: boolean;
  isPaused: boolean;
  onPlay: () => void;
}) => (
  <div
    className={cx(
      'absolute w-full h-full top-0 left-0 bg-opacity-50 bg-GSdeep/900',
      'rounded sm:rounded-lg',
    )}
    onClick={onPlay}
  >
    <div className="flex w-full h-full items-center justify-center">
      {!isStarted || isPaused ? (
        <PlayIcon className="fill-GSblue/500 hover:fill-GSblue/600 transform transition duration-300" />
      ) : (
        <PauseIcon className="fill-GSblue/500 hover:fill-GSblue/600 transform transition duration-300" />
      )}
    </div>
  </div>
);

export default VideoOverlay;
