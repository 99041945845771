/* eslint-disable jsx-a11y/control-has-associated-label */
import { SbBlokData, storyblokEditable } from '@storyblok/react';

import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import { AppStoreIcon, GooglePlayIcon } from '../../components/IconLink/Icons';

export interface DownloadSectionBlokProps extends SbBlokData {
  title: string;
  appStoreLink: string;
  googlePlayLink: string;
  badge: Asset;
  badgeText: string;
}

interface DownloadSectionProps {
  blok: DownloadSectionBlokProps;
}

const DownloadSection = ({ blok }: DownloadSectionProps) => (
  <div {...storyblokEditable(blok)} className="w-full flex flex-col gap-4">
    <div className="font-bold text-lg">{blok.title}</div>
    <div className="flex flex-col sm:flex-row lg:flex-col gap-4">
      <div className="flex flex-col sm:justify-evenly lg:justify-normal gap-2">
        <a
          href={blok.appStoreLink}
          className="w-full flex items-center justify-center text-white bg-GSblue/700 p-1 rounded-sm"
        >
          <AppStoreIcon />
        </a>
        <a
          href={blok.googlePlayLink}
          className="w-full flex items-center justify-center text-white bg-GSblue/700 p-1 rounded-sm"
        >
          <GooglePlayIcon />
        </a>
      </div>
      {blok.badge.filename && <hr className="border-t border-GSblue/400" />}
      <div className="flex flex-row items-center gap-2">
        <StoryblokImage
          className="flex max-h-[84px] max-w-[84px]"
          blok={blok.badge}
        />
        <span className="flex italic text-[10px] leading-4 text-GSblue/50">
          {blok.badgeText}
        </span>
      </div>
    </div>
  </div>
);

export default DownloadSection;
