import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';

export interface ImageCardBlokProps extends SbBlokData {
  photo: Asset;
  name: string;
  role: string;
  description: string;
}

interface ImageCardProps {
  blok: ImageCardBlokProps;
  type?: 'big' | 'small';
}

const ImageCard = ({ blok, type = 'big' }: ImageCardProps) => {
  const { photo, name = '', role = '', description = '' } = blok;

  return (
    <div
      className={cx('items-start overflow-hidden flex flex-col lg:gap-4', {
        'gap-y-2 sm:gap-4': type === 'big',
        'gap-y-1 sm:gap-y-2': type === 'small',
      })}
      {...storyblokEditable(blok)}
    >
      {photo && <StoryblokImage className="w-full rounded" blok={photo} />}
      <div className="flex flex-col gap-y-2 sm:gap-y-4">
        <div className={cx('grid', { 'gap-1': type === 'big' })}>
          <div
            className={cx(
              'text-GSblue/600 font-bold overflow-hidden break-words font-raleway',
              {
                'text-base sm:text-lg lg:text-xl': type === 'small',
                'text-lg lg:text-xl': type === 'big',
              },
            )}
          >
            {name}
          </div>
          <div
            className={cx(
              'text-GSblue/600 uppercase overflow-hidden break-words font-medium',
              {
                'text-[10px] leading-4 sm:text-xs lg:text-sm': type === 'small',
                'text-xs lg:text-sm': type === 'big',
              },
            )}
          >
            {role}
          </div>
        </div>
        {description && (
          <div className="text-GSdeep/900 text-sm overflow-hidden break-words">
            {description}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageCard;
