import TextInput from '@/components/TextInput/TextInput';
import { FormDataType } from '@/helpers/form/formHelpersTypes';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';

const FIELD_ID = 'firstName';

export const FieldData: FormDataType = {
  hubspotId: 'firstname',
  id: FIELD_ID,
  type: 'text',
  validationType: 'string',
};

export interface NameFieldBlokProps extends SbBlokData {
  nameLabel: string;
  namePlaceholder: string;
  formikErrors: FormikErrors<{ firstName: string }>;
  touched: FormikTouched<{ firstName: boolean }>;
}

interface NameFieldProps {
  blok: NameFieldBlokProps;
  field: FieldInputProps<string>;
}

const NameField = ({ blok, field }: NameFieldProps) => {
  const { nameLabel, namePlaceholder, formikErrors, touched } = blok;

  return (
    <div {...storyblokEditable(blok)}>
      <TextInput
        type="text"
        placeholder={namePlaceholder}
        error={
          formikErrors.firstName && touched.firstName
            ? formikErrors.firstName
            : ''
        }
        {...field}
        label={nameLabel}
        name={field.name}
      />
    </div>
  );
};

export default NameField;
