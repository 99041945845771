import cx from 'classnames';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FormStepBlokProps } from '../FormStep/FormStep';

export interface FormStepperBlokProps extends SbBlokData {
  color?: 'blue' | 'green' | '';
  showStepsCounter?: boolean;
  stepLabel?: string;
  title?: string;
}

interface FormStepperProps {
  activeStep: number;
  blok: FormStepperBlokProps;
  steps: Array<FormStepBlokProps>;
}

const FormStepperStep = ({
  color = 'blue',
  isActive,
  isDone,
  isFirst,
}: {
  color?: 'blue' | 'green' | '';
  isActive?: boolean;
  isDone?: boolean;
  isFirst?: boolean;
}) => (
  <div
    className={cx('w-full min-h-2 h-2 max-h-2 bg-GSblue/100', {
      'bg-GSblue/500':
        (color === 'blue' || color === '') && (isFirst || isActive || isDone),
      'bg-GSgreen/500': color === 'green' && (isFirst || isActive || isDone),
    })}
  />
);

const FormStepper = ({ activeStep, blok, steps }: FormStepperProps) => {
  const { color = 'blue', showStepsCounter, stepLabel, title } = blok;

  return (
    <div {...storyblokEditable(blok)} className="flex w-full flex-col gap-y-3">
      {(title || showStepsCounter) && (
        <div className="flex font-raleway font-bold text-xl lg:text-2xl">
          <div className="text-GSblue/600 grow flex flex-wrap">
            <span>{title}</span>
            {showStepsCounter && (
              <>
                <span>&nbsp;</span>
                <span
                  className={cx({
                    'text-GSblue/600': color === 'blue' || color === '',
                    'text-GSgreen/500': color === 'green',
                  })}
                >
                  {`(${stepLabel ? `${stepLabel} ` : ''}${activeStep + 1} / ${
                    steps.length
                  })`}
                </span>
              </>
            )}
          </div>
        </div>
      )}
      <div className="flex w-full">
        {steps.map(({ _uid }, index) => (
          <FormStepperStep
            key={_uid}
            color={color}
            isActive={activeStep === index}
            isDone={activeStep > index}
            isFirst={index === 0}
          />
        ))}
      </div>
    </div>
  );
};

export default FormStepper;
