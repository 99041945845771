import parse from 'html-react-parser';
import { renderRichText } from '@storyblok/react';

import faqRichTextSchema from './richTextSchema';
import { FaqSectionItemBlokProps } from '../FaqSectionItem/types';

export const renderToString = (item: FaqSectionItemBlokProps): string => {
  const { type = 'plainText' } = item;

  if (type === 'plainText' || type === '') {
    return item.body || '';
  }

  if (item.bodyRichtext) {
    return renderRichText(item.bodyRichtext, { schema: faqRichTextSchema });
  }

  return '';
};

export const renderItemBody = (item: FaqSectionItemBlokProps): string => {
  const { type = 'plainText' } = item;

  if (type === 'plainText' || type === '') {
    return item.body || '';
  }

  if (type === 'richText' && item.bodyRichtext) {
    return parse(renderToString(item));
  }

  return '';
};
