import FormikDropdown from '@/components/Dropdown/FormikDropdown';
import { FormDataType } from '@/helpers/form/formHelpersTypes';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FieldProps, FormikErrors, FormikTouched } from 'formik';

const FIELD_ID = 'intent';

export const FieldData: FormDataType = {
  hubspotId: 'intent',
  id: FIELD_ID,
  type: 'text',
  validationType: 'string',
} as const;

export interface FormIntentFieldBlokProps extends SbBlokData {
  config?: { content: { options: Array<{ label: string; value: string }> } };
  formikErrors: FormikErrors<{ [FIELD_ID]: string }>;
  label: string;
  placeholder: string;
  touched: FormikTouched<{ [FIELD_ID]: boolean }>;
}

interface FormIntentFieldProps extends FieldProps {
  blok: FormIntentFieldBlokProps;
}

const FormIntentField = ({ blok, ...props }: FormIntentFieldProps) => {
  const {
    config: { content: { options = [] } = {} } = {},
    formikErrors,
    label,
    placeholder,
    touched,
  } = blok;

  const error =
    formikErrors[FIELD_ID] && touched[FIELD_ID] ? formikErrors[FIELD_ID] : '';

  return (
    <div {...storyblokEditable(blok)}>
      <FormikDropdown
        hubspotId={FieldData.hubspotId}
        label={label}
        placeholder={placeholder}
        options={options.map(
          ({
            label: levelLabel,
            value: levelValue,
          }: {
            label: string;
            value: string;
          }) => ({
            label: levelLabel,
            value: levelValue,
          }),
        )}
        error={error}
        {...props}
      />
    </div>
  );
};

export default FormIntentField;
