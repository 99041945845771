const PhoneIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="7.24994" y="2.8125" width="10.5" height="18.3751" rx="1" />
    <path
      d="M10.5312 19.2188H14.4687"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5312 2.8125V4.28126C10.5312 4.5574 10.7551 4.78126 11.0312 4.78126H13.9687C14.2449 4.78126 14.4687 4.5574 14.4687 4.28126V2.8125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PhoneIcon;
