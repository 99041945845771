import { Size } from '@/components/Button/Button';
import { useMediaQuery } from 'react-responsive';

export const setCtaSize = (): Size => {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  if (isMobile) {
    return 'btn-SM';
  }
  if (isTablet) {
    return 'btn-MD';
  }
  return 'btn-LG';
};

export const setIconColor = (color: string) => {
  switch (color) {
    case 'black':
      return 'fill-GSdeep/900';
    case 'blue':
      return 'fill-GSblue/500';
    case 'white':
      return 'fill-GSbase/white';
    default:
      return 'fill-GSdeep/900';
  }
};

export const setIconSize = (size: string) => {
  switch (size) {
    case 'medium':
      return 'w-8 h-8';
    case 'large':
      return 'w-10 h-10 sm:w-12 sm:h-12';
    default:
      return 'w-8 h-8';
  }
};
