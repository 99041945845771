import { renderToString } from '@/blocks/FaqSection/utils';
import { FaqSectionItemBlokProps } from '@/blocks/FaqSectionItem/types';
import { Content } from '../storyblok/types';
import { getAllComponentsByName } from './utils';

const getSchema = (storyContent: Content) => {
  const faqSectionItems = getAllComponentsByName(storyContent, [
    'faqSectionItem',
    'faqItem',
  ]);

  if (faqSectionItems.length === 0) {
    return;
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: (faqSectionItems as Array<FaqSectionItemBlokProps>).map(
      (item) => ({
        '@type': 'Question',
        name: item.title,
        acceptedAnswer: {
          '@type': 'Answer',
          text: renderToString(item),
        },
      }),
    ),
  };
};

export default getSchema;
