import PageContainer from '@/components/PageContainer/PageContainer';
import { getGreenhouseData } from '@/helpers/jobs/greenhouseAPI';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { useEffect, useState } from 'react';
import CtaLink, { CtaLinkBlokProps } from '../CtaLink/CtaLink';
import JobFilters, { JobFiltersBlokProps } from '../JobFilters/JobFilters';

export interface JobListingsBlokProps extends SbBlokData {
  title: string;
  description: string;
  cta: Array<CtaLinkBlokProps>;
  filters: Array<JobFiltersBlokProps>;
  jobsToShow: 'All' | '5';
  newLabel: string;
  jobDetailPageURL: string;
}

export interface JobListingsProps {
  blok: JobListingsBlokProps;
}

const JobListings = ({ blok }: JobListingsProps) => {
  const {
    filters: [filters] = [],
    title = '',
    description = '',
    cta: [cta] = [],
    jobsToShow = '5',
    newLabel = '',
    jobDetailPageURL = '',
  } = blok;
  const [jobs, setJobs] = useState<Array<Job>>([]);
  const [filteredJobs, setFilteredJobs] = useState<Array<Job>>([]);

  const [locations, setLocations] = useState<Array<GreenHouseOffices>>([]);
  const [departments, setDepartments] = useState<Array<JobDepartment>>([]);

  const [locationFilter, setLocationFilter] = useState('');
  const [departmentFilter, setDepartmentFilter] = useState('');

  const twoWeeksAgo = new Date().setDate(new Date().getDate() - 14);

  const setFilterBy = (
    filter: 'department' | 'location',
    option: { value: string; label: string },
  ) => {
    if (filter === 'department') {
      setDepartmentFilter(option.value);
    }
    if (filter === 'location') {
      setLocationFilter(option.value);
    }
  };

  const applyFilters = () => {
    const filtered = jobs
      .filter((job) =>
        job.location.name
          .toLowerCase()
          .includes(locationFilter.trim().toLowerCase()),
      )
      .filter((job) =>
        job.departments.find((dep) =>
          dep.name
            .toLowerCase()
            .includes(departmentFilter.trim().toLowerCase()),
        ),
      );
    setFilteredJobs(
      jobsToShow !== 'All' ? filtered.splice(0, Number(jobsToShow)) : filtered,
    );
  };

  const clearFilters = () => {
    setFilteredJobs(
      jobsToShow !== 'All' ? jobs.splice(0, Number(jobsToShow)) : jobs,
    );
    setDepartmentFilter('');
    setLocationFilter('');
  };

  const fetchJobs = async () => {
    try {
      const result = await getGreenhouseData('jobs?content=true');
      setJobs(result.jobs);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('failed to fetch');
      return err;
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    const jobsByDate = jobs.sort(
      (a: Job, b: Job) =>
        new Date(b.updated_at).valueOf() - new Date(a.updated_at).valueOf(),
    );

    setFilteredJobs(
      jobsToShow !== 'All'
        ? jobsByDate.splice(0, Number(jobsToShow))
        : jobsByDate,
    );

    const jobsOffices = jobs.map((job) => job.offices || []).flat();
    const allOffices = jobsOffices.filter(
      (office, index) =>
        jobsOffices.findIndex((item) => item.id === office.id) === index,
    );

    setLocations(allOffices);

    const jobsDepartments = jobs.map((job) => job.departments || []).flat();
    const allJobsDepartments = jobsDepartments.filter(
      (office, index) =>
        jobsDepartments.findIndex((item) => item.id === office.id) === index,
    );

    setDepartments(allJobsDepartments);
  }, [jobs]);

  return (
    <div {...storyblokEditable(blok)}>
      <PageContainer className="py-8 gap-4 sm:gap-6 lg:gap-10 sm:py-12 lg:py-16">
        <div className="grid gap-2 text-center">
          <span className="font-raleway font-bold text-GSblue/600 text-xl leading-7 lg:text-3xl lg:leading-10">
            {title}
          </span>
          <span className="font-worksans text-sm text-GSdeep/900 font-regular lg:text-lg">
            {description}
          </span>
        </div>

        <div className="w-full grid grid-cols-12 gap-4 lg:gap-8">
          <div className="grid col-span-12 sm:col-span-5 lg:col-span-4">
            {filters && (
              <JobFilters
                blok={filters}
                locations={locations}
                selectedLocation={locationFilter}
                departments={departments}
                selectedDepartment={departmentFilter}
                onFilterChange={setFilterBy}
                onFilterSearch={applyFilters}
                onClearFilters={clearFilters}
              />
            )}
          </div>
          <div className="grid col-span-12 gap-2 sm:col-span-7 lg:col-span-8 sm:gap-4 lg:gap-4">
            {filteredJobs.map((job) => (
              <a href={`${jobDetailPageURL}/?gh_jid=${job.id}`} key={job.id}>
                <div className="grid group h-max w-full shadow-lg rounded-lg border border-GSblue/300 gap-2 p-4 sm:p-6 lg:p-8 hover:bg-GSblue/50">
                  {new Date(job.updated_at).getTime() > twoWeeksAgo &&
                    newLabel && (
                      <div className="w-full flex justify-end">
                        <span className="rounded bg-GSpurple/100 group-hover:bg-GSpurple/200 text-GSblue/600 text-xs font-medium px-2 py-1 sm:text-sm sm:px-3 sm:py-[6px] lg:px-4 lg:py-2 lg:text-[16px] lg:leading-6">
                          {newLabel}
                        </span>
                      </div>
                    )}
                  <div className="flex flex-col gap-4 lg:flex-row">
                    <div className="grid gap-2">
                      <div className="font-raleway font-bold text-GSblue/600">
                        <h3 className="text-lg sm:text-xl lg:text-2xl">
                          {job.title}
                        </h3>
                      </div>

                      <span className="font-worksans text-sm font-regular text-GSdeep/900 lg:text-[16px] lg:leading-6">
                        {job.location.name}
                      </span>
                    </div>

                    <div className="flex items-center border-1 border-GSblue/500 lg:pl-8 lg:ml-10 lg:border-l">
                      {job.departments.map((department) => (
                        <span
                          key={department.id}
                          className="font-worksans text-xs font-regular text-GSdeep/900 sm:text-sm lg:text-md lg:text-[16px]"
                        >
                          {department.name}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
        {cta && (
          <div className="font-medium">
            <CtaLink blok={cta} hasRTicon />
          </div>
        )}
      </PageContainer>
    </div>
  );
};

export default JobListings;
