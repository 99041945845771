import { ModalComponent } from '@/components';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import { TitleBlokProps } from '../Title/Title';

export interface ModalBlokProps extends SbBlokData {
  title: string;
  description: string;
  routeName: string;
  type: 'dialog' | 'fullscreen';
  closeModalType: 'closeButton' | 'backdropClick' | 'buttonAndBackdrop';
  content: Array<TitleBlokProps | CtaButtonBlokProps>;
}

interface ModalProps {
  blok: ModalBlokProps;
}

const Modal = ({ blok }: ModalProps) => {
  const { content, routeName, closeModalType } = blok;
  return (
    <div {...storyblokEditable(blok)}>
      <ModalComponent routeName={routeName} closeModalType={closeModalType}>
        {content && (
          <div className="flex flex-col gap-4 sm:gap-6 lg:gap-8">
            {content.map((item) => (
              <StoryblokComponent key={item._uid} blok={item} />
            ))}
          </div>
        )}
      </ModalComponent>
    </div>
  );
};

export default Modal;
