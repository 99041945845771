import Head from 'next/head';
import { SbBlokData } from '@storyblok/react';
import { useTemplateDataContext } from '../TemplateData/TemplateDataContext';

export interface SeoMetadataBlokProps extends SbBlokData {
  seoDescription?: string;
  seoIndexing?: 'enabled' | 'disabled' | '';
  seoTitle?: string;
}

interface SeoMetadataProps {
  blok: SeoMetadataBlokProps;
}

const SeoMetadata = ({ blok }: SeoMetadataProps) => {
  const { seoDescription, seoIndexing, seoTitle } = blok;
  const { getFilledTemplateString } = useTemplateDataContext();

  const title = getFilledTemplateString(seoTitle);
  const description = getFilledTemplateString(seoDescription);

  return (
    <Head>
      {title && <title>{title}</title>}
      {description && (
        <meta
          name="description"
          content={getFilledTemplateString(seoDescription)}
        />
      )}
      {(!seoIndexing || seoIndexing === 'disabled') && (
        <meta name="robots" content="noindex" />
      )}
    </Head>
  );
};

export default SeoMetadata;
