import ReactSelect, { StylesConfig } from 'react-select';
import cx from 'classnames';

import { ClearIndicator, DropdownIndicator } from './Indicators';
import { MultiValueRemove, Option, ValueContainer } from './Components';

export type OptionType = {
  value: string;
  label: string;
};

export interface SelectProps {
  hubspotId?: string;
  placeholder?: string;
  error?: string;
  label?: string;
  disabled?: boolean;
  options: OptionType[];
  isMulti?: boolean;
  name: string;
  onChange: (option: unknown) => void;
  getValue: () => undefined | [] | OptionType | OptionType[];
}

const styles: StylesConfig = {
  menuList: (base) => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-track': {
      background: 'white',
      borderLeft: '2px solid #DADBE5',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#DADBE5',
    },
  }),
  option: () => ({
    '@supports (-webkit-touch-callout: none)': {
      borderRight: '9px solid #DADBE5',
    },
  }),
};

const Select = ({
  disabled,
  error,
  hubspotId,
  isMulti,
  label,
  options,
  placeholder,
  name,
  onChange,
  getValue,
}: SelectProps) => {
  const hubspotValue = () => {
    const result = getValue();

    if (!result) {
      return '';
    }

    if (Array.isArray(result)) {
      return result.map((item) => item.value);
    }

    return result.value;
  };

  return (
    <div className="w-full flex flex-col gap-y-2 h-full">
      {hubspotId && (
        <>
          <label className="hidden" htmlFor={hubspotId}>
            {hubspotId}
          </label>
          <input
            name={hubspotId}
            className="hidden"
            value={hubspotValue()}
            readOnly
          />
        </>
      )}
      <div className="w-full flex flex-col gap-y-1 h-full">
        {label && (
          <label className="font-medium text-sm text-GSdeep/900" htmlFor={name}>
            {label}
          </label>
        )}
        <ReactSelect
          value={getValue()}
          onChange={onChange}
          hideSelectedOptions={false}
          closeMenuOnSelect={!isMulti}
          inputId={name}
          instanceId={name}
          isMulti={isMulti}
          isSearchable={false}
          styles={styles}
          options={options}
          placeholder={placeholder || 'Please select'}
          components={{
            ClearIndicator,
            DropdownIndicator,
            MultiValueRemove,
            ValueContainer,
            Option,
          }}
          unstyled
          isDisabled={disabled}
          classNames={{
            placeholder: () => 'text-GSsteel/500 text-base',
            multiValueLabel: () => 'text-xs font-medium py-0.5 pl-2 pr-1',
            multiValue: () => 'bg-GSblue/100 rounded max-w-[96px]',
            menu: () =>
              'bg-GSbase/white border-2 border-GSsteel/300 rounded text-sm mt-1',
            valueContainer: () => 'truncate !block',
            container: () => 'h-full',
            control: ({ isDisabled, isFocused }) =>
              cx(
                'h-full bg-GSbase/white text-sm font-normal py-3.5 px-2 border-2 border-GSsteel/300 rounded text-GSdeep/900 placeholder:text-GSsteel/500 hover:border-GSblue/500',
                {
                  'ring-GSblue/100 ring-offset-0 ring-4 border-GSblue/500':
                    !isDisabled && isFocused,
                  'bg-GSsteel/50 border-GSsteel/100 text-GSsteel/900 text-opacity-40':
                    isDisabled,
                  '!border-GSfunctional/error': error,
                },
              ),
            option: ({ isFocused, isSelected }) =>
              cx('text-GSdeep/900 px-4 py-2 font-worksans', {
                'hover:bg-GSblue/50': !isSelected && isFocused,
                'bg-GSblue/100': isSelected,
              }),
          }}
        />
      </div>
      {error && (
        <div className="text-xs text-GSfunctional/error font-medium">
          {error}
        </div>
      )}
    </div>
  );
};

export default Select;
