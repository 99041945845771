import {
  ISbRichtext,
  SbBlokData,
  renderRichText,
  storyblokEditable,
} from '@storyblok/react';
import mpcRichTextSchema from './richTextSchema';

export interface RichtextItemProps {
  blok: RichtextItemBlokProps;
}

export interface RichtextItemBlokProps extends SbBlokData {
  content: ISbRichtext;
}

const RichtextItem = ({ blok }: RichtextItemProps) => {
  const { content } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col sm:gap-2 text-GSdeep/900"
    >
      {content && (
        <div
          className="break-words min-w-0"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: renderRichText(content, { schema: mpcRichTextSchema }),
          }}
        />
      )}
    </div>
  );
};

export default RichtextItem;
