import {
  storyblokEditable,
  SbBlokData,
  ISbRichtext,
  renderRichText,
} from '@storyblok/react';
import parse from 'html-react-parser';
import { ComponentType } from 'react';

import TitleComponent from '@/components/Title/Title';
import { PageContainer } from '@/components';
import CtaLink, { CtaLinkBlokProps } from '../CtaLink/CtaLink';
import titleRichTextSchema from './richTextSchema';

export interface TitleBlokProps extends SbBlokData {
  header: string;
  headingLevel?: 'h1' | 'h2';
  headerRichtext?: ISbRichtext;
  subheader: string;
  layout?: 'default' | 'full';
  links?: Array<CtaLinkBlokProps>;
  topic?: string;
}

interface TitleProps {
  alignment?: 'left' | 'center' | 'right' | '';
  blok: TitleBlokProps;
  headingLevel?: ComponentType | keyof JSX.IntrinsicElements;
  backgroundColor?: string;
  hideBackground?: 'yes' | 'no';
  theme?: 'default' | 'ideal' | '';
  titleTextColor?: string;
}

const Title = ({ blok, ...rest }: TitleProps) => {
  const renderToString = (titleBlok: TitleBlokProps): string => {
    if (titleBlok.header) {
      return titleBlok.header;
    }

    if (titleBlok.headerRichtext) {
      const result = parse(
        renderRichText(titleBlok.headerRichtext, {
          schema: titleRichTextSchema,
        }),
      );

      if (Array.isArray(result) && result.length === 0) {
        return '';
      }

      return result;
    }

    return '';
  };

  const renderFull = () => {
    const { links, ...restBlok } = blok;

    return (
      <div className="full bg-GSblue/50">
        <PageContainer className="lg:py-20">
          <TitleComponent {...restBlok} {...rest} header={renderToString(blok)}>
            {links && links.length > 0 && (
              <div className="flex flex-col sm:flex-row sm:gap-4">
                {links.map((linkBlok, index) => (
                  <div
                    key={linkBlok._uid}
                    className="flex sm:gap-4 items-center"
                  >
                    {index > 0 && (
                      <span className="text-GSblue/600 hidden sm:block">|</span>
                    )}
                    <CtaLink blok={linkBlok} />
                  </div>
                ))}
              </div>
            )}
          </TitleComponent>
        </PageContainer>
      </div>
    );
  };

  return blok ? (
    <div {...storyblokEditable(blok)} className="w-full">
      {blok.layout === 'full' ? (
        renderFull()
      ) : (
        <TitleComponent {...blok} {...rest} header={renderToString(blok)} />
      )}
    </div>
  ) : null;
};

export default Title;
