/* eslint-disable jsx-a11y/control-has-associated-label */
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

export interface HeaderPartnerLogoBlokProps extends SbBlokData {
  image: Asset;
  link: Link;
  placement: 'left' | 'right';
}

interface HeaderPartnerLogoProps {
  blok: HeaderPartnerLogoBlokProps;
}

const HeaderPartnerLogo = ({ blok }: HeaderPartnerLogoProps) => {
  const { image, link, placement } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx({ 'ml-auto': placement === 'right' })}
    >
      <a className={cx({ 'bg-gray-400': !image.filename })} href={link.url}>
        <StoryblokImage
          blok={image}
          fallbackSrc="/assets/image.svg"
          className="h-8 sm:h-10"
        />
      </a>
    </div>
  );
};

export default HeaderPartnerLogo;
