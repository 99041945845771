import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

export interface IconBlokProps extends SbBlokData {
  image: Asset;
  alignment: 'left' | 'center' | 'right';
  visibleOnMobile: 'enabled' | 'disabled';
}

interface IconProps {
  blok: IconBlokProps;
}

const Icon = ({ blok }: IconProps) => (
  <div
    {...storyblokEditable(blok)}
    className={cx('flex', {
      'justify-start': blok.alignment === 'left',
      'justify-center': blok.alignment === 'center',
      'justify-end': blok.alignment === 'right',
      'hidden sm:block': blok.image && blok.visibleOnMobile === 'disabled',
    })}
  >
    <StoryblokImage blok={blok.image} className="w-12 sm:w-14 lg:w-[72px]" />
  </div>
);

export default Icon;
