import cx from 'classnames';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

import CtaButtonMessage, {
  CtaButtonMessageBlokProps,
} from '@/blocks/CtaButtonMessage/CtaButtonMessage';
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import { useVisibilityController } from '../VisibilityController/VisibilityController';

export interface CtaLabelBlokProps extends SbBlokData {
  label: string;
  leftIcon?: Asset;
  message?: Array<CtaButtonMessageBlokProps>;
}

interface CtaLabelProps extends SbBlokData {
  blok: CtaLabelBlokProps;
}

const CtaLabel = ({ blok }: CtaLabelProps) => {
  const visibilityController = useVisibilityController(blok);

  const { label, leftIcon, message: [message] = [] } = blok;

  if (!visibilityController.isVisible) {
    return null;
  }

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx(
        'w-full flex flex-col gap-1 sm:gap-2 lg:gap-3 items-center max-w-full sm:w-auto',
        visibilityController.deviceClassNames,
      )}
    >
      <div className="flex gap-1 items-center justify-end">
        {leftIcon && (
          <StoryblokImage
            blok={leftIcon}
            lazyLoading={false}
            className="h-5 w-5"
          />
        )}
        <div className="text-sm">{label}</div>
      </div>
      {message && <CtaButtonMessage blok={message} className="!text-sm" />}
    </div>
  );
};

export default CtaLabel;
