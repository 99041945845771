import { SbBlokData, StoryblokComponent } from '@storyblok/react';

export interface PartialBlokProps extends SbBlokData {
  content: Array<SbBlokData>;
}

interface PartialProps {
  blok: PartialBlokProps;
}

const Partial = ({ blok }: PartialProps) => (
  <>
    {blok.content?.map((nestedBlok: SbBlokData) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
    ))}
  </>
);

export default Partial;
