import React from 'react';
import cx from 'classnames';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

import WebsiteMenuLink, {
  WebsiteMenuLinkBlokProps,
} from '@/blocks/WebsiteMenuLink/WebsiteMenuLink';
import { ArrowDownIcon } from '../WebsiteMenuDropdown/icons';
import SubjectCard from '../../components/SubjectCard/SubjectCard';

interface ItemsSetConfig extends SbBlokData {
  content: {
    items: Array<WebsiteMenuLinkBlokProps>;
    type: 'cards' | 'links';
  };
}

export interface WebsiteMenuSubmenuBlokProps extends SbBlokData {
  label: string;
  items?: Array<WebsiteMenuLinkBlokProps>;
  itemsSet?: ItemsSetConfig;
}

export interface WebsiteMenuSubmenuProps {
  blok: WebsiteMenuSubmenuBlokProps;
  itemsOnly?: boolean;
  labelOnly?: boolean;
  desktop?: boolean;
}

const WebsiteMenuSubmenu = ({
  blok,
  desktop,
  itemsOnly,
  labelOnly,
}: WebsiteMenuSubmenuProps) => {
  const { items, itemsSet } = blok;
  const [isOpen, setIsOpen] = React.useState(false);
  const [cardsGridColumnsCount, setCardsGridColumnsCount] = React.useState(4);
  const type = itemsSet?.content?.type || 'links';
  const itemsToRender = React.useMemo(() => {
    if (items && items.length > 0) {
      return items;
    }

    if (itemsSet && itemsSet?.content?.items?.length > 0) {
      return itemsSet.content.items;
    }

    return undefined;
  }, [items, itemsSet]);

  const renderLinks = (menuItems: Array<WebsiteMenuLinkBlokProps>) => (
    <div
      className={cx(
        'flex flex-wrap px-2 sm:pl-6',
        'lg:grid lg:grid-flow-col lg:grid-rows-5 lg:px-0 lg:gap-x-4',
        {
          'sm:hidden lg:hidden': type === 'cards',
          hidden: !isOpen,
          visible: isOpen,
        },
      )}
    >
      {menuItems.map((item) => (
        <div className="basis-0 text-nowrap whitespace-nowrap" key={item._uid}>
          <WebsiteMenuLink blok={item} level={desktop ? 0 : 2} submenuItem />
        </div>
      ))}
    </div>
  );

  const refCallback = (element: HTMLDivElement) => {
    if (element && typeof window !== 'undefined') {
      const rect = element.getBoundingClientRect();

      if (rect.x + rect.width > window.innerWidth) {
        setCardsGridColumnsCount(3);
      }
    }
  };

  const renderCards = (menuItems: Array<WebsiteMenuLinkBlokProps>) => (
    <div
      ref={refCallback}
      className={cx(
        'hidden sm:grid w-fit grid-flow-row grid-cols-4 gap-4 px-2 sm:p-4 sm:pl-10',
        'lg:grid lg:grid-flow-row lg:gap-4 lg:p-0',
        {
          'lg:grid-cols-4': cardsGridColumnsCount === 4,
          'lg:grid-cols-3': cardsGridColumnsCount === 3,
          'sm:hidden': !isOpen,
          'sm:visible': isOpen,
        },
      )}
    >
      {menuItems.map((submenuItem) => (
        <SubjectCard
          label={submenuItem.label}
          image={submenuItem.image}
          link={submenuItem.link}
          key={submenuItem._uid}
        />
      ))}
    </div>
  );

  const renderItems = (menuItems?: Array<WebsiteMenuLinkBlokProps>) => {
    if (!menuItems || menuItems.length === 0) {
      return null;
    }

    if (type === 'links') {
      return renderLinks(menuItems);
    }

    if (type === 'cards') {
      return (
        <>
          {renderLinks(menuItems)}
          {renderCards(menuItems)}
        </>
      );
    }
  };

  return (
    <>
      {!itemsOnly && (
        <div
          {...storyblokEditable(blok)}
          className={cx(
            'select-none group cursor-pointer flex w-full',
            'text-base sm:text-lg',
            'hover:text-GSblue/600',
            'lg:rounded box-border border-y border-transparent lg:border-x',
            'hover:border-y hover:border-GSparchment/400 hover:bg-GSparchment/200 lg:hover:border-x',
            'gap-x-8',
            'py-2 sm:py-4 lg:px-4',
            'pl-6 sm:pl-10',
            'pr-3 sm:pr-5',
            'lg:bg-none lg:font-normal lg:border-none lg:bg-transparent lg:text-GSdeep/600',
            {
              'bg-GSparchment/200 text-GSblue/500 font-medium': isOpen,
              'border-b border-t border-solid !border-GSparchment/400': isOpen,
              'text-GSdeep/500': !isOpen,
            },
          )}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{blok.label}</span>
          <div className="lg:-rotate-90 ml-auto">
            <ArrowDownIcon
              className={cx('group-hover:stroke-GSblue/600', {
                '!stroke-GSdeep/500': !isOpen,
                '!stroke-GSblue/500 rotate-180 lg:rotate-0': isOpen,
              })}
            />
          </div>
        </div>
      )}

      {!labelOnly && renderItems(itemsToRender)}
    </>
  );
};

export default WebsiteMenuSubmenu;
