import { SbBlokData, storyblokEditable } from '@storyblok/react';
import IconLink, {
  IconColor,
  IconLinkBlokProps,
  IconSize,
} from '../IconLink/IconLink';

export interface SocialMediaLinksBlokProps extends SbBlokData {
  icons: Array<IconLinkBlokProps>;
  color: IconColor;
  size: IconSize;
}

interface SocialMediaLinksProps {
  blok: SocialMediaLinksBlokProps;
}

const SocialMediaLinks = ({ blok }: SocialMediaLinksProps) => (
  <div
    {...storyblokEditable(blok)}
    className="flex flex-row gap-4 justify-center"
  >
    {blok.icons?.map((item) => (
      <IconLink
        key={item._uid}
        blok={item}
        color={blok.color}
        size={blok.size}
      />
    ))}
  </div>
);

export default SocialMediaLinks;
