import {
  ISbRichtext,
  SbBlokData,
  renderRichText,
  storyblokEditable,
} from '@storyblok/react';
import mpcRichTextSchema from './richTextSchema';

export interface FormLegalTextProps {
  blok: FormLegalTextBlokProps;
}

export interface FormLegalTextBlokProps extends SbBlokData {
  content: ISbRichtext;
}

const FormLegalText = ({ blok }: FormLegalTextProps) => {
  const { content } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col sm:gap-2 text-GSdeep/900"
    >
      {content && (
        <div
          className="break-words min-w-0 text-xs font-normal"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: renderRichText(content, {
              schema: mpcRichTextSchema,
            }),
          }}
        />
      )}
    </div>
  );
};

export default FormLegalText;
