import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';

import { CtaButtonBlokProps } from '@/blocks/CtaButton/CtaButton';
import PageContainer from '@/components/PageContainer/PageContainer';
import HeaderLogoBlok, {
  HeaderLogoBlokProps,
} from '@/blocks/HeaderLogo/HeaderLogo';
import HeaderPartnerLogoBlok, {
  HeaderPartnerLogoBlokProps,
} from '@/blocks/HeaderPartnerLogo/HeaderPartnerLogo';
import HeaderPhoneNumberBlok, {
  HeaderPhoneNumberBlokProps,
} from '@/blocks/HeaderPhoneNumber/HeaderPhoneNumber';
import useResponsive from '@/helpers/layout/useResponsive';

export interface HeaderBlokProps extends SbBlokData {
  actions?: Array<CtaButtonBlokProps>;
  background: 'opaque' | 'transparent';
  layout: 'logo' | 'logo_phone' | 'logo_actions' | 'logo_partner';
  logo: Array<HeaderLogoBlokProps>;
  partnerLogo: Array<HeaderPartnerLogoBlokProps>;
  phoneNumber: Array<HeaderPhoneNumberBlokProps>;
}

interface HeaderProps {
  blok: HeaderBlokProps;
}

const Header = ({ blok }: HeaderProps) => {
  const { isMobile } = useResponsive();
  const {
    actions,
    background,
    layout,
    logo: [logoBlok],
    partnerLogo: [partnerLogoBlok],
    phoneNumber: [phoneNumberBlok],
  } = blok;

  return (
    <nav
      {...storyblokEditable(blok)}
      className={cx('w-full', {
        'bg-GSbase/white': background === 'opaque',
        'bg-transparent': background === 'transparent',
      })}
    >
      <PageContainer className="!py-2 sm:!py-4">
        <div className="flex flex-row w-full gap-2 sm:gap-4 lg:gap-8 text-GSbase/black items-center">
          {logoBlok && (
            <HeaderLogoBlok
              blok={logoBlok}
              disablePlacement={layout !== 'logo'}
            />
          )}

          {layout === 'logo_phone' && phoneNumberBlok && (
            <HeaderPhoneNumberBlok blok={phoneNumberBlok} />
          )}
          {layout === 'logo_actions' && actions && actions.length > 0 && (
            <div className="ml-auto flex gap-x-4">
              {actions.map((ctaBlok) => (
                <StoryblokComponent
                  blok={ctaBlok}
                  size={!isMobile ? 'btn-SM' : 'btn-XS'}
                  key={ctaBlok._uid}
                />
              ))}
            </div>
          )}
          {layout === 'logo_partner' && partnerLogoBlok && (
            <HeaderPartnerLogoBlok blok={partnerLogoBlok} />
          )}
        </div>
      </PageContainer>
    </nav>
  );
};

export default Header;
