import { SbBlokData, storyblokEditable } from '@storyblok/react';

import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import PhoneIcon from '../../icons/Phone';
import SendMessageIcon from '../../icons/SendMessage';

export interface ContactDetailsBlokProps extends SbBlokData {
  header?: string;
  image?: Asset;
  name?: string;
  role?: string;
  phone?: string;
  email?: string;
}

interface ContactDetailsProps {
  blok: ContactDetailsBlokProps;
}

const ContactDetails = ({ blok }: ContactDetailsProps) => {
  const { header, image, name, role, phone, email } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col gap-2 sm:gap-4 break-words font-raleway"
    >
      {header && (
        <div className="text-GSblue/600 text-lg font-bold sm:text-2xl">
          {header}
        </div>
      )}
      <div className="flex gap-4 sm:gap-8 w-full">
        {image && (
          <StoryblokImage blok={image} className="h-20 w-20 sm:h-40 sm:w-40" />
        )}
        <div className="flex flex-col w-full self-center gap-2">
          <div className="flex flex-col gap-1">
            {name && (
              <div className="text-GSblue/600 text-base font-bold sm:text-lg">
                {name}
              </div>
            )}
            {role && (
              <div className="text-GSblue/600 font-worksans text-xs font-medium [overflow-wrap:anywhere]">
                {role}
              </div>
            )}
          </div>
          <div className="flex flex-col">
            {phone && (
              <div className="flex py-2 gap-2 items-center">
                <PhoneIcon className="w-4 h-4 sm:w-6 sm:h-6 stroke-GSdarkpurple/500" />
                <div className="text-GSdeep/900 font-worksans font-medium text-xs sm:text-sm">
                  {phone}
                </div>
              </div>
            )}
            {email && (
              <div className="flex py-2 gap-2 items-center">
                <SendMessageIcon className="w-4 h-4 sm:w-6 sm:h-6 stroke-GSdarkpurple/500" />
                <a
                  href={`mailto:${email}`}
                  className="text-GSblue/600 break-all font-worksans font-medium text-xs sm:text-sm underline"
                >
                  {email}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
