import { FieldProps } from 'formik';
import Dropdown, { OptionType } from './Dropdown';

export interface FormikDropdownProps extends FieldProps {
  error?: string;
  hubspotId?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  options: OptionType[];
  isMulti?: boolean;
}

const FormikDropdown = ({
  field,
  isMulti,
  form,
  options,
  ...rest
}: FormikDropdownProps) => {
  const onChange = (option: unknown) => {
    if (isMulti) {
      form.setFieldValue(
        field.name,
        (option as Array<OptionType>).map((item) => item.value),
      );
    } else {
      form.setFieldValue(field.name, (option as OptionType).value);
    }
  };

  const getValue = (): undefined | [] | OptionType | OptionType[] => {
    let result;

    if (options && field.value) {
      result = isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    }

    return result || (isMulti ? [] : undefined);
  };

  return (
    <Dropdown
      name={field.name}
      options={options}
      {...rest}
      isMulti={isMulti}
      onChange={onChange}
      getValue={getValue}
    />
  );
};

export default FormikDropdown;
