import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import Image, { ImageBlokProps } from '../Image/Image';
import { languageTagToLanguage } from '../../components/TrustpilotWidget/utils';
import { FormBlokProps } from '../Form/FormType';

export interface HeroFormBoxBlokProps extends SbBlokData {
  alignment?: 'center' | 'top' | '';
  cardFrame?: 'enabled' | 'disabled';
  description?: string;
  form: Array<FormBlokProps>;
  title: string;
  topRightBadge: Array<ImageBlokProps>;
  mobileTextAlignCenter: boolean;
  bottomBadge: Array<ImageBlokProps>;
  trustPilotLanguage: string;
}

interface HeroFormBoxProps {
  blok: HeroFormBoxBlokProps;
}

const HeroFormBox = ({ blok }: HeroFormBoxProps) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 675px)');
    setIsMobile(mediaQuery.matches);
  });
  const {
    cardFrame = 'enabled',
    bottomBadge = [],
    topRightBadge: [topRightBadge] = [],
    description = '',
    form: [form] = [],
    trustPilotLanguage,
    title,
    mobileTextAlignCenter,
  } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx('w-full flex flex-col gap-4', 'sm:gap-6', 'lg:gap-10', {
        'bg-GSbase/white rounded-lg p-6 lg:p-8': cardFrame === 'enabled',
        'sm:pl-12 lg:pl-16': cardFrame === 'disabled',
      })}
    >
      {trustPilotLanguage && (
        <Script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          strategy="lazyOnload"
        />
      )}
      <div className="flex flex-col gap-2 sm:gap-4">
        {(title || topRightBadge) && (
          <div
            className={cx('flex', {
              'text-center justify-center sm:text-left sm:justify-between':
                mobileTextAlignCenter,
              'justify-between': !mobileTextAlignCenter,
            })}
          >
            {title && (
              <h1
                className={cx(
                  'font-raleway text-GSblue/600 font-extrabold text-2xl min-w-0 break-words',
                  'sm:text-[28px] sm:leading-[40px]',
                  'lg:text-[32px] lg:leading-[48px]',
                  '2xl:text-[40px] 2xl:leading-[56px]',
                )}
              >
                {title}
              </h1>
            )}
            {topRightBadge && (
              <div className="relative px-10">
                <Image
                  className="absolute top-[-48px] right-[-16px] lg:top-[-48px] max-h-[84px] max-w-[84px]"
                  blok={topRightBadge}
                  lazyLoading={false}
                />
              </div>
            )}
          </div>
        )}
        {description && (
          <div
            className={cx(
              'text-GSdeep/900 font-normal text-base sm:text-lg lg:text-xl break-words',
              { 'text-center sm:text-left': mobileTextAlignCenter },
            )}
          >
            {description}
          </div>
        )}
        {form && <StoryblokComponent blok={form} />}
        {((bottomBadge && bottomBadge.length > 0) || trustPilotLanguage) && (
          <div className="flex flex-row flex-wrap justify-center items-center gap-4">
            {trustPilotLanguage && (
              <div
                className="trustpilot-widget"
                data-locale={trustPilotLanguage}
                data-template-id="53aa8807dec7e10d38f59f32"
                data-businessunit-id="5b9bd0cf3738d6000130250d"
                data-style-height={isMobile ? '64px' : '96px'}
                data-style-width={isMobile ? '120px' : '178px'}
                data-theme="light"
                data-stars="4,5"
                data-review-languages={languageTagToLanguage(
                  trustPilotLanguage,
                )}
              />
            )}
            {bottomBadge.map((badge) => (
              <Image
                className="max-h-16 lg:max-h-24"
                blok={badge}
                key={badge._uid}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default HeroFormBox;
