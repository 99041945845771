import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FieldProps, FormikErrors, FormikTouched } from 'formik';

import FormikRadioGroup from '@/components/RadioGroup/FormikRadioGroup';
import { FormDataType, OptionType } from '@/helpers/form/formHelpersTypes';

const FIELD_ID = 'certifiedTeacher';

export const FieldData: FormDataType = {
  hubspotId: 'certified_teacher',
  id: FIELD_ID,
  type: 'text',
  validationType: 'string',
} as const;

export interface FormCertifiedTeacherFieldBlokProps extends SbBlokData {
  config?: { content: { options: Array<OptionType> } };
  description?: string;
  formikErrors: FormikErrors<{ [FIELD_ID]: string }>;
  label: string;
  readOnly: boolean;
  touched: FormikTouched<{ [FIELD_ID]: boolean }>;
}

interface FormCertifiedTeacherFieldProps extends FieldProps {
  blok: FormCertifiedTeacherFieldBlokProps;
}

const FormCertifiedTeacherField = ({
  blok,
  ...props
}: FormCertifiedTeacherFieldProps) => {
  const {
    config: { content: { options = [] } = {} } = {},
    description,
    formikErrors,
    label,
    readOnly,
    touched,
  } = blok;

  const error =
    formikErrors[FIELD_ID] && touched[FIELD_ID] ? formikErrors[FIELD_ID] : '';

  return (
    <div {...storyblokEditable(blok)}>
      <FormikRadioGroup
        description={description}
        disabled={readOnly}
        error={error}
        hubspotId={FieldData.hubspotId}
        label={label}
        options={options.map(
          ({
            label: levelLabel,
            value: levelValue,
          }: {
            label: string;
            value: string;
          }) => ({
            label: levelLabel,
            value: levelValue,
          }),
        )}
        {...props}
      />
    </div>
  );
};

export default FormCertifiedTeacherField;
