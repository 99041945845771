export const ArrowIcon = () => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_6506_1392"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
    >
      <rect width="40" height="40" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_6506_1392)">
      <path
        d="M23.3337 30L13.3337 20L23.3337 10L25.6671 12.3333L18.0004 20L25.6671 27.6667L23.3337 30Z"
        fill="#0B2FAC"
      />
    </g>
  </svg>
);
