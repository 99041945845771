import { SbBlokData } from '@storyblok/react';
import Head from 'next/head';

export interface HrefDataBlokProps extends SbBlokData {
  hrefLang: string;
  hrefLink: string;
}

interface HrefDataProps {
  blok: HrefDataBlokProps;
}

const HrefData = ({ blok: { hrefLang, hrefLink, _uid } }: HrefDataProps) => (
  <Head>
    <link
      rel="alternate"
      hrefLang={hrefLang}
      href={hrefLink}
      data-testid={_uid}
    />
  </Head>
);

export default HrefData;
