export const MenuIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 9.6001C3.5 9.32396 3.72386 9.1001 4 9.1001H28C28.2761 9.1001 28.5 9.32396 28.5 9.6001C28.5 9.87624 28.2761 10.1001 28 10.1001H4C3.72386 10.1001 3.5 9.87624 3.5 9.6001ZM3.5 16.0001C3.5 15.724 3.72386 15.5001 4 15.5001H28C28.2761 15.5001 28.5 15.724 28.5 16.0001C28.5 16.2762 28.2761 16.5001 28 16.5001H4C3.72386 16.5001 3.5 16.2762 3.5 16.0001ZM4 21.9001C3.72386 21.9001 3.5 22.124 3.5 22.4001C3.5 22.6762 3.72386 22.9001 4 22.9001H28C28.2761 22.9001 28.5 22.6762 28.5 22.4001C28.5 22.124 28.2761 21.9001 28 21.9001H4Z"
      fill="#0B2FAC"
    />
  </svg>
);

export const LanguageSwitcherIcon = ({ className }: { className?: string }) => (
  <svg
    viewBox="0 0 36 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    data-testid="LanguageSwitcherIcon"
  >
    <path d="M2.5 4.61279H12.8451" stroke="#0B2FAC" strokeWidth="1.25" />
    <path d="M7.31812 3.125V4.60261" stroke="#0B2FAC" strokeWidth="1.25" />
    <path
      d="M10.5979 4.61279C9.85908 6.82981 7.64266 10.5244 3.20862 13.8497M5.05624 6.82981C5.79504 8.06136 7.79018 10.8938 9.85908 12.3721"
      stroke="#0B2FAC"
      strokeWidth="1.25"
    />
    <path
      d="M11.0541 16.8749L11.9948 14.1228M17.3351 16.8749L16.2771 14.1228M11.9948 14.1228L14.0099 8.22607L16.2771 14.1228M11.9948 14.1228H16.2771"
      stroke="#0B2FAC"
      strokeWidth="1.25"
      strokeLinejoin="bevel"
    />
    <path
      d="M32.8 8.39995L28 13.2L23.2 8.39995"
      stroke="#0B2FAC"
      strokeLinecap="round"
    />
  </svg>
);
