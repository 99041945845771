import React from 'react';

import Drawer from '../Drawer/Drawer';
import { DropdownTriangle } from '../../icons/DropdownTriangle';
import { LanguageSwitcherIcon } from '../../icons/LanguageSwitcher';

interface LanguageSwitcherProps {
  items: Array<{
    label: string;
    href: string;
  }>;
  title: string;
}

const LanguageSwitcher = ({ items, title }: LanguageSwitcherProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  return (
    <>
      <div className="lg:hidden">
        <div
          aria-label={title}
          className="select-none flex items-center cursor-pointer"
          onClick={() => setMobileMenuOpen(true)}
          role="button"
          title={title}
        >
          <LanguageSwitcherIcon className="w-9 h-5 sm:w-11 sm:h-6 stroke-GSblue/500" />
        </div>
        <Drawer
          isOpen={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          title={title}
        >
          {items?.map((item) => (
            <a
              key={item.label}
              href={item.href}
              className="
                  font-normal
                  text-GSdeep/500 hover:text-GSblue/600 text-base
                  pl-4 pr-3 py-2 sm:pl-6 sm:pr-5 sm:py-4
                  rounded
                  box-border border-y border-transparent lg:border-x
                  hover:border-y hover:border-GSparchment/400 hover:bg-GSparchment/200 lg:hover:border-x
                  "
              data-testid={`LanguageSwitcherMobileItem-${item.label}`}
            >
              {item.label}
            </a>
          ))}
        </Drawer>
      </div>

      <div className="hidden lg:block relative text-black group/dropdown">
        <div className="select-none flex items-center cursor-pointer">
          <LanguageSwitcherIcon className="hidden lg:block w-14 h-8 stroke-GSblue/500" />
        </div>
        <div className="absolute invisible group-hover/dropdown:visible min-w-full right-0 z-10">
          <div className="flex flex-col pt-4 overflow-hidden max-w-xs shrink-0 min-w-full">
            <div className="mt-[-1px] w-full border border-GSparchment/400 rounded-[3px] bg-GSbase/white shadow-sm flex flex-col text-GSdeep/500 text-lg break-words">
              {items?.map((item) => (
                <a
                  key={item.label}
                  href={item.href}
                  className="font-normal py-2 px-4 hover:bg-GSparchment/200"
                  data-testid={`LanguageSwitcherItem-${item.label}`}
                >
                  {item.label}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="absolute invisible group-hover/dropdown:visible min-w-full flex justify-center">
          <div className="mt-[-8px] pt-4 self-center z-10">
            <DropdownTriangle />
          </div>
        </div>
      </div>
    </>
  );
};

export default LanguageSwitcher;
