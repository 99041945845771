import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';
import { useRouter } from 'next/router';

import CtaButtonBlock, {
  CtaButtonBlokProps,
} from '@/blocks/CtaButton/CtaButton';
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import { LayoutTypes } from '@/components/TrustpilotWidget/utils';
import { fillWithUrlParams } from '@/helpers/text/replacer';
import CtaLinkBlock, { CtaLinkBlokProps } from '../CtaLink/CtaLink';
import { HeroContentBoxListBlokProps } from '../HeroContentBoxList/HeroContentBoxList';
import { HeroContentBoxTestimonialBlokProps } from '../HeroContentBoxTestimonial/HeroContentBoxTestimonial';
import TrustpilotWidget from '../../components/TrustpilotWidget/TrustpilotWidget';

export interface HeroContentBoxBlokProps extends SbBlokData {
  alignment?: 'left' | 'center' | 'right';
  badgeList: Array<Asset>;
  content: Array<
    HeroContentBoxListBlokProps | HeroContentBoxTestimonialBlokProps
  >;
  title: string;
  description: string;
  ctaButton: Array<CtaButtonBlokProps>;
  ctaWidth?: 'default' | 'full';
  ctaLink: Array<CtaLinkBlokProps>;
  cardFrame: 'enabled' | 'disabled';
  layout: 'bulletList' | 'featureList' | 'badgeList';
  layoutSize?: 'medium' | 'default' | '';
  trustPilotLanguage?: string;
}

interface HeroContentBoxProps {
  blok: HeroContentBoxBlokProps;
  backgroundColor: 'dark' | 'light';
  colorVariant: 'scrollVariantGreen500' | 'scrollVariantYellow500';
}

const HeroContentBox = ({
  blok,
  backgroundColor,
  colorVariant,
}: HeroContentBoxProps) => {
  const router = useRouter();
  const {
    alignment = 'left',
    badgeList,
    cardFrame = 'enabled',
    content = [],
    ctaButton: [ctaButton] = [],
    ctaWidth = 'default',
    ctaLink: [ctaLink] = [],
    description,
    layout = 'bulletList',
    title,
    trustPilotLanguage,
  } = { ...blok, ctaWidth: blok.ctaWidth || undefined };

  const lightBackground =
    cardFrame === 'enabled' || backgroundColor === 'light';

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx('w-full flex flex-col gap-4 sm:gap-6 lg:gap-10', {
        'bg-GSbase/white rounded-lg p-8': cardFrame === 'enabled',
      })}
    >
      <div
        className={cx('flex flex-col gap-2 sm:gap-4', {
          'items-center': alignment === 'center',
          'items-start': alignment === 'left',
          'items-end': alignment === 'right',
        })}
      >
        <h1
          className={cx(
            'font-raleway font-extrabold text-2xl min-w-0 break-words sm:text-[28px] sm:leading-[40px] lg:text-[32px] lg:leading-[48px] 2xl:text-[40px] 2xl:leading-[56px]',
            {
              'text-GSblue/600': lightBackground,
              'text-GSbase/white': !lightBackground,
              'text-center': alignment === 'center',
              'text-start': alignment === 'left',
              'text-end': alignment === 'right',
            },
          )}
        >
          {fillWithUrlParams(title, router.query)}
        </h1>
        {description && (
          <p
            className={cx('font-normal text-base sm:text-lg lg:text-xl', {
              'text-GSdeep/900': lightBackground,
              'text-GSbase/white': !lightBackground,
              'text-center': alignment === 'center',
              'text-start': alignment === 'left',
              'text-end': alignment === 'right',
            })}
          >
            {fillWithUrlParams(description, router.query)}
          </p>
        )}
        {content && content.length > 0 && layout !== 'badgeList' && (
          <div className="flex flex-col gap-x-2 lg:gap-x-3 gap-y-4">
            {content.map((nestedBlok) => (
              <StoryblokComponent
                key={nestedBlok._uid}
                blok={nestedBlok}
                colorVariant={colorVariant}
                layout={layout}
                backgroundColor={lightBackground ? 'light' : 'dark'}
              />
            ))}
          </div>
        )}
        {layout === 'badgeList' && badgeList && badgeList.length > 0 && (
          <div className="flex flex-col gap-2 lg:gap-3">
            <div className="flex flex-col gap-2 items-start sm:gap-4">
              <div className="flex flex-row gap-2 sm:gap-4 lg:gap-8 justify-center lg:justify-start">
                {badgeList.slice(0, 3).map((badge) => (
                  <div key={badge.id}>
                    <StoryblokImage
                      blok={badge}
                      className="min-h-24 max-h-32"
                      lazyLoading={false}
                    />
                  </div>
                ))}
              </div>
              <div className="flex flex-row gap-2 sm:gap-4 lg:gap-8 justify-center lg:justify-start">
                {badgeList.slice(3, 6).map((badge) => (
                  <div key={badge.id}>
                    <StoryblokImage
                      blok={badge}
                      className="min-h-24 max-h-32"
                      lazyLoading={false}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      {(ctaButton || ctaLink) && (
        <div
          className={cx('flex flex-col gap-2', {
            'items-start':
              (cardFrame === 'disabled' && ctaWidth !== 'full') ||
              alignment === 'center',
            'items-stretch': ctaWidth === 'full',
            'items-center': alignment === 'center',
            'items-end': alignment === 'right',
          })}
        >
          {ctaButton && (
            <CtaButtonBlock
              blok={ctaButton}
              type={lightBackground ? 'primary' : 'secondary'}
            />
          )}
          {ctaLink && <CtaLinkBlock blok={ctaLink} />}
        </div>
      )}
      {layout !== 'badgeList' && (trustPilotLanguage || badgeList?.length > 0) && (
        <div className="self-center">
          <div className="flex gap-2 sm:gap-4 items-center">
            {trustPilotLanguage && (
              <TrustpilotWidget
                layout={LayoutTypes.Mini}
                language={trustPilotLanguage || 'EN'}
                height={80}
                width={120}
              />
            )}
            {badgeList.slice(0, 3).map((badge) => (
              <div key={badge.id}>
                <StoryblokImage
                  blok={badge}
                  className="min-h-24 max-h-32"
                  lazyLoading={false}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default HeroContentBox;
