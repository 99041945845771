/* eslint-disable no-confusing-arrow */
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';
import React from 'react';

import StoryblokLink, {
  isEmptyLink,
} from '@/components/StoryblokLink/StoryblokLink';
import { ArrowDownIcon } from '../../components/IconLink/Icons';
import { CtaLinkBlokProps } from '../CtaLink/CtaLink';

export interface WebsiteFooterLinkGroupBlokProps extends SbBlokData {
  title: string;
  content: Array<CtaLinkBlokProps>;
}

interface WebsiteFooterLinkGroupProps {
  blok: WebsiteFooterLinkGroupBlokProps;
  align: 'row' | 'col';
  mobileAccordion: boolean;
  handleOpenAccordion?: Function;
  openAccordion?: string;
}

const WebsiteFooterLinkGroup = ({
  blok,
  align,
  mobileAccordion = true,
  handleOpenAccordion,
  openAccordion,
}: WebsiteFooterLinkGroupProps) => (
  <>
    <div
      {...storyblokEditable(blok)}
      className="hidden sm:block w-full sm:mb-8 lg:mb-0 break-words"
    >
      <div className="font-bold text-lg mb-5">{blok.title}</div>
      <ul
        className={cx('flex flex-wrap gap-y-3 gap-x-8 text-GSdeep/500', {
          'flex-col': align === 'col',
          'flex-row': align === 'row',
        })}
      >
        {blok.content?.map((ctaLink, index) =>
          isEmptyLink(ctaLink.link) ? null : (
            <li key={ctaLink._uid}>
              <StoryblokLink
                addInternalParams={ctaLink.addParamsToUrl}
                link={ctaLink.link}
                className="text-white font-regular text-base hover:text-GSblue/200"
                testId={`WebsiteFooterLinkGroup-${index}`}
              >
                {ctaLink.label}
              </StoryblokLink>
            </li>
          ),
        )}
      </ul>
    </div>

    <div {...storyblokEditable(blok)} className="sm:hidden mb-4">
      <div
        onClick={(e) => {
          if (!mobileAccordion) {
            e.preventDefault();
          }
          const linkGroupToOpen = blok._uid === openAccordion ? '' : blok._uid;
          if (handleOpenAccordion) {
            handleOpenAccordion(linkGroupToOpen);
          }
        }}
        className={cx(
          'select-none flex items-center justify-between py-2 border-GSblue/400 cursor-pointer',
          {
            'border-b': align !== 'row',
          },
        )}
      >
        <div className="font-bold text-lg">{blok.title}</div>
        <div
          className={cx('flex items-center ui-open:transform', {
            'rotate-180': openAccordion === blok._uid,
            hidden: !mobileAccordion,
          })}
        >
          <ArrowDownIcon className="w-8 h-8 stroke-GSbase/white" />
        </div>
      </div>
      <div
        className={cx('flex flex-col w-full gap-4', {
          hidden: openAccordion !== blok._uid,
        })}
      >
        <ul
          className={cx('flex flex-wrap gap-x-8 text-GSdeep/500', {
            'flex-col': align === 'col',
            'flex-row': align === 'row',
          })}
        >
          {blok.content?.map((ctaLink, index) =>
            isEmptyLink(ctaLink.link) ? null : (
              <li className="py-2" key={ctaLink._uid}>
                <StoryblokLink
                  addInternalParams={ctaLink.addParamsToUrl}
                  link={ctaLink.link}
                  className="text-white font-regular text-base"
                  testId={`WebsiteFooterLinkGroup-m${index}`}
                >
                  {ctaLink.label}
                </StoryblokLink>
              </li>
            ),
          )}
        </ul>
      </div>
    </div>
  </>
);

export default WebsiteFooterLinkGroup;
