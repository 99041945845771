import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';
import { ArrowDownIcon } from './icons';

export interface AccordionWithTimelineItemBlokProps extends SbBlokData {
  year: string;
  title: string;
  body: string;
  backgroundColor: string;
}

export interface AccordionWithTimelineItemProps {
  blok: AccordionWithTimelineItemBlokProps;
  index: number;
  isLastItem: boolean;
  icon: Asset;
  isExpanded: boolean;
  setExpanded: (id: string | undefined) => void;
}

const AccordionWithTimelineItem = ({
  blok,
  index,
  isLastItem,
  icon,
  isExpanded,
  setExpanded,
}: AccordionWithTimelineItemProps) => {
  const { year, title, body, backgroundColor } = blok;

  const toggleItem = () => setExpanded(isExpanded ? '' : blok._uid);

  return (
    <div
      className="w-full grid gap-2 grid-cols-6 sm:grid-cols-12 sm:gap-4 lg:gap-8"
      {...storyblokEditable(blok)}
    >
      {isLastItem}
      <div
        className={cx(`flex col col-span-1 justify-center relative`, {
          'before:top-[-1rem]': isLastItem,
        })}
      >
        {icon.filename && index === 0 ? (
          <div className="z-20 flex justify-center after:absolute after:top-0 after:bottom-0 after:z-0 after:w-1 after:h-full after:bg-GSblue/400 after:content-[' ']">
            <StoryblokImage
              className="rounded-full overflow-visible aspect-square w-12 bg-GSblue/500 p-3 sm:p-3 md:p-3 h-max z-40 lg:w-16 min-w-[3rem] md:min-w-[3.4rem]"
              blok={icon}
            />
          </div>
        ) : (
          <div className="relative flex flex-col items-center">
            <div className="absolute w-1 h-10 bg-GSblue/400 z-20" />
            <div className="rounded-full mt-5 lg:mt-10 w-6 h-6 sm:w-8 sm:h-8 bg-GSblue/400 z-20" />
            {!isLastItem && (
              <div className="absolute bottom-0 w-1 h-full bg-GSblue/400 z-20" />
            )}
          </div>
        )}
      </div>
      <div className="col col-span-5 sm:col-span-11">
        <div
          className={cx(
            'w-full flex flex-col gap-1 text-GSbase/black border-GSblue/100 border p-4 sm:p-6 lg:p-8 shadow-lg rounded hover:cursor-pointer mb-4 sm:mb-6 lg:mb-8',
            {
              'bg-GSblue/50': backgroundColor === 'GSblue/50',
              'bg-GSpurple/50': backgroundColor === 'GSpurple/50',
              'border-solid !border-GSblue/300':
                isExpanded && backgroundColor === 'GSblue/50',
              'border-solid border-GSpurple/300':
                isExpanded && backgroundColor === 'GSpurple/50',
            },
          )}
          onClick={toggleItem}
        >
          <div
            className={cx(
              'flex-row justify-between font-raleway font-extrabold text-2xl leading-8 cursor-pointer w-full duration-[200ms] [word-break:break-word] flex lg:leading-10 lg:text-3xl',
              {
                'text-GSblue/500': backgroundColor === 'GSblue/50',
                'text-GSpurple/500 lg:!text-[32px] lg:!leading-[48px]':
                  backgroundColor === 'GSpurple/50',
              },
            )}
          >
            <div className="break-words">{year}</div>
            <div
              className={cx('flex items-center justify-center w-8 h-8', {
                'rotate-180': isExpanded,
              })}
            >
              <ArrowDownIcon />
            </div>
          </div>
          <div
            className={cx(
              `font-worksans font-bold break-words text-sm text-GSblue/500 sm:text-lg`,
              {
                'mb-4 lg:mb-6 duration-[200ms]': isExpanded,
                'duration-[200ms]': !isExpanded,
                'text-GSblue/500': backgroundColor === 'GSblue/50',
                'text-GSpurple/500 lg:!text-xl':
                  backgroundColor === 'GSpurple/50',
              },
            )}
          >
            {title}
          </div>

          {isExpanded && (
            <div
              className={cx(
                'grid grid-cols-1 text-GSdeep/900 font-worksans font-normal text-sm break-words whitespace-pre-wrap',
                {
                  'hidden duration-[200ms]': !isExpanded,
                  'block duration-[200ms]': isExpanded,
                },
              )}
            >
              {body}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccordionWithTimelineItem;
