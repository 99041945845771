export const SearchIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9605 9.11039C14.9605 12.3442 12.339 14.9657 9.10526 14.9657C5.87149 14.9657 3.25 12.3442 3.25 9.11039C3.25 5.87662 5.87149 3.25513 9.10526 3.25513C12.339 3.25513 14.9605 5.87662 14.9605 9.11039ZM13.3727 14.7919C12.1844 15.6858 10.7067 16.2157 9.10526 16.2157C5.18113 16.2157 2 13.0345 2 9.11039C2 5.18626 5.18113 2.00513 9.10526 2.00513C13.0294 2.00513 16.2105 5.18626 16.2105 9.11039C16.2105 10.7118 15.6807 12.1895 14.7869 13.3777L17.7072 16.298C18.0977 16.6885 18.0977 17.3217 17.7072 17.7122C17.3166 18.1028 16.6835 18.1028 16.293 17.7122L13.3727 14.7919Z"
      fill="white"
    />
  </svg>
);
