import {
  ISbRichtext,
  SbBlokData,
  renderRichText,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';
import React from 'react';

import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import PageContainer from '@/components/PageContainer/PageContainer';
import mpcRichTextSchema from './richTextSchema';

export interface RichtextSectionBlokProps extends SbBlokData {
  id?: string;
  title?: Array<TitleBlokProps>;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
  content: ISbRichtext;
}

export interface RichtextSectionProps {
  blok: RichtextSectionBlokProps;
}

const RichtextSection = ({ blok }: RichtextSectionProps) => {
  const {
    id,
    title: [title] = [],
    visibility,
    visibilityOnMobile,
    content,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('w-full bg-white', {
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      {title && title.layout === 'full' && <TitleBlok blok={title} />}

      <PageContainer className="!items-start">
        {title && title.layout !== 'full' && <TitleBlok blok={title} />}
        {content && (
          <div
            className="break-words min-w-0 text-GSdeep/900 flex flex-col gap-4"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: renderRichText(content, { schema: mpcRichTextSchema }),
            }}
          />
        )}
      </PageContainer>
    </section>
  );
};

export default RichtextSection;
