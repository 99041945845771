import { SbBlokData, storyblokEditable } from '@storyblok/react';
import PageContainer from '../../components/PageContainer/PageContainer';
import TitleBlok, { TitleBlokProps } from '../Title/Title';
import LegalNoticeItem, {
  LegalNoticeItemBlokProps,
} from '../LegalNoticeItem/LegalNoticeItem';

export interface LegalNoticeSectionBlokProps extends SbBlokData {
  content: Array<LegalNoticeItemBlokProps>;
  title: Array<TitleBlokProps>;
}

export interface LegalNoticeSectionProps {
  blok: LegalNoticeSectionBlokProps;
}

const LegalNoticeSection = ({ blok }: LegalNoticeSectionProps) => {
  const { content = [], title: [title] = [] } = blok;

  return (
    <section {...storyblokEditable(blok)} className="w-full">
      <TitleBlok blok={title} />
      <PageContainer className="sm:grid sm:grid-cols-12 gap-x-8 gap-y-10  lg:!py-20 sm:!py-10">
        <div
          className="
            flex flex-col gap-y-4
            sm:grid sm:grid-cols-2 grid-flow-row sm:col-span-12 sm:gap-x-4 sm:gap-y-4
            lg:grid-cols-4 lg:col-start-2 lg:col-end-12 lg:gap-x-8 lg:gap-y-10
          "
        >
          {content.map((nestedBlok) => (
            <div key={nestedBlok._uid} className="basis-1/4">
              <LegalNoticeItem blok={nestedBlok} />
            </div>
          ))}
        </div>
      </PageContainer>
    </section>
  );
};

export default LegalNoticeSection;
