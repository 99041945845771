export const BenefitImageScroll = ({
  firstPartClassName,
  secondPartClassName,
  isReversed,
}: {
  firstPartClassName: string;
  secondPartClassName: string;
  isReversed: boolean;
}) =>
  isReversed ? (
    <svg viewBox="0 0 559 379" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M340.689 93.8708C348.448 94.9779 356.193 96.7178 362.44 98.3881C339.467 98.6334 314.443 122.13 306.609 129.032C228.243 198.037 203.876 315.206 195.203 369.165L11.603 304.791C45.541 175.504 193.533 70.4357 340.689 93.8708Z"
        className={secondPartClassName}
      />
      <path
        d="M631.585 -45.8393L692.073 141.475C675.671 155.229 647.684 167.261 611.005 172.861C512.311 182.333 405.732 101.275 341.718 94.1491C322.803 91.1358 306.261 90.9099 304.658 90.8373C394.982 78.1866 531.953 32.3661 631.58 -45.8498L631.585 -45.8393Z"
        className={firstPartClassName}
      />
    </svg>
  ) : (
    <svg viewBox="0 0 386 264" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M150.747 65.7965C145.383 66.5572 140.031 67.7573 135.713 68.9107C151.596 69.1043 168.923 85.4435 174.346 90.2432C228.6 138.232 245.571 219.607 251.625 257.079L378.491 212.571C354.889 122.773 252.46 49.6748 150.747 65.7965V65.7965Z"
        className={secondPartClassName}
      />
      <path
        d="M-50.3571 -31.2758L-91.9763 98.7165C-80.6217 108.283 -61.2592 116.665 -35.8942 120.591C32.351 127.267 105.95 71.096 150.2 66.2133C163.274 64.1403 174.71 64.0002 175.819 63.9515C113.358 55.0763 18.61 23.124 -50.3535 -31.2831L-50.3571 -31.2758Z"
        className={firstPartClassName}
      />
    </svg>
  );
