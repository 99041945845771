import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import StoryblokLink from '@/components/StoryblokLink/StoryblokLink';

export interface LinkBlokProps extends SbBlokData {
  label: string;
  link: Link;
  isActive: boolean;
  isClickable: boolean;
}

interface LinkProps {
  blok: LinkBlokProps;
}

const Link = ({ blok }: LinkProps) => (
  <div {...storyblokEditable(blok)} className="w-full max-w-full sm:w-auto">
    <StoryblokLink
      className={cx({
        'pointer-events-none': !blok.isClickable,
        underline: blok.isClickable,
        'text-GSdeep/300': blok.isActive,
        'text-GSdeep/900': !blok.isActive,
      })}
      link={blok.link}
      testId={blok._uid}
    >
      {blok.label}
    </StoryblokLink>
  </div>
);

export default Link;
