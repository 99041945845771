import { FieldProps, FormikErrors, FormikTouched } from 'formik';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

import { FormDataType, OptionType } from '@/helpers/form/formHelpersTypes';
import { TextInput } from '@/components';
import FormikDropdown from '@/components/Dropdown/FormikDropdown';
import { mapToHubspotValue } from '@/helpers/form/subjectHelpers';

const FIELD_ID = 'trialSessionSubject';

export const FieldData: FormDataType = {
  hubspotId: 'trial_session_subject',
  id: FIELD_ID,
  type: 'text',
  validationType: 'string',
} as const;

export interface FormSubjectFieldBlokProps extends SbBlokData {
  config?: { content: { options: Array<OptionType> } };
  formikErrors: FormikErrors<{ [FIELD_ID]: string }>;
  hideLabel?: boolean;
  label: string;
  placeholder: string;
  readOnly: boolean;
  touched: FormikTouched<{ [FIELD_ID]: boolean }>;
  type?: 'dropdown' | 'text' | '';
}

interface FormSubjectFieldProps extends FieldProps {
  blok: FormSubjectFieldBlokProps;
}

const FormSubjectField = ({ blok, ...props }: FormSubjectFieldProps) => {
  const {
    label,
    placeholder,
    formikErrors,
    touched,
    readOnly,
    hideLabel = false,
    type = 'dropdown',
    config: { content: { options = [] } = {} } = {},
  } = blok;

  return (
    <div {...storyblokEditable(blok)} className="h-full">
      {FieldData.hubspotId && (
        <>
          <label className="hidden" htmlFor={FieldData.hubspotId}>
            {FieldData.hubspotId}
          </label>
          <input
            className="hidden"
            value={props.field.value}
            name={FieldData.hubspotId}
            readOnly
          />
        </>
      )}
      {type === 'text' && (
        <TextInput
          disabled={readOnly}
          error={
            formikErrors[FIELD_ID] && touched[FIELD_ID]
              ? formikErrors[FIELD_ID]
              : ''
          }
          label={hideLabel ? undefined : label}
          placeholder={placeholder}
          type="text"
          {...props.field}
        />
      )}
      {type === 'dropdown' && (
        <FormikDropdown
          error={
            formikErrors[FIELD_ID] && touched[FIELD_ID]
              ? formikErrors[FIELD_ID]
              : ''
          }
          isMulti={false}
          label={hideLabel ? undefined : label}
          options={options.map(
            ({
              label: subjectLabel,
              value: subjectValue,
            }: {
              label: string;
              value: string;
            }) => ({
              label: subjectLabel,
              value: mapToHubspotValue(subjectValue),
            }),
          )}
          placeholder={placeholder}
          {...props}
        />
      )}
    </div>
  );
};

export default FormSubjectField;
