import { SbBlokKeyDataTypes } from '@storyblok/react';
import React, { createContext, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

type TemplateDataContextProviderProps = {
  data: Record<string, string>;
  children: React.ReactNode;
};

type TemplateDataContextType = {
  updateImageBlok: (id: string, blok: Asset) => Asset;
  getFilledTemplateStringWithFallback: (
    text: string | undefined,
  ) => string | JSX.Element;
  getFilledTemplateString: (text: string | undefined) => string;
  isTemplateEmptyField: (
    component: string | undefined,
    value: SbBlokKeyDataTypes,
  ) => boolean;
};

export const TemplateDataContext =
  createContext<TemplateDataContextType | null>(null);

export default function TemplateDataContextProvider({
  data,
  children,
}: TemplateDataContextProviderProps) {
  const updateImageBlok = (id: string, blok: Asset) => {
    if (data[id]) {
      return { ...blok, filename: data[id] };
    }

    return blok;
  };

  const getParams = (text: string | undefined) => {
    if (!text) {
      return [];
    }

    const mustacheRegex = /{{\s*(.*?)\s*}}/g;
    const foundParams = text.matchAll(mustacheRegex);
    return Array.from(foundParams);
  };

  const fillParams = (
    text: string | undefined,
    removeUnused: boolean = true,
  ): string => {
    if (!text) {
      return '';
    }

    const mustacheRegex = /{{\s*(.*?)\s*}}/g;
    const foundVariables = text.matchAll(mustacheRegex);

    const paramValueMap: Record<string, string> = {};
    let textVariable;

    do {
      textVariable = foundVariables.next();

      if (textVariable && textVariable.value) {
        const [paramNameInMustache, paramName] = textVariable.value;

        const value = data[paramName];

        if (value) {
          paramValueMap[paramNameInMustache] = Array.isArray(value)
            ? value.toString()
            : value;
        } else if (removeUnused) {
          paramValueMap[paramNameInMustache] = '';
        }
      }
    } while (!textVariable.done);

    if (Object.keys(paramValueMap).length) {
      return Object.entries(paramValueMap).reduce((memo, [key, value]) => {
        return memo.replace(key, value);
      }, text);
    }

    return text;
  };

  const isTemplateEmptyField = (
    component: string | undefined,
    value: SbBlokKeyDataTypes,
  ) => {
    if (typeof value !== 'string') {
      return false;
    }

    if (component === 'formPromoCodeField') {
      if (getParams(value).length > 0 && fillParams(value) === '') {
        return true;
      }
    }

    return false;
  };

  const getFilledTemplateString = (text: string | undefined) => {
    const isDataReady = Object.keys(data).length > 0;
    if (isDataReady) {
      return fillParams(text);
    }

    return text || '';
  };

  const getFilledTemplateStringWithFallback = (text: string | undefined) => {
    const isDataReady = Object.keys(data).length > 0;
    if (isDataReady) {
      return getFilledTemplateString(text);
    }

    return <Skeleton containerClassName="flex-1" highlightColor="#3c59bd" />;
  };

  return (
    <TemplateDataContext.Provider
      value={{
        updateImageBlok,
        isTemplateEmptyField,
        getFilledTemplateString,
        getFilledTemplateStringWithFallback,
      }}
    >
      {children}
    </TemplateDataContext.Provider>
  );
}

export function useTemplateDataContext() {
  const context = useContext(TemplateDataContext);

  return (
    context || {
      updateImageBlok: (id, blok) => blok,
      isTemplateEmptyField: () => false,
      getFilledTemplateString: (text) => text || '',
      getFilledTemplateStringWithFallback: (text) => text,
    }
  );
}
