import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import BenefitHeading, {
  BenefitHeadingBlokProps,
} from '../BenefitHeading/BenefitHeading';

export interface BenefitHeadingListBlokProps extends SbBlokData {
  headings: Array<BenefitHeadingBlokProps>;
}

interface BenefitHeadingListProps {
  blok: BenefitHeadingListBlokProps;
  parentLayout: 'imageAndHeading' | 'imageAndHeadingList' | 'headingList';
  headingsLevel: 'h2' | 'h3';
}

const BenefitHeadingList = ({
  blok,
  headingsLevel,
  parentLayout,
}: BenefitHeadingListProps) => {
  const { headings = [] } = blok;

  const getBackgroundColor = (index: number) => {
    switch (index) {
      case 0:
        return 'GSpurple/100';
      case 1:
        return 'GSgreen/100';
      case 2:
        return 'GSyellow/100';
      case 3:
        return 'GSparchment/100';
    }

    return 'none';
  };

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx('h-full grid gap-2 sm:gap-4 lg:gap-8 grow', {
        'grid-flow-row sm:grid-flow-col sm:auto-cols-fr':
          parentLayout === 'headingList',
        'grid-flow-row': parentLayout === 'imageAndHeadingList',
      })}
    >
      {headings.map((nestedBlok, index) => (
        <BenefitHeading
          backgroundColor={getBackgroundColor(index)}
          textAlignment={parentLayout === 'headingList' ? 'center' : 'left'}
          blok={nestedBlok}
          parentLayout={parentLayout}
          key={nestedBlok._uid}
          headingsLevel={headingsLevel}
        />
      ))}
    </div>
  );
};

export default BenefitHeadingList;
