import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { EarningsPriceItemBlokProps } from '../EarningsPriceItem/EarningsPriceItem';

export interface EarningsCalculatorButtonProps {
  blok: EarningsCalculatorButtonBlokProps;
}

export interface EarningsCalculatorButtonBlokProps extends SbBlokData {
  label: string;
  price: Array<EarningsPriceItemBlokProps>;
}

const EarningsCalculatorButton = ({ blok }: EarningsCalculatorButtonProps) => {
  const { label } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="
        transition w-full h-full box-border border w-full rounded border-GSdarkpurple/300 shadow-lg bg-GSbase/white
        p-4 lg:p-6 text-GSdeep/500
        hover:bg-GSblue/100 hover:text-GSblue/500 cursor-pointer
      "
    >
      <div className="font-medium text-sm lg:text-base text-center">
        {label}
      </div>
    </div>
  );
};

export default EarningsCalculatorButton;
