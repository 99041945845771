import React, { ChangeEventHandler } from 'react';
import cx from 'classnames';

export interface CheckboxInputProps {
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  label?: React.ReactNode;
  name?: string;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  isTutorForm?: boolean;
}

const Checkbox = (field: CheckboxInputProps) => {
  const {
    checked,
    defaultChecked,
    disabled,
    label,
    name,
    onChange,
    isTutorForm,
  } = field;

  return (
    <div className="w-full flex gap-2">
      <input
        checked={checked}
        className={cx(
          'peer relative appearance-none w-4 h-4 shrink-0 border-2 border-GSblue/200 rounded-sm mt-0.5 bg-GSbase/white',
          'focus:outline-none focus:ring-offset-0 focus:ring-2 focus:ring-GSblue/100',
          'checked:bg-GSblue/500 checked:border-0',
          'disabled:border-GSsteel/400 disabled:bg-GSsteel/400',
          { 'mt-1': isTutorForm },
        )}
        defaultChecked={defaultChecked}
        disabled={disabled}
        id={name}
        onChange={onChange}
        type="checkbox"
      />
      <svg
        className={cx(
          'absolute w-4 h-4 pointer-events-none peer-checked:stroke-GSbase/white mt-0.5',
          { 'mt-1': isTutorForm },
        )}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3 8.16667L6.75 11.0833L13 4" strokeWidth="2" />
      </svg>
      <label
        htmlFor={name}
        className={cx('cursor-pointer text-sm text-GSdeep/900', {
          'text-GSsteel/500': disabled === true,
          'lg:text-base': isTutorForm,
        })}
      >
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
