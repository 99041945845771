import FormikDropdown from '@/components/Dropdown/FormikDropdown';
import FormikRadioGroup from '@/components/RadioGroup/FormikRadioGroup';
import { FormDataType } from '@/helpers/form/formHelpersTypes';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FieldProps, FormikErrors, FormikTouched } from 'formik';

const FIELD_ID = 'onlineOrRemote';

export const FieldData: FormDataType = {
  hubspotId: 'would_you_consider_online_or_remote_',
  id: FIELD_ID,
  type: 'text',
  validationType: 'string',
} as const;

export interface FormOnlineOrRemoteFieldBlokProps extends SbBlokData {
  label: string;
  placeholder: string;
  readOnly: boolean;
  formikErrors: FormikErrors<{ [FIELD_ID]: string }>;
  touched: FormikTouched<{ [FIELD_ID]: boolean }>;
  type: 'dropdown' | 'radio';
  config?: { content: { options: Array<{ label: string; value: string }> } };
}

interface FormOnlineOrRemoteFieldProps extends FieldProps {
  blok: FormOnlineOrRemoteFieldBlokProps;
}

const FormOnlineOrRemoteField = ({
  blok,
  ...props
}: FormOnlineOrRemoteFieldProps) => {
  const {
    formikErrors,
    label,
    placeholder,
    readOnly,
    type,
    config: { content: { options = [] } = {} } = {},
    touched,
  } = blok;

  const error =
    formikErrors[FIELD_ID] && touched[FIELD_ID] ? formikErrors[FIELD_ID] : '';

  return (
    <div {...storyblokEditable(blok)}>
      {type === 'radio' && (
        <FormikRadioGroup
          hubspotId={FieldData.hubspotId}
          label={label}
          options={options.map(
            ({
              label: levelLabel,
              value: levelValue,
            }: {
              label: string;
              value: string;
            }) => ({
              label: levelLabel,
              value: levelValue,
            }),
          )}
          {...props}
          disabled={readOnly}
        />
      )}
      {type === 'dropdown' && (
        <FormikDropdown
          hubspotId={FieldData.hubspotId}
          label={label}
          placeholder={placeholder}
          options={options.map(
            ({
              label: levelLabel,
              value: levelValue,
            }: {
              label: string;
              value: string;
            }) => ({
              label: levelLabel,
              value: levelValue,
            }),
          )}
          error={error}
          {...props}
          disabled={readOnly}
        />
      )}
    </div>
  );
};

export default FormOnlineOrRemoteField;
