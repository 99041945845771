import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import PageContainer from '@/components/PageContainer/PageContainer';
import SimpleCard, { SimpleCardBlokProps } from '../SimpleCard/SimpleCard';

export interface SimpleCardsSectionBlokProps extends SbBlokData {
  cards: Array<SimpleCardBlokProps>;
  id?: string;
  title: Array<TitleBlokProps>;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

interface SimpleCardsSectionProps {
  blok: SimpleCardsSectionBlokProps;
}

const SimpleCardsSection = ({ blok }: SimpleCardsSectionProps) => {
  const {
    cards,
    id,
    title: [title] = [],
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx({
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      {title && title.layout === 'full' && <TitleBlok blok={title} />}
      <PageContainer>
        <div className="w-full flex flex-col lg:grid lg:grid-cols-12 gap-4 sm:gap-6 lg:gap-10">
          {title && title.layout !== 'full' && (
            <div className={cx('w-full lg:col-span-10 lg:col-start-2')}>
              <TitleBlok blok={title} />
            </div>
          )}
          <div
            className={cx(
              'flex flex-col gap-4',
              'sm:col-span-full sm:grid sm:grid-cols-12 sm:gap-6',
              'lg:col-span-10 lg:col-start-2 lg:gap-10',
            )}
          >
            {cards.map((card) => (
              <div
                className={cx('sm:col-span-4 lg:col-span-4')}
                key={card._uid}
              >
                <SimpleCard blok={card} />
              </div>
            ))}
          </div>
        </div>
      </PageContainer>
    </section>
  );
};

export default SimpleCardsSection;
