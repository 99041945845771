import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import { GtmIdContext } from '../../helpers/contexts';
import HrefData, { HrefDataBlokProps } from '../HrefData/HrefData';
import { Cookiebot, Gtm, Optimizely } from '../../components';
import WebsiteHeader, {
  WebsiteHeaderBlokProps,
} from '../WebsiteHeader/WebsiteHeader';
import WebsiteFooter, {
  WebsiteFooterBlokProps,
} from '../WebsiteFooter/WebsiteFooter';
import WebsiteHead, { WebsiteHeadProps } from './WebsiteHead';

export interface WebsiteConfigProps {
  config?: {
    content: {
      header: Array<WebsiteHeaderBlokProps>;
      footer: Array<WebsiteFooterBlokProps>;
    };
  };
}

export interface WebsitePageWrapperProps
  extends MpcData,
    WebsiteHeadProps,
    WebsiteConfigProps {
  children?: React.ReactNode;
  hrefLinks: Array<HrefDataBlokProps> | null;
  optimizelyId?: string;
  structuredData?: Array<SbBlokData> | null;
  gtmScripts?: 'disabled' | 'enabled' | '';
  cookiebotScripts?: 'disabled' | 'enabled' | '';
  serverSideGtm?: 'disabled' | 'enabled' | '';
}

const WebsitePageWrapper = (props: WebsitePageWrapperProps) => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _mpc,
    children,
    config: {
      content: { header: [header] = [], footer: [footer] = [] } = {},
    } = {},
    hrefLinks = [],
    optimizelyId,
    structuredData = [],
    cookiebotScripts,
    gtmScripts,
    serverSideGtm,
  } = props;

  return (
    <GtmIdContext.Provider value="GTM-N7N7Z9T">
      <WebsiteHead {...props} />
      {hrefLinks &&
        hrefLinks.map((nestedBlock) => (
          <HrefData blok={nestedBlock} key={nestedBlock._uid} />
        ))}
      {structuredData &&
        structuredData.map((data) => (
          <StoryblokComponent blok={data} key={data._uid} />
        ))}
      {optimizelyId && <Optimizely id={optimizelyId} />}
      {gtmScripts !== 'disabled' && (
        <Gtm serverSide={serverSideGtm === 'enabled'} />
      )}
      {cookiebotScripts !== 'disabled' && (
        <Cookiebot language={_mpc.language} />
      )}
      <main className="w-full font-worksans bg-GSbase/white text-GSbase/black">
        {header && <WebsiteHeader blok={header} />}
        {children}
        {footer && <WebsiteFooter blok={footer} />}
      </main>
    </GtmIdContext.Provider>
  );
};

export default WebsitePageWrapper;
