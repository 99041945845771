import { FieldInputProps, FieldProps, FormikProps, FormikValues } from 'formik';
import RadioGroup, { OptionType } from './RadioGroup';

export interface FormikRadioGroupProps extends FieldProps {
  description?: string;
  error?: string;
  field: FieldInputProps<string>;
  form: FormikProps<FormikValues>;
  hubspotId: string;
  label?: string;
  disabled?: boolean;
  options: OptionType[];
}

const FormikRadioGroup = ({ field, form, ...rest }: FormikRadioGroupProps) => {
  const onChange = (value: string) => {
    form.setFieldValue(field.name, value);
  };

  return (
    <RadioGroup
      name={field.name}
      onChange={onChange}
      defaultChecked={field.value}
      {...rest}
    />
  );
};

export default FormikRadioGroup;
