import { StarIcon } from './StarIcon';

const roundFraction = (fraction: number) => {
  if (fraction < 0.25) {
    return 0;
  }

  if (fraction >= 0.25 && fraction < 0.75) {
    return 0.5;
  }

  return 1;
};

const Rating = ({ value }: { value: number }) => {
  const limitedValue = value > 5 ? 5.0 : value;
  const fullStarsCount = Math.floor(limitedValue);
  const fraction = roundFraction(limitedValue - fullStarsCount);

  return (
    <div
      className="flex gap-0.5 sm:gap-1 py-3"
      aria-label={`${limitedValue} / 5`}
      title={`${limitedValue} / 5`}
    >
      {Array.from({ length: fullStarsCount }, (_, index) => index).map(
        (item) => (
          <StarIcon key={item} />
        ),
      )}
      {fraction > 0 && <StarIcon fillPercent={fraction * 100} />}
    </div>
  );
};

export default Rating;
