/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import cx from 'classnames';
import CtaButtonBlok, {
  CtaButtonBlokProps,
} from '@/blocks/CtaButton/CtaButton';
import { CtaLinkBlokProps } from '@/blocks/CtaLink/CtaLink';
import { WebsiteMenuDropdownBlokProps } from '@/blocks/WebsiteMenuDropdown/WebsiteMenuDropdown';
import { WebsiteMenuLinkBlokProps } from '@/blocks/WebsiteMenuLink/WebsiteMenuLink';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import { CloseIcon } from './icons';

interface WebsiteMenuMobileOverlayProps {
  isOpen: boolean;
  closeModal: () => void;
  menuItems: Array<WebsiteMenuLinkBlokProps | WebsiteMenuDropdownBlokProps>;
  actions: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
  label: string;
}

const WebsiteMenuMobileOverlay = ({
  isOpen,
  closeModal,
  menuItems,
  actions: [firstAction, secondAction] = [],
  label,
}: WebsiteMenuMobileOverlayProps) => {
  React.useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
  }, [isOpen]);

  return (
    <nav
      className={cx('relative z-[100] lg:hidden', {
        block: isOpen,
        hidden: !isOpen,
      })}
    >
      <div className="fixed inset-0 bg-black bg-opacity-25" />
      <div className="fixed inset-0 overflow-y-auto font-worksans">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <div className="absolute right-0 top-0 bottom-0 w-full max-w-xl transform overflow-hidden bg-white text-left shadow-xl transition-all overflow-y-auto h-full">
            <div className="flex justify-between items-center py-3 px-4 sm:p-5 sm:pl-6 border-b border-GSparchment/200 sticky z-10 top-0 bg-white">
              <span className="text-base sm:text-lg font-bold text-GSdeep/500">
                {label}
              </span>
              <button
                type="button"
                className="select-none border-none focus:outline-none"
                onClick={closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            {menuItems && menuItems.length !== 0 && (
              <div className="flex flex-col flex-wrap py-4 sm:py-6">
                {menuItems?.map((nestedBlok: SbBlokData) => (
                  <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                ))}
              </div>
            )}
            {(firstAction || secondAction) && (
              <div className="flex flex-col w-full bg-GSparchment/200 px-10 py-6 sm:px-6 sm:py-8 gap-4 border border-GSparchment/400">
                {firstAction && (
                  <CtaButtonBlok
                    blok={firstAction}
                    size="btn-SM"
                    type="textLink"
                  />
                )}
                {secondAction && (
                  <CtaButtonBlok
                    blok={secondAction}
                    size="btn-SM"
                    type="primary"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default WebsiteMenuMobileOverlay;
