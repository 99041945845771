export const ArrowDownIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.5996 12.8L15.9996 22.4L6.39961 12.8"
      stroke="#09268A"
      strokeLinecap="round"
    />
  </svg>
);
