import { PageContainer } from '@/components';
import { getGreenhouseData } from '@/helpers/jobs/greenhouseAPI';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { useEffect, useState } from 'react';
import parse, { DOMNode } from 'html-react-parser';
import CtaButton, { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import SocialMediaLinksBlok, {
  SocialMediaLinksBlokProps,
} from '../SocialMediaLinks/SocialMediaLinks';
import StoryblokImage from '../../components/StoryblokImage/StoryblokImage';

export interface JobDetailsBlokProps extends SbBlokData {
  backLink: Array<CtaButtonBlokProps>;
  socialMediaLinks: Array<SocialMediaLinksBlokProps>;
  badge?: Asset;
}

export interface JobDetailsProps {
  blok: JobDetailsBlokProps;
  title: string;
}

const htmlParserReplacer = (
  domNode: DOMNode,
): JSX.Element | string | null | boolean | object | void => {
  if (domNode.type === 'text') {
    return <span>{domNode.data}</span>;
  }

  return domNode;
};

const JobDetails = ({ blok }: JobDetailsProps) => {
  const {
    badge,
    backLink = [],
    socialMediaLinks: [socialMediaLinks] = [],
  } = blok;
  const [job, setJob] = useState<Job>();
  const [jobDetails, setJobDetails] = useState();

  useEffect(() => {
    if (!document.getElementById('GreenhouseScript')) {
      const greenHouseWrapper = document.getElementById('GreenhouseWrapper');
      if (greenHouseWrapper) {
        const script = document.createElement('script');
        script.setAttribute('id', 'GreenhouseScript');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('async', 'true');
        script.setAttribute(
          'src',
          'https://boards.eu.greenhouse.io/embed/job_board/js?for=gostudent',
        );

        greenHouseWrapper.appendChild(script);
      }
    }
  }, []);

  const fetchGreenhouseData = async (jobId: string) => {
    try {
      const result = await getGreenhouseData('jobs', jobId);
      setJob(result);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('failed to fetch');
      return err;
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { searchParams } = new URL(window.location.href);
      const jobId = searchParams.get('gh_jid');
      if (jobId) {
        fetchGreenhouseData(jobId);
      }
    }
  }, []);

  useEffect(() => {
    if (job && job.content) {
      const unescapedHtmlString = parse(job?.content);
      const result = parse(unescapedHtmlString, {
        replace: htmlParserReplacer,
      });

      setJobDetails(result);
    }
  }, [job]);

  return (
    <div {...storyblokEditable(blok)}>
      <PageContainer className="px-4 py-8 sm:px-6 sm:py-12 lg:px-10 lg:py-16">
        <div className="grid gap-4 sm:gap-6 lg:gap-10 lg:px-28">
          <div className="grid gap-4 sm:gap-6 lg:gap-8">
            {backLink && backLink.length > 0 && (
              <CtaButton
                blok={backLink[0]}
                hasLTicon
                type="textLink"
                testId={`CtaLink-${blok._uid}`}
                className="!justify-start grid"
              />
            )}

            <div className="flex gap-8 sm:justify-between flex-col sm:flex-row">
              <div className="grid gap-2">
                <h2 className="font-raleway text-GSblue/600 font-bold text-[28px]">
                  {job?.title}
                </h2>
                <h2 className="text-GSdeep/900 font-regular text-lg">
                  {job?.location?.name}
                </h2>
              </div>
              {badge && <StoryblokImage blok={badge} className="self-start" />}
            </div>
          </div>
          {job?.content && <div className="job-details">{jobDetails}</div>}
          {socialMediaLinks && (
            <div className="w-full flex pt-8 gap-8 sm:items-end sm:justify-end sm:pt-0">
              <SocialMediaLinksBlok blok={socialMediaLinks} />
            </div>
          )}
          <div id="grnhse_app" />
          <div id="GreenhouseWrapper" />
        </div>
      </PageContainer>
    </div>
  );
};

export default JobDetails;
